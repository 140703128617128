export const EXTERNAL_URL_PATTERN = /^(https?:)?\/\//;

export const isExternalUrl = (url: string): boolean => {
  return EXTERNAL_URL_PATTERN.test(url);
};

export const cleanUrlWithSection = (url: string) => {
  const parsedUrl = new URL(url);

  const sectionValue = parsedUrl.searchParams.get("section");

  parsedUrl.search = "";

  if (sectionValue) {
    parsedUrl.searchParams.set("section", sectionValue);
  }

  return `${parsedUrl.pathname}${parsedUrl.search}`;
};
