/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import { batch } from "react-redux";

import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import CenterSpinner from "../CenterSpinner";

import { generateUuid } from "@/helpers/uuid.helpers";
import { convertToString, convertToTimeFormat } from "@/helpers/audio.helpers";

import "./Waveform.styles.css";

interface WaveFormProps {
  src: string;
  mimetype?: string;
  isPlaying: boolean;
  currentTime: number;
  setIsPlaying: (value: boolean) => void;
  setCurrentTime: (value: number) => void;
  showReferenceLine?: boolean;
}

interface Point {
  name: number;
  amplitude: number;
}

const AMPLITUDE_HEIGHT_MIN = 0.01;
const AMPLITUDE_HEIGHT_COEF = 1;

const UPLOAD_MEDIA_DATA_ERROR = "Failed to process audio source";

const loadAudioFile = async (file: string, audioContext: AudioContext) => {
  const response = await fetch(file, {
    credentials: "omit",
  });

  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const audioData = audioBuffer.getChannelData(0);

  const timeStep = 1 / audioBuffer.sampleRate;
  const duration = audioData.length * timeStep;
  const audioDataArray: any = [];
  const pointsNumber = duration;
  const step = Math.floor(audioData.length / pointsNumber);

  for (let i = 0; i < audioData.length; i += step) {
    const time = i * timeStep;
    const amplitude = Math.abs(audioData[i]);
    audioDataArray.push({
      name: time,
      amplitude: amplitude > AMPLITUDE_HEIGHT_MIN ? amplitude : amplitude * AMPLITUDE_HEIGHT_COEF,
    });
  }
  if (audioDataArray[audioDataArray.length - 1].name !== duration)
    audioDataArray.push({
      name: duration,
      amplitude: Math.abs(audioData[audioData.length - 1]),
    });
  return { audioDataArray, duration };
};

const CustomTooltip = (data: any) => {
  const { active, payload } = data;
  if (active && payload && payload.length) {
    const formattedTime = convertToTimeFormat(payload[0].payload.name);
    return (
      <div className="custom-tooltip">
        <p className="current-time">{`${convertToString(formattedTime)}`}</p>
      </div>
    );
  }
  return null;
};

// eslint-disable-next-line react/display-name
const Waveform = forwardRef(
  (
    {
      src,
      mimetype,
      currentTime,
      isPlaying,
      setIsPlaying,
      setCurrentTime,
      showReferenceLine = false,
    }: WaveFormProps,
    audioRef: ForwardedRef<HTMLAudioElement>,
  ) => {
    const audioContext = new AudioContext();
    const uuid = generateUuid();

    const [waveform, setWaveform] = useState<Point[]>([]);
    const [timePercent, setTimePercent] = useState<number>(0);
    const [durationTime, setDurationTime] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);

    useEffect(() => {
      if (!audioRef || typeof audioRef !== "object" || !audioRef.current) return;

      isPlaying ? audioRef.current.play() : audioRef.current.pause();
    }, [isPlaying]);

    useEffect(() => {
      if (!audioRef || typeof audioRef !== "object" || !audioRef.current) return;

      audioRef.current.addEventListener("ended", () => setIsPlaying(false));

      return () => {
        if (!audioRef.current) return;

        audioRef.current.removeEventListener("ended", () => setIsPlaying(false));
        audioRef.current.pause();
      };
    }, []);

    useEffect(() => {
      currentTime && setTimePercent(Math.min(currentTime / durationTime, 1));
    }, [currentTime]);

    useEffect(() => {
      setIsLoading(true);

      if (!audioRef || typeof audioRef !== "object" || !audioRef.current) return;

      audioRef.current.load();
      loadAudioFile(src, audioContext)
        .then(({ audioDataArray: data, duration }) => {
          batch(() => {
            setWaveform(() => [...data]);
            setDurationTime(duration);
            setHasLoadingError(false);
          });
        })
        .catch((error) => {
          console.log(error);
          setHasLoadingError(true);
        })
        .finally(() => setIsLoading(false));
    }, [src]);

    const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (!audioRef || typeof audioRef !== "object" || !audioRef.current) return;

      const { offsetX } = event.nativeEvent;
      const { width } = event.currentTarget.getBoundingClientRect();
      const percent = offsetX / width;
      const currentTime = durationTime * percent;
      batch(() => {
        setTimePercent(percent);
        setCurrentTime(currentTime);
      });
      audioRef.current.currentTime = currentTime;
    };

    return (
      <div className="waveform flex justify-center items-center gap-2 relative w-full h-full text-body bg-transparent mx-auto">
        <audio ref={audioRef} preload="none">
          <source src={src} type={mimetype} />
        </audio>
        {isLoading && <CenterSpinner />}
        {hasLoadingError && (
          <p className="text-body text-12 text-warning min-h-0 h-3 mt-[2px]">
            {UPLOAD_MEDIA_DATA_ERROR}
          </p>
        )}
        {!!waveform.length && !isLoading && !hasLoadingError && (
          <div
            className="relative w-full max-w-[470px] h-full cursor-pointer"
            onClick={handleOnClick}
          >
            {showReferenceLine && (
              <div
                className="absolute bottom-0 h-full w-[1px] bg-accent-500"
                style={{ left: `${timePercent * 100}%` }}
              ></div>
            )}
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={waveform}
                syncId={`sync-${uuid}`}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id={`splitColor-${uuid}`} x1="0" y1="0" x2="1" y2="0">
                    <stop offset={timePercent} stopColor="#40E8FF" stopOpacity={1} />
                    <stop
                      offset={timePercent}
                      stopColor="rgba(255, 255, 255, 0.05)"
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                <Area
                  type="basis"
                  dataKey="amplitude"
                  stroke={`rgba(255, 255, 255, 0.0)`}
                  fill={`url(#splitColor-${uuid})`}
                  fillOpacity={1}
                  isAnimationActive={false}
                  animationDuration={0}
                />
                <Tooltip
                  cursor={false}
                  content={<CustomTooltip />}
                  position={{ y: 0 }}
                  wrapperStyle={{ outline: "none" }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  },
);

export default Waveform;
