/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import "./AudioPlayer.styles.css";
import { Area, AreaChart, ResponsiveContainer, Tooltip } from "recharts";
import { PlayIcon } from "@/components/UI/Icons";
import AudioCover from "../AudioCover";
import CenterSpinner from "@/components/UI/CenterSpinner";

interface Point {
  name: number;
  amplitude: number;
}

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

const AUDIO_POINTS = 40;
const AMPLITUDE_HEIGHT_MIN = 0.01;
const AMPLITUDE_HEIGHT_COEF = 10;

const TOOLTIP_VERTICAL_OFFSET = -5;
const AREA_CHART_TOP_OFFSET = 20;

const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;

const UPLOAD_MEDIA_DATA_ERROR = "Upload media data error";

const loadAudioFile = async (file: string, audioContext: AudioContext) => {
  const response = await fetch(file, {
    credentials: "omit",
  });
  const arrayBuffer = await response.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const audioData = audioBuffer.getChannelData(0);

  const timeStep = 1 / audioBuffer.sampleRate;
  const audioDataArray: any = [];
  const pointsNumber = AUDIO_POINTS;
  const step = Math.floor(audioData.length / pointsNumber);
  const duration = audioData.length * timeStep;

  for (let i = 0; i < audioData.length; i += step) {
    const time = i * timeStep;
    const amplitude = Math.abs(audioData[i]);
    audioDataArray.push({
      name: time,
      amplitude: amplitude > AMPLITUDE_HEIGHT_MIN ? amplitude : amplitude * AMPLITUDE_HEIGHT_COEF,
    });
  }
  if (audioDataArray[audioDataArray.length - 1].name !== duration)
    audioDataArray.push({
      name: duration,
      amplitude: Math.abs(audioData[audioData.length - 1]),
    });
  return { audioDataArray, duration };
};

const convertToTimeFormat = (currentTime: number): Time => {
  return {
    hours: Math.floor(currentTime / SECONDS_IN_MINUTE / MINUTES_IN_HOUR),
    minutes: Math.floor((currentTime / SECONDS_IN_MINUTE) % MINUTES_IN_HOUR),
    seconds: Math.floor(currentTime % SECONDS_IN_MINUTE),
  };
};

const addZeroIfNecessary = (number: number) => ("0" + number).slice(-2);

const convertToString = (time: Time): string => {
  return `${addZeroIfNecessary(time.hours)}:${addZeroIfNecessary(
    time.minutes,
  )}:${addZeroIfNecessary(time.seconds)}`;
};

const CustomTooltip = (data: any) => {
  const { active, payload } = data;
  if (active && payload && payload.length) {
    const formattedTime = convertToTimeFormat(payload[0].payload.name);
    return (
      <div className="custom-tooltip">
        <p className="current-time">{`${convertToString(formattedTime)}`}</p>
      </div>
    );
  }
  return null;
};

const AudioPlayer = ({
  src,
  postUuid,
  mediaMimetype,
  compact,
  minified,
}: {
  src: string;
  postUuid: string;
  mediaMimetype: string;
  compact?: boolean;
  minified?: boolean;
}) => {
  const audioContext = new AudioContext();

  const [waveForm, setWaveForm] = useState<Point[]>([]);
  const [playerTime, setPlayerTime] = useState<number>(0);
  const [timePercent, setTimePercent] = useState<number>(0);
  const [durationTime, setDurationTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement>(document.createElement("audio"));

  const setProgress = (e: React.MouseEvent): void => {
    const { offsetX } = e.nativeEvent;
    const { width } = e.currentTarget.getBoundingClientRect();
    const percent = offsetX / width;
    setTimePercent(percent);
    audioRef.current.currentTime = durationTime * percent;
  };

  const updateCurrentTime = (event: any): void => {
    const { currentTime } = event.target;
    setPlayerTime(currentTime);
    if (durationTime) setTimePercent(currentTime / durationTime);
  };

  useEffect(() => {
    audioRef.current.load();
    loadAudioFile(src, audioContext)
      .then(({ audioDataArray: data, duration }) => {
        setWaveForm(() => [...data]);
        setDurationTime(duration);
        setHasLoadingError(false);
        setPlayerTime(duration);
      })
      .catch((error) => {
        console.log(error);
        setHasLoadingError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    isPlaying ? audioRef.current.play() : audioRef.current.pause();
  }, [isPlaying]);

  useEffect(() => {
    audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    audioRef.current.addEventListener("timeupdate", updateCurrentTime);

    return () => {
      audioRef.current && audioRef.current.removeEventListener("ended", () => setIsPlaying(false));
      audioRef.current && audioRef.current.removeEventListener("timeupdate", updateCurrentTime);
      audioRef.current && audioRef.current.pause();
    };
  }, [durationTime]);

  return (
    <div className="w-full h-full flex flex-col">
      {!minified && <AudioCover compact={compact} />}
      <div className="audio-player">
        <audio ref={audioRef}>
          <source src={src} type={mediaMimetype} />
        </audio>
        <button
          type="button"
          className={`play-button left-0 ${isPlaying ? "play-button-pause" : ""}`}
          onClick={() => setIsPlaying((prev) => !prev)}
          disabled={hasLoadingError || isLoading}
        >
          {isPlaying ? "" : <PlayIcon className="icon" />}
        </button>
        {isLoading && (
          <div className="w-full pb-4">
            <CenterSpinner className="audio-player-spinner" />
          </div>
        )}
        {hasLoadingError && <p className="audio-player-error">{UPLOAD_MEDIA_DATA_ERROR}</p>}
        {!isLoading && !hasLoadingError && !minified && (
          <div className="progress-bar xl:w-[84%]" onClick={setProgress}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={waveForm}
                syncId={`sync-${postUuid}`}
                margin={{
                  top: AREA_CHART_TOP_OFFSET,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient id={`splitColor-${postUuid}`} x1="0" y1="0" x2="1" y2="0">
                    <stop offset={timePercent} stopColor="#40E8FF" stopOpacity={1} />
                    <stop
                      offset={timePercent}
                      stopColor="rgba(255, 255, 255, 0.05)"
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="amplitude"
                  stroke={`rgba(255, 255, 255, 0.0)`}
                  fill={`url(#splitColor-${postUuid})`}
                  fillOpacity={1}
                  isAnimationActive={false}
                  animationDuration={0}
                />
                <Tooltip
                  cursor={false}
                  content={<CustomTooltip />}
                  position={{ y: TOOLTIP_VERTICAL_OFFSET }}
                  wrapperStyle={{ outline: "none" }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )}
        {!minified && (
          <div className="current-time absolute ">
            {convertToString(convertToTimeFormat(playerTime))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
