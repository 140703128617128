import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { toast } from "@/utils/toast";

export const handleErrors = (errors: FetchBaseQueryError | SerializedError | undefined) => {
  if (Array.isArray((errors as any)?.data?.error)) {
    (errors as any).data.error.forEach((el: any) =>
      toast.error(el.message, {
        position: "top-right",
      }),
    );
  } else if (Array.isArray((errors as any)?.data?.message)) {
    (errors as any).data.message.forEach((el: any) =>
      toast.error(el, {
        position: "top-right",
      }),
    );
  } else if ((errors as any)?.data?.message) {
    toast.error((errors as any)?.data?.message, {
      position: "top-right",
    });
  } else if ((errors as any)?.error) {
    console.error(errors);
    toast.error("Server is not available", {
      position: "top-right",
    });
  }
};
