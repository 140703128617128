import React, { useCallback, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

import { useListMutation } from "@/api/balanceHistoryApi";
import { useAppNavigate } from "@/app/hooks";

import Table from "@/components/UI/Table";
import EmptySection from "@/components/UI/EmptySection";
import { WishlistIcon } from "@/components/UI/Icons";

import { objectKeyToReadableString } from "@/helpers/string.helpers";
import {
  BalanceHistoryType,
  BalanceHistoryTypesEnum,
} from "@/types/balanceHistory/balanceHistory.types";
import { ColumnType } from "@/components/UI/Table/Table.types";

const MediaForCard = ({ cardUserUuid, cardUrl }: { cardUserUuid: string; cardUrl: string }) => {
  const navigate = useAppNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/card/${cardUserUuid}`);
      }}
    >
      <img
        src={cardUrl}
        className="w-24 max-w-[100%] min-h-[50px] mx-auto pt-0 px-0 mt-[14px] mb-2 cursor-pointer"
      />
    </div>
  );
};

const BillingTab = () => {
  const [getHistoryList, { isLoading }] = useListMutation();
  const [balanceHistories, setBalanceHistories] = useState([] as BalanceHistoryType[]);

  const fetchHistory = useCallback(async () => {
    const response = await getHistoryList({ limit: 0, skip: 0 }).unwrap();
    setBalanceHistories(response.balanceHistories);
  }, [getHistoryList]);

  useEffect(() => {
    fetchHistory();
  }, []);

  const columns: ColumnType<BalanceHistoryType>[] = [
    {
      key: "index",
      title: "Athlete",
    },
    { key: null, title: "Type", render: (item) => objectKeyToReadableString(item.type) },
    { key: null, title: "Changes", render: (item) => `${item.changedValue.toFixed(2)} USD` },
    {
      key: null,
      title: "Paid",
      render: (item) =>
        `${item.currency ? `${item.amountInCurrency} ${item.currency.toLocaleUpperCase()} ` : ""} `,
    },
    {
      key: null,
      title: "Date",
      render: (item) => new Date(item.createdAt).toLocaleString(),
    },
    {
      key: null,
      title: "Card",
      render: (item) =>
        item.cardUser ? (
          <MediaForCard
            cardUserUuid={item.cardUser.uuid}
            cardUrl={item.cardUser?.card.image || "img/default-avatar.png"}
          />
        ) : (
          ""
        ),
    },
    {
      key: null,
      title: "Bought For",
      render: (item) =>
        `${
          [BalanceHistoryTypesEnum.proshopBought, BalanceHistoryTypesEnum.marketBought].includes(
            item.type as any,
          )
            ? (Math.abs(item.changedValue) - Math.abs(item.feeValue)).toFixed(2)
            : ""
        } USD`,
    },
  ];

  return (
    <div className="w-full h-full xl:scrollbar-hidden">
      {/* <div className="w-full text-left pt-6 xl:max-w-[1366px] m-auto xl:my-8">
      <div className="flex-col items-start pb-6 my-12 hidden xl:flex">
        <span className="text-white text-caps text-[32px] font-huben">Transaction History</span>
      </div>
      <div className="overflow-x-auto max-h-[70vh] mt-2 rounded-xl">
        <Scrollbars style={{ width: "100%", height: "70vh" }}>
          {balanceHistories.length ? (
            <div className="flex w-full overflow-x-auto xl:scrollbar-hidden">
              <Table data={balanceHistories} columns={columns} />
            </div>
          ) : (
            <EmptySection icon={<WishlistIcon />} title="Your transaction history is empty" />
          )}
        </Scrollbars>
        {isLoading && <progress className="progress progress-error w-full"></progress>}
      </div> */}
      <Table
        header="Transaction History"
        data={balanceHistories}
        columns={columns}
        isLoading={isLoading}
        noRecordsIcon={<WishlistIcon />}
        noRecordsText="Your transaction history is empty"
      />
    </div>
  );
};

export default BillingTab;
