import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";

import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useCheckMembershipMutation, useNewsletterSignUpMutation } from "@/api/playerApi";
import {
  useGetPostByCommentUuidMutation,
  useGetPostByUuidMutation,
  useGetPostsByPlayerUuidMutation,
  useGetPremiumContentMutation,
} from "@/api/fanClubApi";
import { useAddPostMutation, useUpdatePostMutation } from "@/api/fanClubManagerApi";
import { useUploader } from "@/app/hooks/useUploader";

import {
  FeedFilters,
  FeedResponse,
  OrderByEnum,
  OrderSortEnum,
  EmitActions,
  PostType,
  PremiumContent,
  ViewTypes,
  PostTypes,
  PostFormValues,
  CommentType,
} from "@/types/fanClub/fanClub.types";

import {
  selectPosts,
  selectPostCount,
  selectPostLimit,
  selectFilterBy,
  selectOrderBy,
  selectOrderSort,
  selectSearchQuery,
  selectIsMember,
  selectPostUuid,
  selectPremiumContent,
  selectPremiumContentCount,
  selectPremiumContentLimit,
  selectIsPremiumContentAllowed,
  selectIsAthleteChatAllowed,
  selectIsAthleteFeedAllowed,
  selectIsOwnLimitedCards,
  selectCards,
  selectCurrentCard,
  setCurrentCard as setCurrentCardAction,
  setCards as setCardsAction,
  setIsOwnLimitedCards as setIsOwnLimitedCardsAction,
  setPostLimit as setPostLimitAction,
  setPosts as setPostsAction,
  setPostCount as setPostCountAction,
  setPostUuid as setPostUuidAction,
  setCurrentView as setCurrentViewAction,
  setFilterBy as setFilterByAction,
  setOrderBy as setOrderByAction,
  setOrderSort as setOrderSortAction,
  setSearchQuery as setSearchQueryAction,
  setIsMember as setIsMemberAction,
  setIsMuted as setIsMutedAction,
  setIsPremiumContentAllowed as setIsPremiumContentAllowedAction,
  setIsAthleteChatAllowed as setIsAthleteChatAllowedAction,
  setIsAthleteFeedAllowed as setIsAthleteFeedAllowedAction,
  setPremiumContent as setPremiumContentAction,
  setPremiumContentCount as setPremiumContentCountAction,
  setPremiumContentLimit as setPremiumContentLimitAction,
  selectSection,
  selectPremiumContentUuid,
  selectCommentUuid,
  selectCurrentView,
  selectIsMuted,
} from "@/reducers/fanClub.slice";
import { setIsLoading as setIsLoadingAction, setPlayerProfile } from "@/reducers/scopeBlog.slice";
import { FAN_CLUB_POSTS_STEP, FAN_CLUB_WS_URL } from "@/constants/app.constants";
import { PlayerProfileType } from "@/types/player/player.types";
import { WebSocketSharedChannels } from "@/types/app.types";
import { FanClubCard } from "@/types/card/card.types";
import { toast } from "@/utils/toast";
import { io, Socket } from "socket.io-client";
import ScopeChatProvider from "../scopeChat";
import FanClubContext from "./FanClubContext";
import { SCOPE_CHAT_CHANNEL_ID } from "@/constants/scopeChat.constants";
import { useNavigate } from "react-router-dom";
import { cleanUrlWithSection } from "@/helpers/url.helpers";
import MutedModal from "@/components/Modals/MutedModal";
import { TargetTypes } from "@/types/uploader.types";

let feedSocket: Socket;

const FanClubProvider = ({
  children,
  playerProfile,
}: {
  children?: ReactNode | undefined;
  playerProfile: PlayerProfileType;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isAuthorized, authToken, isManager, userProfile } = useProfile();

  const [currentChannelId, setCurrentChannelId] = useState<string>();
  const [isMuteInformModalOpen, setIsMuteInformModalOpen] = useState<boolean>(false);
  const [isReadyToFetchPosts, setIsReadyToFetchPosts] = useState<boolean>(false);
  const [isMembershipChecked, setIsMembershipChecked] = useState<boolean>(false);
  const [isPostsScrolledDown, setIsPostsScrolledDown] = useState<boolean>(false);
  const [isPremiumContentScrolledDown, setIsPremiumContentScrolledDown] = useState<boolean>(false);
  const [isPostsLoading, setIsPostsLoading] = useState<boolean>(true);
  const [isPremiumContentLoading, setIsPremiumContentLoading] = useState<boolean>(true);

  const cards = useAppSelector(selectCards);
  const isOwnLimitedCards = useAppSelector(selectIsOwnLimitedCards);
  const currentCard = useAppSelector(selectCurrentCard);

  const posts = useAppSelector(selectPosts);
  const postCount = useAppSelector(selectPostCount);
  const postLimit = useAppSelector(selectPostLimit);
  const filterBy = useAppSelector(selectFilterBy);
  const orderBy = useAppSelector(selectOrderBy);
  const orderSort = useAppSelector(selectOrderSort);
  const searchQuery = useAppSelector(selectSearchQuery);
  const isMuted = useAppSelector(selectIsMuted);
  const isMember = useAppSelector(selectIsMember);
  const isPremiumContentAllowed = useAppSelector(selectIsPremiumContentAllowed);
  const isAthleteFeedAllowed = useAppSelector(selectIsAthleteFeedAllowed);
  const isAthleteChatAllowed = useAppSelector(selectIsAthleteChatAllowed);
  const currentView = useAppSelector(selectCurrentView);

  const commentUuid = useAppSelector(selectCommentUuid);
  const postUuid = useAppSelector(selectPostUuid);
  const section = useAppSelector(selectSection);
  const premiumContentUuid = useAppSelector(selectPremiumContentUuid);

  const postsRef = useRef<PostType[]>([]);
  const premiumContentRef = useRef<PremiumContent[]>([]);
  const prevPlayerProfileUuidRef = useRef<string>(playerProfile.uuid);

  const premiumContent = useAppSelector(selectPremiumContent);
  const premiumContentCount = useAppSelector(selectPremiumContentCount);
  const premiumContentLimit = useAppSelector(selectPremiumContentLimit);

  // Member
  const [fetchPostByUuid, fetchPostByUuidStatus] = useGetPostByUuidMutation();
  const [fetchPostByCommentUuid, fetchPostByCommentUuidStatus] = useGetPostByCommentUuidMutation();
  const [fetchPostsByPlayerUuid, fetchPostsByPlayerUuidStatus] = useGetPostsByPlayerUuidMutation();
  const [fetchPremiumContent, fetchPremiumContentStatus] = useGetPremiumContentMutation();
  const [fetchMembership] = useCheckMembershipMutation();

  // Manager
  const [addPost] = useAddPostMutation();
  const [updatePost] = useUpdatePostMutation();
  const { uploadFiles, uploadProgress, isUploadInProgress, setIsUploadInProgress } = useUploader();

  const [newsletterSignUp, newsletterSignUpStatus] = useNewsletterSignUpMutation();

  const scrollerContainerRef = useRef<HTMLDivElement>(null);

  const handleNewsletterSignUp = (payload: {
    email: string;
    playerUuid: string;
    fullName?: string;
  }) => {
    if (!payload.email || !payload.playerUuid) {
      toast.error("Email or athlethe UUID undefined");
    }

    newsletterSignUp(payload);
  };

  const submitPost = async (values: PostFormValues) => {
    if (!isManager) return;

    const isEdit = !!values.postUuid;

    setIsUploadInProgress(true);

    const preparedValues: PostFormValues = { ...values };

    if (values.type === PostTypes.POLL) {
      preparedValues.body = JSON.stringify({
        question: values.body,
        answers: (values.answers || []).map((answer: any, index: number) => ({
          text: answer,
          id: index,
          votes: isEdit ? values.votes?.[index] : 0,
        })),
      });
    }

    const payload = {
      body: preparedValues.body,
      type: preparedValues.type,
      attachmentUuids: preparedValues.media
        ? preparedValues.media.map((mediaPayload) => mediaPayload.uuid)
        : [],
    };

    let response;

    if (isEdit) {
      response = await updatePost({
        postUuid: values.postUuid,
        ownerUuid: playerProfile.uuid,
        payload: {
          ...payload,
          isMediaChanged: preparedValues.media.length ? true : false,
          removeAttachmentUuids: preparedValues.removeAttachmentUuids,
        },
      });
    } else {
      response = await addPost({
        ownerUuid: playerProfile.uuid,
        payload,
      });
    }

    if ("error" in response) {
      console.log(response.error);
    } else if ("data" in response && response.data.uuid && preparedValues.media?.length) {
      await uploadFiles(
        preparedValues.media.map((payload) => ({
          targetType: TargetTypes.POST_ATTACHMENT,
          payload,
        })),
      );
    }

    setIsUploadInProgress(false);
  };

  const setCurrentCard = (card: FanClubCard | undefined) => {
    dispatch(setCurrentCardAction(card));
  };

  const setCards = (cards: FanClubCard[]) => {
    dispatch(setCardsAction(cards));
  };

  const setIsOwnLimitedCards = (value: boolean) => {
    dispatch(setIsOwnLimitedCardsAction(value));
  };

  const sendFeedMessage = (type: string, payload: string) => {
    feedSocket && feedSocket.emit(type, payload);
  };

  const setPosts = (posts: PostType[]) => {
    postsRef.current = posts;
    dispatch(setPostsAction(posts));
  };

  const setPostCount = (value: number) => {
    dispatch(setPostCountAction(value));
  };

  const setPostLimit = (value: number) => {
    dispatch(setPostLimitAction(value));
  };

  const setFilterBy = (value: FeedFilters) => {
    dispatch(setFilterByAction(value));
  };

  const setOrderBy = (value: OrderByEnum) => {
    dispatch(setOrderByAction(value));
  };

  const setOrderSort = (value: OrderSortEnum) => {
    dispatch(setOrderSortAction(value));
  };

  const setSearchQuery = (value: string) => {
    dispatch(setSearchQueryAction(value));
  };

  const setPostUuid = (value: string | undefined) => {
    dispatch(setPostUuidAction(value));
  };

  const setCurrentView = (value: ViewTypes) => {
    // TODO: make normal target view for posts, comments and premium content
    if (currentView === ViewTypes.FEED && posts.length < 2) {
      getPostsByPlayerUuid();
      navigate(cleanUrlWithSection(window.location.href));
    }
    dispatch(setCurrentViewAction(value));
  };

  const setIsMember = (value: boolean) => {
    dispatch(setIsMemberAction(value));
  };

  const setIsMuted = (value: boolean) => {
    dispatch(setIsMutedAction(value));
  };

  const setIsPremiumContentAllowed = (value: boolean) => {
    dispatch(setIsPremiumContentAllowedAction(value));
  };

  const setIsAthleteChatAllowed = (value: boolean) => {
    dispatch(setIsAthleteChatAllowedAction(value));
  };

  const setIsAthleteFeedAllowed = (value: boolean) => {
    dispatch(setIsAthleteFeedAllowedAction(value));
  };

  const setIsLoading = (value: boolean) => {
    setTimeout(() => dispatch(setIsLoadingAction(value)), value ? 0 : 500);
  };

  const setPremiumContent = (premiumContent: PremiumContent[]) => {
    premiumContentRef.current = premiumContent;
    dispatch(setPremiumContentAction(premiumContent));
  };

  const setPremiumContentCount = (value: number) => {
    dispatch(setPremiumContentCountAction(value));
  };

  const setPremiumContentLimit = (value: number) => {
    dispatch(setPremiumContentLimitAction(value));
  };

  const removeBlockedUsersComments = (post: PostType, blockedUserUuids: string[]) => {
    const filterRecursive = (comment: CommentType): CommentType | null => {
      const filteredChildComments = comment.childs
        .map(filterRecursive)
        .filter((child) => child !== null) as CommentType[];

      if (blockedUserUuids.includes(comment.owner.uuid)) {
        return null;
      }

      return {
        ...comment,
        childs: filteredChildComments,
      };
    };

    const comments = post.comments
      .map(filterRecursive)
      .filter((comment) => comment !== null) as CommentType[];

    const commentsCount = comments.reduce(
      (count, comment) => count + comment?.childs?.length || 0,
      comments.length,
    );

    return { ...post, comments, commentsCount };
  };

  const subscribeFeed = (playerProfileUuid: string) => {
    if (isAthleteFeedAllowed) {
      feedSocket.emit(WebSocketSharedChannels.JOIN_REQUEST_FEED, { ownerUuid: playerProfileUuid });

      feedSocket.on(`post/${EmitActions.ADD}/${playerProfileUuid}`, (response: FeedResponse) => {
        const post = removeBlockedUsersComments(response.post, userProfile!.blockedUserUuids);

        setPosts([post, ...postsRef.current]);
      });
      feedSocket.on(`post/${EmitActions.REMOVE}/${playerProfileUuid}`, (response: FeedResponse) => {
        const postsAfterFilter = postsRef.current.filter(
          (post: PostType) => post.uuid !== (response?.post?.uuid || response?.postUuid),
        );

        setPosts(postsAfterFilter);
      });
      feedSocket.on(`post/${EmitActions.UPDATE}/${playerProfileUuid}`, (response: FeedResponse) => {
        const updatedPost = removeBlockedUsersComments(
          response.post,
          userProfile!.blockedUserUuids,
        );

        setPosts(
          postsRef.current.map((post: PostType) =>
            post.uuid === updatedPost.uuid ? updatedPost : post,
          ),
        );
      });
    }

    if (isPremiumContentAllowed) {
      feedSocket.emit(WebSocketSharedChannels.JOIN_REQUEST_PREMIUM_CONTENT, {
        ownerUuid: playerProfileUuid,
      });

      feedSocket.on(
        `premium-content/${EmitActions.ADD}/${playerProfileUuid}`,
        (response: PremiumContent) => {
          setPremiumContent([response, ...premiumContentRef.current]);
        },
      );
      feedSocket.on(
        `premium-content/${EmitActions.REMOVE}/${playerProfileUuid}`,
        (response: PremiumContent) => {
          const filteredPremiumContent = premiumContentRef.current.filter(
            (item) => item.uuid !== response.uuid,
          );
          setPremiumContent(filteredPremiumContent);
        },
      );
      feedSocket.on(
        `premium-content/${EmitActions.UPDATE}/${playerProfileUuid}`,
        (response: PremiumContent) => {
          setPremiumContent(
            premiumContentRef.current.map((item: PremiumContent) =>
              item.uuid === response.uuid ? response : item,
            ),
          );
        },
      );
    }
  };

  const unsubscribeFeed = (playerProfileUuid: string) => {
    if (!feedSocket) return;

    feedSocket.off(`post/${EmitActions.ADD}/${playerProfileUuid}`);
    feedSocket.off(`post/${EmitActions.UPDATE}/${playerProfileUuid}`);
    feedSocket.off(`post/${EmitActions.REMOVE}/${playerProfileUuid}`);
    feedSocket.off(`premium-content/${EmitActions.ADD}/${playerProfileUuid}`);
    feedSocket.off(`premium-content/${EmitActions.UPDATE}/${playerProfileUuid}`);
    feedSocket.off(`premium-content/${EmitActions.REMOVE}/${playerProfileUuid}`);
  };

  const handlePostsScroll = () => {
    if (!scrollerContainerRef.current) return;

    setIsPostsScrolledDown(
      scrollerContainerRef.current.scrollTop + scrollerContainerRef.current.clientHeight >=
        scrollerContainerRef.current.scrollHeight - 50,
    );
  };

  const handlePremiumContentScroll = () => {
    if (!scrollerContainerRef.current) return;

    setIsPremiumContentScrolledDown(
      scrollerContainerRef.current.scrollTop + scrollerContainerRef.current.clientHeight >=
        scrollerContainerRef.current.scrollHeight - 50,
    );
  };

  const getPremiumContent = async () => {
    const data = await fetchPremiumContent({
      ownerUuid: playerProfile.uuid,
      skip: 0,
      limit: premiumContentLimit,
    }).unwrap();
    batch(() => {
      setPremiumContent(data.premiumContent);
      setPremiumContentCount(data.total);
      setIsLoading(false);
    });
  };

  const getPostsByPlayerUuid = async () => {
    const data = await fetchPostsByPlayerUuid({
      ownerUuid: playerProfile.uuid,
      skip: 0,
      limit: postLimit,
      filterBy,
      orderBy,
      orderSort,
      searchQuery,
    }).unwrap();
    batch(() => {
      setPosts(data.posts);
      setPostCount(data.postCount);
      setIsLoading(false);
    });
  };

  const getPostByUuid = async () => {
    if (!postUuid) return;

    const data = await fetchPostByUuid({
      postUuid,
      ownerUuid: playerProfile.uuid,
    }).unwrap();

    batch(() => {
      setPosts([data]);
      setPostCount(1);
      setIsLoading(false);
      setIsPostsLoading(false);
    });
  };

  const getPostByCommentUuid = async () => {
    if (!commentUuid) return;

    const data = await fetchPostByCommentUuid({
      commentUuid,
    }).unwrap();

    batch(() => {
      setPosts([data]);
      setPostCount(1);
      setIsLoading(false);
      setIsPostsLoading(false);
    });
  };

  const checkMembership = async (playerUuid: string) => {
    if (isAuthorized) {
      // TODO: Add isManager check
      const response: {
        isMember: boolean;
        isMuted: boolean;
        isPremiumContentAllowed: boolean;
        isAthleteFeedAllowed: boolean;
        isAthleteChatAllowed: boolean;
      } = await fetchMembership({
        playerUuid,
      }).unwrap();

      const {
        isMember,
        isMuted,
        isPremiumContentAllowed,
        isAthleteFeedAllowed,
        isAthleteChatAllowed,
      } = response;
      batch(() => {
        setIsMember(isMember);
        setIsMuted(isMuted || !!userProfile?.isMuted);
        setIsPremiumContentAllowed(isPremiumContentAllowed);
        setIsAthleteChatAllowed(isAthleteChatAllowed);
        setIsAthleteFeedAllowed(isAthleteFeedAllowed);
        setIsReadyToFetchPosts(isMember && isAthleteFeedAllowed);
        setIsMembershipChecked(true);

        if (isMember) {
          batch(() => {
            setCurrentChannelId(playerUuid);
            section && setCurrentView(section);
          });
          isMuted && !userProfile?.isMuted && setIsMuteInformModalOpen(true);
        } else {
          setCurrentView(section || ViewTypes.CARDS);
        }
      });
    } else {
      batch(() => {
        setIsMember(false);
        setIsMuted(false);
        setIsPremiumContentAllowed(false);
        setIsAthleteChatAllowed(false);
        setIsAthleteFeedAllowed(false);
        setIsReadyToFetchPosts(false);
        setIsLoading(false);
        setIsMembershipChecked(true);
        setCurrentChannelId(undefined);
        setCurrentView(ViewTypes.CARDS);
      });
    }
    batch(() => {
      setPostLimit(FAN_CLUB_POSTS_STEP);
      setPosts([]);
      setIsLoading(false);
    });
  };

  const initFanClub = () => {
    batch(() => {
      setCurrentChannelId(undefined);
      setIsPostsLoading(true);
      setIsPremiumContentLoading(false);
      setIsMembershipChecked(false);
      setIsReadyToFetchPosts(false);
      setIsMember(false);
      setIsMuted(true);
      setIsPremiumContentAllowed(false);
      setIsAthleteChatAllowed(false);
      setIsAthleteFeedAllowed(false);
      setIsLoading(true);
      setCards([]);
      setPosts([]);
      setPostLimit(FAN_CLUB_POSTS_STEP);
      setPremiumContent([]);
      setPremiumContentLimit(FAN_CLUB_POSTS_STEP);
    });
  };

  useEffect(() => {
    if (postCount < postLimit || fetchPostsByPlayerUuidStatus.isLoading) {
      return;
    }

    setPostLimit(postLimit + FAN_CLUB_POSTS_STEP);
  }, [isPostsScrolledDown]);

  useEffect(() => {
    if (premiumContentCount < premiumContentLimit || fetchPremiumContentStatus.isLoading) {
      return;
    }

    setPremiumContentLimit(premiumContentLimit + FAN_CLUB_POSTS_STEP);
  }, [isPremiumContentScrolledDown]);

  useEffect(() => {
    feedSocket?.connected && subscribeFeed(playerProfile.uuid);
  }, [feedSocket?.connected, playerProfile.uuid]);

  useEffect(() => {
    if (!isReadyToFetchPosts || !isMembershipChecked || !isAthleteFeedAllowed) return;

    if (commentUuid) {
      getPostByCommentUuid();
    } else if (postUuid) {
      getPostByUuid();
    } else {
      getPostsByPlayerUuid();
    }
  }, [
    isReadyToFetchPosts,
    postLimit,
    filterBy,
    orderBy,
    orderSort,
    searchQuery,
    isMembershipChecked,
  ]);

  useEffect(() => {
    if (!playerProfile.uuid || !isMember || !isPremiumContentAllowed) return;

    getPremiumContent();
  }, [premiumContentLimit, isMember]);

  useEffect(() => {
    initFanClub();

    const ownedUserCards = userProfile?.recentCardsByCardUuid?.[playerProfile.uuid] || {};

    const uniqueCards = { ...playerProfile.tradeCards, ...ownedUserCards };

    const cards = Object.keys(uniqueCards)
      .map((cardUuid) => ({
        ...uniqueCards[cardUuid],
        owned: !!ownedUserCards[cardUuid]?.uuid,
        basePrice:
          uniqueCards?.[cardUuid].card.proshopPrice ||
          ownedUserCards?.[cardUuid].card.proshopPrice ||
          0,
      }))
      .sort((a, b) => b.basePrice - a.basePrice);

    let defaultCard = cards[cards.length - 1];

    if (Object.keys(ownedUserCards).length > 0) {
      defaultCard = cards.find((card) => card.owned) || cards[cards.length - 1];
    }

    const isOwnLimitedCard = !!cards.filter(
      (tradeCard) => tradeCard.owned && tradeCard.card.limitedAccess,
    ).length;

    batch(() => {
      setCards(cards);
      setCurrentCard(defaultCard);
      setIsOwnLimitedCards(isOwnLimitedCard);
    });

    scrollerContainerRef.current &&
      scrollerContainerRef.current.scrollTo({ behavior: "smooth", top: 0 });
    unsubscribeFeed(prevPlayerProfileUuidRef.current);

    if (playerProfile.uuid) {
      prevPlayerProfileUuidRef.current = playerProfile.uuid;
      checkMembership(playerProfile.uuid);
    }
  }, [playerProfile.uuid, isAuthorized]);

  useEffect(() => {
    setIsPremiumContentLoading(fetchPremiumContentStatus.isLoading);
  }, [fetchPremiumContentStatus.isLoading]);

  useEffect(() => {
    setIsPostsLoading(fetchPostsByPlayerUuidStatus.isLoading);
  }, [fetchPostsByPlayerUuidStatus.isLoading]);

  useLayoutEffect(() => {
    if (isAuthorized && isMember) {
      feedSocket = io(FAN_CLUB_WS_URL, {
        extraHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      feedSocket && feedSocket.close();
    }
  }, [isAuthorized, isMember]);

  useLayoutEffect(() => {
    return () => {
      initFanClub();
      unsubscribeFeed(playerProfile.uuid);
      setCurrentChannelId(undefined);
      dispatch(setPlayerProfile(undefined));
      feedSocket && feedSocket.close();
    };
  }, []);

  return (
    <FanClubContext.Provider
      value={{
        posts,
        isPostsLoading,
        isPremiumContentLoading,
        playerProfile,
        isMuted,
        isMember,
        isPremiumContentAllowed,
        isAthleteFeedAllowed,
        isAthleteChatAllowed,
        filterBy,
        setFilterBy,
        orderBy,
        setOrderBy,
        orderSort,
        setOrderSort,
        searchQuery,
        setSearchQuery,
        handlePostsScroll,
        handlePremiumContentScroll,
        sendFeedMessage,
        scrollerContainerRef,
        premiumContent,
        isOwnLimitedCards,
        cards,
        currentCard,
        setCurrentCard,
        setCurrentView,

        // Manager
        submitPost,
        uploadProgress,
        isUploadInProgress,

        // Newsletters
        newsletterSignUpStatus,
        handleNewsletterSignUp,

        commentUuid,
        postUuid,
        premiumContentUuid,
      }}
    >
      {isMuteInformModalOpen && <MutedModal onToggle={() => setIsMuteInformModalOpen(false)} />}
      {isMembershipChecked &&
        (currentChannelId !== SCOPE_CHAT_CHANNEL_ID || currentChannelId === playerProfile.uuid) && (
          <ScopeChatProvider
            channelId={currentChannelId}
            isNotAllowed={!isAthleteChatAllowed}
            isFanClubMuted={isMuted}
          >
            {children}
          </ScopeChatProvider>
        )}
    </FanClubContext.Provider>
  );
};

export default FanClubProvider;
