import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

export const platformSettingsSlice = createSlice({
  name: "platformSettings",
  initialState: {
    backgroundSettings: {
      dynamicBackground: null,
      staticBackground: null,
    },
    maintenanceMode: true,
    isPWA: false,
    isIosMobileApp: false,
    isAndroidMobileApp: false,
  },
  reducers: {
    setDynamicBackground: (state, action) => {
      state.backgroundSettings.dynamicBackground = action.payload;
    },
    setStaticBackground: (state, action) => {
      state.backgroundSettings.staticBackground = action.payload;
    },
    setPlatformSettings: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof typeof state] = action.payload[key];
      });
    },
    setIsPWA: (state, action) => {
      state.isPWA = action.payload;
    },
    setIsIosMobileApp: (state, action) => {
      state.isIosMobileApp = action.payload;
    },
    setIsAndroidMobileApp: (state, action) => {
      state.isAndroidMobileApp = action.payload;
    },
  },
});

export const {
  setDynamicBackground,
  setStaticBackground,
  setPlatformSettings,
  setIsPWA,
  setIsIosMobileApp,
  setIsAndroidMobileApp,
} = platformSettingsSlice.actions;

export const selectDynamicBackground = (state: RootState): string =>
  state.platformSettings.backgroundSettings.dynamicBackground || "";

export const selectStaticBackground = (state: RootState): string =>
  state.platformSettings.backgroundSettings.staticBackground || "";

export const selectMaintenanceMode = (state: RootState): boolean =>
  state.platformSettings.maintenanceMode;

export const selectIsPWA = (state: RootState): boolean => state.platformSettings.isPWA;

export const selectIsIosMobileApp = (state: RootState): boolean =>
  state.platformSettings.isIosMobileApp;

export const selectIsAndroidMobileApp = (state: RootState): boolean =>
  state.platformSettings.isAndroidMobileApp;

export default platformSettingsSlice.reducer;
