import React, { useEffect, useMemo, useState } from "react";
import { useGLTF } from "@react-three/drei";

import * as THREE from "three";
import { drawTextAt } from "@/helpers/canvas.helpers";
import { CARD_MODELS } from "../PreLoader";
import { CardModels } from "@/types/card/card.types";
import Spinner3D from "../Spinner3D";

const DEFAULT_FONT_SIZE = 78;
const DEFAULT_ATHLETE_NAME_WIDTH = 1200;
const DEFAULT_TEXT_WIDTH = 1000;

interface CardModelProps {
  textures: { [type: string]: CanvasImageSource };
  athleteName: string;
  cardGenerationMonth: string;
  cardGenerationYear: string | number;
  color: string;
  textColor?: string;
  [x: string]: any;
}

const CardModel = ({
  textures,
  isBacksideOverriden,
  athleteName,
  cardGenerationMonth,
  cardGenerationYear,
  color,
  textColor = "white",
  ...rest
}: CardModelProps) => {
  let nodes: any = undefined;
  let materials: any = undefined;
  let frontTexture: THREE.Texture | undefined = undefined;
  let backTexture: THREE.Texture | undefined = undefined;

  try {
    const gltf: { nodes: any; materials: any } = useGLTF(
      CARD_MODELS[CardModels.DEFAULT].preloadModel,
    ) as any;
    nodes = gltf.nodes;
    materials = gltf.materials;

    const [backsideImage, setBacksideImage] = useState<HTMLImageElement>(
      document.createElement("img"),
    );

    useEffect(() => {
      prepareBacksideImage();
    }, []);

    const prepareBacksideImage = async () => {
      if ("backside" in textures) {
        setBacksideImage(textures.backside as HTMLImageElement);
      } else {
        const backsideImage = await new Promise<HTMLImageElement>((resolve) => {
          const backImage = new Image();
          backImage.src = "/3d/cards/default/back.png";
          backImage.crossOrigin = "anonymouse";

          backImage.onload = function () {
            resolve(backImage);
          };
        });

        setBacksideImage(backsideImage);
      }
    };

    const frontTextCanvas = useMemo(() => {
      const canvas = document.createElement("canvas");

      canvas.width = (textures.front as HTMLImageElement).naturalWidth;
      canvas.height = (textures.front as HTMLImageElement).naturalHeight;

      const context = canvas.getContext("2d");

      if (!context) return;

      context.drawImage(textures.front, 0, 0, canvas.width, canvas.height);

      return canvas;
    }, []);

    const backsideTextCanvas = useMemo(() => {
      const canvas = document.createElement("canvas");

      if (isBacksideOverriden) {
        canvas.width = backsideImage.naturalWidth;
        canvas.height = backsideImage.naturalHeight;
      } else {
        canvas.width = 1567;
        canvas.height = 2305;
      }

      const context = canvas.getContext("2d");

      if (!context) return;

      context.drawImage(backsideImage, 0, 0, canvas.width, canvas.height);

      if (!isBacksideOverriden) {
        context.font = `${DEFAULT_FONT_SIZE}px Huben`;
        context.fillStyle = textColor;
        context.fillText(athleteName.toUpperCase(), 105, 185, DEFAULT_ATHLETE_NAME_WIDTH);

        context.font = `68px Huben`;

        drawTextAt(context, "DESIGNED IN NYC", DEFAULT_FONT_SIZE, DEFAULT_TEXT_WIDTH, 430, 2160);

        context.font = `70px Huben`;

        context.fillStyle = "transparent";
        context.strokeStyle = textColor;
        context.lineWidth = 2;

        drawTextAt(context, cardGenerationMonth, DEFAULT_FONT_SIZE, DEFAULT_TEXT_WIDTH, 105, 2085);

        drawTextAt(
          context,
          cardGenerationYear.toString(),
          DEFAULT_FONT_SIZE,
          DEFAULT_TEXT_WIDTH,
          105,
          2160,
        );

        context.font = "70px Roobert";
        context.lineWidth = 1;
        context.fillStyle = textColor;
        context.strokeStyle = "transparent";

        drawTextAt(
          context,
          `This Member Card gives you access to ${athleteName}'s Fan Club`,
          DEFAULT_FONT_SIZE,
          DEFAULT_TEXT_WIDTH,
          105,
          375,
        );
      }

      return canvas;
    }, [backsideImage]);

    backTexture = new THREE.Texture(backsideTextCanvas);
    backTexture.needsUpdate = true;
    backTexture.flipY = false;

    frontTexture = new THREE.Texture(frontTextCanvas);
    frontTexture.needsUpdate = true;
    frontTexture.flipY = false;
  } catch (error) {}

  return backTexture && frontTexture && nodes && materials ? (
    <group {...rest} dispose={null}>
      <mesh
        geometry={nodes.front_stone.geometry}
        material={materials.front_stone}
        position={[0, -0.002, -0.057]}
        rotation={[0, 1.571, 0]}
        scale={[0.005, 0.937, 0.622]}
      />
      <mesh
        geometry={nodes.back_plate.geometry}
        material={materials.back_plate}
        position={[0, -0.008, -0.082]}
        rotation={[Math.PI, 0, Math.PI / 2]}
        scale={[-0.641, -0.326, -0.004]}
      >
        <meshStandardMaterial {...materials.back_plate} color={color} />
      </mesh>
      <mesh
        geometry={nodes.athlete_image_placeholder.geometry}
        material={materials.athlete_image_placeholder}
        position={[0.001, 0.027, -0.051]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.795}
      >
        <meshStandardMaterial map={frontTexture} transparent />
      </mesh>
      <mesh
        geometry={nodes.back_texture.geometry}
        position={[-0.007, -0.012, -0.162]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={1.986}
      >
        <meshStandardMaterial map={backTexture} transparent />
      </mesh>
      <mesh
        geometry={nodes.tennis_court.geometry}
        material={materials.tennis_court}
        position={[0, 0, -0.117]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={0.037}
      />
      <mesh
        geometry={nodes.tennis_net.geometry}
        material={materials.tennis_net}
        position={[0, 0, -0.136]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={0.037}
      />
      <mesh
        geometry={nodes.court.geometry}
        material={materials.court}
        position={[0, 0.002, -0.113]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={0.296}
      >
        <meshStandardMaterial {...materials.court} color={color} side={1} />
      </mesh>
      <mesh geometry={nodes.glass.geometry} position={[0, 0, -0.113]} scale={[0.62, 0.93, 0.048]}>
        <meshPhysicalMaterial
          color={color}
          transmission={1}
          roughness={0.316}
          envMapIntensity={4}
          clearcoat={0.03}
          specularIntensity={0.5}
        />
      </mesh>
    </group>
  ) : (
    <Spinner3D />
  );
};

export default CardModel;
