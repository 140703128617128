import React, { useLayoutEffect } from "react";
import { useGeneralUi } from "@/providers/generalUi";
import { Outlet } from "react-router-dom";

const FullScreenLayout = ({
  bluredBackground,
  staticBackground,
  gradientBackground,
}: {
  bluredBackground?: boolean;
  staticBackground?: boolean;
  gradientBackground?: boolean;
}) => {
  const { setHeader, useStaticBackground, useGradientBackground, useBluredBackground } =
    useGeneralUi();

  useLayoutEffect(() => {
    setHeader(null);
    useStaticBackground(!!staticBackground);
    useGradientBackground(!!gradientBackground);
    useBluredBackground(!!bluredBackground);
  }, [staticBackground, gradientBackground, bluredBackground]);

  return (
    <div className="fixed ios-inset-0">
      <Outlet />
    </div>
  );
};

export default FullScreenLayout;
