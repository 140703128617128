import React from "react";

import PublicNavbar from "@/components/Page/PublicNavbar";
import AppLink from "@/components/AppLink";

const CheckEmail = () => {
  return (
    <div className="flex flex-col xl:p-10 h-full">
      <PublicNavbar>
        Already have an account?
        <AppLink to={"/login"} className="underline">
          Sign in
        </AppLink>
      </PublicNavbar>
      <div className="w-full max-w-[600px] text-left px-4 py-6 xl:px-10 m-auto xl:mt-48">
        <div className="container">
          <div className="w-full mx-auto">
            <div className="flex flex-col gap-3">
              <h4 className="xl:hidden text-heading font-medium">Create your Scope account</h4>
              <h2 className="hidden xl:flex text-heading font-medium">Confirm your email</h2>
              <span className="text-body text-16 font-regular opacity-70">
                Thank you for creating an account on Scope. Please confirm your email address to get
                started.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckEmail;
