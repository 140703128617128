import React from "react";

import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";

import "./Table.styles.css";
import { TablePagination } from "./TablePagination";
import Spinner from "../Spinner";
import Container from "../Container";
import { TableProps } from "./Table.types";
import EmptySection from "../EmptySection";

export const Table = <T,>({
  header,
  actions,
  filters,
  data,
  columns,
  isLoading,
  noRecordsIcon,
  noRecordsText = "No records",
  pagination,
  setSkip = () => null,
  setLimit = () => null,
  total = 0,
  rowsPerPage = 0,
  tableClassName,
  rowClassName,
  onRowClick,
  customPaginationComponent,
}: TableProps<T>) => {
  return (
    <div className="flex flex-col gap-4 h-full w-full p-0.5">
      {header && <span className="font-bold font-huben uppercase tracking-widest">{header}</span>}
      {actions && (
        <Container className={`grid md:grid-flow-col auto-rows-max gap-4`}>{actions}</Container>
      )}
      {filters && (
        <div className="flex flex-col xl:flex-row flex-wrap w-full xl:items-start gap-2 md:gap-4">
          {filters}
        </div>
      )}
      <Container className="relative md:flex-1 min-h-[50vh] md:min-h-0 h-full w-full overflow-hidden">
        <div className="absolute inset-0 overflow-auto">
          <table
            className={`table table-auto w-full !bg-transparent ${tableClassName ?? ""} ${
              isLoading || !data.length ? "h-full" : ""
            }`}
          >
            <thead>
              <TableHeader columns={columns} />
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={columns.length} className="text-center !bg-neutral-white-7">
                    <Spinner />
                  </td>
                </tr>
              ) : data.length ? (
                <TableRow
                  data={data}
                  columns={columns}
                  className={rowClassName}
                  onRowClick={onRowClick}
                />
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center !bg-transparent">
                    <EmptySection icon={noRecordsIcon} title={noRecordsText} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Container>
      {pagination && (
        <TablePagination
          setSkip={setSkip}
          setLimit={setLimit}
          total={total}
          rowsPerPage={rowsPerPage}
        />
      )}
      {customPaginationComponent && <Container>{customPaginationComponent}</Container>}
    </div>
  );
};

export default Table;
