/* eslint-disable react/no-unknown-property */
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useGeneralUi } from "@/providers/generalUi";
import { Camera, Canvas, RootState, useFrame } from "@react-three/fiber";
import { CardModels, Card, Texture } from "@/types/card/card.types";
import { OrbitControls, OrbitControlsProps, PerformanceMonitor } from "@react-three/drei";
import * as THREE from "three";
import { OrbitControls as OrbitControlsImpl } from "three-stdlib";
import { MONTHS } from "@/constants/app.constants";
import Spinner3D from "./Spinner3D";
import { CARD_MODELS } from "./PreLoader";
import CenterSpinner from "../UI/CenterSpinner";

const DEFAULT_VIEW = {
  target: { x: 0, y: 0, z: 0 } as THREE.Vector3,
  cameraPosition: { x: 0, y: 0, z: 4.5 } as THREE.Vector3,
};

const LERP_KOEF = 0.08;
const USER_INACTIVE_TIMEOUT = 2500;
const RESET_TIMEOUT = 2000;
const TARGET_AUTOROTATION_SPEED = 4;
const DEFAULT_AUTOROTATION_SPEED = 500;
const REVERSE_ROTATION = -1;

const CardWrapper = ({
  card,
  color,
  textures,
  model,
  fullscreen = false,
  isActive = true,
  isBacksideOverriden = false,
}: {
  card: Card;
  color: string;
  textures: { [type: string]: CanvasImageSource };
  model: CardModels;
  fullscreen?: boolean;
  isActive?: boolean;
  isBacksideOverriden?: boolean;
}) => {
  const [isRenderNeeded, setIsRenderNeeded] = useState<boolean>(true);
  const [isUserActive, setIsUserActive] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const { isMobile } = useGeneralUi();

  const controlsRef = useRef<any>();

  const cardCreatedAt = new Date(card.createdAt);

  const cardGenerationMonth = MONTHS[cardCreatedAt.getMonth()];
  const cardGenerationYear = cardCreatedAt.getFullYear();

  const CardModel = CARD_MODELS[model].component;

  useEffect(() => {
    setIsRenderNeeded(isActive);
  }, [isActive]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleUserActivity = () => {
    if (!fullscreen && isMobile) return;

    clearTimeout(timer);
    setIsUserActive(true);
  };

  const handleUserInactive = () => {
    if (!fullscreen && isMobile) return;

    clearTimeout(timer);

    const _timer: NodeJS.Timeout = setTimeout(() => {
      setIsUserActive(false);
      setIsReset(true);
      setTimeout(() => setIsReset(false), RESET_TIMEOUT);
    }, USER_INACTIVE_TIMEOUT);

    setTimer(_timer);
  };

  const resetControls = (controls: OrbitControlsImpl, camera: Camera) => {
    controls.target.lerp(DEFAULT_VIEW.target, LERP_KOEF);
    camera.position.lerp(DEFAULT_VIEW.cameraPosition, LERP_KOEF);
    controls.update();
  };

  useFrame((state: RootState, delta: number) => {
    if (!isRenderNeeded) return null;

    const controls = state.controls as OrbitControlsImpl;
    const camera = state.camera;

    if (!controls || !camera) {
      return;
    }

    if (isUserActive) {
      (controls as OrbitControlsProps).autoRotate = false;
    } else {
      controls.autoRotate = true;
      controls.autoRotateSpeed =
        REVERSE_ROTATION * Math.max(DEFAULT_AUTOROTATION_SPEED * delta, TARGET_AUTOROTATION_SPEED);
      isReset && resetControls(controls, camera);
    }
  });

  return CardModel ? (
    <>
      <CardModel
        textures={textures}
        isBacksideOverriden={isBacksideOverriden}
        athleteName={card.cardName}
        cardGenerationYear={cardGenerationYear}
        cardGenerationMonth={cardGenerationMonth}
        color={color}
      />
      <OrbitControls
        ref={controlsRef as any}
        enabled={isRenderNeeded}
        makeDefault
        autoRotate
        autoRotateSpeed={REVERSE_ROTATION * TARGET_AUTOROTATION_SPEED}
        enableZoom={fullscreen}
        enablePan={fullscreen}
        enableRotate={fullscreen || !isMobile}
        onStart={handleUserActivity}
        onEnd={handleUserInactive}
      />
    </>
  ) : null;
};

const Card3D = ({
  card,
  color,
  fullscreen = false,
  isActive = true,
}: {
  card: Card;
  color: string;
  fullscreen?: boolean;
  isActive?: boolean;
}) => {
  const { isMobile } = useGeneralUi();
  const [dpr, setDpr] = useState<number>(1.5);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [textures, setTextures] = useState<{ [type: string]: CanvasImageSource }>();

  const isBacksideOverriden =
    card.model === CardModels.DEFAULT &&
    card.textures.findIndex((texture) => texture.type === "backside") >= 0;

  const prepareImages = async () => {
    try {
      const { textures } = card;
      setIsLoading(true);

      const loadTexturePromises = textures.map((texture: Texture) => {
        return new Promise<void>((resolve, reject) => {
          const { image, type, thumbnails } = texture;

          const canvasImage = new Image();
          canvasImage.crossOrigin = "anonymous";
          canvasImage.src =
            fullscreen || isBacksideOverriden
              ? image
              : isMobile
              ? thumbnails?.sm || image
              : thumbnails?.md || image;

          canvasImage.onload = function () {
            setTextures((prev) => ({ ...prev, [type]: canvasImage }));
            resolve();
          };

          canvasImage.onerror = function () {
            reject(new Error(`Failed to load image at ${image}`));
          };
        });
      });

      await Promise.all(loadTexturePromises);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTextures(undefined);
    prepareImages();
  }, [card.textures]);

  return !isLoading && textures && Object.keys(CARD_MODELS).includes(card.model) ? (
    <Canvas
      camera={{ position: [0, 0, 4.5], fov: 35 }}
      frameloop="demand"
      dpr={dpr}
      gl={{ preserveDrawingBuffer: true, antialias: false }}
    >
      <PerformanceMonitor onIncline={() => setDpr(2)} onDecline={() => setDpr(1)}>
        <Suspense fallback={<Spinner3D />}>
          <ambientLight intensity={1} />
          <pointLight position={[-10, -10, -10]} />
          <CardWrapper
            card={card}
            textures={textures}
            color={color}
            model={card.model}
            fullscreen={fullscreen}
            isActive={isActive}
            isBacksideOverriden={isBacksideOverriden}
          />
        </Suspense>
      </PerformanceMonitor>
    </Canvas>
  ) : (
    <CenterSpinner />
  );
};

export default Card3D;
