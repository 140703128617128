import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import GeneralUiProvider from "./providers/generalUi";
import { initWeb3Modal } from "./app/hooks/useWeb3Wallet";
import { initSentry } from "./utils/sentry";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

initSentry();
initWeb3Modal();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <GeneralUiProvider>
        <App />
      </GeneralUiProvider>
    </Router>
  </Provider>,
  // </React.StrictMode>
);
