export enum ReportOrigins {
  SCOPE_CHAT = "scope_chat",
  FAN_CLUB_CHAT = "fan_club_chat",
  FAN_CLUB_COMMENT = "fan_club_comment",
}

export interface ReportRequest {
  contentUuid: string;
  description: string;
  hideContent: boolean;
  origin: ReportOrigins;
  originUuid?: string;
}

export interface ReportResponse {
  message: string;
}
