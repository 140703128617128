import { createContext, useContext } from "react";

import { ReportOrigins } from "@/types/reports.types";

interface ReportContextType {
  handleReportContent: (contentUuid: string, origin: ReportOrigins, originUuid?: string) => void;
}

const ReportContext = createContext<ReportContextType | undefined>(undefined);

export const useReport = (): ReportContextType => {
  const context = useContext(ReportContext);

  if (!context) {
    throw new Error("useReport must be used within a ReportProvider");
  }

  return context;
};

export default ReportContext;
