import { ButtonTypes } from "./app.types";

export enum AppGatewayEvents {
  REDIRECT = "redirect",
  SHOW_SYSTEM_MODAL = "show_system_modal",
  UPDATE_PLATFORM_SETTINGS = "update_platform_settings",
  UPDATE_PROFILE = "update_profile",
}

export enum AppModalTypes {
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

export enum AppModalActions {
  NONE = "none",
  REDIRECT = "redirect",
  UPDATE_PROFILE = "update_profile",
}

export interface AppModalPayload {
  type: AppModalTypes;
  title: string;
  message: string;
  action?: AppModalAction;
}

export interface AppModalAction {
  type: AppModalActions;
  payload?: {
    url?: string;
    type?: ButtonTypes;
    caption?: string;
  };
  additionalActions?: Omit<AppModalAction, "additionalActions">[];
}
