import React from "react";

import Spinner from "@/components/UI/Spinner";
import { Html } from "@react-three/drei";

const Spinner3D = () => {
  return (
    <Html>
      <div className="flex h-full w-full justify-center items-center -translate-x-1/2 -translate-y-1/2">
        <Spinner />
      </div>
    </Html>
  );
};

export default Spinner3D;
