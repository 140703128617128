import React from "react";

import Avatar from "@/components/User/Avatar";
import { IThumbnails } from "@/types/app.types";

const SearchOwnerSuggestions = ({
  displayValue,
  presignedUrl,
  thumbnails,
}: {
  displayValue: string;
  presignedUrl: string;
  thumbnails: IThumbnails;
}) => {
  return (
    <>
      <Avatar url={(thumbnails?.md ?? presignedUrl) || ""} />
      <span className="text-body text-14 font-medium text-white">{displayValue}</span>
    </>
  );
};

export default SearchOwnerSuggestions;
