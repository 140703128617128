import React from "react";

import Container from "@/components/UI/Container";
import { ScopeIcon } from "@/components/UI/Icons";
import AppLink from "@/components/AppLink";

const NotFound = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container className="p-6 xl:max-w-[50%]">
        <div className="w-full text-center text-white">
          <h1 className="text-[88px] xl:text-[120px] leading-[104px] xl:leading-[128px] font-roobert flex items-center justify-center">
            4<ScopeIcon className="!w-[66px] !h-[66px] xl:!w-24 xl:!h-24" />4
          </h1>
          <h1 className="mt-3">Sorry, the page you were looking for was not found</h1>
          <AppLink to={"/"} className="btn btn-primary rounded-full btn-block my-10">
            Return Home
          </AppLink>
          <div className="flex items-center justify-center">
            <AppLink to={"/support"} className="link text-secondary !text-sm uppercase">
              Get Some Help
            </AppLink>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
