import React, { useEffect, useRef } from "react";

import { ArrowIcon, InboxIcon, NotificationBellIcon } from "@/components/UI/Icons";
import NotificationItem from "../NotificationItem";
import Container from "@/components/UI/Container";
import AppLink from "@/components/AppLink";

import EmptySection from "@/components/UI/EmptySection";
import { objectKeyToReadableString } from "@/helpers/string.helpers";
import {
  GroupedNotificationsType,
  NotificationType,
} from "@/types/notification/notification.types";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useNotifications } from "@/app/hooks/useNotifications";
import { useAppDispatch, useAppSelector, useClickOutside } from "@/app/hooks";
import {
  selectIsNotificationModalOpen,
  setIsNotificationModalOpen,
  setUnreadedCount,
} from "@/reducers/notifications.slice";

import "./NotificationModal.styles.css";

const NotificationList = () => {
  const dispatch = useAppDispatch();

  const { markAllAsRead, notifications } = useNotifications();

  useEffect(() => {
    dispatch(setUnreadedCount(0));

    return () => {
      markAllAsRead();
    };
  }, []);

  return (
    <div className="absolute top-0 -right-2 translate-y-12 w-[540px] h-[578px]">
      <Container className="flex flex-col">
        <div className="w-full p-5">
          <Scrollbars style={{ width: "100%", height: "400px" }}>
            <div className="flex flex-col items-start w-full gap-2">
              {notifications ? (
                Object.keys(notifications)
                  .filter((key) => notifications[key as keyof GroupedNotificationsType].length)
                  .map((key: string) => (
                    <React.Fragment key={key}>
                      <span className="text-caps text-13 font-medium text-neutral-white my-2">
                        {objectKeyToReadableString(key)}
                      </span>
                      {notifications[key as keyof GroupedNotificationsType]
                        .slice(0, 5)
                        .map((notification: NotificationType, index: number) => (
                          <NotificationItem key={index} notification={notification} />
                        ))}
                    </React.Fragment>
                  ))
              ) : (
                <EmptySection
                  icon={<InboxIcon />}
                  title="No Activity"
                  text="You have no activity at the moment, but we can totally change that."
                />
              )}
            </div>
          </Scrollbars>
        </div>
        <div className="card-actions w-full">
          <AppLink
            className="flex w-full items-center justify-center gap-2 p-5 border-top"
            to={"/notifications"}
          >
            <span className="text-button text-12 font-bold">Open Full View</span>
            <ArrowIcon />
          </AppLink>
        </div>
      </Container>
    </div>
  );
};

const NotificationButton = () => {
  const dispatch = useAppDispatch();
  const isNotificationModalOpen = useAppSelector(selectIsNotificationModalOpen);

  const notificationModalRef = useRef(null);

  useClickOutside(
    notificationModalRef,
    () => isNotificationModalOpen && dispatch(setIsNotificationModalOpen(false)),
  );

  const { unreadedCount } = useNotifications();

  return (
    <div ref={notificationModalRef} className="relative select-none">
      <div className="indicator">
        {unreadedCount > 0 && (
          <span className="indicator-item top-1 right-1 badge badge-xs badge-primary notification-indicator"></span>
        )}
        <div className="grid place-items-center">
          <button
            className="btn btn-circle btn-ghost w-10 h-10 min-h-[40px]"
            onClick={() => dispatch(setIsNotificationModalOpen(!isNotificationModalOpen))}
          >
            <NotificationBellIcon stroke="#848484" fill="#848484" />
          </button>
        </div>
      </div>
      {isNotificationModalOpen && <NotificationList />}
    </div>
  );
};

export default NotificationButton;
