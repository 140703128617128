import React, { useState } from "react";
import { useProfile } from "@/providers/profile";
import { useFanClub } from "@/providers/fanClub";

import Button from "@/components/UI/Button";
import Input from "@/components/UI/Input";

const NewsletterSignUp = ({
  playerName,
  playerUuid,
}: {
  playerName: string;
  playerUuid: string;
}) => {
  const { userProfile } = useProfile();
  const {
    handleNewsletterSignUp,
    newsletterSignUpStatus: { isSuccess, isLoading },
  } = useFanClub();

  const [email, setEmail] = useState<string>(userProfile?.email || "");

  const handleSignUp = () => {
    handleNewsletterSignUp({ email, playerUuid, fullName: userProfile?.fullName });
  };

  return (
    <div className="flex flex-col gap-4 mobile:w-full w-[40%]">
      {isSuccess ? (
        <span>Thanks for signing up!</span>
      ) : (
        <>
          <span>
            Enter your email below to <strong>sign up</strong> for{" "}
            <strong>{playerName}&apos;s launch</strong>
          </span>
          <Input
            containerClassName="h-10 focus:border-white backdrop-blur-md"
            placeholder="Your email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            readOnly={!!userProfile?.email}
          />
          <Button
            className={`btn btn-primary ${isLoading ? "loading" : ""}`}
            onClick={handleSignUp}
          >
            Sign up
          </Button>
        </>
      )}
    </div>
  );
};

export default NewsletterSignUp;
