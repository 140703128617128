import React from "react";

import ProfileAvatar from "@/components/User/ProfileAvatar";

import { useProfile } from "@/providers/profile";

const MobileProfiles = () => {
  const { userProfile, managedPlayerProfile } = useProfile();

  return userProfile ? (
    managedPlayerProfile ? (
      <div className="card card-side user-card p-2 items-center">
        <div className="flex flex-row gap-3 justify-center ">
          <ProfileAvatar profile={managedPlayerProfile} />
          <div className="flex flex-col">
            <span className="text-start text-body text-14 font-medium">
              {managedPlayerProfile.name}
            </span>
            <span className="text-start text-body text-14 font-regular opacity-50">
              Athlete Profile
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex flex-row gap-3 justify-center ">
        <ProfileAvatar profile={userProfile} />
        <div className="flex flex-col">
          <span className="text-start text-body text-14 font-medium">{userProfile.fullName}</span>
          <span className="text-start text-body text-14 font-regular opacity-50">
            @{userProfile.username}
          </span>
        </div>
      </div>
    )
  ) : null;
};

export default MobileProfiles;
