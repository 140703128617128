export function saveToLocalStorage(name: string, state: any) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem(name, serializedState);
}

export function loadFromLocalStorage(name: string) {
  const serializedState = localStorage.getItem(name);
  if (serializedState === null) return undefined;
  try {
    return JSON.parse(serializedState);
  } catch (error) {
    return;
  }
}
