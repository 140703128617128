import React from "react";
import { useFanClub } from "@/providers/fanClub";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

import CardView from "../../Common/CardView";
import EmptySection from "@/components/UI/EmptySection";
import Placeholder from "../../Common/Placeholder";
import PostCard from "./PostCard";

import { selectPosts, setPostAutoplayUuid } from "@/reducers/fanClub.slice";
import { ViewTypes } from "@/types/fanClub/fanClub.types";
import CenterSpinner from "@/components/UI/CenterSpinner";

const FeedCard = () => {
  const dispatch = useAppDispatch();
  const { isMember, isAthleteFeedAllowed, isPostsLoading, setCurrentView } = useFanClub();

  const posts = useAppSelector(selectPosts);
  const recentPost = posts.find((post) => !post.isDraft);

  return (
    <CardView
      title="Athlete Feed"
      handleMaximize={() => setCurrentView(ViewTypes.FEED)}
      disabled={!isMember || !isAthleteFeedAllowed}
    >
      {isMember && isAthleteFeedAllowed ? (
        <div
          className="flex flex-col justify-center w-full h-full overflow-hidden"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setCurrentView(ViewTypes.FEED);
            dispatch(setPostAutoplayUuid(recentPost?.uuid));
          }}
        >
          {isPostsLoading ? (
            <CenterSpinner />
          ) : recentPost ? (
            <PostCard post={recentPost} />
          ) : (
            <EmptySection title="No posts yet!" text="Come later." containerClassName="!p-0" />
          )}
        </div>
      ) : (
        <Placeholder text="Access athlete generated content" />
      )}
    </CardView>
  );
};

export default FeedCard;
