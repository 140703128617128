import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";

import {
  setSearchByPlayerNameQuery as setSearchByPlayerNameQueryAction,
  setProshopPlayerProfiles as setProshopPlayerProfilesAction,
  selectSearchByPlayerNameQuery,
  selectProshopPlayerProfiles,
} from "@/reducers/proshop.slice";
import {} from "@/reducers/scopeBlog.slice";
import { selectPlayerProfiles } from "@/reducers/scopeBlog.slice";
import { PlayerProfileType } from "@/types/player/player.types";

interface ProshopContextType {
  proshopPlayerProfiles: PlayerProfileType[];
  searchByPlayerNameQuery: string;
  setSearchByPlayerNameQuery: (searchQuery: string) => void;
}

const ProshopContext = createContext<ProshopContextType | undefined>(undefined);

export function useProshop(): ProshopContextType {
  const context = useContext(ProshopContext);

  if (!context) {
    throw new Error("useProshop must be used within a ProshopProvider");
  }

  return context;
}

const ProshopProvider = ({ children }: { children?: ReactNode | undefined }) => {
  const dispatch = useAppDispatch();

  const searchByPlayerNameQuery = useAppSelector(selectSearchByPlayerNameQuery);
  const proshopPlayerProfiles = useAppSelector(selectProshopPlayerProfiles);
  const playerProfiles = useAppSelector(selectPlayerProfiles);

  const setSearchByPlayerNameQuery = (searchQuery: string) => {
    dispatch(setSearchByPlayerNameQueryAction(searchQuery));
  };

  const setProshopPlayerProfiles = (playerProfiles: PlayerProfileType[]) => {
    dispatch(setProshopPlayerProfilesAction(playerProfiles));
  };

  useEffect(() => {
    setProshopPlayerProfiles(
      playerProfiles.filter((playerProfile) =>
        playerProfile.name.toLowerCase().includes(searchByPlayerNameQuery.toLowerCase()),
      ),
    );
  }, [searchByPlayerNameQuery, playerProfiles]);

  return (
    <ProshopContext.Provider
      value={{
        proshopPlayerProfiles,
        searchByPlayerNameQuery,
        setSearchByPlayerNameQuery,
      }}
    >
      {children}
    </ProshopContext.Provider>
  );
};

export default ProshopProvider;
