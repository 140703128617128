import React from "react";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useExternalWallet } from "@/providers/externalWallet";
import { useGeneralUi } from "@/providers/generalUi";

import { CaretIcon } from "@/components/UI/Icons";
import MobilePlayerSelector from "@/components/Page/Mobile/MobilePlayerSelector";
import MobileProfiles from "./MobileProfiles";
import AppLink from "@/components/AppLink";

import {
  selectIsMobileProfileMenuOpen,
  setIsMobileMenuOpen,
  setIsMobileProfileMenuOpen,
} from "@/reducers/ui.slice";
import { ICON_ITEM, getProfileMenuItems } from "@/constants/app.constants";
import { IProfileMenuItem } from "@/types/app.types";

const MobileProfileMenu = () => {
  const dispatch = useAppDispatch();

  const { managedPlayerProfiles } = useProfile();
  const { isConnected } = useExternalWallet();
  const { isMobileApp } = useGeneralUi();

  const isMobileProfileMenuOpen = useAppSelector(selectIsMobileProfileMenuOpen) || isMobileApp;

  return (
    <div
      className={`flex-1 h-full w-full flex flex-col p-0 ${
        isMobileProfileMenuOpen ? "justify-start" : "justify-end"
      }`}
    >
      <div
        className={`flex w-full px-4 py-3 justify-between items-center hover:bg-neutral-white-5 ${
          isMobileApp ? "border-bottom" : ""
        }`}
        onClick={() => dispatch(setIsMobileProfileMenuOpen(!isMobileProfileMenuOpen))}
      >
        <MobileProfiles />
        {!isMobileApp && <CaretIcon className={isMobileProfileMenuOpen ? "" : "rotate-180"} />}
      </div>
      {isMobileProfileMenuOpen && (
        <div className="flex-1 flex flex-col w-full h-full">
          {managedPlayerProfiles.length ? <MobilePlayerSelector /> : null}
          {getProfileMenuItems({ isWalletConnected: isConnected, isMobile: true, isMobileApp }).map(
            (item: IProfileMenuItem, index) =>
              item.type === ICON_ITEM &&
              !item.hidden && (
                <div
                  key={`${item.title}-${index}`}
                  className={`border-bottom text-caps text-13 font-heavy text-white mobile-navbar-item py-3 px-4 hover:bg-neutral-white-5 ${
                    `${location.pathname}${location.search}` === item.path
                      ? "bg-primary-500 hover:!bg-primary-500"
                      : ""
                  }`}
                >
                  <AppLink
                    to={item.path || "/"}
                    onClick={() => {
                      dispatch(setIsMobileMenuOpen(false));
                      item.action && item.action();
                    }}
                  >
                    <div className="flex items-center gap-4">
                      {item.Icon && <item.Icon stroke="white" />}
                      {item.title}
                    </div>
                  </AppLink>
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default MobileProfileMenu;
