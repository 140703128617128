import React, { PropsWithChildren, ReactNode, useEffect } from "react";
import { useAppNavigate } from "@/app/hooks";
import { useProfile } from "../profile";
import { useGeneralUi } from "../generalUi";
import { useFullscreenModal } from "../fullscreenModal";

import InfoModal from "@/components/UI/InfoModal";
import {
  CreepyFaceIcon,
  CheckInACircleIcon,
  ScopeIcon,
  GradientScopeIcon,
} from "@/components/UI/Icons";

import AppGatewayContext from "./AppGatewayContext";
import { Socket, io } from "socket.io-client";
import { APP_GATEWAY_WS_URL } from "@/constants/app.constants";
import {
  AppModalActions,
  AppGatewayEvents,
  AppModalTypes,
  AppModalAction,
} from "@/types/app-gateway.types";
import { Button, WebSocketSharedChannels } from "@/types/app.types";
import { toast } from "@/utils/toast";
import InstallAppModal from "@/components/Modals/InstallAppModal";

let appGatewaySocket: Socket;

const AppGatewayProvider = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();

  const { authToken, isAuthorized, isInstallAppInformed, getUserProfile } = useProfile();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { getPlatformSettings, isMobile } = useGeneralUi();

  useEffect(() => {
    if (isMobile) return;

    !isInstallAppInformed && setModal(<InstallAppModal />);
    setIsModalOpen(!isInstallAppInformed);
  }, [isInstallAppInformed, isMobile]);

  useEffect(() => {
    if (!isAuthorized && appGatewaySocket) {
      appGatewaySocket.close();
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (!authToken) return;

    appGatewaySocket = io(APP_GATEWAY_WS_URL, {
      extraHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    appGatewaySocket.emit(WebSocketSharedChannels.JOIN_REQUEST, {});
    subscribeEvents();

    return () => {
      unsubscribeEvents();
    };
  }, [authToken]);

  useEffect(() => {
    const handleNativeError = (event: CustomEvent) => {
      console.log(event);
      if (!event || !event.detail) return;
      toast.error(event.detail, { position: "top-center" });
    };

    window.addEventListener("native-error", handleNativeError);

    subscribeEvents();

    return () => {
      window.removeEventListener("native-error", handleNativeError);
      unsubscribeEvents();
    };
  }, []);

  const parseAction = (action: AppModalAction) => {
    switch (action.type) {
      case AppModalActions.REDIRECT:
        return () => {
          navigate(action.payload?.url || "/");
          handleCloseModal();
        };
      case AppModalActions.UPDATE_PROFILE:
        return () => getUserProfile(true);
      default:
        return () => null;
    }
  };

  const showInstallAppModal = () => {
    setModal(<InstallAppModal onToggle={handleCloseModal} />);
    setIsModalOpen(true);
  };

  const handleAppAction = async (action: AppModalAction) => {
    const actionFunctions = [parseAction(action)];

    if (action.additionalActions?.length) {
      action.additionalActions.forEach((action) => actionFunctions.push(parseAction(action)));
    }

    actionFunctions.forEach((actionFunction) => {
      actionFunction();
    });
  };

  const subscribeEvents = () => {
    if (!appGatewaySocket) return;

    // BROADCAST EVENTS
    appGatewaySocket.on(AppGatewayEvents.UPDATE_PLATFORM_SETTINGS, () => {
      getPlatformSettings();
    });

    // Personal user events
    appGatewaySocket.on(
      AppGatewayEvents.REDIRECT,
      ({ url, timeout, state }: { url: string; timeout?: number; state?: Record<string, any> }) => {
        if (timeout) {
          setTimeout(() => navigate(url, { state }), timeout);
        } else {
          navigate(url, { state });
        }
      },
    );

    appGatewaySocket.on(
      AppGatewayEvents.SHOW_SYSTEM_MODAL,
      ({
        type,
        title,
        message,
        actions,
      }: {
        type: AppModalTypes;
        title: string;
        message: string;
        actions?: AppModalAction[];
      }) => {
        let icon: ReactNode;

        switch (type) {
          case AppModalTypes.SUCCESS:
            icon = <CheckInACircleIcon />;
            break;
          case AppModalTypes.ERROR:
            icon = <CreepyFaceIcon />;
            break;
          case AppModalTypes.INFO:
            icon = <ScopeIcon />;
            break;
          case AppModalTypes.WARNING:
            icon = <GradientScopeIcon />;
            break;
          default:
            icon = <CheckInACircleIcon />;
        }

        const buttons: Button[] = [];

        if (actions?.length) {
          const actionButtons: Button[] = actions.map(
            (action) =>
              ({
                onClick: () => handleAppAction(action),
                caption: action.payload?.caption,
                type: action.payload?.type,
              }) as Button,
          );
          buttons.push(...actionButtons);
        } else {
          buttons.push({
            onClick: () => setIsModalOpen(false),
            caption: "Close",
          });
        }

        setModal(
          <InfoModal
            icon={icon}
            title={title}
            body={message}
            onToggle={handleCloseModal}
            buttons={buttons}
          />,
        );
        setIsModalOpen(true);
      },
    );
  };

  const unsubscribeEvents = () => {
    if (!appGatewaySocket) return;

    appGatewaySocket.off(AppGatewayEvents.UPDATE_PLATFORM_SETTINGS);
    appGatewaySocket.off(AppGatewayEvents.REDIRECT);
    appGatewaySocket.off(AppGatewayEvents.SHOW_SYSTEM_MODAL);
  };

  return (
    <AppGatewayContext.Provider value={{ showInstallAppModal }}>
      {children}
    </AppGatewayContext.Provider>
  );
};

export default AppGatewayProvider;
