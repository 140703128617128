import React, { HTMLAttributes } from "react";

import { useGeneralUi } from "@/providers/generalUi";
import { useFanClub } from "@/providers/fanClub";

import Button from "@/components/UI/Button";
import Container from "@/components/UI/Container";
import { MaximizeCardViewIcon } from "@/components/UI/Icons";

interface ExtendedProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  titleClassName?: string;
  containerClassName?: string;
  bodyClassName?: string;
  handleMaximize?: (event: React.MouseEvent) => void;
  disabled?: boolean;
}

const CardView = ({
  children,
  title,
  titleClassName,
  containerClassName,
  bodyClassName,
  handleMaximize,
  disabled,
}: ExtendedProps) => {
  const { isMember } = useFanClub();
  const { isMobile } = useGeneralUi();

  return (
    <Container
      className={`flex flex-col w-full overflow-hidden select-none !gap-0 xl:max-h-[33%] xl:h-[33.33%] ${
        containerClassName ?? ""
      } ${isMobile ? "min-h-[300px]" : ""}`}
    >
      {title && (
        <div className="flex w-full items-center justify-between p-[1.5vh] border-bottom">
          <span className={`text-body text-16 xl:text-[2vh] font-bold ${titleClassName ?? ""}`}>
            {title}
          </span>
          <Button
            className="btn btn-ghost btn-square btn-sm rounded-md disabled:text-neutral-white-12 disabled:bg-transparent xl:min-h-[2vh] xl:h-[3vh] xl:w-[3vh]"
            onClick={handleMaximize}
            disabled={disabled ?? !isMember}
          >
            <MaximizeCardViewIcon className="xl:h-[3vh] xl:w-[3vh]" />
          </Button>
        </div>
      )}
      <div
        className={`flex w-full h-full items-center justify-center overflow-hidden ${
          bodyClassName ?? ""
        }`}
      >
        {children}
      </div>
    </Container>
  );
};

export default CardView;
