import React, { useRef, useState } from "react";

import { useClickOutside } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";
import { useProfile } from "@/providers/profile";
import { EmojiData, useEmojiPicker } from "@/providers/EmojiPickerProvider";
import { useMobileBottomMenu } from "@/providers/MobileBottomMenuProvider";

import ProfileAvatar from "@/components/User/ProfileAvatar";
import EditCommentModal from "./EditCommentModal";
import { ReactionIcon, ThreeDotsIcon } from "@/components/UI/Icons";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";
import CommentMenu from "./CommentMenu";

import { fromNow } from "@/utils/dateFormatter";
import { useHandleCommentReactionMutation } from "@/api/fanClubApi";
import { useHandleCommentReactionMutation as useHandleCommentManagerReactionMutation } from "@/api/fanClubManagerApi";
import {
  CommentType,
  CommentVoteType,
  HandleCommentReactionRequest,
} from "@/types/fanClub/fanClub.types";

import { UserProfileType, UserRoles } from "@/types/user/profile.types";
import { isUserProfileType } from "@/helpers/profile.helpers";

import "./Comment.styles.css";
import { useFanClub } from "@/providers/fanClub";

const VISIBLE_REACTIONS_LIMIT = 5;

const CommentItem = ({
  comment,
  editable,
  postUuid,
  postOwnerUuid,
  replies,
}: {
  comment: CommentType;
  postUuid: string;
  postOwnerUuid: string;
  editable: boolean;
  replies?: boolean;
}) => {
  const { isMobile } = useGeneralUi();
  const { userProfile, managedPlayerProfile, isManager } = useProfile();
  const { setOnEmojiSelect, handleOpenEmojiPicker } = useEmojiPicker();
  const { setIsMobileBottomMenuOpen, setMobileBottomMenu } = useMobileBottomMenu();
  const { commentUuid, isMuted } = useFanClub();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [isCommentMenuOpen, setIsCommentMenuOpen] = useState(false);

  const commentMenuRef = useRef<HTMLDivElement>(document.createElement("div"));

  useClickOutside(commentMenuRef, () => setIsCommentMenuOpen(false));

  const [handleReaction, handleReactionStatus] = isManager
    ? useHandleCommentManagerReactionMutation()
    : useHandleCommentReactionMutation();

  const isCanBlockUser =
    isUserProfileType(comment.owner) &&
    comment.owner.role !== UserRoles.ADMIN &&
    comment.owner.uuid !== userProfile?.uuid;

  const isOwnComment = Boolean(
    (userProfile?.uuid && comment.owner.uuid === userProfile.uuid) ||
      (managedPlayerProfile?.uuid && comment.owner.uuid === managedPlayerProfile.uuid),
  );

  const isMenuEnabled = (editable || isCanBlockUser || isOwnComment) && !isMuted;

  const handleOpenMobileMenu = () => {
    if (!isMobile || !isMenuEnabled || isMuted) return;

    setMobileBottomMenu({
      items: (
        <div className="flex w-full">
          <CommentMenu
            comment={comment}
            editable={editable || isOwnComment}
            isCanBlockUser={isCanBlockUser}
            postOwnerUuid={postOwnerUuid}
            setIsEdit={setIsEditModalOpen}
            handleCloseMenu={() => setIsMobileBottomMenuOpen(false)}
          />
        </div>
      ),
      onCloseMenu: () => setIsMobileBottomMenuOpen(false),
    });
    setIsMobileBottomMenuOpen(true);
  };

  const handleOnEmojiSelect = () => (emoji: EmojiData) => {
    handleOnEmojiClicked(emoji.native);
  };

  const handleOnEmojiClicked = (emojiNative: string) => {
    if (handleReactionStatus.isLoading || !userProfile || isMuted) return;

    const reaction: HandleCommentReactionRequest = {
      commentUuid: comment.uuid,
      content: emojiNative,
    };

    if (managedPlayerProfile) {
      reaction.ownerUuid = managedPlayerProfile.uuid;
    }

    handleReaction(reaction);
  };

  const handleOnEmojiPickerClicked = (event: React.MouseEvent) => {
    if (isMuted) return;

    setOnEmojiSelect(handleOnEmojiSelect);
    handleOpenEmojiPicker(event);
  };

  const renderReactions = () => {
    const reactions = comment.votes || [];

    if (!reactions) return;

    const reactionsWithCount: any = reactions.reduce(
      (occurrences: any, reaction: CommentVoteType) => {
        return (
          occurrences[reaction.content]
            ? ++occurrences[reaction.content]
            : (occurrences[reaction.content] = 1),
          occurrences
        );
      },
      {},
    );

    return (
      <>
        {Object.keys(reactionsWithCount)
          .sort((a, b) => reactionsWithCount[a] - reactionsWithCount[b])
          .slice(0, VISIBLE_REACTIONS_LIMIT)
          .map((key) => (
            <button
              key={key}
              className="btn btn-secondary btn-sm !px-2 !pr-4 m-0 flex items-center"
              onClick={() => handleOnEmojiClicked(key)}
            >
              {key}
              <span className={`btn-reaction`}>{reactionsWithCount[key] || ""}</span>
            </button>
          ))}
      </>
    );
  };

  return (
    <div className="flex flex-col w-full">
      {isEditModalOpen ? (
        <EditCommentModal
          onToggle={() => setIsEditModalOpen(false)}
          comment={comment}
          postUuid={postUuid}
          postOwnerUuid={postOwnerUuid}
        />
      ) : (
        <>
          <div
            className={`card card-side py-2 pr-2 mt-0 overflow-visible w-full comment-container ${
              commentUuid === comment.uuid ? "bg-primary/10" : ""
            }`}
          >
            {isMenuEnabled && (
              <div className="absolute dropdown-bottom dropdown-end top-0 right-0">
                <button
                  className="p-4 rounded-full"
                  onClick={() => (isMobile ? handleOpenMobileMenu() : setIsCommentMenuOpen(true))}
                >
                  <ThreeDotsIcon />
                </button>
                {!isMobile && isCommentMenuOpen && (
                  <div ref={commentMenuRef}>
                    <CommentMenu
                      comment={comment}
                      editable={editable || isOwnComment}
                      isCanBlockUser={isCanBlockUser}
                      postOwnerUuid={postOwnerUuid}
                      setIsEdit={setIsEditModalOpen}
                      handleCloseMenu={() => setIsCommentMenuOpen(false)}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="card-body p-0 w-full gap-1">
              <div className="flex items-center">
                <ProfileAvatar
                  profile={
                    comment.user ||
                    (!comment.user || userEmptyObject(comment.user) ? comment.owner : undefined)
                  }
                  containerClass="mr-2 block"
                  imageClass="w-8 !h-8 xl:w-12 xl:!h-12"
                />
                <div className="flex flex-col text-start items-start gap-0">
                  <span className="text-body font-medium text-14 xl:text-16">
                    {comment.user && userNotEmptyObject(comment.user)
                      ? `${comment.user.fullName}`
                      : comment.owner?.name}
                  </span>
                  <span className="text-body font-regular text-12 opacity-50">
                    {fromNow(comment.createdAt)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-fit ml-10 xl:ml-14">
                <span className="text-body font-regular text-16 text-start">{comment.content}</span>
                <div className="card-actions items-center min-h-[48px]">
                  {renderReactions()}
                  {!isMuted && (
                    <button
                      className="btn btn-circle !btn-sm btn-ghost p-0 m-0"
                      onClick={handleOnEmojiPickerClicked}
                      disabled={isMuted}
                    >
                      <ReactionIcon />
                    </button>
                  )}
                  {!replies && (
                    <>
                      {!isMuted && (
                        <a
                          className="link link-hover text-body font-medium text-14 opacity-50"
                          onClick={() => setShowReplyForm(true)}
                        >
                          Reply
                        </a>
                      )}
                      {comment?.childs?.length ? (
                        <>
                          <span className="text-body font-regular text-12 opacity-50">|</span>
                          <a
                            className="link link-hover text-body font-regular text-12 opacity-50"
                            onClick={() => setShowReplies(true)}
                          >
                            {`${comment.childs.length} Replies`}
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                      {!isMobile && showReplyForm && !isMuted && (
                        <div className="flex w-full">
                          <CommentForm
                            postUuid={postUuid}
                            postOwnerUuid={postOwnerUuid}
                            parentUuid={comment.uuid}
                            cancelHandler={() => setShowReplyForm(false)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {isMobile && showReplyForm && !isMuted && (
                <div className="flex w-full">
                  <CommentForm
                    postUuid={postUuid}
                    postOwnerUuid={postOwnerUuid}
                    parentUuid={comment.uuid}
                    cancelHandler={() => setShowReplyForm(false)}
                  />
                </div>
              )}
            </div>
          </div>
          {showReplies && (
            <div className="flex flex-col pl-14 w-full">
              <CommentList
                comments={comment.childs || []}
                editable={editable}
                postUuid={postUuid}
                postOwnerUuid={postOwnerUuid}
                replies
              />
              <div className="w-1/2 mb-4 flex">
                <a
                  className="link link-hover px-4 text-caps text-13 font-medium underline"
                  onClick={() => setShowReplies(false)}
                >
                  Hide Replies
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

function userNotEmptyObject(user: UserProfileType) {
  return user.uuid;
}

function userEmptyObject(user: UserProfileType) {
  return !user.uuid;
}

export default CommentItem;
