import React from "react";

import { useLocation } from "react-router-dom";
import { useProfile } from "@/providers/profile";
import { useAppNavigate } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";

import ProfileMenu from "./ProfileMenu";
import NotificationButton from "../NotificationCenter/NotificationModal";
import Container from "../UI/Container";
import AppLink from "../AppLink";

import { ProfileTypes } from "@/types/profile-types.enum";
import { INavbarLink } from "@/types/app.types";
import { getNavbarMenuItems } from "@/constants/app.constants";
import { isPathsInLocation } from "@/helpers/history.helpers";

const Navbar = () => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const { isAuthorized, managedPlayerProfile } = useProfile();
  const { isMobileApp } = useGeneralUi();

  const currentProfileType = managedPlayerProfile
    ? ProfileTypes.PLAYER_PROFILE_TYPE
    : ProfileTypes.USER_PROFILE_TYPE;

  const linkClickHandler = (link: INavbarLink) => {
    if (!isAuthorized && !link.public) {
      navigate("/sign-up");
      return;
    }
  };

  const menuItems = getNavbarMenuItems({ isMobileApp }).filter(
    (link) =>
      !link.isHidden &&
      ((isAuthorized && link.profileTypes.includes(currentProfileType)) ||
        (!isAuthorized && link.public)),
  );

  return (
    <div className="sticky flex justify-between items-center h-28 p-8 z-[60]" data-testid="navbar">
      <div className="w-[20%]">
        <AppLink to="/">
          <img className="h-10" src="/img/logos/scope_logo.png" />
        </AppLink>
      </div>
      {menuItems.length > 1 && (
        <Container className="py-[6px] px-2 text-neutral-white-pure-white">
          {menuItems.map((link: INavbarLink) => (
            <AppLink
              data-testid={link.testId}
              to={link.path}
              key={link.title}
              onClick={() => linkClickHandler(link)}
              className={`relative flex items-center px-4 py-1 rounded-full hover:bg-neutral-white-7
                  ${
                    isPathsInLocation(link.activePaths, location.pathname)
                      ? "bg-primary-500 hover:!bg-primary-500"
                      : ""
                  } ${!link.isActive ? "pointer-events-none" : ""}`}
            >
              <span
                className={`text-caps text-13 font-heavy ${
                  !link.isActive ? "text-neutral-white-50" : ""
                }`}
              >
                {link.title.toUpperCase()}
              </span>

              {link.badge && (
                <div className="absolute top-0 -right-2 -translate-y-[80%] bg-primary-500 rounded-full px-[5px] text-caps text-10 font-bold text-neutral-white-pure-white">
                  {link.badge}
                </div>
              )}
            </AppLink>
          ))}
        </Container>
      )}
      <div className="flex items-center justify-end w-[20%] gap-5">
        {isAuthorized ? (
          <>
            <NotificationButton />
            <ProfileMenu />
          </>
        ) : (
          <AppLink
            to="/login"
            className="btn btn-primary btn-sm rounded-full"
            data-testid="login-link"
          >
            Sign in
          </AppLink>
        )}
      </div>
    </div>
  );
};

export default Navbar;
