import React from "react";

import { useGeneralUi } from "@/providers/generalUi";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useBlockUser } from "@/providers/blockUser";

import ConfirmModal from "@/components/UI/ConfirmModal";
import Button from "@/components/UI/Button";
import {
  AlertIcon,
  BlockUserIcon,
  ExclamationIcon,
  MuteUserIcon,
  PenIcon,
  TrashIcon,
} from "@/components/UI/Icons";

import { CommentType } from "@/types/fanClub/fanClub.types";
import { useReport } from "@/providers/report";
import { ReportOrigins } from "@/types/reports.types";
import { useRemoveCommentMutation } from "@/api/fanClubApi";
import { useProfile } from "@/providers/profile";

const CommentMenu = ({
  comment,
  editable,
  isCanBlockUser,
  postOwnerUuid,
  setIsEdit,
  handleCloseMenu,
}: {
  comment: CommentType;
  editable: boolean;
  isCanBlockUser: boolean;
  postOwnerUuid: string;
  setIsEdit: (value: boolean) => void;
  handleCloseMenu: () => void;
}) => {
  const { isAdmin, isManager: isFanClubManager, managedPlayerProfile } = useProfile();
  const { isMobile } = useGeneralUi();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { handleBlockUser, isHandlingBlockUser, handleMuteUser, isHandlingMuteUser } =
    useBlockUser();
  const { handleReportContent } = useReport();

  const [removeComment] = useRemoveCommentMutation();

  const isManager = isFanClubManager && postOwnerUuid === managedPlayerProfile?.uuid;

  const isCanEditMessages = isAdmin || isManager || editable;

  const handleDeleteComment = () => {
    setModal(
      <ConfirmModal
        onToggle={handleCloseModal}
        title="Do you want to delete this comment?"
        body="Are you sure you want to delete this comment? This cannot be undone."
        onConfirm={() => {
          removeComment({ commentUuid: comment.uuid, ownerUuid: postOwnerUuid });
          handleCloseModal();
        }}
        confirmCaption="Delete"
        icon={<AlertIcon />}
      />,
    );
    setIsModalOpen(true);
  };

  const handleAction = (action: (args?: any) => void) => {
    action();
    isMobile && handleCloseMenu();
  };

  return (
    <div
      data-testid="chat-item-menu"
      className="absolute join join-vertical dropdown-content menu mobile:w-full mobile:left-0 mobile:bottom-0 flex flex-col xl:flex-row justify-between items-start xl:items-center gap-2 xl:gap-0 p-3 xl:p-2 xl:rounded-lg bg-grayscale-1000 xl:border-[1.5px] border-neutral-white-12 z-50"
    >
      {isCanEditMessages && (
        <>
          <Button
            className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start w-full"
            onClick={() => handleAction(() => setIsEdit(true))}
          >
            <PenIcon stroke="currentColor" fill="currentColor" />
            <span className="text-body text-14 font-regular">Edit</span>
          </Button>
          <Button
            className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start w-full"
            onClick={() => handleAction(() => handleDeleteComment())}
          >
            <TrashIcon stroke="currentColor" />
            <span className="text-body text-14 font-regular">Delete comment</span>
          </Button>
        </>
      )}
      {!isAdmin && isCanBlockUser && (
        <>
          <Button
            className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start w-full"
            disabled={isHandlingBlockUser}
            onClick={() => handleAction(() => handleBlockUser(comment.owner.uuid))}
          >
            <BlockUserIcon />
            <span className="text-body text-14 font-regular">Block User</span>
          </Button>
          <Button
            className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start w-full"
            onClick={() =>
              handleAction(() =>
                handleReportContent(comment.uuid, ReportOrigins.FAN_CLUB_COMMENT, postOwnerUuid),
              )
            }
          >
            <ExclamationIcon />
            <span className="text-body text-14 font-regular">Report Content</span>
          </Button>
        </>
      )}
      {isAdmin && isCanBlockUser && (
        <Button
          className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start w-full"
          disabled={isHandlingMuteUser}
          onClick={() => handleMuteUser(comment.owner.uuid)}
        >
          <MuteUserIcon />
          <span className="text-body text-14 font-regular">Mute User</span>
        </Button>
      )}
    </div>
  );
};

export default CommentMenu;
