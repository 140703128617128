import React, { PropsWithChildren } from "react";

import Container from "./UI/Container";
import { QRCode } from "react-qrcode-logo";

import { FRONTEND_TWA_URL } from "@/constants/app.constants";

const InstallPWAInfo = ({ children }: PropsWithChildren) => {
  return (
    <Container className="p-6 w-full flex flex-col gap-8">
      <div className="flex flex-col">
        <span className="font-huben text-lg tracking-wider uppercase">
          Scope is currently available on your phone.
        </span>
        <span className="text-body">
          Scan this QR code with your phone to open the page on your mobile browser:
        </span>
      </div>
      <div className="flex rounded-xl bg-[#2F3035] overflow-hidden p-1">
        <QRCode
          value={`${FRONTEND_TWA_URL}install`}
          bgColor="#2F3035"
          fgColor="#ff4c34"
          eyeRadius={5}
          logoImage="icons/icon-flat.png"
          logoWidth={40}
          logoPadding={2}
          qrStyle="dots"
          removeQrCodeBehindLogo
        />
      </div>
      <div className="flex flex-col">
        <span className="text-body">
          *If you use iOS device, make sure to open link in Safari browser.
        </span>
      </div>
      {children}
    </Container>
  );
};

export default InstallPWAInfo;
