import React, { useRef, useState } from "react";

import { batch } from "react-redux";
import { useScopeChat } from "@/providers/scopeChat";
import { useProfile } from "@/providers/profile";
import useLongPress from "@/app/hooks/useLongPress";
import { useGeneralUi } from "@/providers/generalUi";
import { useMobileBottomMenu } from "@/providers/MobileBottomMenuProvider";

import MessageItemMenu from "./MessageItemMenu";
import UpdateMessageForm from "../Inputs/UpdateMessageForm";
import { ReplyIcon } from "@/components/UI/Icons";
import AudioPlayer from "./AudioPlayer";
import MediaGridWrapper from "../../Common/MediaGridWrapper";

import {
  ScopeChatMessage,
  ScopeChatMessageTypes,
  ScopeChatUserTypes,
} from "@/types/scopeChat/scopeChat.types";
import { formatTime } from "@/helpers/date.helpers";
import { snakeCaseToReadableString } from "@/helpers/string.helpers";
import {
  SCOPE_CHAT_LIKE_REACTION,
  SCOPE_CHAT_USER_TYPE_COLORS,
} from "@/constants/scopeChat.constants";
import { DEFAULT_PROFILE_URL } from "@/constants/app.constants";

const MessageItem = ({
  message,
  date,
  threadView,
  searchView,
  pinnedView,
  handleClickMessage,
  cardView,
  ...props
}: {
  message: ScopeChatMessage;
  date: string;
  threadView?: boolean;
  searchView?: boolean;
  pinnedView?: boolean;
  cardView?: boolean;
  handleClickMessage?: (message: ScopeChatMessage) => void;
  [x: string]: any;
}) => {
  const { isAuthorized } = useProfile();
  const { handleMessageReaction, handleShowThread, isMuted } = useScopeChat();
  const { isMobile } = useGeneralUi();
  const { setIsMobileBottomMenuOpen, setMobileBottomMenu } = useMobileBottomMenu();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(document.createElement("div"));

  const messageCreatedAt = formatTime(message.createdAt);

  const isSidebarView = pinnedView || searchView || threadView;

  const handleCloseMenu = () => {
    batch(() => {
      setIsMobileBottomMenuOpen(false);
      setIsMobileMenuOpen(false);
    });
  };

  const { ...handlers } = useLongPress({
    onLongPress: () => {
      if (!isMobile || isEdit || searchView || !isAuthorized || isMuted) return;

      setMobileBottomMenu({
        items: (
          <div className="flex w-full">
            <MessageItemMenu
              message={message}
              setIsEdit={setIsEdit}
              handleCloseMenu={handleCloseMenu}
              threadView={threadView}
              handleShowMessage={searchView || pinnedView ? handleClickMessage : undefined}
            />
          </div>
        ),
        onCloseMenu: handleCloseMenu,
      });
      batch(() => {
        setIsMobileBottomMenuOpen(true);
        setIsMobileMenuOpen(true);
      });
    },
  });

  const renderReactions = () => {
    const { reactions, likes } = message;

    if (!reactions && !likes) return;

    return (
      <div className="flex" data-testid="message-reactions">
        {!!likes.count && (
          <button
            data-testid="like-reaction"
            className="btn btn-sm btn-ghost"
            key={SCOPE_CHAT_LIKE_REACTION}
            onClick={() => handleMessageReaction(message.id, SCOPE_CHAT_LIKE_REACTION)}
          >
            {`${SCOPE_CHAT_LIKE_REACTION} ${likes.count}`}
          </button>
        )}
        {reactions.map((reaction) => (
          <button
            data-testid="emoji-reaction"
            className="btn btn-sm btn-ghost"
            key={reaction.content}
            onClick={() => handleMessageReaction(message.id, reaction.content)}
          >
            {`${reaction.content} ${reaction.count}`}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div
      data-testid="chat-message-item"
      className={`flex w-full bg-transparent rounded-none overflow-visible px-4 xl:px-6 p-2.5 gap-4 xl:hover:bg-neutral-white-5 transition-colors delay-200
        ${isSidebarView ? "!rounded-lg xl:px-2" : ""} 
        ${searchView ? "cursor-pointer w-full overflow-x-scroll scrollbar-hidden" : ""} 
        ${message.isPinned && !pinnedView ? "bg-yellow-10 hover:!bg-yellow-20" : ""} 
        ${isMobile && (isMobileMenuOpen ? "!bg-neutral-white-5" : "")}
      `}
      onMouseEnter={() => !isMobile && !isMuted && setIsMenuOpen(true)}
      onMouseLeave={() => !isMobile && setIsMenuOpen(false)}
      onClick={() => !isMobile && handleClickMessage && handleClickMessage(message)}
      {...handlers}
      id={message.id}
      style={
        isMobile
          ? {
              WebkitTouchCallout: "none",
              WebkitUserSelect: "none",
              KhtmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }
          : {}
      }
      {...props}
    >
      <div className="avatar">
        <div className="w-8 h-8 rounded-full">
          <img
            src={message.owner.thumbnails?.md || message.owner.presignedUrl || DEFAULT_PROFILE_URL}
            alt="user-profile"
          />
        </div>
      </div>
      <div className={`w-full text-start pt-0 relative ${isSidebarView ? "xl:px-0" : ""} `}>
        {!isMobile && isMenuOpen && !isEdit && !searchView && isAuthorized && !cardView && (
          <MessageItemMenu
            message={message}
            setIsEdit={setIsEdit}
            handleCloseMenu={() => setIsMenuOpen(false)}
            threadView={threadView}
          />
        )}
        <div className="flex items-center justify-between w-full gap-2">
          <div className="flex-1 w-full relative">
            <div className="absolute inset-0 flex items-center gap-2">
              <span className="text-body font-bold text-14 xl:text-16 truncate">
                {message.owner.name}
              </span>
              {message.owner.type !== ScopeChatUserTypes.REGULAR && !isSidebarView && (
                <span
                  className="text-body text-12 whitespace-nowrap"
                  style={{ color: SCOPE_CHAT_USER_TYPE_COLORS[message.owner.type] }}
                >
                  {snakeCaseToReadableString(message.owner.type)}
                </span>
              )}
            </div>
          </div>
          <span className="text-body font-regular text-12 opacity-50 whitespace-nowrap">
            {`${date} ${messageCreatedAt}`}
          </span>
        </div>

        <div
          ref={containerRef}
          className={`flex flex-col gap-2 w-full ${
            searchView && message.parentId ? "border-left border-l-2 pl-2" : ""
          }`}
        >
          <div className="flex w-full items-end text-body text-14 font-regular gap-1">
            {isEdit ? (
              <UpdateMessageForm message={message} setIsEdit={setIsEdit} />
            ) : (
              message.content
            )}
            {message?.isEdited && <span className="text-body text-12 text-gray-400">(edited)</span>}
          </div>
          <div
            className={`w-full overflow-hidden rounded-lg h-full ${
              isSidebarView ? "w-full" : "xl:w-3/5 xl:max-h-[500px]"
            }`}
          >
            {message.type === ScopeChatMessageTypes.VOICE ? (
              <div className="flex-1 w-full">
                <AudioPlayer
                  src={message?.attachments?.[0]?.presignedUrl || ""}
                  isDraft={message?.attachments?.[0]?.isDraft}
                />
              </div>
            ) : message.attachments?.length ? (
              <MediaGridWrapper media={message.attachments} />
            ) : null}
          </div>

          {searchView ||
            (pinnedView && (
              <div className="badge badge-ghost">{snakeCaseToReadableString(message.type)}</div>
            ))}

          {isAuthorized && !threadView && message.hasThreadMessages && (
            <div className="flex items-center gap-2">
              <div className="flex items-center text-grayscale-300 text-body text-12">
                <ReplyIcon fill="currentColor" />
                {`${message.threadMessagesCount} ${
                  message.threadMessagesCount > 1 || message.threadMessagesCount === 0
                    ? "Replies"
                    : "Reply"
                }`}
              </div>
              <span
                className="link text-body text-12 text-accent-500"
                onClick={() => handleShowThread(message.id)}
              >
                Show thread
              </span>
            </div>
          )}
          {renderReactions()}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
