import React from "react";

import _get from "lodash/get";
import { CellProps } from "./Table.types";

export const TableRowCell = <T,>({ item, column }: CellProps<T>) => {
  const value = column.key ? (_get(item, column.key) as string) : "";

  return (
    <td className={`!bg-neutral-white-7 border-bottom ${column.className ?? ""}`}>
      {column.render ? column.render(item) : value}
    </td>
  );
};
