import { ReactNode, createContext, useContext } from "react";

interface GeneralUiContextType {
  maintenanceMode: boolean;
  dynamicBackground: string;
  staticBackground: string;
  setDynamicBackground: (src: string) => void;
  setStaticBackground: (src: string) => void;
  useStaticBackground: (value: boolean) => void;
  useGradientBackground: (value: boolean) => void;
  useBluredBackground: (value: boolean) => void;
  setHeader: (value: ReactNode | null) => void;
  getPlatformSettings: () => void;
  isDesktop: boolean;
  isMobile: boolean;
  isPWA: boolean;
  isMobileApp: boolean;
  isIosMobileApp: boolean;
  isAndroidMobileApp: boolean;
}

const GeneralUiContext = createContext<GeneralUiContextType | undefined>(undefined);

export const useGeneralUi = (): GeneralUiContextType => {
  const context = useContext(GeneralUiContext);

  if (!context) {
    throw new Error("useGeneralUi must be used within a GeneralUiProvider");
  }

  return context;
};

export default GeneralUiContext;
