import React, { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { CARD_MODELS } from "../PreLoader";
import { CardModels } from "@/types/card/card.types";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

interface CardModelProps {
  textures: { [type: string]: CanvasImageSource };
  athleteName: string;
  cardGenerationMonth: string;
  cardGenerationYear: string | number;
  color: string;
  textColor?: string;
  [x: string]: any;
}

const CardModel = ({
  textures,
  isBacksideOverriden,
  athleteName,
  cardGenerationMonth,
  cardGenerationYear,
  color,
  textColor = "white",
  ...rest
}: CardModelProps) => {
  let nodes: any = undefined;
  let frontTexture: THREE.Texture | undefined = undefined;
  let backTexture: THREE.Texture | undefined = undefined;
  let courtTexture: THREE.Texture | undefined = undefined;

  try {
    const gltf: { nodes: GLTF } = useGLTF(CARD_MODELS[CardModels.NEW].preloadModel) as any;
    nodes = gltf.nodes;

    const frontCanvas = useMemo(() => {
      const canvas = document.createElement("canvas");

      if (!textures.front) return canvas;

      canvas.width = (textures.front as HTMLImageElement).naturalWidth;
      canvas.height = (textures.front as HTMLImageElement).naturalHeight;

      const context = canvas.getContext("2d");

      if (!context) return;

      context.drawImage(textures.front, 0, 0, canvas.width, canvas.height);
      textures.frontText &&
        context.drawImage(textures.frontText, 0, 0, canvas.width, canvas.height);

      return canvas;
    }, []);

    const backsideTextCanvas = useMemo(() => {
      const canvas = document.createElement("canvas");

      if (!textures.backside) return canvas;

      if (isBacksideOverriden) {
        canvas.width = (textures.backside as HTMLImageElement).naturalWidth;
        canvas.height = (textures.backside as HTMLImageElement).naturalHeight;
      } else {
        canvas.width = 1567;
        canvas.height = 2305;
      }

      const context = canvas.getContext("2d");

      if (!context) return;

      context.drawImage(textures.backside, 0, 0, canvas.width, canvas.height);

      return canvas;
    }, []);

    try {
      courtTexture = useLoader(THREE.TextureLoader, "/3d/cards/new/court.jpg") as THREE.Texture;
      courtTexture.flipY = false;
    } catch (error) {}

    backTexture = new THREE.Texture(backsideTextCanvas);
    backTexture.needsUpdate = true;
    backTexture.flipY = false;

    frontTexture = new THREE.Texture(frontCanvas);
    frontTexture.needsUpdate = true;
    frontTexture.flipY = true;
  } catch (error) {}

  return backTexture && frontTexture && courtTexture && nodes ? (
    <group {...rest} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={[1.7, 1.7, 1.7]}>
        <mesh
          geometry={nodes.BackText.geometry}
          position={[0, -0.078, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <meshStandardMaterial map={backTexture} transparent />
        </mesh>

        <mesh
          geometry={nodes.Glass.geometry}
          position={[0, 0.03, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <meshPhysicalMaterial
            color={color}
            transmission={1}
            roughness={0.01}
            clearcoat={1}
            metalness={0.1}
            side={2}
          />
        </mesh>

        <mesh
          geometry={nodes.Net.geometry}
          position={[0, -0.048, -0.001]}
          rotation={[Math.PI / 2, 0, 0]}
        />

        <mesh
          geometry={nodes.PlayerImage.geometry}
          position={[0, -0.1, 0]}
          rotation={[Math.PI / 2, -Math.PI, Math.PI]}
        >
          <meshStandardMaterial map={frontTexture} transparent side={1} />
        </mesh>

        <mesh
          geometry={nodes.Court.geometry}
          position={[0, 0.03, 0]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <meshPhysicalMaterial
            color={color}
            map={courtTexture}
            transmission={1}
            roughness={0.07}
            clearcoat={1}
            side={2}
          />
        </mesh>
      </group>
    </group>
  ) : null;
};

export default CardModel;
