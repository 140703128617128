import React from "react";

import { ScopeIcon } from "@/components/UI/Icons";
import AppLink from "@/components/AppLink";

const MobilePublicNavbar = ({ children }: { children?: JSX.Element }) => {
  return (
    <div
      data-testid="navbar"
      className="flex items-center justify-between w-full z-[45] border-top border-bottom xl:hidden"
    >
      <div className="flex border-right h-full px-4 py-3">
        <AppLink to="/">
          <ScopeIcon className="w-8 h-8" />
        </AppLink>
      </div>
      <div className="flex w-full items-center justify-end h-full pr-4 gap-4">{children}</div>
    </div>
  );
};

export default MobilePublicNavbar;
