import { ANDROID_MOBILE_APP_IDENTIFIER } from "@/constants/app.constants";
import { NavigateFunction, Location, To } from "react-router-dom";

interface IHistory {
  navigate: NavigateFunction;
  location: Location | null;
}

export const history: IHistory = {
  navigate: () => null,
  location: null,
};

export const isPathsInLocation = (paths: string[], pathname: string): boolean => {
  if (!paths.length) return false;

  return (
    paths.some(
      (path: string) =>
        path.replace("/", "") && pathname.replace("/", "").includes(path.replace("/", "")),
    ) || paths.includes(pathname)
  );
};

export const resolveTo = (to: To) => {
  let pathname = "";
  let searchParams = new URLSearchParams();

  if (typeof to === "string") {
    const url = new URL(to, window.location.origin);
    pathname = url.pathname;
    searchParams = new URLSearchParams(url.search);
  } else if (typeof to === "object" && to.pathname) {
    pathname = to.pathname;
    searchParams = new URLSearchParams(to.search);
  }

  if (!searchParams.get(ANDROID_MOBILE_APP_IDENTIFIER)) {
    searchParams.set(ANDROID_MOBILE_APP_IDENTIFIER, "true");
  }

  return {
    pathname,
    search: searchParams.toString(),
  };
};
