import { TABLET_BREAKPOINT_WIDTH } from "@/constants/app.constants";
import { useState, useEffect } from "react";
import { isMobile, isIOS, isTablet, isDesktop } from "react-device-detect";

const getWindowDimensions = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return {
    width,
    height,
  };
};

const getViewportDimensions = () => {
  if (!window.visualViewport) return;
  const width = window.visualViewport.width;
  const height = window.visualViewport.height;
  return {
    width,
    height,
  };
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [visualViewport, setVisualViewport] = useState(getViewportDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
      setVisualViewport(getViewportDimensions());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    windowDimensions,
    visualViewport,
    isMobile: isMobile || isTablet || windowDimensions.width < TABLET_BREAKPOINT_WIDTH,
    isTablet,
    isIOS,
    isDesktop,
  };
}
