import React, { ReactNode, TextareaHTMLAttributes, useRef } from "react";

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  containerClassName?: string;
}

const Textarea = ({ leftAdornment, rightAdornment, containerClassName, ...props }: InputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div
      className={`cursor-text flex w-full items-center rounded-xl py-3 px-4  bg-neutral-white-5 border border-neutral-white-12 ${
        containerClassName ?? ""
      }`}
      onClick={() => textareaRef?.current?.focus()}
    >
      <textarea
        ref={textareaRef}
        rows={4}
        className="bg-transparent focus:outline-none w-full text-body text-14 font-medium text-neutral-white-pure-white"
        {...props}
      />
    </div>
  );
};

export default Textarea;
