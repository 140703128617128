import { useEffect } from "react";
import { useProfile } from "@/providers/profile";
import { useAppDispatch, useAppNavigate } from "@/app/hooks";

import { notificationsSocket } from "./WebSocketProvider";
import { setNotificationItems, setUnreadedCount } from "@/reducers/notifications.slice";
import { GroupedNotificationsType } from "@/types/notification/notification.types";
import { WebSocketSharedChannels } from "@/types/app.types";

export type GetNotificationsResponse = {
  notifications: GroupedNotificationsType;
  unreadedCount: number;
};

export const NotificationCenterProvider = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { userProfile } = useProfile();

  useEffect(() => {
    if (userProfile && notificationsSocket) {
      notificationsSocket.emit(WebSocketSharedChannels.JOIN_REQUEST, {});

      notificationsSocket.on(
        `/${userProfile.uuid}`,
        ({ notifications, unreadedCount }: GetNotificationsResponse) => {
          dispatch(setNotificationItems(notifications));
          dispatch(setUnreadedCount(unreadedCount));
        },
      );

      // TODO: Move to AppGateway provider
      notificationsSocket.on(
        `/${userProfile.uuid}/purchase-success`,
        ({ playerUuid }: { playerUuid: string }) => {
          navigate(`/purchase-success`, { state: { playerUuid } });
        },
      );

      notificationsSocket.emit(`get`, { subscriberUuid: userProfile.uuid });
    }

    return () => {
      if (userProfile && notificationsSocket) {
        notificationsSocket.off(`/${userProfile.uuid}`);
        notificationsSocket.off(`/${userProfile.uuid}/purchase-success`);
      }
    };
  }, []);

  return null;
};

export default NotificationCenterProvider;
