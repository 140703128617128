import React from "react";

import { FieldArray, FormikProps, FormikProvider } from "formik";
import { PostFormValues } from "@/types/fanClub/fanClub.types";

const PollForm = ({ formik }: { formik: FormikProps<PostFormValues> }) => {
  return (
    <div className="form-control">
      <FormikProvider value={formik}>
        <FieldArray
          name="answers"
          render={({ insert, remove, push }) => (
            <div>
              <span className="text-body text-12 text-warning min-h-0 h-3 mt-[2px]">
                {formik.errors.answerCount as string}
              </span>
              {formik.values.answers && formik.values.answers.length > 0 ? (
                formik.values.answers.map((_answer: any, index: number) => (
                  <div key={index} className="flex mb-2">
                    <input
                      type="text"
                      className={`input input-bordered w-full mr-2 ${
                        formik.errors.answers &&
                        (formik.errors as any).answers[index] &&
                        " !border-red-500 !text-red-900"
                      }`}
                      name={`answers.${index}`}
                      value={formik.values.answers?.[index]}
                      onChange={formik.handleChange}
                    />
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-square btn-error"
                        onClick={() => {
                          const votes = [...(formik.values.votes || [])];
                          votes.splice(index, 1);
                          formik.setFieldValue("votes", votes);
                          remove(index);
                        }}
                      >
                        -
                      </button>
                      <button
                        type="button"
                        className="btn btn-square btn-accent"
                        onClick={() => {
                          const votes = [...(formik.values.votes || [])];
                          votes.splice(index, 0);
                          formik.setFieldValue("votes", votes);
                          insert(index, "");
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <button
                  type="button"
                  className="btn btn-outline btn-accent"
                  onClick={() => {
                    formik.setFieldValue("votes", [0]);
                    push("");
                  }}
                >
                  Add an answer
                </button>
              )}
            </div>
          )}
        />
      </FormikProvider>
    </div>
  );
};
export default PollForm;
