import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import Button from "@/components/UI/Button";
import AudioPlayer from "./AudioPlayer";
import {
  TransparentPauseIcon,
  TransparentPlayIcon,
  TransparentRevertIcon,
  TransparentStopIcon,
  UploadDocumentIcon,
} from "@/components/UI/Icons";
import { AudioRecorder, RECORD_STATUS } from "@/components/UI/AudioRecorder";
import { batch } from "react-redux";

const VoiceForm = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (audioFile: File) => void;
  onCancel: () => void;
}) => {
  const [audioFile, setAudioFile] = useState<File>();
  const [isRecording, setIsRecording] = useState<boolean>(true);

  const voiceInputRef = useRef<HTMLInputElement>(document.createElement("input"));

  useEffect(() => {
    voiceInputRef.current.value = "";
  }, [audioFile]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      setAudioFile(event.currentTarget.files[0]);
    }
  };

  const handleRevert = () => {
    batch(() => {
      setAudioFile(undefined);
      setIsRecording(false);
    });
  };

  return (
    <div className="flex flex-col w-full items-center xl:pt-10">
      <div className="flex flex-col gap-4 w-full h-full justify-between max-w-[784px] px-4 xl:px-0 pb-6 overflow-hidden overflow-y-scroll scrollbar-hidden">
        <div className="flex-1 flex flex-col gap-4 w-full overflow-hidden overflow-y-scroll scrollbar-hidden px-4 xl:px-0">
          <div className="flex flex-col w-full text-start gap-1.5 xl:gap-2.5">
            <span className="text-caps text-13 font-medium text-grayscale-300 pl-1">
              Record Audio
            </span>
            <div className="flex items-center justify-center w-full p-8 border border-neutral-white-12 bg-neutral-white-5 rounded-xl">
              {audioFile ? (
                <AudioPlayer audioFile={audioFile} setAudioFile={setAudioFile} />
              ) : isRecording ? (
                <AudioRecorder
                  setAudioFile={(audioFile: File) => setAudioFile(audioFile)}
                  setAudioResult={() => null}
                  render={({
                    timer,
                    stopRecording,
                    startRecording,
                    pauseRecording,
                    resumeRecording,
                    status,
                    errorMessage,
                  }) => (
                    <div className="flex flex-col w-full items-center gap-4">
                      <span>{new Date(timer * 1000).toISOString().substring(19, 11)}</span>
                      {errorMessage && (
                        <p className="text-body text-12 text-warning">{errorMessage}</p>
                      )}
                      <div className="flex justify-between items-center gap-4">
                        <div
                          className="flex justify-center items-center rounded-full cursor-pointer p-0 w-10 h-10 bg-neutral-white-5 hover:bg-neutral-white-10"
                          onClick={
                            status === RECORD_STATUS.RECORDING ? pauseRecording : resumeRecording
                          }
                        >
                          {status === RECORD_STATUS.RECORDING ? (
                            <TransparentPauseIcon className="!w-10 !h-10" />
                          ) : (
                            <TransparentPlayIcon className="!w-10 !h-10" />
                          )}
                        </div>

                        <div
                          className={`flex justify-center items-center rounded-full cursor-pointer p-0 w-12 h-12  ${
                            status === RECORD_STATUS.RECORDING
                              ? "bg-white hover:bg-neutral-white-70"
                              : "bg-neutral-white-5 hover:bg-neutral-white-10"
                          }`}
                          onClick={
                            status === RECORD_STATUS.RECORDING ? stopRecording : startRecording
                          }
                        >
                          {status === RECORD_STATUS.RECORDING ? (
                            <TransparentStopIcon className="!w-12 !h-12" fill="black" />
                          ) : (
                            <i className="text-center fas fa-microphone" />
                          )}
                        </div>
                        <div
                          className="flex justify-center items-center rounded-full cursor-pointer p-0 w-10 h-10 bg-neutral-white-5 hover:bg-neutral-white-10"
                          onClick={handleRevert}
                        >
                          <TransparentRevertIcon />
                        </div>
                      </div>
                    </div>
                  )}
                />
              ) : (
                <div className="flex w-full px-16 justify-center items-center">
                  <Button
                    className="flex gap-3 justify-center items-center text-neutral-white-pure-white btn btn-ghost px-6 py-[14px] border border-white"
                    onClick={() => setIsRecording(true)}
                  >
                    <div className="w-3 h-3 rounded-full bg-white border border-inherit" />
                    <span className="text-button text-14 font-bold text-inherit">Record Audio</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full text-start gap-1.5 xl:gap-2.5">
            <span className="text-caps text-13 font-medium text-grayscale-300 pl-1">
              Upload Audio
            </span>
            <div className="flex flex-col items-center justify-center w-full px-16 py-8 gap-8 border border-neutral-white-12 bg-neutral-white-5 rounded-xl">
              <div className="flex flex-col gap-2 w-full justify-center items-center">
                <UploadDocumentIcon />
                <span className="text-body text-14 font-bold text-neutral-white-pure-white">
                  Please Select Files
                </span>
                <span className="text-body text-14 font-regular text-grayscale-500">
                  MP3 recommended.
                </span>
              </div>
              <Button
                className="flex gap-3 justify-center items-center text-neutral-white-pure-white btn btn-ghost px-6 py-[14px] border border-white"
                onClick={() => voiceInputRef.current.click()}
              >
                <span className="text-button text-14 font-bold text-inherit">Browse File</span>
              </Button>
              <input
                ref={voiceInputRef}
                type="file"
                className="hidden"
                accept="audio/mpeg, audio/x-m4a, audio/aac, audio/wav"
                onChange={handleOnChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center items-center border-top">
        <div className="flex w-full max-w-[784px] justify-end items-center gap-4 xl:gap-8 xl:py-8 px-4 py-6">
          <Button
            className="btn btn-outline flex-1 xl:flex-none xl:w-[190px] rounded-full !text-button !text-14 !font-bold"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary flex-1 xl:flex-none xl:w-[190px] rounded-full !text-button !text-14 !font-bold"
            onClick={() => audioFile && onSubmit(audioFile)}
          >
            Add Audio
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VoiceForm;
