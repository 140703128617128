import { PaymentIntent } from "@stripe/stripe-js";

export enum PaymentMethods {
  STRIPE = "stripe",
  CRYPTO = "crypto",
}

interface BasePaymentDetails {
  cardPrice: number;
  fee: number;
  totalPrice: number;
  tradeCardUuid: string;
  currency: string;
}

export interface StripePaymentDetails extends BasePaymentDetails {
  paymentIntent: PaymentIntent;
}

export interface CryptoPaymentDetails extends BasePaymentDetails {
  exchangeRate: number;
  scopeWalletAddress: string;
  totalPriceWei: string;
  fromAddress: string;
  toAddress: string;
  value: string;
}

export interface BasePayment {
  uuid: string;
  paymentMethod: PaymentMethods;
}

export interface StripePayment extends BasePayment {
  paymentMethod: PaymentMethods.STRIPE;
  details: StripePaymentDetails;
}

export interface CryptoPayment extends BasePayment {
  paymentMethod: PaymentMethods.CRYPTO;
  details: CryptoPaymentDetails;
}

export type Payment = StripePayment | CryptoPayment;

export interface PaymentTab {
  component: (payment: Payment) => JSX.Element;
  label: string;
  isHidden?: boolean;
}

export type PaymentTabs = {
  [key in PaymentMethods]: PaymentTab;
};
