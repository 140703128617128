import React, { useState } from "react";

import { usePaymentForm } from "@/providers/PaymentFormProvider";
import { usePayment } from "@/providers/payment";
import { useExternalWallet } from "@/providers/externalWallet";

import Button from "@/components/UI/Button";

import { CryptoPayment } from "@/types/payment/payment.types";

const PaymentForm = ({ payment }: { payment: CryptoPayment }) => {
  const {
    handleProcessPayment,
    handleSetTransaction,
    handleApplyCoupon,
    isPaymentProcessing,
    isLoading,
  } = usePayment();
  const { handleOnSuccess, handleOnFailure } = usePaymentForm();
  const { sendTransaction } = useExternalWallet();

  const [couponCode, setCouponCode] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const confirmPayment = async () => {
    setIsSubmitting(true);

    try {
      const transactionPayload = await sendTransaction(
        payment.details.scopeWalletAddress,
        payment.details.totalPriceWei,
      );

      await handleSetTransaction(transactionPayload);
      await handleProcessPayment(handleOnSuccess, handleOnFailure);
    } catch (error: any) {
      handleOnFailure(error?.message || JSON.stringify(error));
    }

    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="flex flex-col w-full gap-2">
        <span className="text-[#A6ADBA] uppercase">Discount Code</span>
        <div className="flex w-full items-center justify-between gap-2">
          <input
            className="input input-bordered w-full"
            placeholder="Enter discount coupon code"
            value={couponCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCouponCode(event.target.value.toUpperCase())
            }
          />
          <Button className="btn btn-success" onClick={() => handleApplyCoupon(couponCode)}>
            Apply
          </Button>
        </div>
      </div>
      {/* <PaymentElement /> */}
      <button
        className={`btn btn-primary w-full mt-5 ${
          isPaymentProcessing || isSubmitting || isLoading ? "loading" : ""
        }`}
        onClick={confirmPayment}
      >
        Pay
      </button>
    </div>
  );
};

export default PaymentForm;
