import React, { PropsWithChildren } from "react";

import AppLink from "../AppLink";

const PublicNavbar = ({ children }: PropsWithChildren) => {
  return (
    <div className="hidden xl:flex justify-between xl:flex-row">
      <AppLink to="/" className="mb-5 xl:mb-0 mx-auto xl:mx-0">
        <img className="navbar-logo" src="/img/logos/scope_logo.png" />
      </AppLink>
      <div className="flex justify-end items-center gap-2 text-caps text-13 font-medium">
        {children}
      </div>
    </div>
  );
};

export default PublicNavbar;
