import React from "react";
import { useFanClub } from "@/providers/fanClub";

import ReactCountryFlag from "react-country-flag";
import CardView from "../../Common/CardView";
import ProfileAvatar from "@/components/User/ProfileAvatar";
import { GradientLockIcon } from "@/components/UI/Icons";
import AthleteStat from "../Common/AthleteStat";

import { getAgeFromBirthDate } from "@/helpers/date.helpers";
import { getCountryByCode } from "@/helpers/country.helpers";
import { getHeightFt } from "@/helpers/player.helpers";
import { Country } from "@/types/countries.enum";

const MobileAthleteInfo = () => {
  const { isMember, playerProfile } = useFanClub();

  const country = getCountryByCode(playerProfile?.nationality || "");
  const [firstName, ...lastName] = playerProfile.name.split(" ");

  return (
    <CardView containerClassName="p-6 select-none">
      <div className={`w-full h-full flex flex-col justify-between `}>
        <div className="flex w-full items-start justify-between">
          <ProfileAvatar imageClass="w-16 h-16" profile={playerProfile} />
          <div className="flex items-center gap-2">
            {country && (
              <ReactCountryFlag
                className="!w-5 rounded-sm"
                title={country}
                countryCode={Country[country as keyof typeof Country]}
                svg
              />
            )}
            <span className="text-body text-14">{playerProfile.nationality}</span>
            {!isMember && <GradientLockIcon />}
          </div>
        </div>
        <div
          className="flex flex-col items-start"
          data-testid="player-profile-name"
          data-profile-name={playerProfile.name}
        >
          <span className="text-body text-14">{firstName}</span>
          <h2 className="text-heading font-bold text-start">{lastName.join(" ") || ""}</h2>
        </div>
        <div className="flex justify-between">
          <AthleteStat title="Age" value={getAgeFromBirthDate(playerProfile.birthday)} />
          <AthleteStat
            title="Plays"
            value={`${
              playerProfile.activeHand.charAt(0).toUpperCase() +
              playerProfile.activeHand.toLowerCase().slice(1)
            } Hand`}
          />{" "}
          <AthleteStat title="Height" value={getHeightFt(playerProfile?.heightCm)} />
        </div>
      </div>
    </CardView>
  );
};

export default MobileAthleteInfo;
