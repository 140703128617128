import React, { useState } from "react";
import { useProfile } from "@/providers/profile";

import Button from "../UI/Button";
import ConfirmModal from "../UI/ConfirmModal";

const DeleteAccountTab = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const { deleteUserProfile, isUserProfileDeleting } = useProfile();

  const handleDeleteAccount = () => {
    setIsConfirmModalOpen(true);
  };

  return (
    <>
      {isConfirmModalOpen && (
        <ConfirmModal
          onToggle={() => setIsConfirmModalOpen(false)}
          onConfirm={deleteUserProfile}
          title="Are you sure you want to delete your account?"
          body="This action is irreversible, are you sure you want to delete your account?"
        />
      )}
      <div className="max-w-xl p-5 m-auto">
        <div className="flex flex-col w-full gap-3">
          <span>
            Pressing the button below will completely remove your account. Please be aware that this
            action is irreversible and will result in the loss of access to Fan Clubs, the general
            chat, and any previously purchased NFTs that have not been transferred to an external
            wallet.
          </span>
          <Button
            className={`btn btn-primary btn-outline ${isUserProfileDeleting ? "loading" : ""}`}
            onClick={handleDeleteAccount}
          >
            Delete account
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeleteAccountTab;
