import React from "react";

import { HeaderProps } from "./Table.types";

export const TableHeader = <T,>({ columns }: HeaderProps<T>) => {
  return (
    <tr className="border-none">
      {columns.map((column, columnIndex) => (
        <th key={`table-head-cell-${columnIndex}`} style={{ width: column.width || "0px" }}>
          {column.title}
        </th>
      ))}
    </tr>
  );
};
