import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { PostType } from "@/types/fanClub/fanClub.types";

export const mobileFanClubUiSlice = createSlice({
  name: "mobileFanClubUi",
  initialState: {
    isChatOpen: false,
    isCommentsOpen: false,
    selectedPost: null,
  },
  reducers: {
    setIsChatOpen: (state, action) => {
      state.isChatOpen = action.payload;
    },
    setIsCommentsOpen: (state, action) => {
      state.isCommentsOpen = action.payload;
    },

    setSelectedPost: (state, action) => {
      state.selectedPost = action.payload;
    },
  },
});

export const { setIsChatOpen, setIsCommentsOpen, setSelectedPost } = mobileFanClubUiSlice.actions;

export const selectIsChatOpen = (state: RootState): boolean => state.mobileFanClubUi.isChatOpen;

export const selectIsCommentsOpen = (state: RootState): boolean =>
  state.mobileFanClubUi.isCommentsOpen;

export const selectSelectedPost = (state: RootState): PostType | null =>
  state.mobileFanClubUi.selectedPost;

export default mobileFanClubUiSlice.reducer;
