/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useAppDispatch, useAppNavigate } from "@/app/hooks";

import { CoinsIcon, ExclamationIcon, ScopeIcon, WalletIcon } from "@/components/UI/Icons";
import ProfileAvatar from "@/components/User/ProfileAvatar";

import {
  NotificationIconsEnum,
  NotificationType,
  NotificationTypes,
} from "@/types/notification/notification.types";
import { fromNow } from "@/utils/dateFormatter";

import { snakeCaseToReadableString } from "@/helpers/string.helpers";
import { useNotifications } from "@/app/hooks/useNotifications";
import { setIsNotificationModalOpen } from "@/reducers/notifications.slice";
import { useProfile } from "@/providers/profile";
import { formatMessage } from "@/helpers/notification.helpers";
import { isExternalUrl } from "@/helpers/url.helpers";

const NotificationItem = ({
  notification,
  fullView = false,
}: {
  notification: NotificationType;
  fullView?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { markAsRead } = useNotifications();
  const { getUserProfile } = useProfile();

  const message = formatMessage(
    notification.template?.content || "",
    notification.payload.templateValues,
  );
  const notificationType = notification.payload.type
    ? snakeCaseToReadableString(notification.payload.type)
    : "General Notifications";

  const NotificationIcons = {
    [NotificationIconsEnum.SCOPE]: <ScopeIcon />,
    [NotificationIconsEnum.WALLET]: <WalletIcon />,
    [NotificationIconsEnum.COINS]: <CoinsIcon />,
    [NotificationIconsEnum.PLAYER_AVATAR]: (
      <ProfileAvatar profile={notification?.payload?.sender?.player} />
    ),
    [NotificationIconsEnum.USER_AVATAR]: (
      <ProfileAvatar profile={notification?.payload?.sender?.user} />
    ),
    [NotificationIconsEnum.EXCLAMATION_MARK]: (
      <ExclamationIcon className="fill-warning !w-10 !h-10" />
    ),
  };

  const handleOnClick = () => {
    const {
      payload: { url },
    } = notification;

    markAsRead(notification.uuid);

    switch (notification.payload.type) {
      case NotificationTypes.PURCHASE_SUCCESS:
        getUserProfile();
        break;
      default:
        markAsRead(notification.uuid);
        break;
    }

    if (url) {
      if (isExternalUrl(url)) {
        window.open(url, "_blank");
      } else {
        navigate(url);
      }
    }
    dispatch(setIsNotificationModalOpen(false));
  };

  return (
    <div
      className={`flex items-center w-full hover:bg-neutral-black-20 rounded-xl cursor-pointer p-1 ${
        fullView ? "bg-neutral-black-10 px-6 py-4" : ""
      }`}
      onClick={handleOnClick}
    >
      <div className="flex-shrink-0 mr-4">
        <div className="relative inline-flex indicator">
          {!notification.isReaded && (
            <span className="indicator-item top-0 left-0 indicator-dot"></span>
          )}
          <div className="w-12 h-12 rounded-full bg-grayscale-1000 flex items-center justify-center">
            {NotificationIcons[notification?.payload?.sender?.icon]}
          </div>
        </div>
      </div>
      {fullView ? (
        <>
          <div className="flex-1 text-start">
            <span className="text-body text-15 font-bold">{message}</span>
          </div>
          <div className="flex-2 text-end uppercase">
            <span className="text-caps text-11 text-white font-regular opacity-50 flex items-center">
              {notificationType}
              <span className="dot mx-1"></span>
              {fromNow(notification.createdAt)}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="flex-1 text-start">
            <span className="text-body text-15 font-bold">{message}</span>
            <span className="text-caps text-11 text-white font-regular opacity-50 flex items-center">
              {notificationType}
              <span className="dot mx-1"></span>
              {fromNow(notification.createdAt)}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationItem;
