import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { OrderByEnum, WishlistItemType } from "@/types/wishlist/wishlist.types";

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlistItems: [],
    totalItems: 0,
    orderBy: OrderByEnum.LAST_ADDED,
  },
  reducers: {
    setWishlistItems: (state, action) => {
      state.wishlistItems = action.payload;
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
  },
});

export const { setWishlistItems, setTotalItems, setOrderBy } = wishlistSlice.actions;

export const selectWishlistItems = (state: RootState): WishlistItemType[] =>
  state.wishlist.wishlistItems;

export const selectTotalItems = (state: RootState): number => state.wishlist.totalItems;

export const selectOrderBy = (state: RootState): OrderByEnum => state.wishlist.orderBy;

export default wishlistSlice.reducer;
