import React, { useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import { useAppNavigate } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";
import { useScopeChat } from "@/providers/scopeChat";

import MobileHeader from "@/components/UI/MobileHeader";
import MainView from "./MainView";
import SideBar from "./Sidebar/SideBar";
import { ScopeChatSidebarElements } from "@/types/scopeChat/scopeChat.types";

const ScopeChat = ({ messageId }: { messageId: string | null }) => {
  const navigate = useAppNavigate();

  const { setHeader } = useGeneralUi();
  const { setSidebarView, handleScrollToMessage } = useScopeChat();
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  useEffect(() => {
    if (messageId) {
      setSidebarView(undefined);
      setHeader(<MobileHeader label="Scope Chat" handleBackClick={() => navigate("/")} />);
      handleScrollToMessage(messageId);
    } else if (
      Object.values(ScopeChatSidebarElements).includes(section as ScopeChatSidebarElements)
    ) {
      setSidebarView(section as ScopeChatSidebarElements | undefined);
    } else {
      setSidebarView(undefined);
      setHeader(<MobileHeader label="Scope Chat" handleBackClick={() => navigate("/")} />);
      navigate("/scope-chat");
    }
  }, [section, messageId]);

  return (
    <div className="flex-1 xl:flex-none flex w-full h-full xl:gap-6">
      <MainView />
      <SideBar />
    </div>
  );
};

export default ScopeChat;
