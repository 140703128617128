import { IThumbnails } from "../app.types";
import { CardUserTradeType } from "../cardUserTrade/cardUserTrade.types";
import { PlayerProfileType } from "../player/player.types";
import { UserProfileType } from "../user/profile.types";

export interface Card {
  player: PlayerProfileType;
  playerInfo: string;
  createdAt: string | number | Date;
  uuid: string;
  cardName: string;
  image: string;
  imageMimetype: string;
  backsideImage: string;
  backsideImageMimetype: string;
  nationality: string;
  cardUsers: CardUserType[];
  proshopPrice: number;
  cardFeatures: string;
  cardType: CardTypes;
  thumbnails: IThumbnails;
  backsideImageThumbnails: IThumbnails;
  model: CardModels;
  textures: Texture[];
  limitedAccess: boolean;
  validUntil: string;
  isPremiumContentAllowed: boolean;
  isAthleteChatAllowed: boolean;
  isAthleteFeedAllowed: boolean;
}

export interface Texture {
  image: string;
  type: string;
  thumbnails: IThumbnails;
}

export enum CardModels {
  DEFAULT = "default",
  NEW = "new",
}

// export type FanClubCard = Partial<PlayerCard> & Partial<UserCard>;
interface CardUserType {
  color: string;
  uuid: string;
  createdAt: Date;
  updatedAt: Date;
  serialNumber: number;
  serialNumberGenerationId: number;
}

export interface PlayerCard {
  color: string;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  serialNumber: number;
  serialNumberGeneration: any;
  serialNumberGenerationId: number;
  basePrice: number;
  card: Card;
  player: PlayerProfileType;
}

export interface UserCard {
  color: string;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  serialNumber: number;
  serialNumberGeneration: any;
  serialNumberGenerationId: number;
  basePrice: number;
  card: Card;
  user: UserProfileType;
  trade: CardUserTradeType;
}

export interface FanClubCard {
  color: string;
  uuid: string;
  card: Card;
  owned: boolean;
  basePrice?: number;
  player?: PlayerProfileType;
}

export interface RecentPlayerCards {
  [cardUuid: string]: PlayerCard;
}

export interface RecentUserCards {
  [cardUuid: string]: UserCard;
}

export enum OriginTypes {
  PROSHOP = "proshop",
  MARKETPLACE = "marketplace",
  ROSTER = "roster",
}

export enum CardTypes {
  MEMBER_CARD = "member_card",
}
