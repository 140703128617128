import React, { useEffect } from "react";
import { useAppNavigate } from "@/app/hooks";
import { useNotifications } from "@/app/hooks/useNotifications";

import EmptySection from "@/components/UI/EmptySection";
import { CloseIcon, InboxIcon } from "@/components/UI/Icons";
import NotificationItem from "@/components/NotificationCenter/NotificationItem";
import Button from "@/components/UI/Button";

import {
  GroupedNotificationsType,
  NotificationType,
} from "@/types/notification/notification.types";
import { objectKeyToReadableString } from "@/helpers/string.helpers";

const MobileNotificationCenter = () => {
  const navigate = useAppNavigate();
  const { notifications, markAllAsRead } = useNotifications() || {};

  useEffect(() => {
    markAllAsRead();
  }, []);

  return (
    <div className="fixed ios-inset-0 overflow-hidden z-[65]">
      <div className="absolute inset-0 flex flex-col items-center justify-between w-full h-full overflow-hidden">
        <div className="flex w-full justify-between items-center px-4 py-2 xl:px-[60px] xl:py-8 border-bottom">
          <span className="text-body xl:text-headline text-16 text-neutral-white-pure-white xl:uppercase">
            Notifications
          </span>
          <Button
            className="btn btn-ghost btn-square btn-sm rounded-md"
            onClick={() => navigate(-1)}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex-1 flex xl:pt-10 w-full justify-center overflow-hidden overflow-y-scroll scrollbar-hidden">
          <div className="w-full h-full">
            <div className="flex flex-col items-start w-full gap-2 px-4 h-full overflow-hidden overflow-y-scroll">
              {notifications ? (
                Object.keys(notifications)
                  .filter((key) => notifications[key as keyof GroupedNotificationsType].length)
                  .map((key: string) => {
                    return notifications[key as keyof GroupedNotificationsType].length ? (
                      <React.Fragment key={key}>
                        <span className="text-caps text-13 font-medium text-neutral-white my-2">
                          {objectKeyToReadableString(key)}
                        </span>
                        {notifications[key as keyof GroupedNotificationsType].map(
                          (notification: NotificationType, index: number) => (
                            <NotificationItem key={index} notification={notification} />
                          ),
                        )}
                      </React.Fragment>
                    ) : null;
                  })
              ) : (
                <EmptySection
                  icon={<InboxIcon />}
                  title="No Activity"
                  text="You have no activity at the moment, but we can totally change that."
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNotificationCenter;
