import React from "react";
import { useScopeChat } from "@/providers/scopeChat";

import SectionView from "../../Common/SectionView";

import { SCOPE_CHAT_SIDEBAR_VIEWS } from "@/constants/scopeChat.constants";

const SideBar = () => {
  const { sidebarView } = useScopeChat();

  if (!sidebarView) return null;

  const View = SCOPE_CHAT_SIDEBAR_VIEWS[sidebarView];

  return sidebarView ? (
    <SectionView
      title=""
      containerClassName="absolute top-0 xl:static flex flex-col w-full h-full xl:min-w-[400px] xl:max-w-[400px] xl:w-[400px] xl:border-left overflow-hidden"
    >
      <div className="flex-1 flex flex-col justify-between p-0 m-0 w-full h-full relative">
        <View />
      </div>
    </SectionView>
  ) : null;
};

export default SideBar;
