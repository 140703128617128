import React from "react";

import { useAppSelector } from "@/app/hooks";

import PremiumContentCard from "./Cards/PremiumContentCard";
import FeedCard from "./Cards/FeedCard";
import ChatCard from "./Cards/ChatCard";
import AthleteInfoCard from "./Cards/AthleteInfoCard";

import { selectCurrentView } from "@/reducers/fanClub.slice";
import { FAN_CLUB_VIEWS } from "@/constants/fanClub.constants";
import { ViewTypes } from "@/types/fanClub/fanClub.types";

const AthleteFanClub = () => {
  const currentView = useAppSelector(selectCurrentView);

  const CurrentView = FAN_CLUB_VIEWS[currentView].element;

  return (
    <div className="flex w-full h-full gap-6">
      <div className="flex-1 flex flex-col items-center justify-between gap-4">
        <CurrentView />
      </div>

      <div className="flex flex-col h-full justify-between gap-4 min-w-[340px] xl:w-1/3 2xl:w-1/4">
        {currentView !== ViewTypes.PREMIUM_CONTENT && <PremiumContentCard />}
        {currentView !== ViewTypes.FEED && <FeedCard />}
        {currentView !== ViewTypes.CHAT && <ChatCard />}
        {currentView !== ViewTypes.CARDS && <AthleteInfoCard />}
      </div>
    </div>
  );
};

export default AthleteFanClub;
