import React from "react";
import { useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useFanClub } from "@/providers/fanClub";
import { usePayment } from "@/providers/payment";

import PaymentFormProvider from "@/providers/PaymentFormProvider";
import InfoModal from "../UI/InfoModal";
import PaymentModal from "./PaymentModal";
import Button from "../UI/Button";

import { PlayerCard } from "@/types/card/card.types";

const GetAccessButton = ({
  className,
  tradeCard,
}: {
  className?: string;
  tradeCard?: PlayerCard;
}) => {
  const navigate = useAppNavigate();
  const { isAuthorized } = useProfile();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { playerProfile, isOwnLimitedCards, currentCard } = useFanClub();
  const { checkAvailabilityToBuyCard, isDisabled } = usePayment();

  tradeCard =
    tradeCard ||
    (currentCard && playerProfile.tradeCards[currentCard.card.uuid]) ||
    (isOwnLimitedCards
      ? Object.values(playerProfile.tradeCards).sort((a, b) => a.basePrice - b.basePrice)?.[0]
      : Object.values(playerProfile.tradeCards).sort((a, b) => b.basePrice - a.basePrice)?.[0]);

  const handleGetAccessClick = async () => {
    if (!isAuthorized) {
      navigate("/sign-up");
      return;
    }

    if (!tradeCard) return;

    try {
      await checkAvailabilityToBuyCard(tradeCard.serialNumberGenerationId);
    } catch (error: any) {
      setModal(
        <InfoModal
          body={error?.message || error?.reason || JSON.stringify(error)}
          onToggle={handleCloseModal}
        />,
      );
      setIsModalOpen(true);
      return;
    }

    setModal(
      <PaymentFormProvider tradeCard={tradeCard}>
        <PaymentModal />
      </PaymentFormProvider>,
    );
    setIsModalOpen(true);
  };

  const isLimitedAccess = tradeCard?.card?.limitedAccess;

  return tradeCard && !isDisabled ? (
    <div className="flex items-center justify-between gap-4 w-full" data-testid="get-access-btn">
      <div className="flex w-full">
        <Button
          className={
            className ??
            `
              btn btn-outline rounded-full min-w-[90%] tablet:min-w-[50%] xl:min-w-[292px] 4k:min-w-[584px] mx-auto max-h-none h-12 4k:h-24 
              text-14 4k:text-[28px] 4k:leading-10 border-[2px] backdrop-blur-md
              hover:!border-white hover:!text-white hover:bg-transparent
              ${
                isLimitedAccess
                  ? "!border-accent-500 !text-accent-500"
                  : "!border-primary !text-primary"
              }
            `
          }
          onClick={handleGetAccessClick}
        >
          {isOwnLimitedCards && !tradeCard.card.limitedAccess ? "Upgrade" : "Get Access"}
        </Button>
      </div>
    </div>
  ) : null;
};

export default GetAccessButton;
