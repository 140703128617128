import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { batch } from "react-redux";
import { useProfile } from "@/providers/profile";
import { useScopeChat } from "@/providers/scopeChat";
import { useClickOutside } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";

import { CloseIcon, PinIcon, SearchIcon } from "@/components/UI/Icons";
import Button from "@/components/UI/Button";
import SearchDropdownMenu, {
  SearchOwnerSuggestions,
  SearchTypeSuggestions,
  SearchHelp,
  SearchDatePicker,
} from "./SearchDropdownMenu";

import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  BACKSPACE_KEY,
  ENTER_KEY,
  ESCAPE_KEY,
} from "@/constants/app.constants";
import { SCOPE_CHAT_INPUT_CONTAINER_HEIGHT } from "@/constants/scopeChat.constants";
import { ScopeChatSearchPayload, ScopeChatSearchTypes } from "@/types/scopeChat/scopeChat.types";
import { snakeCaseToReadableString } from "@/helpers/string.helpers";

const SearchInput = () => {
  const { isAuthorized } = useProfile();
  const { handleSearch, suggestions, getSuggestions, handleShowPinnedMessages } = useScopeChat();
  const { isMobile } = useGeneralUi();

  const searchContainerRef = useRef<HTMLDivElement>(document.createElement("div"));
  const searchInputRef = useRef<HTMLInputElement>(document.createElement("input"));

  const [query, setQuery] = useState<string>("");
  const [triggerHandleSearch, setTriggerHandleSearch] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [type, setType] = useState<ScopeChatSearchTypes | undefined>();
  const [prefix, setPrefix] = useState<string>("");
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState<number>(-1);

  const [searchPayload, setSearchPayload] = useState<ScopeChatSearchPayload>({});

  const [currentSearchContainerHeight, setCurrentSearchContainerHeight] = useState<number>(0);

  useClickOutside(searchContainerRef, () => setIsInputFocused(false));

  useEffect(() => {
    triggerHandleSearch && handleSearchQuery();
    setTriggerHandleSearch(false);
  }, [triggerHandleSearch]);

  useEffect(() => {
    isAuthorized && type && getSuggestions(type, prefix);
  }, [type, prefix, isAuthorized]);

  useEffect(() => {
    setCurrentSearchContainerHeight(searchContainerRef.current.clientHeight);
    checkQuery(query);
  }, [query]);

  useEffect(() => {
    setCurrentSearchContainerHeight(searchContainerRef.current.clientHeight);
  }, [searchPayload]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setQuery(value);
  };

  const checkQuery = (query: string) => {
    const from = /\bfrom:\s*(\S*)/i.exec(query.toLowerCase());
    const type = /\btype:\s*(\S*)/i.exec(query.toLowerCase());
    const before = /\bbefore:\s*(\S*)/i.exec(query.toLowerCase());
    const after = /\bafter:\s*(\S*)/i.exec(query.toLowerCase());
    const on = /\bon:\s*(\S*)/i.exec(query.toLowerCase());

    if (from) {
      batch(() => {
        setType(ScopeChatSearchTypes.FROM);
        setPrefix(from[1]);
      });
    } else if (type) {
      batch(() => {
        setType(ScopeChatSearchTypes.TYPE);
        setPrefix(type[1]);
      });
    } else if (before) {
      batch(() => {
        setType(ScopeChatSearchTypes.BEFORE);
        setPrefix(before[1]);
      });
    } else if (after) {
      batch(() => {
        setType(ScopeChatSearchTypes.AFTER);
        setPrefix(after[1]);
      });
    } else if (on) {
      batch(() => {
        setType(ScopeChatSearchTypes.ON);
        setPrefix(on[1]);
      });
    } else {
      batch(() => {
        setType(ScopeChatSearchTypes.KEYWORDS);
        setPrefix(query);
      });
    }
  };

  const handleFocusSearch = () => {
    setIsInputFocused(true);
    checkQuery(query);
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const suggestionType = type as ScopeChatSearchTypes;

    if (suggestions[suggestionType]?.length) {
      const suggestionsLength = suggestions[suggestionType].length;

      if (!suggestionsLength) return;

      const inputElement = event.currentTarget;
      const parentElement = inputElement.parentElement;

      switch (event.key) {
        case ARROW_DOWN_KEY:
          setSelectedMenuItemIndex((prevIndex) => (prevIndex + 1) % suggestionsLength);
          break;
        case ARROW_UP_KEY:
          event.preventDefault();
          setSelectedMenuItemIndex(
            (prevIndex) => (prevIndex - 1 + suggestionsLength) % suggestionsLength,
          );
          break;
        case ENTER_KEY:
          if (selectedMenuItemIndex !== -1) {
            const selectedSuggestion = suggestions[suggestionType][selectedMenuItemIndex];

            if (!selectedSuggestion) return;

            if (type === ScopeChatSearchTypes.KEYWORDS) {
              handleHelpItemSelected(selectedSuggestion);
            } else {
              handleSuggestionSelected(suggestionType, prefix, selectedSuggestion);
            }
          } else {
            const hasSearchPayload = Object.values(searchPayload).some(Boolean);

            if (!hasSearchPayload && !query) return;

            setTriggerHandleSearch(true);
          }

          break;
        case BACKSPACE_KEY:
          if (query) break;

          if (parentElement) {
            const children = Array.from(parentElement.children);
            const inputIndex = children.indexOf(inputElement);

            if (inputIndex > 0) {
              const type = children[inputIndex - 1].id;

              if (!type) return;

              setSearchPayload((prev) => ({ ...prev, [type]: "" }));
            }
          }
          break;
        case ESCAPE_KEY:
          batch(() => {
            setType(undefined);
            setPrefix("");
          });

          break;
        default:
          break;
      }
    } else {
      const hasSearchPayload = Object.values(searchPayload).some(Boolean);

      switch (event.key) {
        case ENTER_KEY:
          if (!hasSearchPayload && !query) return;

          setTriggerHandleSearch(true);
          break;

        case ARROW_DOWN_KEY:
          batch(() => {
            setType(ScopeChatSearchTypes.KEYWORDS);
            setPrefix("");
          });
      }
    }
  };

  const handleSearchQuery = () => {
    searchInputRef.current.blur();
    const trimmedQuery = query.toLowerCase().trim();
    handleSearch({ ...searchPayload, query: trimmedQuery || undefined });
  };

  const handleClear = () => {
    batch(() => {
      setQuery("");
      setSearchPayload({});
    });
  };

  const handleSuggestionSelected = (type: ScopeChatSearchTypes, prefix: string, item: any) => {
    batch(() => {
      setSearchPayload((prev) => ({
        ...prev,
        [type]: { value: item.value, displayValue: item.displayValue },
      }));
      setQuery(query.replace(new RegExp(`${type}:${prefix}`, "i"), ""));
      setSelectedMenuItemIndex(-1);
    });
  };

  const handleRemoveFilter = (type: ScopeChatSearchTypes) => {
    setSearchPayload((prev) => ({ ...prev, [type]: "" }));
    setCurrentSearchContainerHeight(searchContainerRef.current.clientHeight + 16);
  };

  const handleHelpItemSelected = (item: { value: string }) => {
    const event = {
      target: {
        value: `${item.value}:`,
      },
    } as ChangeEvent<HTMLInputElement>;

    setSelectedMenuItemIndex(-1);
    handleOnChange(event);
  };

  const SelectedFilters = () => {
    const searchPayloadKeys = Object.keys(searchPayload);
    return (
      <>
        {searchPayloadKeys.map((key) => {
          const type = key as ScopeChatSearchTypes;

          const { value, displayValue } = searchPayload[type] || {};

          return (
            value && (
              <div
                key={key}
                id={key}
                data-value={value}
                className="flex items-center gap-2 w-full xl:w-fit xl:pr-6 text-body text-14 font-medium leading-[22px] hover:cursor-pointer relative rounded-lg hover:bg-error-900 max-w-full xl:max-w-[20%]"
                onClick={() => handleRemoveFilter(type)}
              >
                <div className="absolute inset-0 opacity-0 hover:opacity-100 flex items-center justify-end">
                  <CloseIcon stroke="white" strokeOpacity={1} />
                </div>
                <div className="flex items-center px-2 justify-center rounded-[4px] bg-neutral-white-12">
                  <span className="text-neutral-white-pure-white">
                    {snakeCaseToReadableString(key)}
                  </span>
                </div>
                <span className="text-accent-500 max-w-[90%] truncate">{displayValue}</span>
              </div>
            )
          );
        })}
      </>
    );
  };

  return (
    <div
      ref={searchContainerRef}
      className={`flex px-2 xl:px-4 py-2 xl:py-0 w-full justify-between items-end xl:items-center border-bottom gap-3 relative ${
        !isAuthorized ? "pointer-events-none opacity-50" : ""
      }`}
    >
      <div
        className="flex flex-col xl:flex-row w-full gap-1"
        onClick={() => searchInputRef?.current?.focus()}
        onFocus={handleFocusSearch}
      >
        {isMobile && <SelectedFilters />}

        {isInputFocused && type === ScopeChatSearchTypes.FROM && !!suggestions[type]?.length && (
          <SearchDropdownMenu
            title="From user"
            menuItems={suggestions[type]}
            selectedIndex={selectedMenuItemIndex}
            onSelect={(item: any) =>
              handleSuggestionSelected(ScopeChatSearchTypes.FROM, prefix, item)
            }
            renderComponent={SearchOwnerSuggestions}
            isVisible={isInputFocused}
            top={isMobile ? currentSearchContainerHeight : SCOPE_CHAT_INPUT_CONTAINER_HEIGHT}
          />
        )}
        {isInputFocused && type === ScopeChatSearchTypes.TYPE && !!suggestions[type]?.length && (
          <SearchDropdownMenu
            title="Message type"
            menuItems={suggestions[type]}
            selectedIndex={selectedMenuItemIndex}
            onSelect={(item: any) =>
              handleSuggestionSelected(ScopeChatSearchTypes.TYPE, prefix, item)
            }
            renderComponent={SearchTypeSuggestions}
            isVisible={isInputFocused}
            top={isMobile ? currentSearchContainerHeight : SCOPE_CHAT_INPUT_CONTAINER_HEIGHT}
          />
        )}
        {isInputFocused && type === ScopeChatSearchTypes.BEFORE && (
          <SearchDatePicker
            onDateSelect={(displayValue: string, value: string) =>
              handleSuggestionSelected(ScopeChatSearchTypes.BEFORE, prefix, {
                value,
                displayValue,
              })
            }
          />
        )}
        {isInputFocused && type === ScopeChatSearchTypes.AFTER && (
          <SearchDatePicker
            onDateSelect={(displayValue: string, value: string) =>
              handleSuggestionSelected(ScopeChatSearchTypes.AFTER, prefix, {
                value,
                displayValue,
              })
            }
          />
        )}
        {isInputFocused && type === ScopeChatSearchTypes.ON && (
          <SearchDatePicker
            onDateSelect={(displayValue: string, value: string) =>
              handleSuggestionSelected(ScopeChatSearchTypes.ON, prefix, {
                value,
                displayValue,
              })
            }
          />
        )}
        {isInputFocused &&
          type === ScopeChatSearchTypes.KEYWORDS &&
          !!suggestions[type]?.length && (
            <SearchDropdownMenu
              title="Search keywords"
              menuItems={suggestions[type]}
              selectedIndex={selectedMenuItemIndex}
              onSelect={(item: any) => handleHelpItemSelected(item)}
              renderComponent={SearchHelp}
              isVisible={isInputFocused}
              top={isMobile ? currentSearchContainerHeight : SCOPE_CHAT_INPUT_CONTAINER_HEIGHT}
            />
          )}
        <div className="relative flex items-center gap-2 self-stretch w-full justify-between xl:h-12 xl:px-0">
          <div className="flex items-center w-full gap-2 cursor-text">
            <Button
              className="btn btn-ghost btn-square btn-sm rounded-md"
              onClick={() => setTriggerHandleSearch(true)}
            >
              <SearchIcon stroke="#ffffff" />
            </Button>
            <div className="flex w-full gap-2 items-center">
              {!isMobile && <SelectedFilters />}
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Search..."
                className="w-full input p-0 -my-2 rounded-none focus:border-none focus:outline-none !bg-transparent text-body leading-4 text-14 font-medium placeholder:text-body placeholder:text-14 placeholder:font-medium placeholder:text-neutral-white-60"
                value={query}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
              />
            </div>
          </div>
          {!isMobile && (
            <Button className="btn btn-ghost btn-square btn-sm rounded-md" onClick={handleClear}>
              <CloseIcon />
            </Button>
          )}
        </div>
      </div>

      <Button
        className="btn btn-ghost btn-square btn-sm rounded-md"
        onClick={() => handleShowPinnedMessages()}
      >
        <PinIcon />
      </Button>
    </div>
  );
};

export default SearchInput;
