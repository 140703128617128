import React from "react";
import { useProfile } from "@/providers/profile";

import { ScopeIcon } from "@/components/UI/Icons";
import AppLink from "@/components/AppLink";

import { FooterLinkTypeEnum, IFooterLink } from "@/types/app.types";
import { FOOTER_LINKS, FOOTER_SOCIAL_LINKS } from "@/constants/app.constants";

import "./Footer.styles.css";

const FooterLink = ({ link }: { link: IFooterLink }) => {
  return link.type === FooterLinkTypeEnum.EXTERNAL ? (
    <a
      href={link.path}
      target="_blank"
      rel="noreferrer"
      className="text-button text-12 font-medium text-white"
    >
      {link.Icon ? <link.Icon /> : `${link.title}⌝`}
    </a>
  ) : (
    <AppLink className="text-button text-12 font-medium text-white" to={link.path}>
      {link.Icon ? <link.Icon /> : `${link.title}⌝`}
    </AppLink>
  );
};

const Footer = () => {
  const { isAuthorized } = useProfile();

  return (
    <footer className="sticky bottom-0 flex justify-between px-8 py-2 w-full z-50">
      <div className="flex gap-6 items-center">
        <ScopeIcon />
        <div className="text-body text-14 font-regular hidden xl:flex">
          Copyright © 2023 Scope Sports Inc. All rights reserved.
        </div>
      </div>
      <div className="flex gap-6 items-center">
        {FOOTER_LINKS.map(
          (link: IFooterLink) =>
            (link.public || isAuthorized) && <FooterLink key={link.title} link={link} />,
        )}
      </div>

      <div className="flex gap-1 items-center">
        {FOOTER_SOCIAL_LINKS.map((link: IFooterLink) => (
          <FooterLink key={link.title} link={link} />
        ))}
      </div>
    </footer>
  );
};

export default Footer;
