import React from "react";

import PostForm from "./PostForm/PostForm";

const CreatePostForm = ({
  setIsVisible,
}: {
  setIsVisible: (value: boolean) => void;
}) => {
  return (
    <>
      <PostForm setIsVisible={setIsVisible} />
    </>
  );
};

export default CreatePostForm;
