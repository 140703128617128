import { createContext, useContext } from "react";

interface ExternalWalletContextType {
  isConnected: boolean;
  isExternalWalletActive: boolean;
  isVerifyWalletLoading: boolean;
  activeAccountAddress?: string;
  handleConnectWallet: () => void;
  handleDisconnectWallet: () => void;
  handleVerifyWallet: () => void;
  sendTransaction: (
    scopeWalletAddress: string,
    amountWei: string,
  ) => Promise<{
    fromAddress: string;
    toAddress: string;
    value: string;
    transactionHash: string;
  }>;
  handleTransferNft: () => Promise<void>;
  addChain: () => Promise<void>;
}

const ExternalWalletContext = createContext<ExternalWalletContextType | undefined>(undefined);

export function useExternalWallet(): ExternalWalletContextType {
  const context = useContext(ExternalWalletContext);

  if (!context) {
    throw new Error("useExternalWallet must be used within a ExternalWalletProvider");
  }

  return context;
}

export default ExternalWalletContext;
