import { createContext, useContext } from "react";

interface ServiceWorkerContextType {}

const ServiceWorkerContext = createContext<ServiceWorkerContextType | undefined>(undefined);

export const useServiceWorker = (): ServiceWorkerContextType => {
  const context = useContext(ServiceWorkerContext);

  if (!context) {
    throw new Error("useServiceWorker must be used within a ServiceWorkerProvider");
  }

  return context;
};

export default ServiceWorkerContext;
