/* eslint-disable react/no-unknown-property */
import React, { useRef } from "react";

import { useAppNavigate } from "@/app/hooks";
import useOnScreen from "@/app/hooks/useOnScreen";
import useIsTabActive from "@/app/hooks/useIsTabActive";
import { useGeneralUi } from "@/providers/generalUi";

import { MaximizeIcon } from "@/components/UI/Icons";
import Card3D from "@/components/Card3D";

import WebGL from "three/examples/jsm/capabilities/WebGL.js";
import { FanClubCard } from "@/types/card/card.types";

const CardMedia = ({ tradeCard }: { tradeCard: FanClubCard }) => {
  const navigate = useAppNavigate();

  const isWebGLAvailable = WebGL.isWebGLAvailable();
  const { isMobile } = useGeneralUi();
  const isTabActive = useIsTabActive();

  const cardMediaContainerRef = useRef<HTMLDivElement>(document.createElement("div"));

  const isVisible = !!useOnScreen(cardMediaContainerRef) && isTabActive;

  const handleShowCard = () => {
    navigate(`/card/${tradeCard.card.uuid}`);
  };

  return (
    <div
      ref={cardMediaContainerRef}
      className={`relative w-full h-full ${isMobile ? "!w-full !h-full relative" : ""}`}
    >
      {isWebGLAvailable ? (
        <Card3D card={tradeCard.card} color={tradeCard.color} isActive={isVisible} />
      ) : (
        <img
          src={tradeCard.card.image}
          className="cursor-pointer w-full h-full object-contain rounded-2xl"
        />
      )}
      {isMobile && <div className="absolute inset-0"></div>}
      <div
        className="btn btn-square btn-sm btn-ghost absolute top-3 right-3"
        onClick={handleShowCard}
      >
        <MaximizeIcon />
      </div>
    </div>
  );
};

export default CardMedia;
