import { useCallback, useRef, useState } from "react";

interface PressHandlers<T> {
  onLongPress: (event: React.MouseEvent<T> | React.TouchEvent<T>) => void;
  onClick?: (event: React.MouseEvent<T> | React.TouchEvent<T>) => void;
}

interface Options {
  delay?: number;
  shouldPreventDefault?: boolean;
}

const preventDefault = (event: Event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const isTouchEvent = (event: Event): event is TouchEvent => {
  return event && "touches" in event;
};

const useLongPress = <T>(
  { onLongPress, onClick }: PressHandlers<T>,
  { delay = 500, shouldPreventDefault = false }: Options = {},
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();
  const target = useRef<EventTarget>();

  const start = useCallback(
    (event: React.MouseEvent<T> | React.TouchEvent<T>) => {
      event.persist();
      const clonedEvent = { ...event };

      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, { passive: false });
        target.current = event.target;
      }

      timeout.current = setTimeout(() => {
        onLongPress(clonedEvent);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  const clear = useCallback(
    (event: React.MouseEvent<T> | React.TouchEvent<T>, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);

      shouldTriggerClick && !longPressTriggered && onClick?.(event);

      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  );

  return {
    onTouchStart: (event: React.TouchEvent<T>) => start(event),
    onTouchMove: (event: React.TouchEvent<T>) => clear(event, false),
    onTouchEnd: (event: React.TouchEvent<T>) => clear(event),
  };
};

export default useLongPress;
