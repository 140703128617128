import React, { PropsWithChildren, useState } from "react";
import {
  useApplyCouponMutation,
  useCreatePaymentMutation,
  useProcessPaymentMutation,
  useSetTransactionMutation,
} from "@/api/paymentsApi";
import { useGeneralUi } from "../generalUi";
import { useCheckAvailabilityToBuyCardMutation } from "@/api/userApi";

import PaymentContext from "./PaymentContext";

import { PlayerCard } from "@/types/card/card.types";
import { Payment, PaymentMethods } from "@/types/payment/payment.types";

const PaymentProvider = ({ children }: PropsWithChildren) => {
  const { isMobileApp } = useGeneralUi();
  const [checkAvailability] = useCheckAvailabilityToBuyCardMutation();
  const [createPayment, { isLoading: isCreatePaymentLoading }] = useCreatePaymentMutation();
  const [
    processPayment,
    { isLoading: isPaymentProcessing, isSuccess: isPaymentSuccessed, isError: isPaymentFailed },
  ] = useProcessPaymentMutation();
  const [applyCoupon, { isLoading: isApplyCouponLoading }] = useApplyCouponMutation();
  const [setTransaction, { isLoading: isSetTransactionLoading }] = useSetTransactionMutation();

  const [payment, setPayment] = useState<Payment>();

  const isDisabled = isMobileApp || import.meta.env.VITE_DISABLE_PROSHOP === "true";

  const checkAvailabilityToBuyCard = async (serialNumberGenerationId: number): Promise<void> => {
    const { cardGenerationRestriction, locationRestricted } =
      await checkAvailability(serialNumberGenerationId).unwrap();

    if (cardGenerationRestriction) {
      throw new Error("You already own a card from this card generation.");
    }

    if (locationRestricted) {
      throw new Error("This purchase cannot be completed from your location.");
    }
  };

  const handlePreparePayment = async (
    paymentMethod: PaymentMethods,
    tradeCard: PlayerCard,
  ): Promise<void> => {
    setPayment(undefined);
    if (!paymentMethod || !tradeCard) return;

    createPayment({ paymentMethod, tradeCardUuid: tradeCard.uuid })
      .unwrap()
      .then((payment) => setPayment(payment))
      .catch((error) => {
        throw new Error(error);
      });
  };

  const handleProcessPayment = async (
    onSuccessCallback = () => null,
    onErrorCallback = () => null,
  ): Promise<void> => {
    if (!payment?.uuid) return;

    processPayment({ paymentSessionUuid: payment.uuid })
      .unwrap()
      .then(() => {
        onSuccessCallback();
      })
      .catch((error) => {
        onErrorCallback();
        throw new Error(error);
      });
  };

  const handleApplyCoupon = async (code: string) => {
    if (!payment?.uuid || !code) return;

    applyCoupon({ code, paymentSessionUuid: payment.uuid })
      .unwrap()
      .then((payment) => setPayment(payment));
  };

  const handleSetTransaction = async (payload: {
    fromAddress: string;
    toAddress: string;
    value: string;
    transactionHash: string;
  }) => {
    if (!payment?.uuid) return;

    setTransaction({ ...payload, paymentSessionUuid: payment.uuid })
      .unwrap()
      .then((payment) => setPayment(payment));
  };

  return (
    <PaymentContext.Provider
      value={{
        payment,
        checkAvailabilityToBuyCard,
        handlePreparePayment,
        handleProcessPayment,
        isPaymentProcessing,
        isPaymentSuccessed,
        isPaymentFailed,
        handleApplyCoupon,
        handleSetTransaction,
        isLoading:
          isCreatePaymentLoading ||
          isPaymentProcessing ||
          isApplyCouponLoading ||
          isSetTransactionLoading,
        isDisabled,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
