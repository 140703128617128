import React from "react";

import { CardIcon } from "@/components/UI/Icons";

import { PlayerCard } from "@/types/card/card.types";

const CheckoutCard = ({ tradeCard }: { tradeCard: PlayerCard }) => {
  return (
    <div className="flex flex-col justify-between items-center gap-2 p-2 w-[312px] h-full bg-grayscale-900 rounded-xl mx-auto">
      <div className="relative overflow-hidden w-full rounded-lg">
        <img src={tradeCard.card.image} className="w-full" />
        <div className="absolute inset-0 bg-gradient-to-b from-[#00000000_30%] via-[#000_82%] to-black mix-blend-normal" />
        <div className="absolute inset-0 flex flex-col justify-end items-start px-4 py-5">
          <span className="text-13 font-heavy tracking-wider">{tradeCard.card.cardName}</span>
        </div>
      </div>
      <div className="flex items-center gap-2.5 w-full px-3 py-2">
        <span className="text-11 uppercase leading-4 tracking-wider">Member card</span>
        <CardIcon />
      </div>
    </div>
  );
};

export default CheckoutCard;
