import React from "react";
import ResetNewPasswordForm from "@/components/ResetNewPasswordForm";

const ResetPassword = () => {
  return (
    <div className="xl:px-10">
      <ResetNewPasswordForm />
    </div>
  );
};

export default ResetPassword;
