import React, { MutableRefObject, useEffect, useRef, useState, ReactNode } from "react";

import { batch } from "react-redux";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useAthletChatActivityMutation } from "@/api/playerApi";
import useWindowDimensions from "@/app/hooks/useWindowDimensions";
import {
  useGetMessageByIdMutation,
  useGetPlayerMessagesMutation,
  useGetPublicMessagesMutation,
  useGetScopeMessagesMutation,
  useSendMessageMutation,
} from "@/api/scopeChatApi";
import { useUploader } from "@/app/hooks/useUploader";

import Loading from "@/pages/Loading";

import { io, Socket } from "socket.io-client";
import {
  ScopeChatEvents,
  ScopeChatGroupedMessages,
  ScopeChatMessage,
  ScopeChatMessagePayload,
  ScopeChatMessageTypes,
  ScopeChatPinPayload,
  ScopeChatPollPayload,
  ScopeChatPollVotePayload,
  ScopeChatReactionPayload,
  ScopeChatSearchPayload,
  ScopeChatSearchSuggestionTypes,
  ScopeChatSearchTypes,
  ScopeChatSidebarElements,
  ScopeChatThread,
  ScopeChatThreadActions,
  ScopeChatUpdateMessagePayload,
  ScopeChatUserMetadata,
  ScopeChatUserTypes,
} from "@/types/scopeChat/scopeChat.types";
import {
  selectMessages,
  selectMessagesCount,
  selectGroupedMessages,
  selectHasNextPage,
  selectLastMessageDate,
  selectSidebarView,
  setMessages as setMessagesAction,
  setGroupedMessages as setGroupedMessagesAction,
  setLastMessageDate as setLastMessageDateAction,
  setHasNextPage as setHasNextPageAction,
  setSidebarView as setSidebarViewAction,
  // Search
  selectSuggestions,
  selectSearchResults,
  selectSearchResultsCount,
  setSuggestions as setSuggestionsAction,
  setSearchResults as setSearchResultsAction,
  // Thread
  selectThread,
  setThread as setThreadAction,
  // Pinned messages
  selectPinnedMessages,
  setPinnedMessages as setPinnedMessagesAction,
} from "@/reducers/scopeChat.slice";
import { handleWsException } from "@/utils/wsErrorHandler";
import {
  SCOPE_CHAT_CHANNEL_ID,
  SCOPE_CHAT_PUBLIC_MESSAGES_COUNT,
} from "@/constants/scopeChat.constants";
import { SCOPE_CHAT_WS_URL, TABLET_BREAKPOINT_WIDTH } from "@/constants/app.constants";
import { setIsLoading as setIsLoadingAction } from "@/reducers/scopeBlog.slice";
import EmojiPickerProvider from "../EmojiPickerProvider";
import MobileBottomMenuProvider from "../MobileBottomMenuProvider";
import ScopeChatContext from "./ScopeChatContext";
import { TargetTypes } from "@/types/uploader.types";

export let scopeChatSocket: Socket;

const ScopeChatProvider = ({
  children,
  channelId,
  isNotAllowed,
  isFanClubMuted,
}: {
  children: ReactNode;
  channelId?: string;
  isNotAllowed?: boolean;
  isFanClubMuted?: boolean;
}) => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { isAuthorized, userProfile, authToken, managedPlayerProfile, handleMuteUser } =
    useProfile();
  const {
    windowDimensions: { width: windowWidth },
  } = useWindowDimensions();
  const { uploadFiles, uploadProgress, isUploadInProgress } = useUploader();

  const isScopeManager = !!userProfile?.isScopeAdmin;
  const isMuted = !!userProfile?.isMuted || !!isFanClubMuted;

  const containerRef = useRef<HTMLDivElement>(document.createElement("div"));

  const [isHandlingReaction, setIsHandlingReaction] = useState<boolean>(false);
  const [isHandlingPin, setIsHandlingPin] = useState<boolean>(false);
  const [isHandlingVotePoll, setIsHandlingVotePoll] = useState<boolean>(false);
  const [isUpdatingMessage, setIsUpdatingMessage] = useState<boolean>(false);
  const [isDeletingMessage, setIsDeletingMessage] = useState<boolean>(false);
  const [isHandlingSuggestions, setIsHandlingSuggestions] = useState<boolean>(false);
  const [isHandlingPinnedMessages, setIsHandlingPinnedMessages] = useState<boolean>(false);

  const [isHandlingThread, setIsHandlingThread] = useState<boolean>(false);
  const [handleScrollToBottom, setHandleScrollToBottom] = useState<boolean>(false);

  const [messagesUpdatedCallback, setMessagesUpdatedCallback] = useState<() => void>(
    () => () => null,
  );
  // eslint-disable-next-line @typescript-eslint/ban-types
  const threadUpdatedCallbackRef = useRef<Function[]>([]);

  const [sendMessage, sendMessageStatus] = useSendMessageMutation();
  const [fetchMessageById, fetchMessageByIdStatus] = useGetMessageByIdMutation();
  const [fetchPublicMessages, fetchPublicMessagesStatus] = useGetPublicMessagesMutation();
  const [fetchScopeMessages, fetchScopeMessagesStatus] = useGetScopeMessagesMutation();
  const [fetchPlayerMessages, fetchPlayerMessagesStatus] = useGetPlayerMessagesMutation();
  const [fetchAthletChatActivity] = useAthletChatActivityMutation();

  const isMessagesLoadingRef = useRef<boolean>(
    fetchScopeMessagesStatus.isLoading || fetchPlayerMessagesStatus.isLoading,
  );

  const previousScrollHeightRef = useRef<number>(0);

  const messages = useAppSelector(selectMessages);
  const messagesCount = useAppSelector(selectMessagesCount);
  const groupedMessages = useAppSelector(selectGroupedMessages);
  const lastMessageDate = useAppSelector(selectLastMessageDate);
  const hasNextPage = useAppSelector(selectHasNextPage);
  const sidebarView = useAppSelector(selectSidebarView);
  const messagesRef = useRef<typeof messages>(messages);
  const hasNextPageRef = useRef<boolean>(hasNextPage);
  const lastMessageDateRef = useRef<typeof lastMessageDate>(lastMessageDate);

  const [isHandlingSearch, setIsHandlingSearch] = useState<boolean>(false);
  const suggestions = useAppSelector(selectSuggestions);
  const searchResults = useAppSelector(selectSearchResults);
  const searchResultsCount = useAppSelector(selectSearchResultsCount);

  const thread = useAppSelector(selectThread);
  const threadMessagesHasNextPageRef = useRef<boolean>(true);
  const threadRef = useRef<typeof thread>(thread);

  const pinnedMessages = useAppSelector(selectPinnedMessages);

  const setMessages = (messages: ScopeChatMessage[]) => {
    const messagesMap = new Map(messages.map((message) => [message.id, message]));

    const uniqueMessages = Array.from(messagesMap.values());
    messagesRef.current = uniqueMessages;

    const groupedMessages = groupMessagesByDate(uniqueMessages);
    batch(() => {
      dispatch(setMessagesAction(uniqueMessages));
      dispatch(setGroupedMessagesAction(groupedMessages));
    });
  };

  const setThreadMessages = (messages: ScopeChatMessage[]) => {
    const messagesMap = new Map(messages.map((message) => [message.id, message]));

    const uniqueMessages = Array.from(messagesMap.values());
    const groupedMessages = groupMessagesByDate(uniqueMessages);

    const _thread = {
      ...threadRef.current,
      hasNextPage: threadMessagesHasNextPageRef.current,
      lastMessageDate: uniqueMessages[0]?.createdAt?.toString(),
      messages: uniqueMessages,
      groupedMessages,
    };

    setThread(_thread);
  };

  const setPinnedMessages = (messages: ScopeChatMessage[]) => {
    dispatch(setPinnedMessagesAction(groupMessagesByDate(messages)));
  };

  const setSidebarView = (element: ScopeChatSidebarElements | undefined) => {
    dispatch(setSidebarViewAction(element));
  };

  const setLastMessageDate = (lastMessageDate: string | undefined) => {
    lastMessageDateRef.current = lastMessageDate;
    dispatch(setLastMessageDateAction(lastMessageDate));
  };

  const setHasNextPage = (value: boolean) => {
    hasNextPageRef.current = value;
    dispatch(setHasNextPageAction(value));
  };

  const setSuggestions = (type: ScopeChatSearchSuggestionTypes, newSuggestions: any[]) => {
    const updatedSuggestions = { ...suggestions, [type]: newSuggestions };
    dispatch(setSuggestionsAction(updatedSuggestions));
  };

  const setSearchResults = (searchResults: ScopeChatMessage[]) => {
    const groupedMessages = groupMessagesByDate(searchResults);
    dispatch(setSearchResultsAction({ groupedMessages, count: searchResults.length }));
  };

  const setThread = (thread: ScopeChatThread) => {
    threadRef.current = thread;
    dispatch(setThreadAction(thread));
  };

  const setIsSearchResultsVisible = (value: boolean) => {
    if (value) {
      setSidebarView(ScopeChatSidebarElements.SEARCH);
      navigate(`${location.pathname}?section=${ScopeChatSidebarElements.SEARCH}`);
    } else {
      setSidebarView(undefined);
      navigate(location.pathname);
    }
  };

  const setIsThreadVisible = (value: boolean) => {
    if (value) {
      setSidebarView(ScopeChatSidebarElements.THREAD);
      navigate(`${location.pathname}?section=${ScopeChatSidebarElements.THREAD}`);
    } else {
      setThread({});
      setSidebarView(undefined);
      navigate(location.pathname);
    }
  };

  const setIsPinnedMessagesVisible = (value: boolean) => {
    if (value) {
      setSidebarView(ScopeChatSidebarElements.PINNED_MESSAGES);
      navigate(`${location.pathname}?section=${ScopeChatSidebarElements.PINNED_MESSAGES}`);
    } else {
      setSidebarView(undefined);
      navigate(location.pathname);
    }
  };

  const setIsLoading = (value: boolean) => {
    setTimeout(() => dispatch(setIsLoadingAction(value)), 200);
  };

  const scrollToMessageById = (
    messageId: string,
    behavior: ScrollBehavior = "instant" as ScrollBehavior,
    thread?: boolean,
  ) => {
    if (windowWidth < TABLET_BREAKPOINT_WIDTH && !thread) {
      setSidebarView(undefined);
    }

    const messageElement = document.getElementById(messageId);

    if (!messageElement) return;

    setTimeout(() => {
      messageElement.scrollIntoView({ behavior, block: "start" });
      messageElement.classList.add("!bg-info/10");

      setTimeout(() => {
        messageElement.classList.remove("!bg-info/10");
      }, 2000);

      setMessagesUpdatedCallback(() => () => null);
    }, 500);
  };

  const handleGetThread = () => {
    batch(() => {
      setIsHandlingThread(true);
      setIsThreadVisible(true);
    });

    scopeChatSocket.emit(ScopeChatEvents.HANDLE_GET_THREAD, {
      threadMessageId: threadRef.current.messageId,
      lastMessageDate: threadRef.current.lastMessageDate,
      toMessageDate: threadRef.current.toMessageDate,
      channelId,
    });
  };

  const handleShowThread = async (threadMessageId: string, toMessageDate?: string) => {
    if (threadRef.current.messageId === threadMessageId) return;

    threadRef.current.messageId && unsubscribeThread(threadRef.current.messageId);

    batch(() => {
      setIsThreadVisible(true);
      setThread({
        channelId,
        toMessageDate,
        messageId: threadMessageId,
        groupedMessages: undefined,
      });
    });
  };

  const handleShowPinnedMessages = () => {
    setIsPinnedMessagesVisible(true);
  };

  const handleSearch = (searchPayload: ScopeChatSearchPayload) => {
    batch(() => {
      setIsHandlingSearch(true);
      setIsSearchResultsVisible(true);
    });
    scopeChatSocket.emit(ScopeChatEvents.HANDLE_SEARCH, { ...searchPayload, channelId });
  };

  const handleShowMessage = (message: ScopeChatMessage) => {
    const messageId = message.id;
    const messageDate = message.createdAt;
    const threadMessageId = message.parentId;

    const isMessageLoaded = messagesRef.current.some(
      (message: ScopeChatMessage) => message.id === messageId,
    );

    if (isMessageLoaded) {
      scrollToMessageById(messageId, "smooth");
    } else if (threadMessageId) {
      handleShowThread(threadMessageId, messageDate.toString());
      threadUpdatedCallbackRef.current.push(() => {
        scrollToMessageById(messageId, "smooth", true);
      });
    } else {
      setMessagesUpdatedCallback(() => () => {
        scrollToMessageById(messageId, "smooth");
      });
      getMessages({
        toMessageDate: messageDate.toString(),
      });
    }
  };

  const handleScrollToMessage = async (messageId: string) => {
    const message = await fetchMessageById(messageId).unwrap();

    if (!message) return;

    handleShowMessage(message);
  };

  const handleMessageReaction = async (messageId: string, content: string) => {
    if (!userProfile?.uuid || isMuted) return;

    setIsHandlingReaction(true);
    const reactionPayload: ScopeChatReactionPayload = {
      messageId,
      content,
      ownerUuid: managedPlayerProfile?.uuid || userProfile.uuid,
      channelId,
    };
    scopeChatSocket.emit(ScopeChatEvents.HANDLE_REACTION, reactionPayload);
  };

  const handleMessagePin = async (messageId: string, isPinned: boolean) => {
    if (!userProfile?.uuid) return;

    setIsHandlingPin(true);
    const pinPayload: ScopeChatPinPayload = {
      messageId,
      isPinned,
      ownerUuid: managedPlayerProfile?.uuid || userProfile.uuid,
      channelId,
    };

    scopeChatSocket.emit(ScopeChatEvents.HANDLE_PIN_MESSAGE, pinPayload);
  };

  const handleMessageUpdate = (messageId: string, values: Partial<ScopeChatMessage>) => {
    if (!userProfile?.uuid) return;

    setIsUpdatingMessage(true);
    const updateMessagePayload: Partial<ScopeChatUpdateMessagePayload> = {
      messageId,
      ownerUuid: managedPlayerProfile?.uuid || userProfile.uuid,
      channelId,
      ...values,
    };
    scopeChatSocket.emit(ScopeChatEvents.HANDLE_UPDATE_MESSAGE, updateMessagePayload);
  };

  const handleVotePoll = async (messageId: string, option: number) => {
    if (!userProfile?.uuid) return;

    setIsHandlingVotePoll(true);
    const votePayload: ScopeChatPollVotePayload = {
      messageId,
      option,
      ownerUuid: managedPlayerProfile?.uuid || userProfile.uuid,
      channelId,
    };
    scopeChatSocket.emit(ScopeChatEvents.HANDLE_VOTE_POLL, votePayload);
  };

  const handleMessageDelete = async (messageId: string) => {
    if (!userProfile?.uuid) return;

    setIsDeletingMessage(true);

    scopeChatSocket.emit(ScopeChatEvents.HANDLE_DELETE_MESSAGE, {
      messageId,
      channelId,
      ownerUuid: managedPlayerProfile?.uuid || userProfile.uuid,
    });
  };

  const getSuggestions = (type: ScopeChatSearchTypes, prefix: string) => {
    if (!userProfile?.uuid || !type || !scopeChatSocket) return;

    setIsHandlingSuggestions(true);

    scopeChatSocket.emit(ScopeChatEvents.HANDLE_GET_SUGGESTIONS, { type, prefix });
  };

  const getPinnedMessages = () => {
    if (!userProfile?.uuid) return;

    setIsHandlingPinnedMessages(true);

    scopeChatSocket.emit(ScopeChatEvents.HANDLE_GET_PINNED_MESSAGES);
  };

  const getMessages = async ({
    lastMessageDate,
    toMessageDate,
    clean,
  }: {
    lastMessageDate?: string;
    toMessageDate?: string;
    clean?: boolean;
  }) => {
    if (!channelId) return;

    const { messages: olderMessages, hasNextPage } = isAuthorized
      ? channelId === SCOPE_CHAT_CHANNEL_ID
        ? await fetchScopeMessages({
            lastMessageDate,
            toMessageDate,
          }).unwrap()
        : await fetchPlayerMessages({
            channelId,
            params: {
              lastMessageDate,
              toMessageDate,
            },
          }).unwrap()
      : await fetchPublicMessages({
          lastMessageDate,
          toMessageDate,
        }).unwrap();

    clean && (messagesRef.current = []);

    const combinedMessages = [...(olderMessages || []), ...messagesRef.current];

    batch(() => {
      setMessages(combinedMessages);
      setLastMessageDate(combinedMessages?.[0]?.createdAt);
      setHasNextPage(hasNextPage);
      setIsLoading(false);
    });
  };

  const groupMessagesByDate = (messages: ScopeChatMessage[]) =>
    messages.reduce((acc, message) => {
      const date = new Date(message.createdAt).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {} as ScopeChatGroupedMessages);

  const handleSendMessage = async ({
    content,
    type = ScopeChatMessageTypes.TEXT,
    parentId,
    pollPayload,
    mediaFiles,
  }: {
    content: string;
    type: ScopeChatMessageTypes;
    parentId?: string;
    pollPayload?: ScopeChatPollPayload;
    mediaFiles?: { uuid: string; file: File }[];
  }) => {
    if (!userProfile?.uuid || !channelId) return;

    if (managedPlayerProfile) fetchAthletChatActivity({ playerUuid: managedPlayerProfile?.uuid });

    const messagePayload: ScopeChatMessagePayload = {
      channelId,
      ownerUuid: managedPlayerProfile?.uuid || userProfile.uuid,
      content,
      type,
      parentId,
      pollPayload,
      attachmentUuids: mediaFiles ? mediaFiles.map((mediaPayload) => mediaPayload.uuid) : [],
      isDraft: true,
    };

    await sendMessage(messagePayload);

    if (mediaFiles?.length) {
      await uploadFiles(
        mediaFiles.map((payload) => ({
          targetType: TargetTypes.CHAT_ATTACHMENT,
          payload,
        })),
      );
    }
  };

  const setUserMetadata = (metadata: ScopeChatUserMetadata) => {
    scopeChatSocket && scopeChatSocket.emit(ScopeChatEvents.SET_USER_METADATA, metadata);
  };

  const handleMessagesScroll = async (containerRef: MutableRefObject<HTMLDivElement>) => {
    if (!containerRef.current) return;

    const { height } = containerRef.current.getBoundingClientRect();
    const scrollHeight = containerRef.current.scrollHeight - height;
    const triggerHeight = scrollHeight - scrollHeight * 0.1;

    if (
      Math.abs(containerRef.current.scrollTop) >= triggerHeight &&
      hasNextPageRef.current &&
      !isMessagesLoadingRef.current
    ) {
      isMessagesLoadingRef.current = true;
      await getMessages({ lastMessageDate: lastMessageDateRef.current });
    }
  };

  const handleThreadScroll = async (containerRef: MutableRefObject<HTMLDivElement>) => {
    if (!containerRef.current || !threadRef.current.messageId) return;

    if (containerRef.current.scrollTop === 0 && threadMessagesHasNextPageRef.current) {
      previousScrollHeightRef.current = containerRef.current.scrollHeight;

      handleGetThread();

      threadUpdatedCallbackRef.current.push(() => {
        const newScrollHeight = containerRef.current.scrollHeight;
        containerRef.current.scrollTop = newScrollHeight - previousScrollHeightRef.current;
      });
    }
  };

  const subscribeThread = (threadMessageId: string) => {
    scopeChatSocket.off(ScopeChatEvents.THREAD);
    scopeChatSocket.on(
      ScopeChatEvents.THREAD,
      ({
        messages,
        hasNextPage,
        threadName,
      }: {
        messages: ScopeChatMessage[];
        hasNextPage: boolean;
        threadName: string;
      }) => {
        const combinedMessages = [...messages, ...(threadRef.current.messages || [])];
        threadMessagesHasNextPageRef.current = hasNextPage;

        batch(() => {
          setThread({ ...threadRef.current, threadName });
          setThreadMessages(combinedMessages);
          setIsHandlingThread(false);
        });
      },
    );

    scopeChatSocket.off(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.ADD}`,
    );
    scopeChatSocket.on(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.ADD}`,
      (message: ScopeChatMessage) => {
        const threadMessages = [...(threadRef.current.messages || []), message];

        setThreadMessages(threadMessages);
      },
    );

    scopeChatSocket.off(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.UPDATE}`,
    );
    scopeChatSocket.on(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.UPDATE}`,
      (message: ScopeChatMessage) => {
        const { id } = message;

        const threadMessages = threadRef.current.messages || [];

        const updatedMessageIndex = threadMessages.findIndex(
          (message: ScopeChatMessage) => message.id === id,
        );

        if (updatedMessageIndex === -1) return;

        const updatedThreadMessages = [...threadMessages];

        updatedThreadMessages[updatedMessageIndex] = message;

        batch(() => {
          setIsHandlingReaction(false);
          setIsUpdatingMessage(false);
          setIsHandlingPin(false);
          setThreadMessages(updatedThreadMessages);
        });
      },
    );

    scopeChatSocket.off(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.DELETE}`,
    );
    scopeChatSocket.on(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.DELETE}`,
      ({ _id }: { _id: string }) => {
        const threadMessages = threadRef.current.messages || [];
        batch(() => {
          setIsDeletingMessage(false);
          setThreadMessages(threadMessages.filter((message) => message.id !== _id));
        });
      },
    );
  };

  const unsubscribeThread = (threadMessageId: string) => {
    scopeChatSocket.off(ScopeChatEvents.THREAD);
    scopeChatSocket.off(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.ADD}`,
    );
    scopeChatSocket.off(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.UPDATE}`,
    );
    scopeChatSocket.off(
      `${ScopeChatEvents.THREAD}/${threadMessageId}/${ScopeChatThreadActions.DELETE}`,
    );
  };

  const subscribe = () => {
    if (!userProfile?.uuid) return;

    scopeChatSocket.emit(ScopeChatEvents.JOIN_CHANNEL, { channelId });

    scopeChatSocket.off(ScopeChatEvents.EXCEPTION);
    scopeChatSocket.on(ScopeChatEvents.EXCEPTION, (exception) => {
      handleWsException(exception);

      if (exception?.error?.code === "403" && !isMuted) {
        handleMuteUser();
      }

      batch(() => {
        setIsHandlingReaction(false);
        setIsUpdatingMessage(false);
        setIsHandlingPin(false);
      });
    });

    scopeChatSocket.off(ScopeChatEvents.PINNED_MESSAGES);
    scopeChatSocket.on(ScopeChatEvents.PINNED_MESSAGES, (messages: ScopeChatMessage[]) => {
      batch(() => {
        setIsHandlingPinnedMessages(false);
        setPinnedMessages(messages);
      });
    });

    scopeChatSocket.off(ScopeChatEvents.NEW_MESSAGE);
    scopeChatSocket.on(ScopeChatEvents.NEW_MESSAGE, (message: ScopeChatMessage) => {
      batch(() => {
        if ([userProfile.uuid, managedPlayerProfile?.uuid].includes(message.owner.externalUuid)) {
          setHandleScrollToBottom(true);
        }

        setMessages([...messagesRef.current, message]);
      });
    });

    scopeChatSocket.off(ScopeChatEvents.SUGGESTIONS);
    scopeChatSocket.on(
      ScopeChatEvents.SUGGESTIONS,
      ({ type, suggestions }: { type: ScopeChatSearchSuggestionTypes; suggestions: any[] }) => {
        batch(() => {
          setIsHandlingSuggestions(false);
          setSuggestions(type, suggestions);
        });
      },
    );

    scopeChatSocket.off(ScopeChatEvents.SEARCH);
    scopeChatSocket.on(ScopeChatEvents.SEARCH, (messages: ScopeChatMessage[]) => {
      batch(() => {
        setIsHandlingSearch(false);
        setSearchResults(messages);
      });
    });

    scopeChatSocket.off(ScopeChatEvents.UPDATE_MESSAGE);
    scopeChatSocket.on(ScopeChatEvents.UPDATE_MESSAGE, (message: ScopeChatMessage) => {
      const { id } = message;
      const updatedMessageIndex = messagesRef.current.findIndex(
        (message: ScopeChatMessage) => message.id === id,
      );

      if (updatedMessageIndex === -1) return;
      const updatedMessages = [...messagesRef.current];
      updatedMessages[updatedMessageIndex] = message;

      if (threadRef.current.messageId === id && threadRef.current.messages) {
        const updatedThreadMessageIndex = threadRef.current.messages.findIndex(
          (message: ScopeChatMessage) => message.id === id,
        );

        if (updatedThreadMessageIndex !== -1) {
          const updatedThreadMessages = [...threadRef.current.messages];
          updatedThreadMessages[updatedThreadMessageIndex] = message;

          setThreadMessages(updatedThreadMessages);
        }
      }

      batch(() => {
        setMessages(updatedMessages);
        setIsHandlingReaction(false);
        setIsUpdatingMessage(false);
        setIsHandlingPin(false);
        setIsHandlingVotePoll(false);
      });
    });

    scopeChatSocket.off(ScopeChatEvents.DELETE_MESSAGE);
    scopeChatSocket.on(ScopeChatEvents.DELETE_MESSAGE, ({ messageId }: { messageId: string }) => {
      batch(() => {
        setMessages(messagesRef.current.filter((message) => message.id !== messageId));
        setIsDeletingMessage(false);
      });
    });
  };

  const unsubscribe = () => {
    scopeChatSocket.off(ScopeChatEvents.EXCEPTION);
    scopeChatSocket.off(ScopeChatEvents.NEW_MESSAGE);
    scopeChatSocket.off(ScopeChatEvents.UPDATE_MESSAGE);
    scopeChatSocket.off(ScopeChatEvents.DELETE_MESSAGE);
    scopeChatSocket.off(ScopeChatEvents.SUGGESTIONS);
    scopeChatSocket.off(ScopeChatEvents.SEARCH);
    scopeChatSocket.off(ScopeChatEvents.THREAD);
  };

  const getFirstPage = async () => {
    await getMessages({});
  };

  const initChat = () => {
    batch(() => {
      setMessages([]);
      setLastMessageDate(undefined);
      setHasNextPage(true);
    });
  };

  const sendMetadataAndSubscribe = async () => {
    if (!scopeChatSocket?.active || !userProfile?.uuid) return;

    let metadata: ScopeChatUserMetadata;

    if (managedPlayerProfile?.uuid) {
      metadata = {
        externalUuid: managedPlayerProfile.uuid,
        name: managedPlayerProfile.name,
        type: ScopeChatUserTypes.ATHLETE,
      };
    } else {
      metadata = {
        externalUuid: userProfile.uuid,
        name: `${userProfile.fullName}`,
        type: userProfile.scopeChatType,
      };
    }

    setUserMetadata(metadata);
    setSidebarView(undefined);
    subscribe();
  };

  useEffect(() => {
    if (!thread.messageId || !scopeChatSocket?.active) return;
    subscribeThread(thread.messageId);
    handleGetThread();
  }, [thread.messageId]);

  useEffect(() => {
    if (!Object.keys(thread.groupedMessages || {}).length) return;

    setTimeout(() => {
      threadUpdatedCallbackRef.current.forEach((callback) => callback());
      threadUpdatedCallbackRef.current = [];
    }, 500);
  }, [thread.groupedMessages, threadUpdatedCallbackRef.current]);

  useEffect(() => {
    isMessagesLoadingRef.current =
      fetchScopeMessagesStatus.isLoading || fetchPlayerMessagesStatus.isLoading;
  }, [fetchScopeMessagesStatus.isLoading, fetchPlayerMessagesStatus.isLoading]);

  useEffect(() => {
    if (scopeChatSocket?.active) {
      setTimeout(() => {
        messagesUpdatedCallback();
      }, 100);
    }
  }, [messages, scopeChatSocket?.active]);

  useEffect(() => {
    if (!channelId || isNotAllowed) {
      initChat();
      return;
    }
    sendMetadataAndSubscribe();
  }, [scopeChatSocket?.active, userProfile?.uuid, managedPlayerProfile?.uuid, channelId]);

  useEffect(() => {
    if (isAuthorized && !isNotAllowed) {
      scopeChatSocket = io(SCOPE_CHAT_WS_URL, {
        extraHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else {
      scopeChatSocket && scopeChatSocket.close();
      batch(() => {
        setSidebarView(undefined);
        setMessages(messagesRef.current.slice(-SCOPE_CHAT_PUBLIC_MESSAGES_COUNT));
        setLastMessageDate(undefined);
      });
    }
  }, [isAuthorized]);

  useEffect(() => {
    switch (sidebarView) {
      case ScopeChatSidebarElements.SEARCH:
        setThread({});
        break;
      case ScopeChatSidebarElements.THREAD:
        setSearchResults([]);
        break;
      case ScopeChatSidebarElements.PINNED_MESSAGES:
        getPinnedMessages();
        break;
      default:
        setThread({});
        setSearchResults([]);
        break;
    }
  }, [sidebarView]);

  useEffect(() => {
    return () => {
      if (scopeChatSocket) {
        unsubscribe();
        scopeChatSocket.close();
      }
      initChat();
    };
  }, []);

  useEffect(() => {
    if (scopeChatSocket) {
      unsubscribe();
    }

    initChat();

    if (!channelId || isNotAllowed) {
      return;
    }

    sendMetadataAndSubscribe();
    getFirstPage();
  }, [channelId]);

  return (
    <ScopeChatContext.Provider
      value={{
        isScopeManager,
        messages,
        messagesCount,
        isMessagesLoading: isMessagesLoadingRef.current,
        groupedMessages,
        isSendingMessage: sendMessageStatus.isLoading,
        isHandlingReaction,
        isUpdatingMessage,
        isHandlingPin,
        isHandlingVotePoll,
        isMuted,
        isUploadInProgress,
        sidebarView,
        handleScrollToBottom,
        containerRef,
        channelId,
        handleSendMessage,
        handleMessageReaction,
        handleMessageUpdate,
        handleMessagePin,
        handleMessageDelete,
        handleVotePoll,
        setUserMetadata,
        handleMessagesScroll,
        setHandleScrollToBottom,
        setSidebarView,
        handleScrollToMessage,
        uploadProgress,

        // Search
        suggestions,
        searchResults,
        searchResultsCount,
        isHandlingSearch,
        setIsSearchResultsVisible,
        handleSearch,
        getSuggestions,
        handleShowMessage,

        // Thread
        isHandlingThread,
        thread,
        handleShowThread,
        handleGetThread,
        setIsThreadVisible,
        handleThreadScroll,

        // Pinned messages
        pinnedMessages,
        isHandlingPinnedMessages,
        getPinnedMessages,
        handleShowPinnedMessages,
        setIsPinnedMessagesVisible,
      }}
    >
      <EmojiPickerProvider>
        <MobileBottomMenuProvider>
          {fetchScopeMessagesStatus.isLoading && !messages.length && <Loading />}
          {children}
        </MobileBottomMenuProvider>
      </EmojiPickerProvider>
    </ScopeChatContext.Provider>
  );
};

export default ScopeChatProvider;
