import { FRONTEND_URL } from "@/constants/app.constants";
import { DEFAULT_BLOCKCHAIN_ID } from "@/constants/crypto.constants";
import { IS_CRYPTO_WALLET_DISABLED } from "@/constants/app.constants";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWalletInfo,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import type { Metadata, Chain } from "@web3modal/scaffold-utils/ethers";

const useWeb3Wallet = () => {
  const { open: handleConnectWallet } = useWeb3Modal();
  const { address: activeAccountAddress, isConnected } = useWeb3ModalAccount();
  const { walletInfo } = useWalletInfo();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();

  return {
    handleConnectWallet,
    activeAccountAddress,
    isConnected,
    walletInfo,
    walletProvider,
    disconnect,
  };
};

export const initWeb3Modal = () => {
  if (IS_CRYPTO_WALLET_DISABLED) return;

  const projectId = import.meta.env.VITE_WEB_3_MODAL_PROJECT_ID;

  const mainnet: Chain = {
    chainId: DEFAULT_BLOCKCHAIN_ID,
    name: "Avalanche",
    currency: "AVAX",
    explorerUrl: "https://snowtrace.io",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  };

  const metadata: Metadata = {
    name: "Scope",
    description: "Scope Sports",
    url: FRONTEND_URL,
    icons: [`${FRONTEND_URL}icons/icon-192.png`],
  };

  const ethersConfig = defaultConfig({
    metadata,

    /*Optional*/
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    defaultChainId: DEFAULT_BLOCKCHAIN_ID,
  });

  createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // TODO: check
    themeVariables: {
      "--w3m-accent": "#FF4C34",
      "--w3m-font-family": "Roobert",
    },
  });
};

export const disabledState: {
  handleConnectWallet: (options?: any) => Promise<void>;
  activeAccountAddress?: `0x${string}`;
  isConnected: boolean;
  walletInfo: any;
  walletProvider?: any;
  disconnect: () => Promise<void>;
} = {
  handleConnectWallet: async () => {},
  isConnected: false,
  walletInfo: {},
  disconnect: async () => {},
};
export default useWeb3Wallet;
