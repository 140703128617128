import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from "react";
import { batch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useGetPlatformSettingsMutation } from "@/api/platformSettingsApi";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import useWindowDimensions from "@/app/hooks/useWindowDimensions";

import GeneralUiContext from "./GeneralUiContext";

import Loading from "@/pages/Loading";
import MetaPixel from "@/components/MetaPixel";

import ProfileProvider from "../profile";
import FullscreenModalProvider from "../fullscreenModal";
import ExternalWalletProvider from "../externalWallet";
import PaymentProvider from "../payment";
import AppGatewayProvider from "../appGateway";

import {
  selectDynamicBackground,
  selectIsGlobalLoading,
  selectStaticBackground,
  setDynamicBackground as setDynamicBackgroundAction,
  setStaticBackground as setStaticBackgroundAction,
} from "@/reducers/ui.slice";
import {
  selectStaticBackground as selectDefaultStaticBackground,
  selectMaintenanceMode,
  selectIsPWA,
  setPlatformSettings,
  setIsPWA,
  setIsIosMobileApp,
  setIsAndroidMobileApp,
  selectIsIosMobileApp,
  selectIsAndroidMobileApp,
} from "@/reducers/platformSettings.slice";
import { selectPlayerProfile } from "@/reducers/scopeBlog.slice";
import { IBackgroundSettings, IPlatformSettings } from "@/types/app.types";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
import Cookie from "js-cookie";
import {
  ANDROID_MOBILE_APP_IDENTIFIER,
  IOS_MOBILE_APP_IDENTIFIER,
  IS_DEV,
} from "@/constants/app.constants";
import ServiceWorkerProvider from "../serviceWorker/ServiceWorkerProvider";
import ReportProvider from "../report";
import BlockUserProvider from "../blockUser";

const hasIosMessagingCapability = () =>
  !!window.webkit &&
  !!window.webkit.messageHandlers &&
  !!window.webkit.messageHandlers["push-permission-request"] &&
  !!window.webkit.messageHandlers["push-permission-state"];

const GeneralUiProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useAppNavigate();
  const [searchParams] = useSearchParams();

  const { isMobile, isDesktop } = useWindowDimensions();

  const maintenanceMode = useAppSelector(selectMaintenanceMode);

  const defaultStaticBackground = useAppSelector(selectDefaultStaticBackground);

  const selectedPlayerProfile = useAppSelector(selectPlayerProfile);
  const dynamicBackground = useAppSelector(selectDynamicBackground);
  const staticBackground = useAppSelector(selectStaticBackground);
  const isGlobalLoading = useAppSelector(selectIsGlobalLoading);

  const [fetchPlatformSettings] = useGetPlatformSettingsMutation();

  const [isBlured, setIsBlured] = useState<boolean>(false);
  const [isGradient, setIsGradient] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [header, setHeader] = useState<ReactNode>(null);
  const [defaultBackgroundSettings, setDefaultBackgroundSettings] = useState<IBackgroundSettings>();

  const backgroundVideoRef = useRef<HTMLVideoElement>(null);

  const isPWA = useAppSelector(selectIsPWA);
  const isIosMobileApp = useAppSelector(selectIsIosMobileApp);
  const isAndroidMobileApp = useAppSelector(selectIsAndroidMobileApp);
  const isTwa = import.meta.env.VITE_IS_TWA === "true";
  const isMobileApp =
    isIosMobileApp || isAndroidMobileApp || isPWA || import.meta.env.VITE_IS_TWA === "true";

  useEffect(() => {
    if (isTwa && isDesktop && !IS_DEV) {
      window.location.replace(import.meta.env.VITE_FRONTEND_URL);
      return;
    }

    if (isDesktop) return;

    const isIosApp =
      Cookie.get("app-platform") === IOS_MOBILE_APP_IDENTIFIER && hasIosMessagingCapability();

    const isAndroidApp =
      (document.referrer || "").includes(ANDROID_MOBILE_APP_IDENTIFIER) ||
      !!searchParams.get(ANDROID_MOBILE_APP_IDENTIFIER);

    const isPWA = window.matchMedia("(display-mode: standalone)").matches;

    batch(() => {
      dispatch(setIsPWA(isPWA));
      dispatch(setIsIosMobileApp(isIosApp));
      dispatch(setIsAndroidMobileApp(isAndroidApp));
    });

    isAndroidMobileApp && navigate(location.pathname);
  }, []);

  useEffect(() => {
    getPlatformSettings();
  }, [location]);

  useEffect(() => {
    if (!defaultBackgroundSettings) return;

    const { dynamicBackground: defaultDynamic, staticBackground: defaultStatic } =
      defaultBackgroundSettings || {};
    const {
      dynamicBackground: playerDynamic,
      staticBackground: playerStatic,
      thumbnails,
    } = selectedPlayerProfile?.backgroundSettings || {};

    const newDynamicBackground = playerDynamic || defaultDynamic;
    const newStaticBackground = thumbnails?.md || playerStatic || defaultStatic;

    if (dynamicBackground !== newDynamicBackground) {
      setDynamicBackground(newDynamicBackground);
    }
    if (staticBackground !== newStaticBackground) {
      setStaticBackground(newStaticBackground);
    }
  }, [selectedPlayerProfile, dynamicBackground, staticBackground, defaultBackgroundSettings]);

  const getPlatformSettings = async () => {
    fetchPlatformSettings({})
      .then((response: any) => {
        if (response.error) {
          return;
        }

        const {
          defaultBackground: {
            dynamicBackground,
            staticBackground,
            thumbnails: { md },
          },
        } = response.data as IPlatformSettings;

        batch(() => {
          dispatch(setPlatformSettings(response.data));
          setDefaultBackgroundSettings(response.data.defaultBackground);
          setDynamicBackground(dynamicBackground);
          setStaticBackground(md || staticBackground);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setDynamicBackground = (src: string) => {
    dispatch(setDynamicBackgroundAction(src));
  };

  const setStaticBackground = (src: string) => {
    dispatch(setStaticBackgroundAction(src));
  };

  const useStaticBackground = (value: boolean) => {
    if (value) {
      backgroundVideoRef.current?.pause();
    } else {
      backgroundVideoRef.current?.play();
    }
  };

  const useGradientBackground = (value: boolean) => {
    setIsGradient(value);
  };

  const useBluredBackground = (value: boolean) => {
    setIsBlured(value);
  };

  const handleStaticBackgroundError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    const target = event.target as HTMLImageElement;
    target.src = defaultStaticBackground;
  };

  const handleDynamicBackgroundError = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    const target = event.target as HTMLVideoElement;
    target.poster = defaultStaticBackground;
    target.src = "";
  };

  return (
    <GeneralUiContext.Provider
      value={{
        maintenanceMode,
        dynamicBackground,
        staticBackground,
        setDynamicBackground,
        setStaticBackground,
        useBluredBackground,
        useStaticBackground,
        useGradientBackground,
        setHeader,
        getPlatformSettings,
        isMobile,
        isDesktop,
        isPWA,
        isMobileApp,
        isIosMobileApp,
        isAndroidMobileApp,
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <div className="relative w-full h-full">
          <div className="fixed inset-0">
            <div className="absolute inset-0">
              {isMobile ? (
                <img
                  className="w-full h-full object-cover"
                  src={staticBackground}
                  onError={handleStaticBackgroundError}
                />
              ) : (
                <video
                  ref={backgroundVideoRef}
                  className="w-full h-full object-cover"
                  poster={staticBackground}
                  src={dynamicBackground}
                  onError={handleDynamicBackgroundError}
                  autoPlay
                  muted
                  loop
                ></video>
              )}
            </div>
            {isGradient && (
              <>
                <div className="absolute inset-0 bg-element-background" />
                <div className="absolute inset-0 opacity-50 bg-gradient-to-b from-pure-black to-transparent" />
              </>
            )}
            {isBlured && <div className="absolute inset-0 backdrop-blur-[22px]" />}
            {!isGradient && <div className="absolute inset-0 backdrop-blur-[5px]" />}
          </div>
          {isGlobalLoading && <Loading />}
          <ProfileProvider>
            <MetaPixel />
            <ExternalWalletProvider>
              <PaymentProvider>
                <FullscreenModalProvider>
                  <AppGatewayProvider>
                    <ServiceWorkerProvider>
                      <ReportProvider>
                        <BlockUserProvider>
                          <div
                            id="ios_content"
                            className={`fixed ios-inset-0 z-10 flex flex-col ${
                              isMobile ? "p-4" : "p-0"
                            } gap-4`}
                          >
                            {isMobile && header}
                            <div className="flex-1 w-full relative">
                              <div className="absolute inset-0 overflow-hidden overflow-y-scroll scrollbar-hidden">
                                {children}
                              </div>
                            </div>
                          </div>
                        </BlockUserProvider>
                      </ReportProvider>
                    </ServiceWorkerProvider>
                  </AppGatewayProvider>
                </FullscreenModalProvider>
              </PaymentProvider>
            </ExternalWalletProvider>
          </ProfileProvider>
          <ToastContainer
            className="bottom-4 xs:left-1/2 xs:-translate-x-1/2 z-auto"
            style={{ zIndex: 9999999 }}
          />
          <Toaster gutter={0} containerStyle={{ position: "absolute", inset: "0px" }} />
        </div>
      )}
    </GeneralUiContext.Provider>
  );
};

export default GeneralUiProvider;
