interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

export const TIME_KOEFFICIENT = 60;

export const convertToTimeFormat = (currentTime: number): Time => {
  return {
    hours: Math.floor(currentTime / TIME_KOEFFICIENT / TIME_KOEFFICIENT),
    minutes: Math.floor((currentTime / TIME_KOEFFICIENT) % TIME_KOEFFICIENT),
    seconds: Math.floor(currentTime % TIME_KOEFFICIENT),
  };
};

export const addZeroIfNecessary = (number: number) => ("0" + number).slice(-2);

export const convertToString = (
  time: Time,
  options?: { hours?: boolean; minutes?: boolean; seconds?: boolean },
): string => {
  if (!options) {
    options = {
      hours: true,
      minutes: true,
      seconds: true,
    };
  }

  const { hours, minutes, seconds } = options;
  let formattedString = "";

  if (hours) {
    formattedString += addZeroIfNecessary(time.hours);
  }
  if (minutes) {
    formattedString += `${hours ? ":" : ""}${addZeroIfNecessary(time.minutes)}`;
  }
  if (seconds) {
    formattedString += `${hours || minutes ? ":" : ""}${addZeroIfNecessary(time.seconds)}`;
  }

  return formattedString;
};
