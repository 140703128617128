import React, { useState } from "react";

import Datepicker from "tailwind-datepicker-react";

const DATEPICKER_OPTIONS = {
  autoHide: true,
  todayBtn: true,
  clearBtn: false,
  maxDate: new Date("2030-01-01"),
  minDate: new Date("1950-01-01"),
  theme: {
    background: "bg-grayscale-900 dark:bg-grayscale-900",
    todayBtn: "!btn-primary",
    clearBtn: "!btn-ghost",
    icons: "!btn-ghost",
    text: "",
    disabledText: "!text-neutral-white-50",
    input: "hidden",
    inputIcon: "hidden",
    selected: "bg-primary",
  },
  icons: {
    prev: () => <span>{"<"}</span>,
    next: () => <span>{">"}</span>,
  },
  datepickerClassNames: "top-4",
};

const SearchDatePicker = ({
  onDateSelect,
}: {
  onDateSelect: (displayValue: string, value: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleChangeFrom = (selectedDate: Date) => {
    if (
      !selectedDate ||
      (selectedDate.getHours() !== 0 &&
        selectedDate.getMinutes() !== 0 &&
        selectedDate.getSeconds() !== 0)
    )
      return;

    onDateSelect(selectedDate.toLocaleDateString(), selectedDate.toDateString());
  };

  const handleCloseFrom = (state: boolean) => {
    setIsOpen(state);
  };

  return (
    <div className="flex absolute">
      <Datepicker
        options={DATEPICKER_OPTIONS}
        onChange={handleChangeFrom}
        show={isOpen}
        setShow={handleCloseFrom}
      />
    </div>
  );
};

export default SearchDatePicker;
