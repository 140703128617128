import React, { useEffect, useLayoutEffect } from "react";

import { batch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useGeneralUi } from "@/providers/generalUi";

import FanClubProvider from "@/providers/fanClub";
import ScopeChatProvider from "@/providers/scopeChat";

import BlogSelector from "@/components/ScopeBlog/BlogSelector";
import ScopeChat from "@/components/ScopeBlog/ScopeChat/ScopeChat";
import AthleteFanClub from "@/components/ScopeBlog/FanClub/AthleteFanClub";
import MobileAthleteFanClub from "@/components/ScopeBlog/FanClub/MobileAthleteFanClub";
import Loading from "./Loading";
import MobileBlogSelector from "@/components/ScopeBlog/BlogSelector/MobileBlogSelector";

import {
  selectPlayerProfile,
  selectPlayerProfiles,
  setPlayerProfile,
} from "@/reducers/scopeBlog.slice";
import {
  setCommentUuid,
  setPostUuid,
  setPremiumContentUuid,
  setSection,
} from "@/reducers/fanClub.slice"; // TODO: Move to FanClub provider
import { setMessageId } from "@/reducers/scopeChat.slice";
import { BlogTypes, ViewTypes } from "@/types/fanClub/fanClub.types";
import { SCOPE_CHAT_CHANNEL_ID } from "@/constants/scopeChat.constants";

const ScopeBlog = ({ blogType }: { blogType?: BlogTypes }) => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");
  const commentUuid = searchParams.get("commentUuid");
  const postUuid = searchParams.get("postUuid");
  const premiumContentUuid = searchParams.get("premiumContentUuid");
  const messageId = searchParams.get("messageId");

  const { isMobile } = useGeneralUi();
  const { playerUuid, postUuid: oldPostUuid } = useParams();
  const { managedPlayerProfile } = useProfile();

  const playerProfile = useAppSelector(selectPlayerProfile);
  const playerProfiles = useAppSelector(selectPlayerProfiles);

  const isFanClubBlog = blogType === BlogTypes.FAN_CLUB;
  const showBlogSelector = (isMobile && !blogType) || !isMobile;
  const showBlog = (isMobile && blogType) || !isMobile;

  const currentPlayerProfile =
    playerProfiles.find((playerProfile) => playerProfile.uuid === managedPlayerProfile?.uuid) ||
    playerProfile;

  useLayoutEffect(() => {
    if (!messageId && section && !Object.values(ViewTypes).includes(section as ViewTypes)) return;

    batch(() => {
      dispatch(setCommentUuid(commentUuid));
      dispatch(setSection(section));
      dispatch(setPremiumContentUuid(premiumContentUuid));
      dispatch(setPostUuid(postUuid || oldPostUuid));
      dispatch(setMessageId(section));
    });
  }, [commentUuid, postUuid, oldPostUuid, section, premiumContentUuid]);

  useEffect(() => {
    let playerProfile;

    if (playerUuid && playerProfiles.length) {
      playerProfile = playerProfiles.find(
        (playerProfile) =>
          playerProfile.uuid === playerUuid ||
          (playerProfile.urlAlias && playerProfile.urlAlias === playerUuid),
      );

      if (!playerProfile) {
        navigate("/");
      } else if (playerUuid && playerProfile.urlAlias && playerUuid !== playerProfile.urlAlias) {
        navigate(window.location.pathname.replace(playerUuid, playerProfile.urlAlias));
      }
    }

    dispatch(setPlayerProfile(playerProfile));
  }, [playerUuid, playerProfiles]);

  return (
    <div className={`flex items-start h-full gap-6 ${isMobile ? "" : "xl:px-6"} py-[1px]`}>
      {showBlogSelector && (isMobile ? <MobileBlogSelector /> : <BlogSelector />)}
      {showBlog && (
        <div className="flex relative flex-col w-full h-full xl:flex-row mobile:gap-4">
          {isFanClubBlog ? (
            currentPlayerProfile ? (
              <FanClubProvider playerProfile={currentPlayerProfile}>
                {isMobile ? <MobileAthleteFanClub /> : <AthleteFanClub />}
              </FanClubProvider>
            ) : (
              <Loading blur={40} />
            )
          ) : (
            <ScopeChatProvider channelId={SCOPE_CHAT_CHANNEL_ID}>
              <ScopeChat messageId={messageId} />
            </ScopeChatProvider>
          )}
        </div>
      )}
    </div>
  );
};

export default ScopeBlog;
