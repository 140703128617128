import { RootState } from "@/app/store";
import { PlayerCard } from "@/types/card/card.types";
import { PlayerProfileType } from "@/types/player/player.types";
import { createSlice } from "@reduxjs/toolkit";

export const proshopSlice = createSlice({
  name: "proshop",
  initialState: {
    proshopPlayerProfiles: [],
    selectedProshopPlayerUuid: undefined,
    tradeCards: [],
    searchByPlayerNameQuery: "",
  },
  reducers: {
    setProshopPlayerProfiles: (state, action) => {
      state.proshopPlayerProfiles = action.payload;
    },
    setSelectedProshopPlayerUuid: (state, action) => {
      state.selectedProshopPlayerUuid = action.payload;
    },
    setTradeCards: (state, action) => {
      state.tradeCards = action.payload;
    },
    clearTradeCards: (state) => {
      state.tradeCards = [];
    },
    setSearchByPlayerNameQuery: (state, action) => {
      state.searchByPlayerNameQuery = action.payload;
    },
  },
});

export const {
  setProshopPlayerProfiles,
  setSelectedProshopPlayerUuid,
  setTradeCards,
  clearTradeCards,
  setSearchByPlayerNameQuery,
} = proshopSlice.actions;

export const selectProshopPlayerProfiles = (state: RootState): PlayerProfileType[] =>
  state.proshop.proshopPlayerProfiles;

export const selectSelectedProshopPlayerUuid = (state: RootState): undefined | string =>
  state.proshop.selectedProshopPlayerUuid;

export const selectSearchByPlayerNameQuery = (state: RootState): string =>
  state.proshop.searchByPlayerNameQuery;

export const selectTradeCards = (
  state: RootState,
): { tradeCard: PlayerCard; available: number }[] => state.proshop.tradeCards;

export default proshopSlice.reducer;
