import React from "react";

import Poll from "../Feed/Post/Poll/Poll";
import Container from "@/components/UI/Container";
import MediaGridWrapper from "../../Common/MediaGridWrapper";

import { PostType, PostTypes } from "@/types/fanClub/fanClub.types";

const PostCard = ({ post }: { post: PostType }) => {
  return (
    <Container
      className="flex flex-col w-full h-full gap-0 bg-transparent"
      border="rounded-none m-0 border-none"
    >
      {post.type === PostTypes.POLL ? (
        <Poll post={post} />
      ) : (
        <div className="flex-1 w-full h-full overflow-hidden overflow-y-scroll scrollbar-hidden">
          {!!post.attachments?.length && <MediaGridWrapper media={post.attachments} minified />}
          {post.type === PostTypes.TEXT_NOTE && (
            <div className="p-4 text-body text-14 font-regular w-full text-clip text-start">
              {post.body}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default PostCard;
