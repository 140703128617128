import React, { useRef } from "react";

import { useAppSelector } from "@/app/hooks";
import { useFanClub } from "@/providers/fanClub";
import { useGeneralUi } from "@/providers/generalUi";
import useAnimateScroll from "@/app/hooks/useAnimateScroll";

import Button from "@/components/UI/Button";
import Container from "@/components/UI/Container";

import { FAN_CLUB_VIEWS } from "@/constants/fanClub.constants";
import { ViewTypes } from "@/types/fanClub/fanClub.types";
import { selectCurrentView } from "@/reducers/fanClub.slice";

const MobileViewSelector = () => {
  const { isMobile } = useGeneralUi();
  const { setCurrentView } = useFanClub();

  const currentView = useAppSelector(selectCurrentView);
  const viewList: ViewTypes[] = [
    ViewTypes.CARDS,
    ViewTypes.PREMIUM_CONTENT,
    ViewTypes.FEED,
    ViewTypes.CHAT,
  ];
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useAnimateScroll(scrollContainerRef);

  return (
    <Container
      ref={scrollContainerRef}
      className={`p-2 gap-2 h-14 overflow-hidden overflow-x-scroll scrollbar-hidden ${
        !isMobile ? "hidden" : ""
      }`}
    >
      {viewList.map((key) => {
        const view = key as keyof typeof FAN_CLUB_VIEWS;
        const label = FAN_CLUB_VIEWS[view].label;
        const isActive = view === currentView;
        return (
          <Button
            key={key}
            className={`btn btn-ghost rounded-xl min-h-[40px] h-10 border-inherit focus:border-inherit ${
              isActive ? "!bg-white !bg-opacity-100 !text-black" : "!bg:white text:black"
            }`}
            onClick={() => setCurrentView(view)}
          >
            {label}
          </Button>
        );
      })}
    </Container>
  );
};

export default MobileViewSelector;
