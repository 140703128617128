import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useFullscreenModal } from "@/providers/fullscreenModal";

import ProfileAvatar from "@/components/User/ProfileAvatar";
import { GradientScopeIcon } from "@/components/UI/Icons";
import GreetingsModal from "../../Modals/GreetingsModal";
import Container from "@/components/UI/Container";

import { PlayerProfileType } from "@/types/player/player.types";
import {
  selectPlayerProfile,
  setPlayerProfile,
  selectGroupedPlayerProfiles,
} from "@/reducers/scopeBlog.slice";

const PlayerItem = ({
  playerProfile,
  isActive,
  onClick,
}: {
  playerProfile: PlayerProfileType;
  isActive: boolean;
  onClick: (playerProfile: PlayerProfileType) => void;
}) => {
  return (
    <div className="flex relative w-full" onClick={() => onClick(playerProfile)}>
      {isActive && <ActiveItemBackground />}
      <div className="flex w-full items-center cursor-pointer gap-3 p-1">
        <ProfileAvatar profile={playerProfile} imageClass="!w-8 !h-8" />
        <div className="card-body p-0 gap-0 items-start ">
          <span className="text-body text-16 font-medium text-start truncate block w-[160px]">
            {playerProfile.name}
          </span>
        </div>
      </div>
    </div>
  );
};

const ScopeItem = () => {
  return (
    <div className="flex flex-col w-full items-start cursor-pointer gap-6 p-4 rounded-lg bg-neutral-white-12">
      <GradientScopeIcon className="!w-10 !h-10" />
      <div className="flex flex-col p-0 gap-2 items-start text-neutral-white-pure-white">
        <span className="text-body text-16 font-bold text-start">Scope Community</span>
        <span className="text-body text-14 font-regular text-start">
          Welcome to the Scope Community, click here to access the chat.
        </span>
      </div>
    </div>
  );
};

const ActiveItemBackground = () => (
  <div className="absolute -top-1 -left-1 -bottom-1 -right-1 bg-neutral-white-12 rounded-lg flex"></div>
);

const GroupLabel = ({ label, className }: { label: string; className?: string }) => (
  <div
    className={`w-full text-start text-button text-10 font-heavy text-neutral-white-50 items-center justify-start ${
      className ? className : ""
    }`}
  >
    {label}
  </div>
);

const MobileBlogSelector = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { managedPlayerProfile, isNew, isEulaAgreed } = useProfile();
  const { setModal, setIsModalOpen } = useFullscreenModal();

  const groupedPlayerProfiles = useAppSelector(selectGroupedPlayerProfiles);
  const playerProfile = useAppSelector(selectPlayerProfile);

  const [currentManagedPlayerProfile, setCurrentManagedPlayerProfile] =
    useState<PlayerProfileType>();

  const { uuid: currentPlayerUuid } = playerProfile || {};

  useEffect(() => {
    const isGreetingsNeeded = isNew || !isEulaAgreed;
    isGreetingsNeeded && setModal(<GreetingsModal />);
    setIsModalOpen(isGreetingsNeeded);
  }, [isNew, isEulaAgreed]);

  useEffect(() => {
    if (!managedPlayerProfile?.uuid) {
      setCurrentManagedPlayerProfile(undefined);
      return;
    }
    setCurrentManagedPlayerProfile(
      groupedPlayerProfiles.managed?.find((player) => player.uuid === managedPlayerProfile.uuid),
    );
  }, [managedPlayerProfile]);

  const handleScopeChatClick = () => {
    navigate("/scope-chat");
  };

  const handlePlayerFanClubClick = (playerProfile: PlayerProfileType) => {
    dispatch(setPlayerProfile(playerProfile));
    navigate(`/fan-club/${playerProfile.urlAlias || playerProfile.uuid}`);
  };

  return (
    <Container className="py-3 px-4 flex flex-col w-full">
      <div className="flex flex-col items-start gap-2">
        <h4 className="text-heading font-medium text-neutral-white-pure-white">Home</h4>
      </div>
      <div className="flex flex-col items-center justify-center text-white gap-4 w-full">
        <div className={`flex relative w-full`} onClick={handleScopeChatClick}>
          <ScopeItem />
        </div>

        {!!groupedPlayerProfiles.managed?.length && !currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="Managed" />

            {groupedPlayerProfiles.managed.map((playerProfile: PlayerProfileType) => (
              <PlayerItem
                key={playerProfile.uuid}
                playerProfile={playerProfile}
                isActive={currentPlayerUuid === playerProfile.uuid}
                onClick={handlePlayerFanClubClick}
              />
            ))}
          </>
        )}

        {!!groupedPlayerProfiles.owned?.length && !currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="My Athletes" />

            {groupedPlayerProfiles.owned.map((playerProfile: PlayerProfileType) => (
              <PlayerItem
                key={playerProfile.uuid}
                playerProfile={playerProfile}
                isActive={currentPlayerUuid === playerProfile.uuid}
                onClick={handlePlayerFanClubClick}
              />
            ))}
          </>
        )}

        {!!groupedPlayerProfiles.rest?.length && !currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="Athletes" />

            {groupedPlayerProfiles.rest.map((playerProfile: PlayerProfileType) => (
              <PlayerItem
                key={playerProfile.uuid}
                playerProfile={playerProfile}
                isActive={currentPlayerUuid === playerProfile.uuid}
                onClick={handlePlayerFanClubClick}
              />
            ))}
          </>
        )}

        {currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="Athletes" className="xl:flex" />

            <PlayerItem
              playerProfile={currentManagedPlayerProfile}
              isActive={currentPlayerUuid === currentManagedPlayerProfile.uuid}
              onClick={handlePlayerFanClubClick}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default MobileBlogSelector;
