import React from "react";

const SearchHelp = ({
  value,
  displayValue,
  ...restProps
}: {
  value: any;
  displayValue: string;
  [x: string]: any;
}) => {
  return (
    <>
      <div className="flex items-center px-2 justify-center rounded-[4px] bg-neutral-white-12">
        <span className="text-neutral-white-pure-white">{value}</span>
      </div>
      <span className="text-body text-14 font-medium text-white">{restProps?.description}</span>
    </>
  );
};

export default SearchHelp;
