import { FooterLinkTypeEnum, IFooterLink, INavbarLink, IProfileMenuItem } from "@/types/app.types";
import { ProfileTypes } from "@/types/profile-types.enum";
import {
  BillingIcon,
  HelpSupportIcon,
  LogoutIcon,
  RosterIcon,
  AccountIcon,
  TwitterXFlatIcon,
  LinkedInFlatIcon,
  YouTubeFlatIcon,
  InstagramFlatIcon,
  CryptoWalletIcon,
  InstallAppIcon,
} from "@/components/UI/Icons";
import { user } from "@/helpers/user.helpers";
import { history } from "@/helpers/history.helpers";

export const IS_DEV = import.meta.env.VITE_NODE_ENV === "development";
export const IS_CRYPTO_WALLET_DISABLED = import.meta.env.VITE_DISABLE_CRYPTO === "true";

export const FRONTEND_TWA_URL = import.meta.env.VITE_FRONTEND_TWA_URL;
export const FRONTEND_URL =
  import.meta.env.VITE_IS_TWA === "true" ? FRONTEND_TWA_URL : import.meta.env.VITE_FRONTEND_URL;
export const DEFAULT_PROFILE_URL = FRONTEND_URL + "img/default-avatar.png";
export const SCOPE_NAME = "Scope";

export const ENTER_KEY = "Enter";
export const BACKSPACE_KEY = "Backspace";
export const ESCAPE_KEY = "Escape";
export const ARROW_UP_KEY = "ArrowUp";
export const ARROW_DOWN_KEY = "ArrowDown";
export const ARROW_LEFT_KEY = "ArrowLeft";
export const ARROW_RIGHT_KEY = "ArrowRight";

export const ARROW_KEYS = [ARROW_UP_KEY, ARROW_DOWN_KEY, ARROW_LEFT_KEY, ARROW_RIGHT_KEY];

export const TABLET_BREAKPOINT_WIDTH = 1200;

export const MOBILE_HEADERS_HEIGHT = 56;
export const CHAT_HEADER_HEIGHT = 48;
export const CHAT_INPUT_HEIGHT = 48;
export const MOBILE_PADDING_OFFSET = 24;
export const DEFAULT_INPUT_HEIGHT = 40;

export const getNavbarMenuItems = ({ isMobileApp }: { isMobileApp: boolean }): INavbarLink[] => [
  {
    title: "Home",
    path: "/",
    activePaths: ["/", "/home", "/fan-club", "/scope-chat"],
    profileTypes: [ProfileTypes.PLAYER_PROFILE_TYPE, ProfileTypes.USER_PROFILE_TYPE],
    public: true,
    isActive: true,
    testId: "navbar-link-home",
  },
  {
    title: "Pro shop",
    path: "/pro-shop",
    activePaths: ["/pro-shop"],
    profileTypes: [ProfileTypes.USER_PROFILE_TYPE],
    public: true,
    isActive: true,
    isHidden: isMobileApp,
    testId: "navbar-link-pro-shop",
  },
  {
    title: "Marketplace",
    path: "/marketplace",
    activePaths: ["/marketplace"],
    profileTypes: [ProfileTypes.USER_PROFILE_TYPE],
    public: true,
    isActive: false,
    isHidden: isMobileApp,
    badge: "soon",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    activePaths: ["/dashboard"],
    profileTypes: [ProfileTypes.PLAYER_PROFILE_TYPE],
    public: false,
    isActive: true,
  },
];

export const DIVIDER = "divider";
export const ICON_ITEM = "icon_item";

export const getProfileMenuItems = ({
  isWalletConnected,
  isMobile,
  isMobileApp,
  showInstallAppModal,
}: {
  isWalletConnected: boolean;
  isMobile?: boolean;
  isMobileApp?: boolean;
  showInstallAppModal?: () => void;
}): IProfileMenuItem[] => [
  { type: DIVIDER },
  {
    type: ICON_ITEM,
    Icon: RosterIcon,
    title: "My Cards",
    testId: "my-cards",
    path: "/my-roster",
  },
  {
    type: ICON_ITEM,
    Icon: AccountIcon,
    title: "Profile",
    testId: "profile-link-settings",
    path: "/account-settings?section=profile",
  },
  // {
  //   type: ICON_ITEM,
  //   Icon: CryptoWalletIcon,
  //   title: "Connect wallet",
  //   testId: "connect-wallet-link-settings",
  //   path: "/account-settings?section=connect-wallet",
  //   hidden: isWalletConnected,
  // },
  {
    type: ICON_ITEM,
    Icon: BillingIcon,
    title: "Billing",
    path: "/account-settings?section=billing",
  },
  { type: DIVIDER },
  {
    type: ICON_ITEM,
    Icon: HelpSupportIcon,
    title: "Help & Support",
    path: "/support",
  },
  {
    type: ICON_ITEM,
    Icon: InstallAppIcon,
    title: "Install iPhone App",
    testId: "install-app-link-settings",
    action: isMobile
      ? () => (window.location.href = `${FRONTEND_TWA_URL}install`)
      : showInstallAppModal,
    hidden: isMobileApp,
  },
  {
    type: ICON_ITEM,
    Icon: LogoutIcon,
    title: "Logout",
    path: "",
    testId: "profile-link-logout",
    action: () => user.logout(),
  },
];

export const ATHLETE_DESCRIPTION_MAX_SYMBOLS = 100;

export const FOOTER_SOCIAL_LINKS: IFooterLink[] = [
  {
    title: "Twitter",
    Icon: TwitterXFlatIcon,
    path: "https://twitter.com/scopesports_io",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "LinkedIn",
    Icon: LinkedInFlatIcon,
    path: "https://www.linkedin.com/company/81939499",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "YouTube",
    Icon: YouTubeFlatIcon,
    path: "https://www.youtube.com/@scope-sports",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "Insta",
    Icon: InstagramFlatIcon,
    path: "https://www.instagram.com/scopesports.io/",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
];

export const FOOTER_LINKS: IFooterLink[] = [
  {
    title: "Resources",
    path: "https://www.scopesports.io/documentation",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "Terms & Conditions",
    path: "https://www.scopesports.io/docs/terms-and-conditions",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "Privacy Policy",
    path: "https://www.scopesports.io/docs/privacy-policy",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "Community Guidelines",
    path: "https://www.scopesports.io/docs/community-guidelines",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },

  {
    title: "Blog",
    path: "https://www.scopesports.io/blog",
    type: FooterLinkTypeEnum.EXTERNAL,
    public: true,
  },
  {
    title: "Help & support page",
    path: "/support",
    type: FooterLinkTypeEnum.INTERNAL,
    public: false,
  },
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PRO_SHOP_BUTTON = ({
  buttonCaption,
  playerUuid,
}: {
  buttonCaption?: string;
  playerUuid?: string;
}) => ({
  buttonCaption: buttonCaption || "Go To Pro Shop",
  onButtonClick: () => {
    history.navigate(`/pro-shop/${playerUuid ?? ""}`);
  },
});

export const FAN_CLUB_POSTS_STEP = 5;

export const SUGGESTED_BID_STEP = 1;
export const BID_CHANGE_STEP = 0.5;
export const BUYER_FEE_PERCENT = 5;

export const EMAIL_NOT_VERIFIED_REASON = "email_not_verified";

// WebSocket urls
const WS_URL = import.meta.env.VITE_WS_URL;

export const APP_GATEWAY_WS_URL = WS_URL + "app";
export const NOTIFICATIONS_WS_URL = WS_URL + "notifications";
export const WISHLIST_WS_URL = WS_URL + "wishlist";
export const VIDEO_PROCESSING_WS_URL = WS_URL + "media";
export const PROSHOP_WS_URL = WS_URL + "proshop";
export const FAN_CLUB_WS_URL = WS_URL + "fan-club";
export const SCOPE_CHAT_WS_URL = WS_URL + "scope-chat";

export const IOS_MOBILE_APP_IDENTIFIER = "iOS App Store";
export const ANDROID_MOBILE_APP_IDENTIFIER = "android-app";
