import { createSlice } from "@reduxjs/toolkit";
import { PlayerProfileType } from "../types/player/player.types";
import { MemberProfileType } from "@/types/user/profile.types";
import { RootState } from "@/app/store";

export const playerSlice = createSlice({
  name: "player",
  initialState: {
    profile: undefined,
    members: [],
    membersSkip: 0,
    membersTotal: 0,
    membersSearchQuery: "",
  },
  reducers: {
    setPlayerProfile: (state, action) => {
      state.profile = action.payload;
    },
    setMembers: (state, action) => {
      state.members = action.payload;
    },
    setMembersSkip: (state, action) => {
      state.membersSkip = action.payload;
    },
    setMembersTotal: (state, action) => {
      state.membersTotal = action.payload;
    },
    setMembersSearchQuery: (state, action) => {
      state.membersSearchQuery = action.payload;
    },
  },
});

export const {
  setPlayerProfile,
  setMembers,
  setMembersSkip,
  setMembersTotal,
  setMembersSearchQuery,
} = playerSlice.actions;

export const selectPlayerProfile = (state: RootState): undefined | PlayerProfileType =>
  state.player.profile;

export const selectMembers = (state: RootState): MemberProfileType[] => state.player.members;

export const selectMembersSkip = (state: RootState): number => state.player.membersSkip;

export const selectMembersTotal = (state: RootState): number => state.player.membersTotal;
export const selectMembersSearchQuery = (state: RootState): string =>
  state.player.membersSearchQuery;

export default playerSlice.reducer;
