import { ReactNode, createContext, useContext } from "react";

export interface FullscreenModalType {
  title: string;
  body: ReactNode;
  headerClass?: string;
  bodyClass?: string;
  cancelHandler?: () => void;
}

interface FullscreenModalContextType {
  setFullscreenModal: (modalProps: FullscreenModalType) => void;
  setIsFullscreenModalOpen: (value: boolean) => void;
  setModal: (modal: ReactNode) => void;
  setIsModalOpen: (value: boolean) => void;
  handleCloseModal: () => void;
}

const FullscreenModalContext = createContext<FullscreenModalContextType | undefined>(undefined);

export const useFullscreenModal = (): FullscreenModalContextType => {
  const context = useContext(FullscreenModalContext);

  if (!context) {
    throw new Error("useFullscreenModal must be used within a FullscreenModalProvider");
  }

  return context;
};

export default FullscreenModalContext;
