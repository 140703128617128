import React, { useEffect, useLayoutEffect } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useGeneralUi } from "./providers/generalUi";
import { useProfile } from "@/providers/profile";
import { useAppNavigate } from "@/app/hooks";

// Providers
import NotificationCenterProvider from "./providers/NotificationCenterProvider";
import WebSocketProvider from "./providers/WebSocketProvider";

// Layouts
import MobileLayout from "./layouts/MobileLayout";
import PublicPagesLayout from "./layouts/PublicPagesLayout";
import PageLayout from "./layouts/PageLayout";
import FullScreenLayout from "./layouts/FullScreenLayout";

// Pages
import AccountSettings from "./pages/AccountSettings";
import CheckEmail from "./pages/CheckEmail";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import MyCards from "./pages/MyCards";
import NotFound from "./pages/NotFound";
import NotificationCenter from "./pages/NotificationCenter";
import OAuth from "./pages/OAuth";
import Members from "./pages/PlayerDashboard/Members";
import PlayerDashboard from "./pages/PlayerDashboard/PlayerDashboard";
import Proshop from "./pages/ProShop";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import SignUp from "./pages/SignUp";
import Support from "./pages/Support";
import ScopeBlog from "./pages/ScopeBlog";
import VerifyEmail from "./pages/VerifyEmail";
import Onboard from "./pages/Onboard";
import PremiumContentPreview from "./pages/PremiumContentPreview";
import CardViewer from "./pages/CardViewer";
import MobileBlogSelector from "./components/ScopeBlog/BlogSelector/MobileBlogSelector";
import MobileNotificationCenter from "./pages/NotificationCenter/MobileNotificationCenter";
import Welcome from "./pages/Welcome";
import PurchaseSuccess from "./pages/PurchaseSuccess";
import MaintenancePage from "./pages/MaintenancePage";
import ConfirmEmail from "./pages/ConfirmEmail";
import InstallPWA from "./pages/InstallPWA";
import InstallPWAInstructions from "./pages/InstallPWAInstructions";

import { history } from "./helpers/history.helpers";
import { user } from "./helpers/user.helpers";
import { notificationsSocket } from "@/providers/WebSocketProvider";
import { BlogTypes } from "./types/fanClub/fanClub.types";
import { UserTrackingActions } from "./types/notification/notification.types";
import PreLoader from "./components/Card3D/PreLoader";

function App() {
  history.navigate = useAppNavigate();
  history.location = useLocation();

  const { isAuthorized, managedPlayerProfile, logOut, userProfile, isAdmin } = useProfile();
  const { maintenanceMode, isMobile, isMobileApp } = useGeneralUi();

  const allowMaintenanceInteraction = !maintenanceMode || (maintenanceMode && isAdmin);

  user.logout = logOut;

  useEffect(() => {
    if (
      userProfile &&
      !userProfile?.isOnboarded &&
      history.location?.pathname &&
      !(
        history.location.pathname.includes("onboard") ||
        history.location.pathname.includes("login") ||
        history.location.pathname.includes("sign-in")
      )
    ) {
      history.navigate("/onboard", { state: { from: history.location } });
    }
  }, [userProfile, history.location]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useLayoutEffect(() => {
    if (!userProfile || !notificationsSocket) return;

    notificationsSocket.emit(UserTrackingActions.START_TRACK_USER, JSON.stringify(userProfile));

    return () => {
      notificationsSocket.emit(UserTrackingActions.END_TRACK_USER, JSON.stringify(userProfile));
    };
  }, [userProfile, notificationsSocket]);

  return (
    <>
      {isAuthorized && allowMaintenanceInteraction && (
        <>
          <WebSocketProvider />
          <NotificationCenterProvider />
        </>
      )}

      <PreLoader />

      <Routes>
        <Route path="*" element={maintenanceMode ? <MaintenancePage /> : <NotFound />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/oauth/:accessToken/:role" element={<OAuth />} />

        {isMobile ? (
          <Route path="/install" element={<InstallPWAInstructions />} />
        ) : (
          <Route path="/install" element={<InstallPWA />} />
        )}

        {!allowMaintenanceInteraction ? (
          <Route
            element={<PublicPagesLayout staticBackground gradientBackground bluredBackground />}
          >
            <Route path="/sign-in" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Route>
        ) : (
          <>
            {/* General routes */}
            <Route
              element={<PublicPagesLayout staticBackground gradientBackground bluredBackground />}
            >
              <Route path="/sign-in" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/check-email" element={isMobile ? <ConfirmEmail /> : <CheckEmail />} />
              <Route path="/verifyEmail" element={<VerifyEmail />} />
              <Route path="/resetPasswordRequest" element={<ResetPasswordRequest />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/onboard" element={<Onboard />} />
            </Route>

            {/* Public routes */}
            {isMobile ? (
              <Route element={<MobileLayout showNavbar showFooter />}>
                <Route path="/" element={<MobileBlogSelector />} />
              </Route>
            ) : (
              <Route element={<PageLayout />}>
                <Route path="/" element={<ScopeBlog />} />
              </Route>
            )}

            <Route element={<FullScreenLayout gradientBackground />}>
              <Route
                path="/fan-club/:playerUuid/preview-video"
                element={<PremiumContentPreview />}
              />
              <Route path="/card/:cardUuid" element={<CardViewer />} />
            </Route>

            <Route element={isMobile ? <MobileLayout /> : <PageLayout />}>
              <Route path="/scope-chat" element={<ScopeBlog blogType={BlogTypes.SCOPE_CHAT} />} />

              <Route path="/fan-club" element={<ScopeBlog blogType={BlogTypes.FAN_CLUB} />} />
              <Route
                path="/fan-club/:playerUuid"
                element={<ScopeBlog blogType={BlogTypes.FAN_CLUB} />}
              />
              <Route
                path="/fan-club/:playerUuid/:postUuid"
                element={<ScopeBlog blogType={BlogTypes.FAN_CLUB} />}
              />
              <Route path="/home" element={<Navigate to="/" replace />} />
            </Route>

            {!isMobileApp && (
              <Route
                element={
                  isMobile ? (
                    <MobileLayout bluredBackground showNavbar showFooter />
                  ) : (
                    <PageLayout bluredBackground />
                  )
                }
              >
                <Route path="/pro-shop" element={<Proshop />} />
                <Route path="/pro-shop/:playerUuid" element={<Proshop />} />
              </Route>
            )}

            {/* Private routes */}
            {isAuthorized && (
              <>
                {isMobile ? (
                  <Route element={<FullScreenLayout gradientBackground />}>
                    <Route path="/notifications" element={<MobileNotificationCenter />} />
                  </Route>
                ) : (
                  <Route element={<PageLayout bluredBackground />}>
                    <Route path="/notifications" element={<NotificationCenter />} />
                  </Route>
                )}

                <Route element={<FullScreenLayout gradientBackground />}>
                  <Route path="/welcome" element={<Welcome />} />
                  {!isMobileApp && <Route path="/purchase-success" element={<PurchaseSuccess />} />}
                </Route>

                <Route
                  element={
                    isMobile ? (
                      <MobileLayout bluredBackground showNavbar showFooter />
                    ) : (
                      <PageLayout bluredBackground />
                    )
                  }
                >
                  <Route path="/my-roster" element={<MyCards />} />
                  {managedPlayerProfile && (
                    <>
                      <Route path="/dashboard" element={<PlayerDashboard />} />
                      <Route path="/dashboard/members" element={<Members />} />
                    </>
                  )}
                  <Route path="/account-settings" element={<AccountSettings />} />
                  <Route path="/support" element={<Support />} />
                </Route>
              </>
            )}
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
