/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlayerProfileType } from "../player/player.types";
import { UserProfileType } from "../user/profile.types";

export type NotificationType = {
  uuid: string;
  payload: NotificationPayloadType;
  template: NotificationTemplateType;
  createdAt: string;
  isReaded: boolean;
};

export type GroupedNotificationsType = {
  latest: NotificationType[];
  oldest: NotificationType[];
};

export type NotificationTemplateType = {
  content: string;
};

export type NotificationPayloadType = {
  type: NotificationTypes;
  sender: NotificationSenderType;
  templateValues: { [x: string]: any };
  url?: string;
};

export type NotificationSenderType = {
  icon: NotificationIconsEnum;
  player?: PlayerProfileType;
  user?: UserProfileType;
};

export enum NotificationTypes {
  // WITHDRAW = 'withdraw',
  // TOP_UP_BALANCE = 'top_up_balance',
  // BID_PAID = 'bid_paid',
  // PAYMENT_FAILED = 'payment_failed',
  // PAYOUT_SUCCESS = 'payout_success',
  // PAYOUT_FAILED = 'payout_failed',
  // GENERAL = 'general_notifications',

  // Depricated
  PAY_THE_BID = "pay_the_bid",
  MARKETPLACE_CARD_ADDED = "marketplace_card_added",

  // Actual notifications
  GIFT_CARD = "gift_card",
  PURCHASE_SUCCESS = "purchase_success",
  PROSHOP_CARDS_ADDED = "proshop_cards_added",
  BLOCKCHAIN_TRANSACTION_STARTED = "blockchain_transaction_started",
  BLOCKCHAIN_TRANSACTION_ENDED = "blockchain_transaction_ended",
  BLOCKCHAIN_TRANSACTION_FAILED = "blockchain_transaction_failed",
  FAN_CLUB_NEW_PREMIUM_CONTENT = "fan_club_new_premium_content",
  FAN_CLUB_NEW_POST = "fan_club_new_post",
  FAN_CLUB_NEW_COMMENT = "fan_club_new_comment",
  FAN_CLUB_REPLY_COMMENT = "fan_club_reply_comment",
  FAN_CLUB_PLAYER_CHAT_ACTIVITY = "fan_club_player_chat_activity",
}

export interface NotificationItem {
  label: string;
  key: string;
  value: boolean;
}

export enum NotificationIconsEnum {
  WALLET = "wallet",
  COINS = "coins",
  PLAYER_AVATAR = "player_avatar",
  USER_AVATAR = "user_avatar",
  SCOPE = "scope",
  EXCLAMATION_MARK = "exclamation_mark",
}

export type NotificationSettingsPayload = {
  key: string;
  itemKey: string;
  value: boolean;
};

export enum NotificationActions {
  MARK_AS_READ = "mark_as_read",
  MARK_ALL_AS_READ = "mark_all_as_read",
}

export enum UserTrackingActions {
  END_TRACK_USER = "user_tracking_end",
  START_TRACK_USER = "user_tracking_start",
}
