import React from "react";
import { useEffect } from "react";
import { useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useGeneralUi } from "@/providers/generalUi";

import Spinner from "@/components/UI/Spinner";
import { RosterIcon } from "@/components/UI/Icons";
import EmptySection from "@/components/UI/EmptySection";
import Card from "@/components/MyCards/Card";

import { TradeCard } from "@/types/card/cardUserList.types";

const MyCards = () => {
  const navigate = useAppNavigate();
  const { userCards, getUserCards, isUserCardsLoading } = useProfile();
  const { isMobileApp } = useGeneralUi();

  useEffect(() => {
    getUserCards();
  }, []);

  return (
    <div className="w-full text-left px-4 py-6 xl:max-w-[1366px] m-auto xl:my-8">
      <div className="flex flex-col items-start pb-6 xl:my-12">
        <h1 className="text-white text-caps !font-huben text-16 xl:text-[32px]">My Cards</h1>
        <p className="text-body text-16 font-regular py-2">
          The place to store all your cards! Viewer, Member and Experience Cards will all be kept
          here for you to view or move to the marketplace.
        </p>
      </div>
      <div className="w-full items-center flex flex-col xl:flex-row gap-6 text-left xl:px-6 xl:max-w-[1366px] m-auto mb-6">
        <span className="w-full text-caps text-13 font-medium text-white text-start xl:text-right">
          available | {userCards.length} cards |
        </span>
      </div>

      {isUserCardsLoading && (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      )}
      {!isUserCardsLoading && userCards && userCards.length === 0 ? (
        <div className="ml-[60px] mr-[60px]">
          {isMobileApp ? (
            <EmptySection
              icon={<RosterIcon />}
              title="Unlock Exclusive content from top world athletes"
              text={
                <span>
                  You don’t own any access passes, go to <strong>scopesports.io</strong> to purchase
                  them.
                </span>
              }
            />
          ) : (
            <EmptySection
              icon={<RosterIcon />}
              title="Unlock Exclusive content from top world athletes"
              text="Purchase a Member Card from the Pro Shop or the Marketplace to get the most out of Scope."
              buttons={[{ onClick: () => navigate("/pro-shop"), caption: "Explore Pro Shop" }]}
            />
          )}
        </div>
      ) : null}

      {!isUserCardsLoading && userCards && userCards.length !== 0 ? (
        <div className="w-full text-left  xl:px-5 xl:max-w-[1366px] m-auto">
          <div className="grid grid-cols-1 justify-items-center gap-5 xl:grid-cols-3 2xl:grid-cols-4">
            {userCards.map((userCard: TradeCard) => (
              <Card key={userCard.uuid} tradeCard={userCard} onClick={() => null} />
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyCards;
