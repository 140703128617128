import React from "react";

import { snakeCaseToReadableString } from "@/helpers/string.helpers";

const SearchTypeSuggestions = ({
  value,
  displayValue,
}: {
  value: string;
  displayValue: string;
}) => {
  return (
    <span className="text-body text-14 font-medium text-white">
      {snakeCaseToReadableString(displayValue)}
    </span>
  );
};

export default SearchTypeSuggestions;
