import React, { HTMLAttributes, ReactNode } from "react";
import Container from "@/components/UI/Container";

interface ExtendedProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  titleClassName?: string;
  titleAction?: ReactNode;
  containerClassName?: string;
  bodyClassName?: string;
}

const SectionView = ({
  children,
  title,
  titleClassName,
  containerClassName,
  titleAction,
}: ExtendedProps) => {
  return (
    <Container
      className={`w-full h-full max-h-full flex flex-col gap-0 
        ${containerClassName ?? ""}
    	`}
    >
      {title && (
        <div className="flex w-full items-center justify-between p-4 border-bottom">
          <span className={`text-body text-16 font-bold ${titleClassName ?? ""}`}>{title}</span>
          {titleAction}
        </div>
      )}
      {children}
    </Container>
  );
};

export default SectionView;
