import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppNavigate } from "../app/hooks";

import { login } from "../reducers/user.slice";

const OAuth = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { accessToken } = useParams();

  useEffect(() => {
    if (accessToken) {
      dispatch(login(accessToken));
      navigate("/");
    }
  }, [accessToken]);

  return <div>Redirecting</div>;
};

export default OAuth;
