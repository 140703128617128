import React, { useEffect } from "react";

const easeInOutQuad = (t: number): number => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

const performSmoothScroll = (
  container: HTMLDivElement,
  targetScrollPosition: number,
  duration: number,
) => {
  const startScrollPosition = container.scrollLeft;
  const distanceToScroll = targetScrollPosition - startScrollPosition;
  const startTime = performance.now();

  const smoothStepScroll = (currentTime: number) => {
    const timeElapsed = currentTime - startTime;
    const progress = timeElapsed / duration;
    const easedProgress = easeInOutQuad(progress);

    container.scrollLeft = startScrollPosition + distanceToScroll * easedProgress;

    if (timeElapsed < duration) {
      requestAnimationFrame(smoothStepScroll);
    }
  };

  requestAnimationFrame(smoothStepScroll);
};

const useAnimateScroll = (
  ref: React.RefObject<HTMLDivElement>,
  distanceFraction = 0.5,
  duration = 3000,
) => {
  useEffect(() => {
    const container = ref.current;

    if (container) {
      const scrollWidth = container.offsetWidth * distanceFraction;
      const durationFraction = duration / 3;

      performSmoothScroll(container, scrollWidth, durationFraction);
      setTimeout(() => performSmoothScroll(container, 0, durationFraction), durationFraction);
    }
  }, []);
};

export default useAnimateScroll;
