import React, { useEffect, useRef, useState } from "react";
import { useGeneralUi } from "@/providers/generalUi";
import { batch } from "react-redux";

import ReactPlayer from "react-player";
import { FilledPauseIcon, FilledPlayIcon, MaximizeIcon } from "../Icons";
import Button from "../Button";

import { OnProgressProps } from "react-player/base";
import screenfull from "screenfull";
import { convertToString, convertToTimeFormat } from "@/helpers/audio.helpers";

import "./VideoPlayer.styles.css";

interface VideoPlayerProps {
  url: string;
  className?: string;
  fullscreen?: boolean;
  autoplay?: boolean;
  [x: string]: unknown;
}

const VideoPlayer_v2 = ({ url, autoplay, ...rest }: VideoPlayerProps) => {
  const videoRef = useRef<ReactPlayer>(null);
  const { isMobile } = useGeneralUi();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<boolean>(false);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [formattedVideoDuration, setFormattedVideoDuration] = useState<string>("00:00:00");
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const handleError = (error: any) => {
    console.log(error?.target?.error);

    setErrorMessage(`Error while loading video: ${JSON.stringify(error)}`);
  };

  const handleProgress = (progress: OnProgressProps) => {
    batch(() => {
      setCurrentTime(progress.playedSeconds);
      setProgress(progress.played * 100);
    });
  };

  const handleProgressClick = (event: any) => {
    if (!videoRef.current) return;
    event.stopPropagation();
    const selectedTime = Number(event.target.value);
    videoRef.current.seekTo(selectedTime);
    batch(() => {
      setCurrentTime(selectedTime);
      setProgress(selectedTime / (videoDuration / 100));
    });
  };

  const handleToggleFullscreen = () => {
    if (!videoRef.current) return;

    screenfull.request(videoRef.current.getInternalPlayer() as Element);
    setIsFullscreen(true);
  };

  useEffect(() => {
    setFormattedVideoDuration(
      convertToString(convertToTimeFormat(videoDuration), { minutes: true, seconds: true }),
    );
  }, [videoDuration]);

  useEffect(() => {
    if (autoplay) {
      setTimeout(() => setPlaying(true), 100);
    }

    const fullscreenHandler = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", fullscreenHandler);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", fullscreenHandler);
      }
    };
  }, []);

  return (
    <div className="overflow-hidden w-full h-full flex flex-col" data-testid="video-player">
      <div className="flex-1 w-full relative" onClick={() => setPlaying((prev) => !prev)}>
        <div className="absolute inset-0">
          {errorMessage ? (
            <div className="flex w-full h-full justify-center items-center">
              <span className="text-error-content">{errorMessage}</span>
            </div>
          ) : (
            <ReactPlayer
              id={`video-player`}
              ref={videoRef}
              width="100%"
              height="100%"
              controls={isFullscreen || (isMobile && played)}
              url={url}
              playing={playing}
              onPlay={() => setPlayed(true)}
              loop={true}
              onError={handleError}
              onProgress={handleProgress}
              onDuration={(duration) => setVideoDuration(duration)}
              {...rest}
            />
          )}
        </div>
      </div>
      <div
        className={`flex-col justify-end controls-v2 ${
          isMobile ? (played ? "hidden" : "") : "flex"
        }`}
      >
        <div className="flex w-full p-0 m-0 gap-1">
          <input
            className="absolute -translate-y-1/2 cursor-pointer"
            type="range"
            style={{
              background:
                "linear-gradient(to right, #fff 0%, #fff " +
                progress +
                "%, rgba(0, 0, 0, 0.4) " +
                progress +
                "%, rgba(0, 0, 0, 0.4))",
            }}
            min={0}
            max={videoDuration}
            step={0.01}
            value={currentTime}
            onChange={handleProgressClick}
          />
        </div>
        <div className="flex items-center justify-between px-5 py-6 xl:py-[28px] h-6 max-h-6 xl:h-20 xl:max-h-[80px]">
          <div className="flex gap-4 items-center">
            <Button
              className="btn btn-sm btn-ghost !btn-square rounded-md"
              onClick={() => setPlaying((prev) => !prev)}
            >
              {playing ? <FilledPauseIcon /> : <FilledPlayIcon />}
            </Button>
            <span className="text-body text-14 font-regular text-neutral-white-pure-white">
              {`${convertToString(convertToTimeFormat(currentTime), {
                minutes: true,
                seconds: true,
              })} / ${formattedVideoDuration}`}
            </span>
          </div>
          <Button
            className="btn btn-sm btn-ghost !btn-square rounded-md"
            onClick={handleToggleFullscreen}
          >
            <MaximizeIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer_v2;
