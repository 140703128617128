import { RootState } from "@/app/store";
import { setIsGlobalLoading } from "@/reducers/ui.slice";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const setGlobalLoadingState = (_queryArg: any, { dispatch, queryFulfilled }: any) => {
  dispatch(setIsGlobalLoading(true));

  queryFulfilled
    .then(() => {
      dispatch(setIsGlobalLoading(false));
    })
    .catch(() => {
      dispatch(setIsGlobalLoading(false));
    });
};

export const setLoadingState =
  (setState: ActionCreatorWithPayload<any, string>) =>
  (_queryArg: any, { dispatch, queryFulfilled }: any) => {
    dispatch(setState(true));

    queryFulfilled
      .then(() => {
        dispatch(setState(false));
      })
      .catch(() => {
        dispatch(setState(false));
      });
  };

// TODO: update baseQuery in all slices
export const baseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).user.authToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      const { isIosMobileApp, isAndroidMobileApp } = (getState() as RootState).platformSettings;
      const isMobileApp =
        !!isIosMobileApp || !!isAndroidMobileApp || import.meta.env.VITE_IS_TWA === "true";

      headers.set("X-Is-Pwa", String(isMobileApp));

      return headers;
    },
  });
