import { Country } from "@/types/countries.enum";

export const getCountryByCode = (code: string) => {
  const index = Object.values(Country).indexOf(code as unknown as Country);

  if (index < 0) return undefined;

  const country = Object.keys(Country)[index];
  return country;
};
