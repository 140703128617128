import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useAppNavigate } from "@/app/hooks";

import EmptySection from "./EmptySection";
import Button from "./Button";
import { batch } from "react-redux";

const MediaTile = ({
  component,
  overlay,
  onClick,
}: {
  component: ReactNode;
  overlay?: ReactNode;
  onClick?: () => void;
}) => (
  <div
    className="relative bg-black w-full child:w-full child:h-full child:object-cover child:max-h-full"
    onClick={onClick}
  >
    {component}
    {overlay}
    {onClick && <div className="absolute inset-0 bg-transparent cursor-pointer" />}
  </div>
);

const Carousel = ({
  activeSlideIndex = 0,
  renderMediaComponents,
}: {
  activeSlideIndex?: number;
  renderMediaComponents: (isFullscreen: boolean, activeIndex?: number) => ReactNode[];
}) => {
  const { setIsFullscreenModalOpen } = useFullscreenModal();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(activeSlideIndex);
  const [mediaArray, setMediaArray] = useState<ReactNode[]>([]);
  const [totalSlides, setTotalSlides] = useState<number>(0);
  const [isRendered, setIsRendered] = useState<boolean>(false);

  const carouselRef = useRef<HTMLDivElement>(document.createElement("div"));

  useLayoutEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsFullscreenModalOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const mediaArray = renderMediaComponents(true, currentSlideIndex);

    batch(() => {
      setMediaArray(mediaArray);
      setTotalSlides(mediaArray.length);
    });

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        handlePrevSlideClick();
      } else if (event.key === "ArrowRight") {
        handleNextSlideClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentSlideIndex]);

  useEffect(() => {
    if (!isRendered) {
      setIsRendered(true);
      return;
    }

    scrollToSilde(currentSlideIndex);
  }, [isRendered]);

  const scrollToSilde = (slideIndex: number) => {
    carouselRef.current.scrollLeft = carouselRef.current.clientWidth * slideIndex;
  };

  const handlePrevSlideClick = () => {
    const slideIndex = currentSlideIndex > 0 ? currentSlideIndex - 1 : totalSlides - 1;

    batch(() => {
      scrollToSilde(slideIndex);
      setCurrentSlideIndex(slideIndex);
    });
  };

  const handleNextSlideClick = () => {
    const slideIndex = currentSlideIndex < totalSlides - 1 ? currentSlideIndex + 1 : 0;

    batch(() => {
      scrollToSilde(slideIndex);
      setCurrentSlideIndex(slideIndex);
    });
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    const scrollPosition = element.scrollLeft;
    const slideWidth = element.clientWidth;
    const newIndex = Math.round(scrollPosition / slideWidth);

    setCurrentSlideIndex(newIndex);
  };

  return (
    <div className="relative flex flex-col w-full h-full overflow-hidden">
      {totalSlides > 1 && (
        <>
          <div className="hidden xl:flex absolute left-2 w-12 h-12 top-1/2 -translate-y-1/2 z-10">
            <Button className="btn btn-outline rounded-md" onClick={handlePrevSlideClick}>
              ❮
            </Button>
          </div>
          <div className="hidden xl:flex absolute right-2 w-12 h-12 top-1/2 -translate-y-1/2 z-10">
            <Button className="btn btn-outline rounded-md" onClick={handleNextSlideClick}>
              ❯
            </Button>
          </div>
        </>
      )}

      <div
        ref={carouselRef}
        className="carousel w-full h-full mobile:flex-1"
        onScroll={handleScroll}
      >
        {mediaArray?.length ? (
          mediaArray.map((component, index) => (
            <div
              id={`slide-${index}`}
              key={index}
              className="carousel-item w-full h-full flex xl:child:inset-0 bg-black [&_video]:object-contain [&_img]:object-contain child:w-full child:h-full overflow-hidden"
            >
              {component}
            </div>
          ))
        ) : (
          <EmptySection title="No media" />
        )}
      </div>
    </div>
  );
};

const MediaGrid = ({
  renderMediaComponents,
  onClick,
  countFrom = 5,
}: {
  renderMediaComponents: (isFullscreen: boolean, activeIndex?: number) => ReactNode[];
  onClick?: (index: number) => void;
  countFrom?: number;
}) => {
  const navigate = useAppNavigate();
  const { setFullscreenModal, setIsFullscreenModalOpen } = useFullscreenModal();

  const validCountFrom = countFrom > 0 && countFrom < 5 ? countFrom : 5;

  const mediaComponents = renderMediaComponents(false);

  let toShowCount = mediaComponents.length;

  if (validCountFrom && mediaComponents.length > validCountFrom) {
    toShowCount = validCountFrom;
  }

  const handleOnClick = (index: number) => {
    if (onClick) {
      onClick(index);
      return;
    }

    setFullscreenModal({
      body: <Carousel renderMediaComponents={renderMediaComponents} activeSlideIndex={index} />,
      title: "",
      cancelHandler: () => navigate(""),
    });
    setIsFullscreenModalOpen(true);
  };

  const renderCountOverlay = () => {
    const extraCount = mediaComponents.length - validCountFrom;

    return (
      <>
        <div className="absolute inset-0 bg-black/50" />
        <div className="absolute inset-0 flex items-center justify-center text-white text-2xl cursor-pointer">
          <p>+{extraCount}</p>
        </div>
      </>
    );
  };

  const renderOne = () => {
    const conditionalRender =
      [3, 4].includes(mediaComponents.length) ||
      (mediaComponents.length > validCountFrom && [3, 4].includes(validCountFrom));

    return (
      <div
        className="flex w-full overflow-hidden"
        style={{
          height: conditionalRender ? "66.6666%" : "100%",
        }}
      >
        <MediaTile component={mediaComponents[0]} onClick={() => handleOnClick(0)} />
      </div>
    );
  };

  const renderTwo = () => {
    const overlay =
      mediaComponents.length > validCountFrom && [2, 3].includes(validCountFrom)
        ? renderCountOverlay()
        : null;

    const conditionalRender =
      [3, 4].includes(mediaComponents.length) ||
      (mediaComponents.length > validCountFrom && [3, 4].includes(validCountFrom));

    const height = conditionalRender
      ? "33.3333%"
      : mediaComponents.length >= countFrom
      ? "66.6666%"
      : "100%";

    return (
      <div
        className="flex w-full h-full overflow-hidden"
        style={{
          height,
        }}
      >
        <MediaTile
          component={conditionalRender ? mediaComponents[1] : mediaComponents[0]}
          onClick={() => handleOnClick(conditionalRender ? 1 : 0)}
        />
        <MediaTile
          component={conditionalRender ? mediaComponents[2] : mediaComponents[1]}
          overlay={overlay}
          onClick={() => handleOnClick(conditionalRender ? 2 : 1)}
        />
      </div>
    );
  };

  const renderThree = () => {
    const overlay =
      !validCountFrom ||
      validCountFrom > 5 ||
      (mediaComponents.length > validCountFrom && [4, 5].includes(validCountFrom))
        ? renderCountOverlay()
        : null;

    const conditionalRender =
      mediaComponents.length === 4 ||
      (mediaComponents.length > validCountFrom && validCountFrom === 4);

    return (
      <div className="flex w-full overflow-hidden h-1/3">
        <MediaTile
          component={conditionalRender ? mediaComponents[1] : mediaComponents[2]}
          onClick={() => handleOnClick(conditionalRender ? 1 : 2)}
        />
        <MediaTile
          component={conditionalRender ? mediaComponents[2] : mediaComponents[3]}
          onClick={() => handleOnClick(conditionalRender ? 2 : 3)}
        />
        <MediaTile
          component={conditionalRender ? mediaComponents[3] : mediaComponents[4]}
          overlay={overlay}
          onClick={() => handleOnClick(conditionalRender ? 3 : 4)}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full aspect-video max-h-[500px] overflow-hidden">
      {[1, 3, 4].includes(toShowCount) && renderOne()}
      {toShowCount >= 2 && toShowCount !== 4 && renderTwo()}
      {toShowCount >= 4 && renderThree()}
    </div>
  );
};

export default MediaGrid;
