import { createSlice } from "@reduxjs/toolkit";
import { BlockedUserProfileType, UserProfileType, UserRoles } from "../types/user/profile.types";
import { PlayerProfileType } from "@/types/player/player.types";
import { RootState } from "@/app/store";
import { TradeCard } from "@/types/card/cardUserList.types";
import { history } from "@/helpers/history.helpers";
import { loadFromLocalStorage } from "@/utils/localStorage";

const isNew = loadFromLocalStorage("isNewUser"); // TODO: refactor for use with many users from 1 pc
const isEulaAgreed = loadFromLocalStorage("isEulaAgreed"); // TODO: refactor for use with many users from 1 pc
const isInstallAppInformed = loadFromLocalStorage("isInstallAppInformed"); // TODO: refactor for use with many users from 1 pc

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userProfile: undefined,
    managedPlayerProfile: undefined,
    authToken: loadFromLocalStorage("authToken"),
    isAuthorized: false,
    isNew: isNew === undefined ? !isNew : isNew,
    isEulaAgreed: !!isEulaAgreed,
    isInstallAppInformed: !!isInstallAppInformed,
    isAdmin: false,
    userCards: [],
    managedPlayerProfiles: [],
    blockedUsers: [],
    blockedUsersTotal: 0,
  },
  reducers: {
    login: (state, action) => {
      state.authToken = action.payload;
    },
    logout: (state) => {
      state.userProfile = undefined;
      state.managedPlayerProfile = undefined;
      state.authToken = null;
      state.isAuthorized = false;
      state.isAdmin = false;
      state.userCards = [];
      history.navigate("/");
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
      state.isAuthorized = !!action.payload.uuid;
      state.isAdmin = action.payload?.role && action.payload.role === UserRoles.ADMIN;
    },
    setManagedPlayerProfile: (state, action) => {
      state.managedPlayerProfile = action.payload;
    },
    clearManagedPlayerProfile: (state) => {
      state.managedPlayerProfile = undefined;
      history.navigate("/");
    },
    setUserCards: (state, action) => {
      state.userCards = action.payload;
    },
    setManagedPlayerProfiles: (state, action) => {
      state.managedPlayerProfiles = action.payload;
    },
    setIsNew: (state, action) => {
      state.isNew = action.payload;
    },
    setIsEulaAgreed: (state, action) => {
      state.isEulaAgreed = action.payload;
    },
    setIsInstallAppInformed: (state, action) => {
      state.isInstallAppInformed = action.payload;
    },
    setBlockedUsers: (state, action) => {
      state.blockedUsers = action.payload;
    },
    setBlockedUsersTotal: (state, action) => {
      state.blockedUsersTotal = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setUserCards,
  setUserProfile,
  setManagedPlayerProfiles,
  setManagedPlayerProfile,
  clearManagedPlayerProfile,
  setIsNew,
  setIsEulaAgreed,
  setIsInstallAppInformed,
  setBlockedUsers,
  setBlockedUsersTotal,
} = userSlice.actions;

export const selectUserProfile = (state: RootState): UserProfileType | undefined =>
  state.user.userProfile;

export const selectManagedPlayerProfile = (state: RootState): PlayerProfileType | undefined =>
  state.user.managedPlayerProfile;

export const selectIsAuthorized = (state: RootState): boolean => state.user.isAuthorized;

export const selectIsNew = (state: RootState): boolean => state.user.isNew;

export const selectIsEulaAgreed = (state: RootState): boolean => state.user.isEulaAgreed;

export const selectIsInstallAppInformed = (state: RootState): boolean =>
  state.user.isInstallAppInformed;

export const selectIsAdmin = (state: RootState): boolean => state.user.isAdmin;

export const selectAuthToken = (state: RootState): string | null => state.user.authToken;

export const selectUserCards = (state: RootState): TradeCard[] => state.user.userCards;

export const selectManagedPlayerProfiles = (state: RootState): PlayerProfileType[] =>
  state.user.managedPlayerProfiles;

export const selectBlockedUsers = (state: RootState): BlockedUserProfileType[] =>
  state.user.blockedUsers;

export const selectBlockedUsersTotal = (state: RootState): number => state.user.blockedUsersTotal;

export default userSlice.reducer;
