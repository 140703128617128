import React, { useEffect } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import { toast } from "@/utils/toast";
import { useChangePasswordMutation } from "@/api/userApi";

const ChangePasswordTab = () => {
  const [changePassword, { isLoading, isSuccess }] = useChangePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("New password successful set", {
        position: "top-right",
      });

      formik.values.currentPassword = "";
      formik.values.newPassword = "";
      formik.values.repeatPassword = "";
    }
  }, [isLoading]);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.currentPassword) {
        errors.currentPassword = "Required";
      }
      if (!values.newPassword) {
        errors.newPassword = "Required";
      }
      if (values.newPassword.length < 8) {
        errors.newPassword = "Password must be at least 8 characters long";
      }
      if (!values.repeatPassword) {
        errors.repeatPassword = "Required";
      }
      if (_.isEqual(values.newPassword, values.repeatPassword) === false) {
        errors.repeatPassword = "Is not equal New Password";
      }
      return errors;
    },
    onSubmit: async (values) => {
      await changePassword(values);
    },
  });

  return (
    <div className="max-w-xl xl:p-5 m-auto">
      <div className="items-start mb-5 hidden xl:container">
        <span className="text-title !text-lg">Update your password</span>
        <p className="text-secondary !text-base py-2">
          Password must be at least 8 characters long
        </p>
      </div>
      <form
        data-testid="account-settings-change-password-form"
        onSubmit={formik.handleSubmit}
        className="form-control w-full mt-6 xl:mt-10 p-0 flex gap-4"
      >
        <div>
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">
              Current password
            </span>
          </label>
          <input
            data-testid="current-password-input"
            type="password"
            name="currentPassword"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentPassword}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.currentPassword &&
              formik.touched.currentPassword &&
              " !border-red-500 !text-red-900"
            }`}
          />
        </div>
        <div>
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">
              Type new password
            </span>
          </label>
          <input
            data-testid="new-password-input"
            type="password"
            name="newPassword"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.newPassword &&
              formik.touched.newPassword &&
              " !border-red-500 !text-red-900"
            }`}
          />
        </div>
        <div>
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">
              Re - Type new password
            </span>
          </label>
          <input
            data-testid="repeat-password-input"
            type="password"
            name="repeatPassword"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.repeatPassword}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.repeatPassword &&
              formik.touched.repeatPassword &&
              " !border-red-500 !text-red-900"
            }`}
          />
        </div>
        <button
          data-testid="update-password-btn"
          type="submit"
          className={`btn btn-primary mt-2 ${formik.isSubmitting ? "loading" : ""}`}
        >
          Update password
        </button>
      </form>
    </div>
  );
};

export default ChangePasswordTab;
