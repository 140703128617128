export interface WishlistItemType {
  playerUuid: string;
  uuid: string;
  type: WishlistItemTypesEnum;
  createdAt: string;
  expireAt: string;
  mintedAt: string;
  cardType: string;
  cardName: string;
  mediaUuid: string;
  ownerUuid: string;
  tradeUuid: string;
  currentPrice: number;
}

export interface AddToWishlistRequest {
  cardPlayerUuid: string;
  cardUserUuid: string;
}

export enum WishlistItemTypesEnum {
  MARKETPLACE = "marketplace",
  PROSHOP = "proshop",
  PLAYER = "player",
}

export const CardItems = [WishlistItemTypesEnum.MARKETPLACE, WishlistItemTypesEnum.PROSHOP];

export enum WishlistActions {
  ADD = "add",
  REMOVE = "remove",
}

export enum OrderByEnum {
  LAST_ADDED = "last_added",
  NAME = "name",
  CARD_TYPE = "card_type",
  CURRENT_PRICE = "current_price",
  EDITION = "edition",
  EXPIRATION = "expiration",
}

export interface AddToWishlist {
  cardPlayerUuid?: string;
  cardUserUuid?: string;
  playerUuid?: string;
}
