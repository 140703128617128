import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./common";
import { ReportRequest, ReportResponse } from "@/types/reports.types";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + "report/";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: baseQuery(BASE_URL),
  endpoints: (builder) => ({
    reportContent: builder.mutation<ReportResponse, ReportRequest>({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useReportContentMutation } = reportApi;
