import React, { useFormik } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppNavigate } from "@/app/hooks";
import { useResetNewPasswordMutation } from "../api/userApi";

import { toast } from "../utils/toast";
import _ from "lodash";

const ResetNewPasswordForm = () => {
  const [params] = useSearchParams();
  const navigate = useAppNavigate();
  const [resetNewPassword, { isLoading, isSuccess }] = useResetNewPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("New password successful set", {
        position: "top-right",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [isLoading]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      repeatPassword: "",
      resetCode: "",
    },
    validate: (values) => {
      const errors: any = {};

      if (values.newPassword && values.newPassword.length < 8) {
        errors.newPassword = "Must be longer than or equal to 8 characters";
      }

      if (!values.newPassword) {
        errors.newPassword = "Required";
      }
      if (!values.repeatPassword) {
        errors.repeatPassword = "Required";
      }
      if (_.isEqual(values.newPassword, values.repeatPassword) === false) {
        errors.repeatPassword = "Is not equal New Password";
      }
      return errors;
    },
    onSubmit: async (values) => {
      await resetNewPassword(values);
    },
  });

  useEffect(() => {
    const resetCode = params.get("resetCode");
    if (resetCode) {
      formik.setFieldValue("resetCode", resetCode);
    }
  }, [params]);

  return (
    <div className="w-full max-w-[600px] text-left px-4 py-6 xl:px-10 m-auto xl:mt-48">
      <div className="container">
        <div className="w-full ">
          <div className="flex flex-col gap-3">
            <h4 className="xl:hidden text-heading font-medium">Reset password</h4>
            <h2 className="hidden xl:flex text-heading font-medium">Reset password</h2>
            <span className="text-body text-16 font-regular opacity-70">
              Enter a new password for your Scope account.
            </span>
          </div>

          <form
            data-testid="new-password-form"
            onSubmit={formik.handleSubmit}
            className="form-control w-full mt-6 xl:mt-10 p-0 gap-4"
          >
            <div>
              <label className="label">
                <span className="text-caps text-13 font-medium text-white opacity-70">
                  New password
                </span>
              </label>
              <input
                data-testid="new-password-field"
                type="password"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                autoComplete="new-password"
                className={`input input-bordered w-full rounded-full ${
                  formik.errors.newPassword &&
                  formik.touched.newPassword &&
                  " !border-red-500 !text-red-900"
                }`}
              />
            </div>
            <div>
              <label className="label">
                <span className="text-caps text-13 font-medium text-white opacity-70">
                  Re-Type New password
                </span>
              </label>
              <input
                data-testid="new-repeat-password-field"
                type="password"
                name="repeatPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.repeatPassword}
                autoComplete="new-password"
                className={`input input-bordered w-full rounded-full ${
                  formik.errors.repeatPassword &&
                  formik.touched.repeatPassword &&
                  " !border-red-500 !text-red-900"
                }`}
              />
            </div>
            <button
              data-testid="change-password-btn"
              type="submit"
              disabled={formik.isSubmitting}
              className="btn btn-primary rounded-full mt-6 xl:mt-10"
            >
              Change my Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetNewPasswordForm;
