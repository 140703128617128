import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";

import { batch } from "react-redux";
import { useScopeChat } from "@/providers/scopeChat";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { EmojiData, useEmojiPicker } from "@/providers/EmojiPickerProvider";
import { useGeneralUi } from "@/providers/generalUi";

import {
  CloseIcon,
  EmojiReactionFilled,
  PlayIcon,
  PlusIcon,
  SendIcon,
} from "@/components/UI/Icons";
import EmojiPicker from "@emoji-mart/react";
import AttachmentMenu from "./AttachmentMenu";
import CenterSpinner from "@/components/UI/CenterSpinner";
import PollForm from "./AttachmentForms/PollForm";
import VoiceForm from "./AttachmentForms/VoiceForm";
import AudioPlayer from "../../MessageList/AudioPlayer";
import Button from "@/components/UI/Button";

import {
  SCOPE_CHAT_INPUT_CONTAINER_HEIGHT,
  SCOPE_CHAT_MESSAGE_LENGTH,
  SCOPE_CHAT_TEXTAREA_HEIGHT,
} from "@/constants/scopeChat.constants";
import { ENTER_KEY } from "@/constants/app.constants";
import { getLocalMediaUrl } from "@/helpers/file.helpers";
import { ScopeChatMessageTypes, ScopeChatPollPayload } from "@/types/scopeChat/scopeChat.types";
import { generateUuid } from "@/helpers/uuid.helpers";
import { MAX_FILES_COUNT } from "@/constants/fanClub.constants";
import { toast } from "@/utils/toast";

const ChatInput = ({
  thread,
  messageId,
  cardView,
}: {
  thread?: boolean;
  messageId?: string;
  cardView?: boolean;
}) => {
  const { handleSendMessage, isSendingMessage, isMuted, uploadProgress, isUploadInProgress } =
    useScopeChat();
  const { setOnEmojiSelect, handleOpenEmojiPicker } = useEmojiPicker();
  const { setFullscreenModal, setIsFullscreenModalOpen } = useFullscreenModal();
  const { isMobile } = useGeneralUi();

  const [content, setContent] = useState<string>("");
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [isAttachmentMenuOpen, setIsAttachmentMenuOpen] = useState<boolean>(false);
  const [type, setType] = useState<ScopeChatMessageTypes>(ScopeChatMessageTypes.TEXT);
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const [mediaPreviews, setMediaPreviews] = useState<
    { uuid: string; src: string; isLoading: boolean; mimetype: string }[]
  >([]);

  const [focused, setFocused] = useState<string>("");
  const [mediaFiles, setMediaFiles] = useState<{ uuid: string; file: File }[]>([]);
  const [attachmentAspectRatio, setAttachmentAspectRatio] = useState<number>();

  const textareaRef = useRef<HTMLTextAreaElement>(document.createElement("textarea"));
  const chatInputRef = useRef<HTMLDivElement>(document.createElement("div"));
  const mediaInputRef = useRef<HTMLInputElement>(document.createElement("input"));

  useEffect(() => {
    switch (type) {
      case ScopeChatMessageTypes.POLL:
        setFullscreenModal({
          title: "Create your POLL",
          body: (
            <PollForm
              onSubmit={handleOnPollSubmit}
              onCancel={() => {
                batch(() => {
                  setIsFullscreenModalOpen(false);
                  setType(ScopeChatMessageTypes.TEXT);
                });
              }}
            />
          ),
          cancelHandler: () => {
            setType(ScopeChatMessageTypes.TEXT);
          },
        });
        setIsFullscreenModalOpen(true);
        break;
      case ScopeChatMessageTypes.VOICE:
        setFullscreenModal({
          title: "Create your Audio",
          body: (
            <VoiceForm
              onSubmit={handleOnVoiceSubmit}
              onCancel={() => {
                batch(() => {
                  setIsFullscreenModalOpen(false);
                  setType(ScopeChatMessageTypes.TEXT);
                });
              }}
            />
          ),
          cancelHandler: () => {
            setType(ScopeChatMessageTypes.TEXT);
          },
        });
        setIsFullscreenModalOpen(true);
        break;
      default:
        break;
    }
  }, [type]);

  useEffect(() => {
    setOnEmojiSelect(() => (emoji: EmojiData) => handleEmojiSelect(emoji));
  }, []);

  useEffect(() => {
    if (textareaRef.current && !isMobile) {
      textareaRef.current.focus();
    }
  }, [textareaRef]);

  useEffect(() => {
    if (!isSendingMessage) {
      setContent("");
      handleRemoveMedia();
      !isMobile && textareaRef.current.focus();
    }
  }, [isSendingMessage]);

  useEffect(() => {
    if (!textareaRef.current) return;
    autoExpandTextArea();
  }, [content]);

  useEffect(() => {
    autoExpandTextArea();
  }, [textareaRef.current.scrollHeight]);

  const handleTextChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
    setContent(event.target.value);
  };

  const handleTextareaClick = (event: any) => {
    setCursorPosition(event.target.selectionStart);
  };

  const handleSendMessageClick = async () => {
    if (!content && !mediaFiles.length) return;

    setIsEmojiPickerOpen(false);
    setIsAttachmentMenuOpen(false);
    handleSendMessage({ content, parentId: messageId, type, mediaFiles, attachmentAspectRatio });
  };

  const autoExpandTextArea = () => {
    setTimeout(() => {
      if (!textareaRef || !textareaRef.current) return;
      textareaRef.current.rows = content.split("\n").length;
      textareaRef.current.style.cssText = `height: auto; min-height:${SCOPE_CHAT_TEXTAREA_HEIGHT};`;
      textareaRef.current.style.cssText = `height: ${textareaRef.current.scrollHeight}px; min-height: ${SCOPE_CHAT_TEXTAREA_HEIGHT}px;`;
      chatInputRef.current.style.cssText = `height: auto; min-height:${SCOPE_CHAT_INPUT_CONTAINER_HEIGHT};`;
      chatInputRef.current.style.cssText = `height: ${textareaRef.current.scrollHeight}px; min-height:${SCOPE_CHAT_INPUT_CONTAINER_HEIGHT}px;`;
    }, 0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    setCursorPosition(textareaRef.current.selectionStart);

    if (event.key === ENTER_KEY && (event.altKey || event.shiftKey) && content) {
      event.preventDefault();

      setContent((prev) => prev.slice(0, cursorPosition) + "\n" + prev.slice(cursorPosition));

      setTimeout(() => {
        !isMobile && textareaRef.current.focus();
        const newCursorPosition = cursorPosition + 1;
        setCursorPosition(newCursorPosition);
        textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
      return;
    }

    if (event.key === ENTER_KEY) {
      event.preventDefault();
      handleSendMessageClick();
    }
  };

  const handleEmojiSelect = (selectedEmoji: EmojiData) => {
    const { native } = selectedEmoji;

    const newValue = content.slice(0, cursorPosition) + native + content.slice(cursorPosition);

    setContent(newValue);
    autoExpandTextArea();

    setTimeout(() => {
      !isMobile && textareaRef.current.focus();
      const newCursorPosition = cursorPosition + native.length;
      setCursorPosition(newCursorPosition);
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      setIsEmojiPickerOpen(false);
    }, 0);
  };

  const handleOnMediaInputChange = (files: FileList | null, singleFile?: boolean) => {
    setIsAttachmentMenuOpen(false);

    if (files?.length) {
      const newFiles = Array.from(files);
      const existingFiles = singleFile ? [] : mediaFiles;

      const filesToAdd = newFiles
        .filter(
          (newFile) =>
            !existingFiles.some(
              ({ file }) =>
                file &&
                file.name === newFile.name &&
                file.size === newFile.size &&
                file.lastModified === newFile.lastModified,
            ),
        )
        .map((file) => ({ uuid: generateUuid(), file }));

      const totalFilesCount = existingFiles.length + filesToAdd.length;

      if (totalFilesCount > MAX_FILES_COUNT) {
        toast.warning(`You can only upload a maximum of ${MAX_FILES_COUNT} files`);
        filesToAdd.splice(MAX_FILES_COUNT - existingFiles.length);
      }

      batch(() => {
        setMediaFiles([...existingFiles, ...filesToAdd]);
        filesToAdd.forEach(({ uuid, file }) => {
          setMediaPreviews((prev) => [
            ...(singleFile ? [] : prev),
            { uuid, src: "", isLoading: true, mimetype: "" },
          ]);

          getLocalMediaUrl(file, (result: string) => {
            setMediaPreviews((prev) =>
              prev.map((preview) =>
                preview.uuid === uuid
                  ? { uuid, src: result, isLoading: false, mimetype: file.type }
                  : preview,
              ),
            );
          });
        });
        setIsPreviewVisible(true);
      });
    }
  };

  const handleRemoveMedia = (index?: number) => {
    if (index === undefined) {
      batch(() => {
        setMediaFiles([]);
        setMediaPreviews([]);
        setType(ScopeChatMessageTypes.TEXT);
      });

      return;
    }

    const updatedMediaFiles = [...mediaFiles];
    const updatedMediaPreviews = [...mediaPreviews];

    updatedMediaFiles.splice(index, 1);
    updatedMediaPreviews.splice(index, 1);

    batch(() => {
      setMediaFiles(updatedMediaFiles);
      setMediaPreviews(updatedMediaPreviews);
    });
  };

  const handleOnPollSubmit = (pollPayload: ScopeChatPollPayload) => {
    handleSendMessage({
      content: pollPayload.question,
      type: ScopeChatMessageTypes.POLL,
      pollPayload,
    });
    setIsFullscreenModalOpen(false);
  };

  const handleOnVoiceSubmit = (audioFile: File) => {
    const uuid = generateUuid();
    batch(() => {
      setMediaFiles([{ uuid, file: audioFile }]);
      setIsFullscreenModalOpen(false);
      setIsPreviewVisible(true);
    });

    getLocalMediaUrl(audioFile, (result: string) => {
      batch(() => {
        setMediaPreviews([{ uuid, src: result, isLoading: false, mimetype: audioFile.type }]);
      });
    });
  };

  const handleImagePreviewLoaded = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.target as HTMLImageElement;

    setAttachmentAspectRatio(target.width / target.height);
  };

  const handleVideoPreviewMetadataLoaded = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    const target = event.target as HTMLVideoElement;

    setAttachmentAspectRatio(target.videoWidth / target.videoHeight);
  };

  return (
    <div
      data-testid="chat-input"
      className={`bg-neutral-black-20 backdrop-blur-[17.5px] border border-neutral-white-12 hover:border-white 
        flex flex-col justify-between relative px-3 xl:px-4 rounded-[24px] transition-all delay-200
        ${focused} 
        ${isSendingMessage ? "cursor-not-allowed pointer-events-none" : "cursor-text"} 
        ${isPreviewVisible && isMobile ? "!bg-grayscale-1000" : ""}
        ${isMuted ? "opacity-50 pointer-events-none" : ""}
      `}
    >
      {isSendingMessage && (
        <div className="absolute inset-0 z-10 flex w-full">
          <CenterSpinner className="!w-8 !h-8" />
        </div>
      )}
      {isEmojiPickerOpen && (
        <div className="absolute w-fit right-0 bottom-[110%] z-50">
          <EmojiPicker
            onEmojiSelect={handleEmojiSelect}
            onClickOutside={() => isEmojiPickerOpen && setIsEmojiPickerOpen(false)}
          />
        </div>
      )}
      {isAttachmentMenuOpen && (
        <AttachmentMenu
          onClickOutside={() => setIsAttachmentMenuOpen(false)}
          setType={setType}
          handleAttachMedia={() => mediaInputRef.current.click()}
        />
      )}

      {!!mediaPreviews.length && type === ScopeChatMessageTypes.MEDIA && (
        <div className="flex gap-4 w-full justify-start flex-wrap py-4 cursor-default">
          {mediaPreviews.map(({ uuid, src, isLoading, mimetype }, index) => (
            <div
              key={`preview-${index}`}
              className="relative w-16 xl:w-24 aspect-square border border-neutral-white-10 rounded-lg overflow-hidden"
            >
              {isLoading && (
                <div className="absolute inset-0 backdrop-blur-lg">
                  <CenterSpinner />
                </div>
              )}
              {!!mimetype.match("image") && (
                <img
                  className="w-16 xl:w-24 aspect-square object-cover"
                  src={src}
                  onLoad={handleImagePreviewLoaded}
                />
              )}
              {!!mimetype.match("video") && (
                <div className="flex max-w-24 w-16 xl:w-24 aspect-square relative items-center justify-center">
                  <div className="absolute inset-0 flex justify-center items-center">
                    <PlayIcon className="!w-8 !h-8" />
                  </div>
                  <video
                    preload="metadata"
                    src={src}
                    onLoadedMetadata={handleVideoPreviewMetadataLoaded}
                  />
                </div>
              )}
              {isUploadInProgress && !!uploadProgress?.[uuid] && (
                <progress
                  className="absolute bottom-0 left-0 w-full progress progress-primary"
                  value={uploadProgress[uuid] / 100}
                />
              )}
              <div className="absolute top-0 right-0 min-h-min h-6">
                <Button
                  className="btn btn-xs btn-circle hover:bg-neutral-black-90 p-0 m-0"
                  onClick={() => handleRemoveMedia(index)}
                  disabled={isUploadInProgress}
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          ))}
          {mediaPreviews.length < MAX_FILES_COUNT && !isUploadInProgress && (
            <Button
              className="btn btn-outline btn-square w-16 xl:w-24 h-16 xl:h-24 rounded-md"
              onClick={() => mediaInputRef.current.click()}
              disabled={isUploadInProgress}
            >
              +
            </Button>
          )}
        </div>
      )}

      {!!mediaPreviews.length && type === ScopeChatMessageTypes.VOICE && (
        <div className="flex w-full justify-start py-4 cursor-default">
          {mediaPreviews.map(({ uuid, src, isLoading, mimetype }, index) => (
            <div
              key={`preview-${index}`}
              className="relative w-full h-[88px] border border-neutral-white-10 rounded-lg overflow-hidden"
            >
              {isLoading && (
                <div className="absolute inset-0 backdrop-blur-lg z-10">
                  <CenterSpinner />
                </div>
              )}
              {!!mimetype.match("audio") && (
                <div className="flex w-full h-full items-center justify-center relative">
                  <AudioPlayer src={src} />
                </div>
              )}
              {isUploadInProgress && !!uploadProgress?.[uuid] && (
                <progress
                  className="absolute bottom-0 left-0 w-full progress progress-primary"
                  value={uploadProgress[uuid] / 100}
                />
              )}
              <div className="absolute top-0 right-0 min-h-min h-6 z-10">
                <Button
                  className="btn btn-xs btn-circle hover:bg-neutral-black-90 p-0 m-0"
                  onClick={() => handleRemoveMedia(index)}
                  disabled={isUploadInProgress}
                >
                  <CloseIcon />
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
      <input
        ref={mediaInputRef}
        className="hidden"
        accept="image/*, video/*"
        type="file"
        onChange={(event) => {
          setType(ScopeChatMessageTypes.MEDIA);
          handleOnMediaInputChange(event.target.files);
          event.target.value = "";
        }}
        multiple
      />

      <div
        ref={chatInputRef}
        className="flex w-full items-center justify-between gap-2 box-border py-3"
      >
        <div className="flex items-center w-full h-content">
          <textarea
            ref={textareaRef}
            value={content}
            maxLength={SCOPE_CHAT_MESSAGE_LENGTH}
            onChange={handleTextChanged}
            onClick={handleTextareaClick}
            onKeyDown={handleKeyDown}
            onFocus={() => setFocused("border-white")}
            onBlur={() => setFocused("")}
            disabled={isSendingMessage || isMuted}
            placeholder="Say something..."
            className="ios-no-scroll focus:border-none focus:outline-none w-full bg-transparent box-border resize-none border-none overflow-hidden text-body text-14 font-medium"
          />
        </div>
        <div className="flex justify-center items-center gap-2">
          {isMobile && (
            <div className="tooltip !tooltip-black w-8 h-8" data-tip="Send message">
              <button
                className="btn btn-square btn-ghost rounded-md w-8 h-8 min-h-8"
                onClick={() => handleSendMessageClick()}
              >
                <SendIcon className="fill-grayscale-400" />
              </button>
            </div>
          )}
          {!isMobile && (
            <div className="tooltip !tooltip-black w-8 h-8" data-tip="Add emoji reaction">
              <button
                className="btn btn-square btn-ghost rounded-md w-8 h-8 min-h-8"
                onClick={(event) => {
                  if (cardView) {
                    handleOpenEmojiPicker(event);
                  } else {
                    event.stopPropagation();
                    setIsEmojiPickerOpen((prev) => !prev);
                  }
                }}
              >
                <EmojiReactionFilled />
              </button>
            </div>
          )}
          {!thread && !mediaFiles.length && !cardView && (
            <div className="tooltip !tooltip-black w-8 h-8" data-tip="Add file">
              <button
                className="btn btn-square btn-ghost rounded-md w-8 h-8 min-h-8"
                onClick={() => setIsAttachmentMenuOpen((prev) => !prev)}
              >
                <PlusIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
