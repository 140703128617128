import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useClickOutside } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useExternalWallet } from "@/providers/externalWallet";

import Profiles from "./Profiles";
import ProfileAvatar from "@/components/User/ProfileAvatar";
import Container from "@/components/UI/Container";
import AppLink from "@/components/AppLink";

import { DIVIDER, ICON_ITEM, getProfileMenuItems } from "@/constants/app.constants";
import { useGeneralUi } from "@/providers/generalUi";
import { useAppGateway } from "@/providers/appGateway";

interface ProfileMenuProps {
  className?: string;
  [x: string]: unknown;
}

const ProfileMenu = ({ className, ...rest }: ProfileMenuProps) => {
  const location = useLocation();

  const { userProfile, managedPlayerProfile } = useProfile();
  const { isConnected } = useExternalWallet();
  const { isMobileApp } = useGeneralUi();
  const { showInstallAppModal } = useAppGateway();

  const profileMenuRef = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useClickOutside(profileMenuRef, () => setIsMenuOpen(false));

  return (
    <div ref={profileMenuRef} className="relative h-10">
      <button
        data-testid="user-profile-menu-btn"
        onClick={() => setIsMenuOpen((prev) => !prev)}
        className={`btn btn-circle w-10 h-10 min-h-[40px] flex-nowrap ${className}`}
        {...rest}
      >
        <ProfileAvatar
          profile={managedPlayerProfile || userProfile}
          containerClass="p-0"
          imageClass="!w-[38px] !h-[38px]"
        />
      </button>
      {isMenuOpen && (
        <ul className="absolute top-0 -right-2 translate-y-12">
          <Container className="py-4 px-2 flex-col w-full gap-1">
            <Profiles />
            {getProfileMenuItems({
              isWalletConnected: isConnected,
              isMobileApp,
              showInstallAppModal,
            }).map((item, index) => {
              const isActive = location.pathname === item.path;

              return item.hidden ? null : (
                <div key={`profile-${index}`} className="w-full">
                  {item.type === ICON_ITEM && (
                    <li className="text-caps text-13 font-heavy text-white">
                      {item.path ? (
                        <AppLink
                          data-testid={item.testId}
                          to={item.path ?? ""}
                          onClick={() => {
                            setIsMenuOpen(false);
                            item.action && item.action();
                          }}
                          className={`w-full flex gap-2 justify-start items-center px-4 py-2 hover:bg-neutral-white-7 rounded-lg whitespace-nowrap ${
                            isActive ? "bg-neutral-white-7" : ""
                          }`}
                        >
                          {item.Icon && <item.Icon />}
                          {item.title}
                        </AppLink>
                      ) : (
                        <div
                          data-testid={item.testId}
                          onClick={() => {
                            setIsMenuOpen(false);
                            item.action && item.action();
                          }}
                          className="w-full flex gap-2 justify-start items-center cursor-pointer px-4 py-2 hover:bg-neutral-white-7 rounded-lg whitespace-nowrap"
                        >
                          {item.Icon && <item.Icon />}
                          {item.title}
                        </div>
                      )}
                    </li>
                  )}
                  {item.type === DIVIDER && <div className="divider h-6 px-4 -my-1"></div>}
                </div>
              );
            })}
          </Container>
        </ul>
      )}
    </div>
  );
};

export default ProfileMenu;
