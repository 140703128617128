import { IBackgroundSettings } from "@/types/app.types";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  setDynamicBackground,
  setStaticBackground,
  selectDynamicBackground,
  selectStaticBackground,
} from "@/reducers/ui.slice";
import {
  selectDynamicBackground as selectDefaultDynamicBackground,
  selectStaticBackground as selectDefaultStaticBackground,
} from "@/reducers/platformSettings.slice";

export default function useToggleBackground(backgroundSettings?: IBackgroundSettings) {
  const dispatch = useAppDispatch();

  const defaultDynamicBackground = useAppSelector(selectDefaultDynamicBackground);
  const defaultStaticBackground = useAppSelector(selectDefaultStaticBackground);

  const currentDynamicBackground = useAppSelector(selectDynamicBackground);
  const currentStaticBackground = useAppSelector(selectStaticBackground);

  useEffect(() => {
    if (!backgroundSettings) return;

    backgroundSettings.dynamicBackground &&
      currentDynamicBackground !== backgroundSettings.dynamicBackground &&
      dispatch(setDynamicBackground(backgroundSettings.dynamicBackground));
    backgroundSettings.staticBackground &&
      currentStaticBackground !== backgroundSettings.staticBackground &&
      dispatch(
        setStaticBackground(
          backgroundSettings.thumbnails?.md || backgroundSettings.staticBackground,
        ),
      );

    return () => {
      dispatch(setDynamicBackground(defaultDynamicBackground));
      dispatch(setStaticBackground(defaultStaticBackground));
    };
  }, [backgroundSettings]);
}
