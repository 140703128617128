import React from "react";

import { useGeneralUi } from "@/providers/generalUi";
import { useFanClub } from "@/providers/fanClub";
import { useScopeChat } from "@/providers/scopeChat";

import SearchInput from "@/components/ScopeBlog/ScopeChat/Inputs/Search/SearchInput";
import MessageList from "@/components/ScopeBlog/ScopeChat/MessageList/MessageList";
import { ChatInput } from "@/components/ScopeBlog/ScopeChat/Inputs/ChatInput";
import CenterSpinner from "@/components/UI/CenterSpinner";
import SideBar from "@/components/ScopeBlog/ScopeChat/Sidebar/SideBar";
import SectionView from "../../Common/SectionView";
import Button from "@/components/UI/Button";
import { CloseIcon } from "@/components/UI/Icons";
import Placeholder from "../../Common/Placeholder";

import { ViewTypes } from "@/types/fanClub/fanClub.types";

const ChatView = () => {
  const { isMobile } = useGeneralUi();
  const { isMember, isAthleteChatAllowed, playerProfile, setCurrentView } = useFanClub();
  const { groupedMessages, handleMessagesScroll, sidebarView } = useScopeChat();

  return (
    <div className="flex-1 w-full h-full flex items-center justify-center">
      <SectionView
        title={isMobile ? "" : "Fan Club Chat"}
        containerClassName={`2xl:max-w-[778px] ${sidebarView ? "!mr-4" : ""}`}
        titleAction={
          <Button
            className="btn btn-ghost btn-square btn-sm rounded-md"
            onClick={() => setCurrentView(ViewTypes.CARDS)}
          >
            <CloseIcon />
          </Button>
        }
      >
        <div className="relative w-full h-full overflow-hidden overflow-y-scroll scrollbar-hidden flex flex-col">
          {isMember && isAthleteChatAllowed ? (
            <div className="flex-1 w-full h-full flex flex-col overflow-hidden">
              <SearchInput />
              <div className="absolute bottom-0 w-full p-4 xl:p-6 z-10">
                <ChatInput />
              </div>
              {groupedMessages ? (
                <MessageList
                  groupedMessages={groupedMessages}
                  handleScroll={handleMessagesScroll}
                />
              ) : (
                <CenterSpinner />
              )}
            </div>
          ) : (
            <div className="flex w-full h-full justify-center items-center">
              <Placeholder text={`Chat with ${playerProfile.name}`} />
            </div>
          )}
        </div>
      </SectionView>
      <SideBar />
    </div>
  );
};

export default ChatView;
