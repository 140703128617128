import { PlayerProfileType } from "../player/player.types";
import { UserProfileType } from "../user/profile.types";
import { IThumbnails } from "../app.types";

export enum PostTypes {
  MEDIA = "media",
  POLL = "poll",
  TEXT_NOTE = "text_note",
  VOICE_NOTE = "voice_note",
}

export interface PostType {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  body: string;
  type: PostTypes;
  media: string;
  mediaMimetype: string;
  id: number;
  comments: CommentType[];
  commentsCount: number;
  votes: VoteType[];
  owner: PlayerProfileType;
  likesCount: number;
  isScopePost: boolean;
  isPinned: boolean;
  thumbnails: IThumbnails;
  isDraft: boolean;
  attachments: PostAttachment[];
}

export interface PostAttachment {
  id: number;
  presignedUrl: string;
  mimetype: string;
  isDraft: boolean;
  uuid: string;
  videoThumbnails?: IThumbnails;
  thumbnails?: IThumbnails;
}

export interface CommentType {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  content: string;
  post: PostType;
  postUuid: string;
  author: PlayerProfileType;
  user: UserProfileType;
  owner: PlayerProfileType | UserProfileType | any;
  parent: CommentType;
  childs: CommentType[];
  votes: CommentVoteType[];
}

export interface VoteType {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  post: PostType;
  owner: PlayerProfileType | UserProfileType | any;
  author: PlayerProfileType;
  content: string;
  isPollVote: boolean;
}

export interface CommentVoteType {
  createdAt: string;
  updatedAt: string;
  uuid: string;
  comment: CommentType;
  owner: PlayerProfileType | UserProfileType | any;
  content: string;
}

export interface PostListRequest {
  skip: number;
  limit: number;
  ownerUuid?: string;
  orderBy?: string;
  orderSort?: string;
  filterBy?: string;
  searchQuery?: string;
  isScopePosts?: boolean;
}

export interface AddCommentRequest {
  content: string;
  postUuid: string;
  ownerUuid?: string;
  parentUuid?: string;
}

export interface HandlePostReactionRequest {
  ownerUuid?: string;
  postUuid?: string;
  content: PostReactionsEnum;
}

export interface HandleCommentReactionRequest {
  ownerUuid?: string;
  commentUuid?: string;
  content: string;
}

export interface PollType {
  id: number;
  votes: number;
  text: string;
}

export interface TransactionType {
  _id: string;
  createdAt: string;
  cardUserUuid: string;
  type: string;
  changedValue: number;
  balanceBefore: number;
  balanceAfter: number;
  feePercent: number;
  feeValue: number;
}

export enum PostReactionsEnum {
  LIKE = "like",
  GREEN_FIRE = "green_fire",
  TRUE_LOVE = "true_love",
  SUPER_STAR = "super_star",
  LIGHTING = "lighting",
}

export enum FeedFilters {
  ALL = "",
  PHOTO = "photo",
  VIDEO = "video",
  POLL = "poll",
  TEXT_NOTE = "text_note",
  VOICE_NOTE = "voice_note",
}

export type FeedResponse = {
  post: PostType;
  postUuid: string;
};

export type GetPostsResponse = {
  items: PostType[];
  meta: any;
};

export enum EmitActions {
  ADD = "add",
  UPDATE = "update",
  REMOVE = "remove",
}

export enum OrderByEnum {
  RECENT = "recent",
  LIKED = "liked",
}

export enum OrderSortEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum BlogTypes {
  FAN_CLUB = "fan_club",
  SCOPE_CHAT = "scope_chat",
}

export enum ViewTypes {
  CARDS = "cards",
  FEED = "feed",
  PREMIUM_CONTENT = "premium_content",
  CHAT = "chat",
}

export interface PremiumContent {
  createdAt: Date;
  videoThumbnails?: IThumbnails;
  presignedUrl: string;
  uuid: string;
  title: string;
  owner: PlayerProfileType;
  aspectRatio: number;
  isDraft: boolean;
}

export enum IncomeMessages {
  USER_IN_FAN_CLUB = "userInFanClub",
  USER_OUT_FAN_CLUB = "userOutFanClub",
}

export interface PostFormValues {
  postUuid: string | undefined;
  body: string;
  type: PostTypes;
  answers?: string[];
  votes?: string[];
  answerCount?: number;
  media: { uuid: string; file: File }[];
  attachments: { uuid: string; presignedUrl: string; mimetype: string }[];
  removeAttachmentUuids: string[];
}
