import React, { useState, useEffect } from "react";

import { usePaymentForm } from "@/providers/PaymentFormProvider";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useExternalWallet } from "@/providers/externalWallet";
import { useProfile } from "@/providers/profile";

import CenterSpinner from "../UI/CenterSpinner";
import Spinner from "@/components/UI/Spinner";
import CheckoutCard from "@/components/Payment/CheckoutCard";
import Button from "../UI/Button";
import { CloseIcon } from "@/components/UI/Icons";
import { default as StripePaymentForm } from "./Stripe/PaymentForm";
import { default as CryptoPaymentForm } from "./Crypto/PaymentForm";

import {
  CryptoPayment,
  Payment,
  PaymentMethods,
  PaymentTab,
  PaymentTabs,
  StripePayment,
} from "@/types/payment/payment.types";

const PaymentModal = () => {
  const { userProfile } = useProfile();
  const { handleCloseModal } = useFullscreenModal();
  const { paymentMethod, payment, setPaymentMethod, tradeCard, isLoading } = usePaymentForm();
  const { handleConnectWallet, isConnected, isExternalWalletActive } = useExternalWallet();

  const cardPrice = payment?.details.cardPrice || tradeCard.basePrice;
  const scopeFee = payment?.details.fee || 0;
  const totalPrice = payment?.details.totalPrice || cardPrice + scopeFee;

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
    ({ type: PaymentMethods } & PaymentTab)[]
  >([]);

  const handleCloseModalClick = () => {
    !isLoading && handleCloseModal();
  };

  useEffect(() => {
    if (userProfile?.useExternalWallet && !isConnected) {
      handleConnectWallet();
    }
  }, [userProfile]);

  useEffect(() => {
    const availablePaymentMethods = Object.keys(PAYMENT_TABS)
      .map((key) => ({ type: key as PaymentMethods, ...PAYMENT_TABS[key as PaymentMethods] }))
      .filter((method) => !method.isHidden);

    if (!availablePaymentMethods.length) return;

    setAvailablePaymentMethods(availablePaymentMethods);
    setPaymentMethod(availablePaymentMethods[0].type);
  }, [isConnected]);

  const PAYMENT_TABS: PaymentTabs = {
    [PaymentMethods.STRIPE]: {
      component: (payment: Payment) => <StripePaymentForm payment={payment as StripePayment} />,
      label: "Pay with cash",
    },
    [PaymentMethods.CRYPTO]: {
      component: (payment: Payment) => <CryptoPaymentForm payment={payment as CryptoPayment} />,
      label: "Pay with crypto",
      isHidden: !isConnected,
    },
  };

  return (
    <div className="modal backdrop-blur-md modal-open">
      <div className="modal-box text-start max-w-6xl flex flex-col gap-4 bg-element-background border border-neutral-white-12">
        <div className="flex justify-between items-center mobile:flex-col mobile:gap-4">
          <span className="text-header">Payment</span>
          <div className="flex items-center justify-between gap-4 w-1/2 mobile:w-full">
            <div className="flex w-full gap-4">
              {availablePaymentMethods.length === 1
                ? null
                : availablePaymentMethods.map((method) => {
                    return !method.isHidden ? (
                      <Button
                        key={method.type}
                        onClick={() => setPaymentMethod(method.type)}
                        className={`btn ${
                          method.type === paymentMethod ? "btn-primary" : "btn-ghost"
                        }`}
                      >
                        {method.label}
                      </Button>
                    ) : null;
                  })}
            </div>
            <button
              onClick={handleCloseModalClick}
              className="btn btn-sm btn-square btn-ghost p-0 m-0"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="flex flex-col w-full xl:flex-row">
          <div className="flex-1 flex flex-col gap-4 justify-between ">
            <CheckoutCard tradeCard={tradeCard} />
            <div className="flex">
              <div className="card w-full border-dashed border">
                <div className="card-body">
                  {!isLoading && !isExternalWalletActive ? (
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <span className="text-md">Card cost:</span>
                        <span className="text-md">
                          {cardPrice < tradeCard.basePrice ? (
                            <div className="flex gap-1">
                              <span className="line-through text-red-400">
                                ${tradeCard.basePrice}
                              </span>
                              {payment?.details.currency} {cardPrice}
                            </div>
                          ) : (
                            `${payment?.details.currency} ${cardPrice}`
                          )}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-md">Fee:</span>
                        <span className="text-md">
                          {payment?.details.currency} {scopeFee.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-title !text-lg">Total:</span>
                        <span className="text-title !text-lg">
                          {payment?.details.currency} {totalPrice.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <CenterSpinner />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="divider xl:divider-horizontal"></div>
          <div className="flex-1 card w-full rounded-none">
            {availablePaymentMethods.length ? (
              <div className="flex flex-col justify-between w-full h-full">
                {payment && paymentMethod && !(isLoading || isExternalWalletActive) ? (
                  PAYMENT_TABS[paymentMethod].component(payment)
                ) : (
                  <div className="flex flex-row justify-center items-center gap-3 my-auto">
                    <Spinner />
                    <span>Retrieving payment info...</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-row justify-center items-center gap-3 my-auto">
                <span>There is no available payment methods...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
