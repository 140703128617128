import React from "react";

import { DEFAULT_PROFILE_URL } from "@/constants/app.constants";

interface AvatarProps {
  url: string;
  containerClass?: string;
  imageClass?: string;
}

const Avatar = ({ url, containerClass, imageClass }: AvatarProps) => {
  return (
    <div className={`avatar ${containerClass ?? ""}`}>
      <figure className="m-0 p-0">
        <div className={`w-6 h-6 rounded-full overflow-hidden ${imageClass}`}>
          <img src={url || DEFAULT_PROFILE_URL} />
        </div>
      </figure>
    </div>
  );
};

export default Avatar;
