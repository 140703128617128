import { Card } from "../card/card.types";

export interface BalanceHistoryType {
  _id: string;
  cardUserUuid: string;
  type: string;
  feePercent: number;
  feeValue: number;
  currency: string;
  amountInCurrency: number;
  changedValue: number;
  balanceBefore: number;
  balanceAfter: number;
  createdAt: string;
  cardUser?: CardUser;
}

export interface CardUser {
  uuid: string;
  card: Card;
}

export interface LastRefillType {
  type: string;
  changedValue: number;
  balanceBefore: number;
  balanceAfter: number;
  createdAt: string;
}

/**
 * The enum of types balance history.
 */
export enum BalanceHistoryTypesEnum {
  marketBought = "marketplace-bought",
  marketSold = "marketplace-sold",
  stripeGained = "stripe-gained",
  proshopBought = "proshop-bought",
  proshopSold = "proshop-sold",
}
