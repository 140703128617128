import React from "react";
import "./AudioCover.styles.css";
import { AudioCoverIcon } from "@/components/UI/Icons";

const AudioCover = ({ compact }: { compact?: boolean }) => {
  return (
    <div
      className={`flex-1 flex w-full h-full bg-grayscale-900 border-bottom justify-center items-center ${
        compact ? "py-2" : "py-10"
      }`}
    >
      <AudioCoverIcon className={`${compact ? "w-1/6" : "w-1/4"}`} />
    </div>
  );
};

export default AudioCover;
