import React, { useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";

import Button from "@/components/UI/Button";
import VideoPlayer_v2 from "@/components/UI/VideoPlayer/VideoPlayer_v2";
import CenterSpinner from "@/components/UI/CenterSpinner";
import { CloseIcon } from "@/components/UI/Icons";

import {
  selectPlayerProfile,
  selectPlayerProfiles,
  setPlayerProfile,
} from "@/reducers/scopeBlog.slice";

const PremiumContentPreview = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { playerUuid } = useParams();
  const [searchParams] = useSearchParams();
  const learnMore = searchParams.get("learnMore");

  const playerProfile = useAppSelector(selectPlayerProfile);
  const playerProfiles = useAppSelector(selectPlayerProfiles);

  useEffect(() => {
    if (!playerUuid) return;

    const playerProfile = playerProfiles.find((playerProfile) =>
      [playerProfile.urlAlias, playerProfile.uuid].includes(playerUuid),
    );

    dispatch(setPlayerProfile(playerProfile));
  }, [playerProfiles, playerUuid]);

  const onClickLearnMore = () => {
    playerProfile?.uuid && navigate(`/fan-club/${playerProfile.urlAlias || playerProfile.uuid}`);
  };

  return (
    <div className="fixed ios-inset-0 overflow-hidden z-[65]">
      <div className="absolute inset-0 flex flex-col items-center justify-between w-full h-full overflow-hidden">
        <div className="flex w-full justify-end items-center px-4 py-2 xl:px-[60px] xl:py-8 border-bottom !p-4 !border-none">
          <Button
            className="btn btn-ghost btn-square btn-sm rounded-md"
            onClick={() => navigate(-1)}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex-1 flex xl:pt-10 w-full justify-center overflow-hidden overflow-y-scroll scrollbar-hidden p-0 xl:!p-6">
          {playerProfile && playerProfile.previewVideo ? (
            <div
              className="flex flex-col w-full h-full justify-between xl:px-14"
              data-testid="preview-video-modal"
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col items-start text-start px-4 xl:px-0">
                  <span className="text-caps text-13 font-regular">FAN CLUB SAMPLE</span>
                  <h2 className="text-heading font-medium">{playerProfile.name}</h2>
                </div>
                {learnMore && (
                  <Button className="btn btn-primary hidden xl:block" onClick={onClickLearnMore}>
                    LEARN MORE
                  </Button>
                )}
              </div>
              <div className="flex-1 w-full relative">
                <div className="absolute inset-0 flex-col w-full h-full gap-6 xl:gap-12">
                  <VideoPlayer_v2 url={playerProfile.previewVideo} autoplay />
                </div>
              </div>
              {learnMore && (
                <div className="flex w-full xl:hidden p-4">
                  <Button className="btn btn-primary hidden xl:block" onClick={onClickLearnMore}>
                    LEARN MORE
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <CenterSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default PremiumContentPreview;
