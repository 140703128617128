import { useEffect } from "react";
import {
  useGetMembersByPlayerUuidMutation,
  useGetByUidMutation,
  useToggleReadOnlyMutation,
} from "@/api/playerApi";
import { useAppDispatch, useAppSelector } from "../hooks";

import {
  selectMembers,
  selectMembersSearchQuery,
  selectMembersSkip,
  selectMembersTotal,
  selectPlayerProfile,
  setMembers,
  setMembersSearchQuery,
  setMembersSkip,
  setMembersTotal,
  setPlayerProfile,
} from "@/reducers/player.slice";
import { useProfile } from "@/providers/profile";

export const MEMBERS_PAGE_LIMIT = 7;

export const usePlayer = () => {
  const dispatch = useAppDispatch();

  const { managedPlayerProfile } = useProfile();

  const playerProfile = useAppSelector(selectPlayerProfile);
  const members = useAppSelector(selectMembers);
  const membersSkip = useAppSelector(selectMembersSkip);
  const membersTotal = useAppSelector(selectMembersTotal);
  const membersSearchQuery = useAppSelector(selectMembersSearchQuery);

  const [getMembers, { isLoading: isMembersLoading }] = useGetMembersByPlayerUuidMutation();
  const [getPlayerByUuid, { isLoading: isPlayerProfileLoading }] = useGetByUidMutation();
  const [toggleReadOnly, { isLoading: isToggleReadOnlyLoading }] = useToggleReadOnlyMutation();

  const setSkip = (value: number) => {
    dispatch(setMembersSkip(value));
  };
  const setSearchQuery = (value: string) => {
    dispatch(setMembersSearchQuery(value));
  };

  const fetchPlayerByUuid = async (playerUuid: string) => {
    const playerProfile = await getPlayerByUuid(playerUuid).unwrap();
    dispatch(setPlayerProfile(playerProfile));

    return playerProfile;
  };

  const fetchMembers = async () => {
    if (!managedPlayerProfile?.uuid) {
      return;
    }

    const response = await getMembers({
      playerUuid: managedPlayerProfile.uuid,
      skip: membersSkip,
      limit: MEMBERS_PAGE_LIMIT,
      searchQuery: membersSearchQuery,
    }).unwrap();

    const { members, total } = response || {};

    dispatch(setMembersTotal(total));
    dispatch(setMembers(members));
  };

  useEffect(() => {
    if (managedPlayerProfile && !isMembersLoading) {
      fetchMembers();
    }
  }, [membersSkip, membersSearchQuery]);

  useEffect(() => {
    if (managedPlayerProfile && !isMembersLoading) {
      dispatch(setMembersSkip(0));
    }
  }, [managedPlayerProfile]);

  return {
    playerProfile,
    members,
    membersTotal,
    membersSearchQuery,
    fetchMembers,
    fetchPlayerByUuid,
    isMembersLoading,
    isPlayerProfileLoading,
    setSkip,
    setSearchQuery,
    toggleReadOnly,
    isToggleReadOnlyLoading,
  };
};
