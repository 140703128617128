import React from "react";
import ResetPasswordForm from "@/components/ResetPasswordForm";

const ResetPasswordRequest = () => {
  return (
    <div className="xl:px-10">
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPasswordRequest;
