import React from "react";

import Container from "@/components/UI/Container";
import { CreepyFaceIcon } from "@/components/UI/Icons";
import AppLink from "@/components/AppLink";

const ErrorPage = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container className="p-6 xl:max-w-[50%]">
        <div className="w-full text-center text-white">
          <div className="flex items-center justify-center mb-4 xl:mb-10">
            <CreepyFaceIcon className="!w-16 !h-16 xl:!w-[72px] xl:!h-[72px]" />
          </div>
          <h2 className="text-heading font-medium xl:!text-[56px] ">Aaaah! Something went wrong</h2>
          <h1 className="text-body text-16 font-regular mt-3">
            Sorry, there seems to be an internal error, we are doing our best to fix it.
          </h1>
          <a href={"/"} className="btn btn-primary rounded-full btn-block my-6 xl:my-10">
            Lets try again
          </a>
          <div className="flex items-center justify-center">
            <AppLink to={"/support"} className="link text-secondary !text-sm uppercase">
              Get Some Help
            </AppLink>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ErrorPage;
