import React, { ChangeEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import { useClickOutside } from "@/app/hooks";

import { ChevronIcon, CheckmarkIcon, FilterIcon } from "../Icons";
import Container from "../Container";

import { snakeCaseToReadableString } from "@/helpers/string.helpers";

import "./Select.styles.css";

export interface Option {
  value: string | number;
  label: string | number;
}

interface _SelectProps {
  options: { value: string; label: string }[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  label?: string;
  value?: string | number;
  className?: string;
  inputProps?: HTMLInputElement;
}

interface SelectButtonProps {
  options: Option[];
  label?: string;
  placeholder?: string;
  valueOnLabel?: boolean;
  icon?: ReactNode;
  iconPosition?: IconPositionsEnum;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  value?: string | number;
  className?: string;
  inputProps?: HTMLInputElement;
  onChange: (value: string | number) => void;
}

export enum IconPositionsEnum {
  LEFT = "left",
  RIGHT = "left",
}

export const Select = ({
  options,
  onChange,
  value,
  placeholder = "Select...",
  leftAdornment,
  rightAdornment,
  ...props
}: SelectButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option>();

  const listRef = useRef<HTMLDivElement>(document.createElement("div"));

  useClickOutside(listRef, () => setIsOpen(false));

  const { className, ...restProps } = props || {};

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value));
  }, [value, options]);

  const handleSelectOption = (option: Option) => {
    onChange(option.value);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={`btn btn-ghost rounded-full !text-caps !text-13 bg-neutral-white-5 border border-neutral-white-12 py-1 px-3 ${
          className ?? ""
        }`}
        {...restProps}
      >
        <div className="flex items-center justify-between gap-2">
          {leftAdornment}
          <span className={!leftAdornment ? "pl-1" : ""}>
            {selectedOption?.label || placeholder}
          </span>
          {rightAdornment || <ChevronIcon />}
        </div>
      </div>
      {isOpen && (
        <Container ref={listRef} className="absolute p-3 z-10 flex flex-col w-full">
          {options &&
            options.map((option) => (
              <div
                key={option.value}
                className={`cursor-pointer flex flex-row text-start w-full gap-2 ${
                  option.value === selectedOption?.value ? "font-heavy" : ""
                }`}
                onClick={() => handleSelectOption(option)}
              >
                <CheckmarkIcon
                  stroke={option.value === selectedOption?.value ? "currentColor" : "transparent"}
                />
                <span className="w-full">{option.label}</span>
              </div>
            ))}
        </Container>
      )}
    </div>
  );
};

export const _Select = ({ options, onChange, ...inputProps }: _SelectProps) => {
  const { className, ...restInputProps } = inputProps || {};
  return (
    <select
      className={`select select-bordered rounded-full h-10 min-h-[40px] max-w-[250px] w-full xl:min-w-[150px] pl-5 ${className}`}
      onChange={onChange}
      {...restInputProps}
    >
      {options &&
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
  );
};

export const SelectButton = ({
  options,
  onChange,
  value,
  label,
  valueOnLabel,
  icon,
  iconPosition,
  ...props
}: SelectButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const listRef = useRef<HTMLUListElement>(document.createElement("ul"));
  useClickOutside(listRef, () => setIsOpen(false));

  const { className, ...restProps } = props || {};

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={`btn btn-sm btn-ghost rounded-full min-w-[110px] bg-opacity-10 xl:!h-10 select-button-btn 
                    !text-caps !text-13 !font-heavy ${className}`}
        {...restProps}
      >
        <div className="flex items-center gap-1">
          {icon && iconPosition !== IconPositionsEnum.RIGHT && icon}
          {label}
          {valueOnLabel && ` ${snakeCaseToReadableString(value as string)}`}
          {icon && iconPosition === IconPositionsEnum.LEFT && icon}
        </div>
      </button>
      {isOpen && (
        <ul
          ref={listRef}
          className="absolute select-button-list dropdown-content menu p-2 shadow z-50"
        >
          {options &&
            options.map((option) => (
              <li key={option.value}>
                <a
                  className="flex flex-row text-start justify-between"
                  onClick={() => onChange(option.value)}
                >
                  <span className={`${option.value === value ? "!text-white" : ""}`}>
                    {option.label}
                  </span>
                  {option.value === value ? <CheckmarkIcon /> : null}
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export const FilterSelect = ({ options, onChange, value, ...props }: SelectButtonProps) => {
  const { ...restProps } = props || {};

  return (
    <SelectButton
      label="Filters"
      icon={<FilterIcon />}
      value={value}
      options={options}
      onChange={onChange}
      {...restProps}
    />
  );
};

export const SelectMinimal = ({ options, onChange, ...inputProps }: _SelectProps) => {
  const { className, label, ...restInputProps } = inputProps || {};
  return (
    <div className="w-full flex justify-center items-center min-w-[220px]">
      <span className="text-secondary uppercase !text-xs">{label}</span>
      <select
        className={`select select-ghost select-xs w-fit max-w-xs text-white !text-xs uppercase mx-1 !bg-opacity-0 ${className}`}
        onChange={onChange}
        {...restInputProps}
      >
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};
