import React from "react";

import { useAppNavigate } from "@/app/hooks";

import Button from "@/components/UI/Button";

const Welcome = () => {
  const navigate = useAppNavigate();

  const handleAccessPlatform = () => {
    navigate("/");
  };

  return (
    <div className="w-full max-w-[600px] text-left px-4 py-6 xl:px-10 m-auto xl:mt-48">
      <div className="container">
        <div className="w-full mx-auto">
          <div className="flex flex-col gap-10">
            <h4 className="xl:hidden text-heading font-medium">Welcome to Scope!</h4>
            <h2 className="hidden xl:flex text-heading font-medium">Welcome to Scope!</h2>
            <span className="text-body text-16 font-regular opacity-70">
              You&apos;ve successfully created an account.
            </span>
          </div>
          <div className="form-control w-full py-6 xl:py-10">
            <Button className="btn btn-primary rounded-full" onClick={handleAccessPlatform}>
              Access Platform
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
