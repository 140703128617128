import { ReactElement } from "react";
import { RECORD_STATUS, useAudioRecorder } from "@/app/hooks/useAudioRecorder";

const AudioRecorder = ({
  setAudioFile,
  setAudioResult,
  render,
}: {
  render: ({
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioResult,
    errorMessage,
    timer,
  }: {
    startRecording: () => void;
    stopRecording: () => void;
    pauseRecording: () => void;
    resumeRecording: () => void;
    status: string;
    audioResult: string;
    errorMessage: string;
    timer: number;
  }) => ReactElement;
  setAudioFile: (audioFile: any) => void;
  setAudioResult: (audioResult: any) => void;
}) => {
  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioResult,
    errorMessage,
    timer,
  } = useAudioRecorder(setAudioFile, setAudioResult);

  return render({
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioResult,
    errorMessage,
    timer,
  });
};

export { AudioRecorder, RECORD_STATUS };
