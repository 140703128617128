import React, { PropsWithChildren, useState } from "react";

import { batch } from "react-redux";
import { useProfile } from "../profile";
import { useReportContentMutation } from "@/api/reportApi";
import { Formik } from "formik";

import Button from "@/components/UI/Button";
import { CloseIcon } from "@/components/UI/Icons";

import { toast } from "@/utils/toast";
import ReportContext from "./ReportContext";
import { ReportOrigins, ReportRequest } from "@/types/reports.types";

const ReportProvider = ({ children }: PropsWithChildren) => {
  const { getUserProfile } = useProfile();

  const [reportContent] = useReportContentMutation();

  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
  const [contentUuid, setContentUuid] = useState<string>();
  const [origin, setOrigin] = useState<ReportOrigins>(ReportOrigins.SCOPE_CHAT);
  const [originUuid, setOriginUuid] = useState<string>();

  const handleReportContent = (contentUuid: string, origin: ReportOrigins, originUuid?: string) => {
    batch(() => {
      setContentUuid(contentUuid);
      setOrigin(origin);
      setOriginUuid(originUuid);
      setIsReportModalOpen(true);
    });
  };

  return (
    <ReportContext.Provider value={{ handleReportContent }}>
      {isReportModalOpen && (
        <div className="modal backdrop-blur-md modal-open modal-bottom xl:modal-middle overflow-hidden">
          <div className="modal-box bg-grayscale-800">
            <Formik
              initialValues={{
                contentUuid,
                description: "",
                hideContent: true,
                origin,
                originUuid,
              }}
              validate={(values) => {
                const errors: Partial<ReportRequest> = {};

                if (!values.description) {
                  errors.description = "Required";
                }

                return errors;
              }}
              onSubmit={async (values, { resetForm }) => {
                const { contentUuid, description, hideContent, origin, originUuid } = values;
                if (!contentUuid || !origin) return;

                reportContent({ contentUuid, description, hideContent, origin, originUuid })
                  .unwrap()
                  .then(async ({ message }) => {
                    if (hideContent) {
                      await getUserProfile();
                      toast.info(
                        "You have admin rights, hiding messages have no effect for your account.",
                      );
                    }
                    toast.success(message);
                  })
                  .finally(() => {
                    resetForm();
                    setIsReportModalOpen(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                resetForm,
              }) => (
                <>
                  <div className="flex justify-between items-center">
                    <div className="flex gap-3 items-center">
                      <span className="text-body text-16 font-medium xl:text-18">
                        Report content
                      </span>
                    </div>
                    <Button
                      onClick={() => {
                        resetForm();
                        setIsReportModalOpen(false);
                      }}
                      className="btn btn-sm btn-square btn-ghost p-0 m-0"
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                  <form className="form-control" onSubmit={handleSubmit}>
                    <div>
                      <label className="label">
                        <span className="text-caps text-13 font-medium text-white opacity-70">
                          Reason for reporting
                        </span>
                      </label>
                      <textarea
                        className={`textarea w-full ${
                          errors.description &&
                          touched.description &&
                          " !border-red-500 !text-red-900"
                        }`}
                        name="description"
                        rows={4}
                        placeholder="Please describe what is wrong with this message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                      ></textarea>
                    </div>
                    <label className="label cursor-pointer justify-start gap-4">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="hideContent"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultChecked
                      />
                      <span className="label-text">{`Hide this ${
                        origin === ReportOrigins.FAN_CLUB_COMMENT ? "comment" : "message"
                      }`}</span>
                    </label>

                    <div className="modal-action">
                      <Button
                        onClick={() => {
                          resetForm();
                          setIsReportModalOpen(false);
                        }}
                        className="btn btn-ghost rounded-full text-body text-14 font-bold uppercase !text-grayscale-500"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary rounded-full px-5"
                        disabled={isSubmitting}
                      >
                        Report
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>
      )}
      {children}
    </ReportContext.Provider>
  );
};

export default ReportProvider;
