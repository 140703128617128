import { CardModels } from "@/types/card/card.types";
import { useGLTF } from "@react-three/drei";
import DefaultCardModel from "./Default/CardModel";
import NewCardModel from "./New/CardModel";

export const CARD_MODELS = {
  [CardModels.DEFAULT]: {
    preloadModel: "/3d/cards/default/card.glb",
    component: DefaultCardModel,
  },

  [CardModels.NEW]: {
    preloadModel: "/3d/cards/new/card.glb",
    component: NewCardModel,
  },
};

const PreLoader = () => {
  try {
    useGLTF.preload(CARD_MODELS[CardModels.DEFAULT].preloadModel);
    useGLTF.preload(CARD_MODELS[CardModels.NEW].preloadModel);
  } catch (error) {
    console.log(error);
  }

  return null;
};

export default PreLoader;
