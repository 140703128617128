import React, { useEffect } from "react";
import { useScopeChat } from "@/providers/scopeChat";
import { useGeneralUi } from "@/providers/generalUi";

import MessageList from "../MessageList/MessageList";
import CenterSpinner from "@/components/UI/CenterSpinner";
import SidebarHeader from "./SidebarHeader";
import MobileHeader from "@/components/UI/MobileHeader";

const SearchView = () => {
  const {
    setIsSearchResultsVisible,
    searchResults,
    searchResultsCount,
    isHandlingSearch,
    handleShowMessage,
  } = useScopeChat();
  const { setHeader } = useGeneralUi();

  useEffect(() => {
    setHeader(
      <MobileHeader label={label} handleBackClick={() => setIsSearchResultsVisible(false)} />,
    );
  }, [searchResultsCount, isHandlingSearch]);

  const label = `Searches (${searchResultsCount} Results)`;

  return (
    <>
      <SidebarHeader label={label} handleBackClick={() => setIsSearchResultsVisible(false)} />
      <div className="flex-1 flex xl:p-4 h-full overflow-hidden">
        {isHandlingSearch ? (
          <CenterSpinner />
        ) : (
          <MessageList
            groupedMessages={searchResults}
            handleClickMessage={(message) => handleShowMessage(message)}
            searchView
          />
        )}
      </div>
    </>
  );
};

export default SearchView;
