import React from "react";

import { useAppNavigate } from "@/app/hooks";

import { CardIcon, CryptoWalletIcon } from "@/components/UI/Icons";

import { TradeCard } from "@/types/card/cardUserList.types";

import "./Card.styles.css";

const Card = ({
  tradeCard,
  onClick,
}: {
  tradeCard: TradeCard;
  boughtFor?: string;
  onClick?: () => void;
}) => {
  const navigate = useAppNavigate();

  const handleShowCard = () => {
    navigate(`/card/${tradeCard.card.uuid}`);
  };

  return (
    <div className="card-container cursor-pointer" onClick={handleShowCard}>
      <div className="card-backdrop" onClick={onClick}>
        <div className="card-placeholder">
          <img
            src={
              tradeCard.card.thumbnails && tradeCard.card.thumbnails.md
                ? tradeCard.card.thumbnails.md
                : tradeCard.card.image
            }
            className="cursor-pointer"
          />
        </div>
        <div className="gradient"></div>

        <div className="card-text">
          <div className="name">{tradeCard?.card?.cardName}</div>
        </div>
      </div>
      <div className="card-bottom">
        <div className="label">
          member card <CardIcon />
        </div>
        {tradeCard.isExternal && (
          <div className="tooltip z-10" data-tip="This card is in your external wallet">
            <CryptoWalletIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
