import { notificationsSocket } from "@/providers/WebSocketProvider";
import {
  selectIsNotificationModalOpen,
  selectNotificationItems,
  selectUnreadedCount,
} from "@/reducers/notifications.slice";
import { useAppSelector } from "../hooks";
import { useProfile } from "@/providers/profile";
import { NotificationActions } from "@/types/notification/notification.types";

export const useNotifications = () => {
  const notifications = useAppSelector(selectNotificationItems);
  const unreadedCount = useAppSelector(selectUnreadedCount);
  const isNotificationModalOpen = useAppSelector(selectIsNotificationModalOpen);

  const { userProfile } = useProfile();

  const markAsRead = (notificationUuid: string) => {
    if (userProfile) {
      notificationsSocket.emit(`${NotificationActions.MARK_AS_READ}`, { notificationUuid });
    }
  };

  const markAllAsRead = () => {
    if (userProfile) {
      notificationsSocket.emit(`${NotificationActions.MARK_ALL_AS_READ}`, {
        subscriberUuid: userProfile.uuid,
      });
    }
  };

  return { notifications, unreadedCount, isNotificationModalOpen, markAsRead, markAllAsRead };
};
