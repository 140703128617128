import React from "react";

import { useProfile } from "@/providers/profile";
import { useAppDispatch } from "@/app/hooks";

import { MenuIcon, ScopeIcon } from "@/components/UI/Icons";
import Container from "@/components/UI/Container";
import MobileNotificationButton from "@/components/NotificationCenter/MobileNotificationButton";
import AppLink from "@/components/AppLink";

import { setIsMobileMenuOpen } from "@/reducers/ui.slice";

const MobileNavbar = () => {
  const dispatch = useAppDispatch();
  const { isAuthorized } = useProfile();

  return (
    <Container border="rounded-xl">
      <div
        data-testid="navbar"
        className="flex border-right !border-neutral-white-40 h-full px-4 py-3"
      >
        <AppLink to="/">
          <ScopeIcon className="w-8 h-8" />
        </AppLink>
      </div>
      {isAuthorized ? (
        <div className="flex justify-between items-center w-full h-full pl-1 pr-2">
          <button
            data-testid="mobile-menu-btn"
            className="btn btn-ghost btn-square"
            onClick={() => dispatch(setIsMobileMenuOpen(true))}
          >
            <MenuIcon />
          </button>
          <MobileNotificationButton />
        </div>
      ) : (
        <div className="flex justify-end items-center w-full h-full pl-1 pr-2">
          <AppLink
            to="/login"
            className="btn btn-primary btn-sm rounded-full text-12"
            data-testid="login-link"
          >
            <span>Sign in</span>
          </AppLink>
        </div>
      )}
    </Container>
  );
};

export default MobileNavbar;
