import React from "react";

import MobilePublicNavbar from "@/components/Page/Mobile/MobilePublicNavbar";
import OnboardForm from "@/components/OnboardForm";
import PublicNavbar from "@/components/Page/PublicNavbar";
import AppLink from "@/components/AppLink";

const Onboard = () => {
  return (
    <div className="flex flex-col xl:p-10 h-full">
      <MobilePublicNavbar />
      <PublicNavbar>
        Already have an account?
        <AppLink to={"/login"} className="underline">
          Sign in
        </AppLink>
      </PublicNavbar>
      <div className="flex-1 flex w-full justify-center py-10">
        <OnboardForm />
      </div>
    </div>
  );
};

export default Onboard;
