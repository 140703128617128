import React from "react";
import { useProshop } from "@/providers/ProshopProvider";

import EmptySection from "@/components/UI/EmptySection";
import { MyOfferIcon } from "@/components/UI/Icons";
import Player from "@/components/ProShop/Player";
import Filters from "./Filters";

const AthletesSection = () => {
  const { proshopPlayerProfiles, searchByPlayerNameQuery, setSearchByPlayerNameQuery } =
    useProshop();

  return (
    <div className="flex flex-col w-full">
      <Filters onSearch={setSearchByPlayerNameQuery} searchQuery={searchByPlayerNameQuery} />
      <div className="flex w-full">
        {proshopPlayerProfiles?.length ? (
          <div className="grid w-full grid-cols-1 justify-items-center my-4 gap-5 xl:grid-cols-3 2xl:grid-cols-4">
            {proshopPlayerProfiles.map((playerProfile) => (
              <Player key={playerProfile.uuid} playerProfile={playerProfile} />
            ))}
          </div>
        ) : (
          <EmptySection
            icon={<MyOfferIcon />}
            title="Looks like there is no athletes … yet!"
            text="Try go to the Marketplace to start placing bids for your favorite cards."
          />
        )}
      </div>
    </div>
  );
};

export default AthletesSection;
