import React from "react";

import { useGeneralUi } from "@/providers/generalUi";

import Spinner from "./Spinner";
import VideoPlayer_v3 from "./VideoPlayer/VideoPlayer_v3";

import { IThumbnails } from "@/types/app.types";
import { isImage, isVideo } from "@/helpers/media.helpers";

const MediaElement = ({
  presignedUrl,
  mimetype,
  index,
  activeIndex,
  isDraft,
  isFullscreen,
  thumbnails,
  videoThumbnails,
}: {
  presignedUrl: string;
  mimetype: string;
  index: number;
  activeIndex?: number;
  isDraft?: boolean;
  isFullscreen?: boolean;
  thumbnails?: IThumbnails;
  videoThumbnails?: IThumbnails;
}) => {
  const { isMobile } = useGeneralUi();

  if (isDraft)
    return isFullscreen ? (
      <div className="flex flex-col w-full justify-center items-center xl:min-h-72 px-4 gap-4">
        <Spinner />
        This media is being processed, please check again in several minutes…
      </div>
    ) : (
      <div className="flex items-center justify-center p-4">
        <Spinner />
      </div>
    );

  if (isImage({ mimetype }))
    return <img src={isMobile ? thumbnails?.md || presignedUrl : presignedUrl} />;

  if (isVideo({ mimetype }))
    return (
      <VideoPlayer_v3
        src={isMobile ? videoThumbnails?.md || presignedUrl : videoThumbnails?.xl || presignedUrl}
        fallBackSrc={presignedUrl}
        className="flex w-full h-full justify-center object-cover"
        autoPlay={index === activeIndex}
        controls={isFullscreen}
        showPlayIcon={!isFullscreen}
        playsInline
      />
    );

  return null;
};

export default MediaElement;
