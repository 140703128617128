import React from "react";
import SearchInput from "@/components/UI/SearchInput";

interface FiltersProps {
  searchQuery: string;
  onSearch: (value: string) => unknown;
}

const Filters = ({ searchQuery, onSearch }: FiltersProps) => {
  return (
    <div className="flex flex-col w-full py-2 text-white">
      <div className="flex flex-col items-center w-full sm:w-2/3 xl:w-1/2 sm:justify-start gap-2 sm:gap-8 sm:flex-row">
        <div className="w-full">
          <SearchInput
            value={searchQuery}
            onSearch={onSearch}
            containerClassName="sm:max-w-[260px]"
            placeholder="Search"
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
