import { PlayerProfileType } from "@/types/player/player.types";
import { ProfileTypes } from "@/types/profile-types.enum";
import { UserProfileType, UserRoles } from "@/types/user/profile.types";

export const getProfileType = (profile: UserProfileType | PlayerProfileType) => {
  if ("role" in profile) {
    return ProfileTypes.USER_PROFILE_TYPE;
  } else if ("earningPercent" in profile) {
    return ProfileTypes.PLAYER_PROFILE_TYPE;
  }

  return undefined;
};

export const isUserProfileType = (object: any): object is UserProfileType => {
  try {
    return "role" in object || "fullName" in object;
  } catch (error) {
    return false;
  }
};

export const isPlayerProfileType = (object: any): object is PlayerProfileType => {
  try {
    return "urlAlias" in object;
  } catch (error) {
    return false;
  }
};

export const isAdmin = (profile: UserProfileType): boolean => {
  return profile.role === UserRoles.ADMIN;
};
