import React, { ChangeEvent } from "react";
import { useFormik } from "formik";
import { useProfile } from "@/providers/profile";

import ProfileAvatar from "../User/ProfileAvatar";

import { AllowedUpdateUserProfileType } from "@/types/user/profile.types";
import { toast } from "../../utils/toast";

const ProfileTab = () => {
  const {
    updateUserProfile,
    userProfile,
    removeUserAvatar,
    removeUserAvatarStatus,
    setUserAvatar,
    setUserAvatarStatus,
  } = useProfile();

  const handleRemoveProfileImage = async () => {
    await removeUserAvatar();
    toast.success("Profile Image successfully removed!");
  };

  const formik = useFormik({
    initialValues: {
      fullName: userProfile?.fullName,
      bio: userProfile?.bio,
    },
    validate: (values) => {
      const errors: AllowedUpdateUserProfileType = {};
      if (!values.fullName) {
        errors.fullName = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      await updateUserProfile(values);
      toast.success("User profile was successfully updated!");
    },
  });

  return (
    <form
      data-testid="account-settings-profile"
      className="flex flex-col"
      onSubmit={formik.handleSubmit}
    >
      <div className="flex flex-col gap-6 pt-6 xl:mt-10 xl:mx-auto w-full max-w-[600px]">
        <div className="flex flex-col w-full gap-2 xl:gap-8 xl:flex-row">
          <ProfileAvatar profile={userProfile} imageClass="!w-20 !h-20 mb-4" />
          <div className="flex flex-col w-full gap-3">
            <div className="flex gap-2 w-full xl:gap-3 justify-between">
              <label
                htmlFor="profileImageInput"
                className={`btn btn-primary !text-12 xl:!text-14 grow ${
                  setUserAvatarStatus.isLoading ? "loading" : ""
                }`}
              >
                Change photo
              </label>
              <input
                data-testid="profile-image-input"
                id="profileImageInput"
                type="file"
                hidden
                accept="image/*"
                onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                  if (event.currentTarget.files) {
                    const formData = new FormData();
                    formData.append("filename", "avatar");
                    formData.append("file", event.currentTarget.files[0]);
                    formData.append("mimetype", event.currentTarget.files[0].type);

                    await setUserAvatar(formData);
                    toast.success("Profile Image successfully updated!");
                  }
                }}
              />
              <button
                data-testid="remove-profile-avatar-btn"
                onClick={handleRemoveProfileImage}
                type="button"
                className="btn btn-outline btn-primary !text-12 xl:!text-14 grow"
                disabled={removeUserAvatarStatus.isLoading || setUserAvatarStatus.isLoading}
              >
                Remove Image
              </button>
            </div>
            <span className="text-body text-12 font-regular text-grayscale-500">
              JPEG, PNG, or GIF and cannot exceed 10MB.
            </span>
          </div>
        </div>
        <div>
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">
              Your Full Name
            </span>
          </label>
          <input
            data-testid="profile-full-name-input"
            name="fullName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullName}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.fullName && formik.touched.fullName && " !border-red-500 !text-red-900"
            }`}
          />
        </div>
        <div>
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">Add Bio</span>
          </label>
          <textarea
            data-testid="profile-bio-input"
            name="bio"
            rows={4}
            placeholder="Introduce yourself"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bio || ""}
            className="textarea w-full"
          ></textarea>
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          disabled={formik.isSubmitting}
          data-testid="profile-update-btn"
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default ProfileTab;
