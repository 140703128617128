/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

import { useAppNavigate } from "@/app/hooks";
import { useNotifications } from "@/app/hooks/useNotifications";

import { NotificationBellIcon } from "@/components/UI/Icons";

export const MobileNotificationButton = () => {
  const navigate = useAppNavigate();
  const { unreadedCount } = useNotifications();

  return (
    <div className="indicator">
      {unreadedCount > 0 && (
        <span className="indicator-item top-1 right-1 badge badge-xs badge-primary notification-indicator z-auto"></span>
      )}
      <div className="grid place-items-center">
        <button
          className={`btn btn-circle btn-ghost w-10 h-10 min-h-[40px] btn-sm`}
          onClick={() => navigate("/notifications")}
        >
          <NotificationBellIcon stroke="white" fill="white" className="ml-1" />
        </button>
      </div>
    </div>
  );
};

export default MobileNotificationButton;
