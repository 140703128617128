import React, { useEffect, useState } from "react";
import { useVotePollMutation } from "@/api/fanClubApi";
import { PostType, VoteType } from "@/types/fanClub/fanClub.types";
import { useProfile } from "@/providers/profile";
import { RadioCheckmarkIcon } from "@/components/UI/Icons";

interface PollProps {
  post: PostType;
}

const Poll = ({ post }: PollProps) => {
  let poll = null;

  try {
    poll = JSON.parse(post.body);
  } catch (error) {
    console.log(error);
  }

  const { question, answers } = poll || {};

  const { userProfile, managedPlayerProfile } = useProfile();

  const [voted, setVoted] = useState<boolean>(!!managedPlayerProfile?.uuid);
  const [votes, setVotes] = useState<VoteType[]>([]);
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | null>(null);
  const [votesById, setVotesById] = useState<{ [key: string]: number }>();

  const [votePoll, votePollRequestStatus] = useVotePollMutation();

  useEffect(() => {
    if (!userProfile) {
      setVoted(false);
      setVotes([]);
      setSelectedAnswerId(null);
    }

    const vote =
      post.votes &&
      post.votes.find(
        (vote: VoteType) => vote?.owner?.uuid === userProfile?.uuid && vote.isPollVote
      );

    if (vote !== undefined) {
      setSelectedAnswerId(Number(vote.content));
      setVoted(true);
    }
  }, [userProfile?.uuid]);

  useEffect(() => {
    const pollVotes = (post.votes && post.votes.filter((vote: VoteType) => vote.isPollVote)) || [];
    const votesById = pollVotes.reduce(
      (acc: { [key: string]: number }, vote: VoteType) => ({
        ...acc,
        [vote.content]: (acc[vote.content] || 0) + 1,
      }),
      {}
    );

    setVotes(pollVotes);
    setVotesById(votesById);
  }, [post.votes]);

  const toPercent = (answerId: number) => {
    return votes.length === 0 ? 0 : Math.round(((votesById?.[answerId] || 0) / votes.length) * 100);
  };

  const onVote = async (answerId: number) => {
    const votedPost = await votePoll({
      postUuid: post.uuid,
      answerId,
    }).unwrap();

    if (!votedPost) {
      return;
    }

    setVotes(
      (votedPost.votes && votedPost.votes.filter((vote: VoteType) => vote.isPollVote)) || []
    );
    setVoted(true);
    setSelectedAnswerId(answerId);
  };

  return (
    <div className="flex flex-col items-center w-full p-0 m-0 text-start text-body text-14 font-regular xl:text-16 ">
      {question && <div className="w-full p-4">{question}</div>}
      <div className="divider m-0"></div>
      <div className="w-full p-4">
        {answers &&
          answers.map((answer: any) => (
            <div
              key={answer.id}
              id={answer.id}
              className={`w-full flex relative mb-3 bg-white bg-opacity-[0.05] px-4 py-2 ${
                voted ? "voted" : "hover:bg-grayscale-700 cursor-pointer"
              }`}
              onClick={() => {
                if (!voted && !votePollRequestStatus.isLoading) {
                  onVote(answer.id);
                }
              }}
            >
              {voted && (
                <span className="text-accent-500">
                  {votesById?.[answer.id] ? toPercent(answer.id) : 0}%
                </span>
              )}
              <span className="pl-4 max-w-[80%] overflow-scroll scrollbar-hidden">{answer.text}</span>
              {!voted && (
                <div className="absolute w-5 h-5 right-3 top-1/2 -translate-y-1/2 rounded-full border-[1px] border-white border-opacity-[0.12]">
                  {selectedAnswerId === answer.id && <RadioCheckmarkIcon />}
                </div>
              )}
              <div
                className="absolute inset-0 bg-white bg-opacity-[0.05]"
                style={{
                  width: voted && votesById?.[answer.id] ? `${toPercent(answer.id)}%` : 0,
                }}
              ></div>
            </div>
          ))}
        {voted && (
          <div className="w-full text-start text-body text-14 font-regular opacity-50 xl:text-16">
            Total votes: {votes.length}
          </div>
        )}
      </div>
    </div>
  );
};

export default Poll;
