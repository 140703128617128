import React from "react";
import { useGeneralUi } from "@/providers/generalUi";

import { Link, LinkProps } from "react-router-dom";

import { resolveTo } from "@/helpers/history.helpers";

const AppLink = ({ to, ...props }: LinkProps) => {
  const { isAndroidMobileApp } = useGeneralUi();

  return <Link to={isAndroidMobileApp ? resolveTo(to) : to} {...props} />;
};

export default AppLink;
