import React, { ReactNode, useEffect } from "react";

import { useScopeChat } from "@/providers/scopeChat";
import { useProfile } from "@/providers/profile";
import { EmojiData, useEmojiPicker } from "@/providers/EmojiPickerProvider";
import { useGeneralUi } from "@/providers/generalUi";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useBlockUser } from "@/providers/blockUser";

import Button from "@/components/UI/Button";
import ConfirmModal from "@/components/UI/ConfirmModal";
import {
  AlertIcon,
  BlockUserIcon,
  ChatIcon,
  EmojiReactionFilled,
  ExclamationIcon,
  LikeIcon,
  MuteUserIcon,
  PenIcon,
  PinFilledIcon,
  ReplyIcon,
  TrashIcon,
} from "@/components/UI/Icons";

import { ScopeChatMessage, ScopeChatUserTypes } from "@/types/scopeChat/scopeChat.types";
import { SCOPE_CHAT_CHANNEL_ID, SCOPE_CHAT_LIKE_REACTION } from "@/constants/scopeChat.constants";
import { useReport } from "@/providers/report";
import { ReportOrigins } from "@/types/reports.types";

const MenuItem = ({
  children,
  title,
  disabled,
  dataTestId,
  onClick,
}: {
  children?: ReactNode;
  title?: string;
  disabled?: boolean;
  dataTestId?: string;
  onClick?: (args?: any) => void;
}) => {
  return (
    <Button
      data-testid={dataTestId}
      className="btn btn-square btn-ghost rounded-[3px] h-10 min-h-[40px] xl:w-8 xl:h-8 xl:min-h-8 !text-grayscale-400 w-full flex gap-2 justify-start xl:justify-center"
      title={title}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

const MessageItemMenu = ({
  message,
  setIsEdit,
  handleCloseMenu,
  handleShowMessage,
  threadView,
}: {
  message: ScopeChatMessage;
  setIsEdit: (value: boolean) => void;
  handleCloseMenu: () => void;
  handleShowMessage?: (message: ScopeChatMessage) => void;
  threadView?: boolean;
}) => {
  const { userProfile, managedPlayerProfile, isManager: isFanClubManager, isAdmin } = useProfile();
  const {
    handleMessageReaction,
    handleMessageDelete,
    handleMessagePin,
    handleShowThread,
    isHandlingPin,
    isScopeManager,
  } = useScopeChat();
  const { setOnEmojiSelect, handleOpenEmojiPicker } = useEmojiPicker();
  const { isMobile } = useGeneralUi();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { handleReportContent } = useReport();
  const { channelId } = useScopeChat();
  const { handleBlockUser, isHandlingBlockUser, handleMuteUser, isHandlingMuteUser } =
    useBlockUser();

  const isOwnMessage =
    (userProfile?.uuid && message.owner.externalUuid === userProfile.uuid) ||
    (managedPlayerProfile?.uuid && message.owner.externalUuid === managedPlayerProfile.uuid);

  const isLiked =
    (userProfile?.uuid &&
      message.likes.ownerUuids.some((likeOwnerUuid) => likeOwnerUuid === userProfile.uuid)) ||
    (managedPlayerProfile?.uuid &&
      message.likes.ownerUuids.some(
        (likeOwnerUuid) => likeOwnerUuid === managedPlayerProfile.uuid,
      ));

  const isManager =
    (isScopeManager && channelId === SCOPE_CHAT_CHANNEL_ID) ||
    (isFanClubManager && channelId === managedPlayerProfile?.uuid);

  const isCanEditMessages = isAdmin || isManager || isOwnMessage;

  const isCanBlockUser =
    ![ScopeChatUserTypes.ADMIN, ScopeChatUserTypes.ATHLETE].includes(message.owner.type) &&
    message.owner.externalUuid !== userProfile?.uuid;

  const handleEmojiSelect = () => (selectedEmoji: EmojiData) => {
    handleMessageReaction(message.id, selectedEmoji.native);
  };

  const handleDeleteMessage = () => {
    setModal(
      <ConfirmModal
        onToggle={handleCloseModal}
        title="Do you want to delete this comment?"
        body="Are you sure you want to delete this comment? This cannot be undone."
        onConfirm={() => {
          handleMessageDelete(message.id);
          handleCloseModal();
        }}
        confirmCaption="Delete"
        icon={<AlertIcon />}
      />,
    );
    setIsModalOpen(true);
  };

  const handleAction = (action: (args?: any) => void) => {
    action();
    isMobile && handleCloseMenu();
  };

  useEffect(() => {
    setOnEmojiSelect(handleEmojiSelect);
  }, []);

  return (
    <div
      data-testid="chat-item-menu"
      className="absolute mobile:w-full mobile:left-0 mobile:bottom-0 xl:top-0 xl:right-2 xl:-translate-y-1/2 flex flex-col xl:flex-row justify-between items-start xl:items-center gap-2 xl:gap-0 p-3 xl:p-2 xl:rounded-lg bg-grayscale-1000 xl:border-[1.5px] border-neutral-white-12 z-50"
    >
      {isMobile && !!handleShowMessage && (
        <MenuItem
          title="Show message"
          onClick={() => handleAction(() => handleShowMessage(message))}
        >
          <ChatIcon />
          <span className="text-body text-14 font-regular xl:hidden">Show message</span>
        </MenuItem>
      )}
      <MenuItem
        dataTestId="message-like"
        title={isMobile ? "" : "Like"}
        onClick={() =>
          handleAction(() => handleMessageReaction(message.id, SCOPE_CHAT_LIKE_REACTION))
        }
      >
        <LikeIcon
          stroke={isLiked ? "#ff4c34" : "currentColor"}
          fill={isLiked ? "#ff4c34" : "currentColor"}
        />
        <span className="text-body text-14 font-regular xl:hidden">Like</span>
      </MenuItem>
      <MenuItem
        dataTestId="message-emoji"
        title={isMobile ? "" : "Add emoji reaction"}
        onClick={(event) => handleAction(() => handleOpenEmojiPicker(event))}
      >
        <EmojiReactionFilled fill="currentColor" />
        <span className="text-body text-14 font-regular xl:hidden">Add emoji reaction</span>
      </MenuItem>
      {!threadView && (
        <MenuItem
          dataTestId="message-reply"
          title={isMobile ? "" : "Reply"}
          onClick={() => handleAction(() => handleShowThread(message.id))}
        >
          <ReplyIcon fill="currentColor" />
          <span className="text-body text-14 font-regular xl:hidden">Reply</span>
        </MenuItem>
      )}
      {!threadView && (isAdmin || isManager) && (
        <MenuItem
          title={isMobile ? "" : message.isPinned ? "Unpin message" : "Pin message"}
          disabled={isHandlingPin}
          onClick={() => handleAction(() => handleMessagePin(message.id, !message.isPinned))}
        >
          <PinFilledIcon stroke="currentColor" fill="currentColor" />
          <span className="text-body text-14 font-regular xl:hidden">
            {message.isPinned ? "Unpin message" : "Pin message"}
          </span>
        </MenuItem>
      )}
      {isCanEditMessages && (
        <>
          <MenuItem
            title={isMobile ? "" : "Edit"}
            onClick={() => handleAction(() => setIsEdit(true))}
          >
            <PenIcon stroke="currentColor" fill="currentColor" />
            <span className="text-body text-14 font-regular xl:hidden">Edit</span>
          </MenuItem>
          <MenuItem
            dataTestId="message-delete"
            title={isMobile ? "" : "Delete comment"}
            onClick={() => handleAction(() => handleDeleteMessage())}
          >
            <TrashIcon stroke="currentColor" />
            <span className="text-body text-14 font-regular xl:hidden">Delete comment</span>
          </MenuItem>
        </>
      )}
      {!isAdmin && isCanBlockUser && (
        <>
          <MenuItem
            title={isMobile ? "" : "Block user"}
            disabled={isHandlingBlockUser}
            onClick={() => handleAction(() => handleBlockUser(message.owner.externalUuid))}
          >
            <BlockUserIcon />
            <span className="text-body text-14 font-regular xl:hidden">Block User</span>
          </MenuItem>
          <MenuItem
            title={isMobile ? "" : "Report"}
            onClick={() =>
              handleAction(() =>
                handleReportContent(
                  message.id,
                  channelId === SCOPE_CHAT_CHANNEL_ID
                    ? ReportOrigins.SCOPE_CHAT
                    : ReportOrigins.FAN_CLUB_CHAT,
                  channelId,
                ),
              )
            }
          >
            <ExclamationIcon />
            <span className="text-body text-14 font-regular xl:hidden">Report</span>
          </MenuItem>
        </>
      )}
      {isAdmin && isCanBlockUser && (
        <MenuItem
          title={isMobile ? "" : "Mute user"}
          disabled={isHandlingMuteUser}
          onClick={() => handleAction(() => handleMuteUser(message.owner.externalUuid))}
        >
          <MuteUserIcon />
          <span className="text-body text-14 font-regular xl:hidden">Mute User</span>
        </MenuItem>
      )}
    </div>
  );
};

export default MessageItemMenu;
