import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useFanClub } from "@/providers/fanClub";
import { useGeneralUi } from "@/providers/generalUi";

import SectionView from "../../Common/SectionView";
import Button from "@/components/UI/Button";
import { CloseIcon } from "@/components/UI/Icons";
import Container from "@/components/UI/Container";
import EmptySection from "@/components/UI/EmptySection";
import Spinner from "@/components/UI/Spinner";
import CenterSpinner from "@/components/UI/CenterSpinner";
import VideoPlayer_v3 from "@/components/UI/VideoPlayer/VideoPlayer_v3";

import {
  selectPremiumContent,
  selectPremiumContentAutoplayUuid,
  setPremiumContentAutoplayUuid,
} from "@/reducers/fanClub.slice"; // TODO: move to FanClub provider
import { PremiumContent, ViewTypes } from "@/types/fanClub/fanClub.types";

const PremiumContentItem = ({
  item,
  autoplay,
  isMobile,
}: {
  item: PremiumContent;
  autoplay: boolean;
  isMobile: boolean;
}) => {
  return (
    <Container
      className="flex-col w-full overflow-hidden gap-0"
      border="rounded-[20px] xl:rounded-xl"
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col text-start gap-2 xl:gap-0 p-4 xl:p-6">
          <h6 className="text-body text-16 font-bold xl:text-heading xl:font-medium">
            {item.owner.name} - {item.title}
          </h6>
          <span className="text-caps text-12 font-medium text-neutral-white-60">
            {`Published: ${new Date(item.createdAt).toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}`}
          </span>
        </div>
      </div>
      {item.isDraft ? (
        <div className="flex flex-col w-full justify-center items-center aspect-video p-4 gap-4">
          <Spinner />
          This video is being processed, please check again in several minutes…
        </div>
      ) : (
        <div className="flex-1 w-full aspect-video">
          <VideoPlayer_v3
            src={
              (isMobile ? item.videoThumbnails?.md : item.videoThumbnails?.xl) || item.presignedUrl
            }
            fallBackSrc={item.presignedUrl}
            autoPlay={autoplay}
          />
        </div>
      )}
    </Container>
  );
};

const PremiumContentView = () => {
  const dispatch = useAppDispatch();
  const premiumContent = useAppSelector(selectPremiumContent);
  const premiumContentAutoplayUuid = useAppSelector(selectPremiumContentAutoplayUuid);
  const { isMobile } = useGeneralUi();

  const {
    scrollerContainerRef,
    handlePremiumContentScroll,
    isPremiumContentAllowed,
    isMember,
    isPremiumContentLoading,
    setCurrentView,
  } = useFanClub();

  useEffect(() => {
    if (!isMember || !isPremiumContentAllowed) {
      setCurrentView(ViewTypes.CARDS);
      return;
    }

    scrollerContainerRef.current &&
      scrollerContainerRef.current.addEventListener("scroll", handlePremiumContentScroll);

    dispatch(setPremiumContentAutoplayUuid(undefined));

    return () => {
      scrollerContainerRef.current &&
        scrollerContainerRef.current.removeEventListener("scroll", handlePremiumContentScroll);
    };
  }, []);

  const renderPremiumContent = (isLoading: boolean) => (
    <>
      {premiumContent.length ? (
        premiumContent.map((item) => (
          <PremiumContentItem
            key={item?.uuid}
            item={item}
            autoplay={!!premiumContentAutoplayUuid && premiumContentAutoplayUuid === item.uuid}
            isMobile={isMobile}
          />
        ))
      ) : (
        <div className="flex">{!isLoading && <EmptySection title="Coming soon..." />}</div>
      )}
      {isLoading && <CenterSpinner />}
    </>
  );

  return isMobile ? (
    <div
      ref={scrollerContainerRef}
      className="flex flex-col h-full w-full gap-4 overflow-hidden overflow-y-scroll"
    >
      <div className="flex flex-col gap-4 w-full">
        {renderPremiumContent(isPremiumContentLoading)}
      </div>
    </div>
  ) : (
    <SectionView
      title="Premium Content"
      containerClassName="2xl:max-w-[778px]"
      titleAction={
        <Button
          className="btn btn-ghost btn-square btn-sm rounded-md"
          onClick={() => setCurrentView(ViewTypes.CARDS)}
        >
          <CloseIcon />
        </Button>
      }
    >
      <div
        ref={scrollerContainerRef}
        className="flex flex-col h-full w-full gap-4 overflow-hidden overflow-y-scroll scrollbar-hidden p-6"
      >
        <div className="flex flex-col gap-4 w-full">
          {renderPremiumContent(isPremiumContentLoading)}
        </div>
      </div>
    </SectionView>
  );
};

export default PremiumContentView;
