import React, { useEffect, useState } from "react";

import { useAppSelector } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { useFanClub } from "@/providers/fanClub";

import CreatePostForm from "./Post/CreatePostForm";
import Post from "./Post/Post";
import EmptySection from "@/components/UI/EmptySection";
import { ArrowFilledIcon, RSSIcon } from "@/components/UI/Icons";
import { FilterSelect, IconPositionsEnum, SelectButton } from "@/components/UI/Select/Select";
import MobileCommentsView from "../Views/MobileCommentsView";
import CenterSpinner from "@/components/UI/CenterSpinner";

import { FeedFilters, OrderByEnum, PostType } from "@/types/fanClub/fanClub.types";
import { selectIsCommentsOpen } from "@/reducers/mobileFanClubUi.slice";

interface FiltersProps {
  filterBy: FeedFilters;
  setFilterBy: (value: FeedFilters | string | number) => void;
  orderBy: OrderByEnum;
  setOrderBy: (value: OrderByEnum | string | number) => void;
}

const Filters = ({ filterBy, setFilterBy, orderBy, setOrderBy }: FiltersProps) => {
  const filterOptions = {
    filterBy: [
      { label: "All", value: FeedFilters.ALL },
      { label: "Video", value: FeedFilters.VIDEO },
      { label: "Photo", value: FeedFilters.PHOTO },
      { label: "Poll", value: FeedFilters.POLL },
      { label: "Text Notes", value: FeedFilters.TEXT_NOTE },
      { label: "Voice Notes", value: FeedFilters.VOICE_NOTE },
    ],
    orderBy: [
      { label: "Recent post", value: OrderByEnum.RECENT },
      { label: "Most liked posts", value: OrderByEnum.LIKED },
    ],
  };

  return (
    <div className="flex flex-row w-full justify-between my-4 px-4 xl:mx-0 xl:items-center">
      <div className="flex">
        <FilterSelect
          options={filterOptions.filterBy}
          value={filterBy}
          onChange={setFilterBy}
          className="!text-caps !text-13 !font-heavy !text-grayscale-400 xl:!text-16"
        />
      </div>
      <div className="flex">
        <SelectButton
          label="Sort by"
          valueOnLabel
          icon={<ArrowFilledIcon className="fill-grayscale-400" />}
          iconPosition={IconPositionsEnum.LEFT}
          value={orderBy}
          options={filterOptions.orderBy}
          onChange={setOrderBy}
          className="!text-caps !text-13 !font-heavy !text-grayscale-400 xl:!text-16"
        />
      </div>
    </div>
  );
};

const Feed = () => {
  const { playerProfile } = useFanClub();
  const isCommentsOpen = useAppSelector(selectIsCommentsOpen);

  const { userProfile, managedPlayerProfile } = useProfile();
  const {
    posts,
    isPostsLoading,
    filterBy,
    setFilterBy,
    orderBy,
    setOrderBy,
    scrollerContainerRef,
    handlePostsScroll,
  } = useFanClub();

  const [showCreatePostForm, setShowCreatePostForm] = useState(false);
  const [isCreator, setIsCreator] = useState(false);

  useEffect(() => {
    setIsCreator(managedPlayerProfile ? managedPlayerProfile.uuid === playerProfile.uuid : false);
  }, [userProfile, managedPlayerProfile, playerProfile]);

  useEffect(() => {
    scrollerContainerRef.current &&
      scrollerContainerRef.current.addEventListener("scroll", handlePostsScroll);

    return () => {
      scrollerContainerRef.current &&
        scrollerContainerRef.current.removeEventListener("scroll", handlePostsScroll);
    };
  }, []);

  return (
    <>
      <div
        ref={scrollerContainerRef}
        className={`flex flex-col w-full h-full xl:p-6 overflow-y-scroll xl:scrollbar-hidden gap-4 ${
          isCommentsOpen ? "hidden" : ""
        }`}
      >
        {isCreator && (
          <div className="flex w-full">
            {showCreatePostForm ? (
              <CreatePostForm setIsVisible={setShowCreatePostForm} />
            ) : (
              <button
                className="btn btn-primary btn-block"
                onClick={() => setShowCreatePostForm(true)}
              >
                Create Your Feed
              </button>
            )}
          </div>
        )}
        {/* <Filters {...{ filterBy, setFilterBy, orderBy, setOrderBy }} /> */}
        {posts && (
          <div className="flex flex-col gap-4">
            {(posts as PostType[])
              .filter((post) => {
                return post.isDraft ? managedPlayerProfile : true;
              })
              .map((post: PostType) => (
                <Post
                  key={post.uuid}
                  post={post}
                  editable={
                    !!managedPlayerProfile && post?.owner?.uuid === managedPlayerProfile?.uuid
                  }
                  expandComments={posts.length === 1}
                />
              ))}
          </div>
        )}
        {!posts?.length && !isPostsLoading && (
          <div className="flex">
            <EmptySection icon={<RSSIcon />} title="No posts yet!" text="Come later." />
          </div>
        )}
        {isPostsLoading && (
          <div className="block pt-6">
            <CenterSpinner />
          </div>
        )}
      </div>
      {isCommentsOpen && <MobileCommentsView />}
    </>
  );
};

export default Feed;
