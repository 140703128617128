import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import { useAppNavigate } from "@/app/hooks";
import { useProfile } from "../profile";
import { useGeneralUi } from "../generalUi";
import { useRegisterSW } from "virtual:pwa-register/react";
import { useOnlineStatus } from "@/app/hooks/useOnlineStatus";
import { useSubscribeMutation } from "@/api/fcmApi";

import ConfirmModal from "@/components/UI/ConfirmModal";

import ServiceWorkerContext from "./ServiceWorkerContext";
import { messaging } from "@/utils/firebase";
import { getToken } from "firebase/messaging";
import { isSupportedPushNotifications } from "@/helpers/notification.helpers";

const ServiceWorkerProvider = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();
  const online = useOnlineStatus();

  const { userProfile } = useProfile();
  const { isIosMobileApp } = useGeneralUi();
  const [subscribe] = useSubscribeMutation();

  const [notificationPermission, setNotificationPermission] = useState<boolean>(false);

  const syncIntervalRef = useRef<NodeJS.Timeout>();

  const requestPermission = async () => {
    if (isSupportedPushNotifications()) {
      const permission = await Notification.requestPermission();

      if (permission !== "granted") {
        setNotificationPermission(false);

        throw new Error("Permission not granted for Notification");
      }

      setNotificationPermission(true);
    } else if (isIosMobileApp) {
      window.webkit.messageHandlers["push-permission-request"].postMessage(
        "push-permission-request",
      );
    }
  };

  const subscribeWebPush = async (token?: string) => {
    if (!userProfile?.uuid) return;

    try {
      if (!token && messaging) {
        if (isSupportedPushNotifications()) {
          const registration = await navigator.serviceWorker.ready;

          token = await getToken(messaging, {
            vapidKey: import.meta.env.VITE_FCM_VAPID_PUBLIC_KEY,
            serviceWorkerRegistration: registration,
          });
        } else if (isIosMobileApp) {
          window.webkit.messageHandlers["push-token"].postMessage("push-token");
          window.webkit.messageHandlers["get-redirect-url"].postMessage("get-redirect-url");
          return;
        }
      }

      if (token && userProfile?.uuid) await subscribe({ userUuid: userProfile?.uuid, token });
    } catch (error) {
      console.log(error);
    }
  };

  const registerPeriodicSync = (
    swScriptUrl: string,
    registration: ServiceWorkerRegistration,
    clear?: boolean,
  ) => {
    if (clear && syncIntervalRef.current) {
      clearInterval(syncIntervalRef.current);
      return;
    }

    syncIntervalRef.current = setInterval(async () => {
      if (!online) return;

      try {
        const response = await fetch(swScriptUrl, {
          cache: "no-store",
          headers: {
            cache: "no-store",
            "cache-control": "no-cache",
          },
        });

        if (response?.status === 200) await registration.update();
      } catch (error) {
        console.log(error);
      }
    }, 60 * 1000);
  };

  const handleUpdateServiceWorker = () => {
    updateServiceWorker(true);
    setNeedRefresh(false);
  };

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    immediate: true,
    onRegisterError: (error: any) => {
      console.log(error);
    },
    onRegisteredSW: (swScriptUrl: string, registration: ServiceWorkerRegistration | undefined) => {
      if (registration?.active?.state === "activated") {
        registerPeriodicSync(swScriptUrl, registration);
      } else if (registration?.installing) {
        registration.installing.addEventListener("statechange", (event) => {
          const serviceWorker = event.target as ServiceWorker;

          if (serviceWorker.state === "activated") {
            registerPeriodicSync(swScriptUrl, registration);
          }

          if (serviceWorker.state === "redundant") {
            registerPeriodicSync(swScriptUrl, registration, true);
          }
        });
        registration.addEventListener("pushsubscriptionchange", () => {
          requestPermission();
        });
      }
    },
  });

  useEffect(() => {
    subscribeWebPush();

    // IOS
    const handlePushToken = (event: CustomEvent) => {
      if (!event || !event.detail) return;

      subscribeWebPush(event.detail);
    };

    const handlePushPermissionRequest = (event: CustomEvent) => {
      if (!event || !event.detail) return;

      switch (event.detail) {
        case "granted":
          setNotificationPermission(true);
          subscribeWebPush();
          break;
        default:
          setNotificationPermission(false);
          break;
      }
    };

    const handleIosNotificationClick = (event: CustomEvent) => {
      if (!event || !event.detail) return;

      window.webkit.messageHandlers["get-redirect-url"].postMessage("get-redirect-url");
    };

    const handleRedirect = (event: CustomEvent) => {
      if (!event || !event.detail) return;

      navigate(event.detail);
    };

    window.addEventListener("push-token", handlePushToken);
    window.addEventListener("push-permission-request", handlePushPermissionRequest);
    window.addEventListener("ios-notification-click", handleIosNotificationClick);
    window.addEventListener("handle-redirect", handleRedirect);

    return () => {
      window.removeEventListener("push-token", handlePushToken);
      window.removeEventListener("push-permission-request", handlePushPermissionRequest);
      window.removeEventListener("ios-notification-click", handleIosNotificationClick);
      window.removeEventListener("handle-redirect", handleRedirect);
    };
  }, []);

  useEffect(() => {
    if (!userProfile) return;

    requestPermission();
  }, [userProfile]);

  return (
    <ServiceWorkerContext.Provider value={{ notificationPermission }}>
      {needRefresh && (
        <ConfirmModal
          onToggle={() => setNeedRefresh(false)}
          title="New version of Scope Sports available"
          body="Good news! We've updated Scope Sports with new features and improvements. Please click Update to enjoy the latest version."
          onConfirm={handleUpdateServiceWorker}
          confirmCaption="Update"
          cancelCaption="Later"
        />
      )}
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export default ServiceWorkerProvider;
