import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../api/userApi";
import { useAppDispatch, useAppNavigate } from "@/app/hooks";

import { toast } from "../utils/toast";
import { login } from "../reducers/user.slice";

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const [isVerified, setIsVerified] = useState(false);
  const [verifyEmail, { isLoading, isError, isSuccess, data }] = useVerifyEmailMutation();

  useEffect(() => {
    if (isSuccess && data) {
      const { message, access_token } = data;
      message && toast.success(message);
      setIsVerified(isVerified);
      dispatch(login(access_token));
      navigate("/welcome");
    } else if (isError) {
      navigate("/");
    }
  }, [isLoading]);

  useEffect(() => {
    const isVerifyEmailPath = location.pathname === "/verifyEmail";

    if (isVerifyEmailPath && !isVerified && !isLoading) {
      const verifyCode = params.get("verifyCode");
      const email = params.get("email");
      verifyEmail({ email: email || "", verifyCode: verifyCode || "" });
    }
  }, [isVerified]);

  return null;
};

export default VerifyEmail;
