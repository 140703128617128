import React from "react";
import { ArrowIcon } from "@/components/UI/Icons";

const ShowMoreAnchor = (props: any) => (
  <a
    href="#"
    className="flex items-center text-caps text-13 font-bold text-grayscale-400 hover:border-bottom hover:!border-grayscale-400 min-w-fit"
    {...props}
  >
    Show less
    <ArrowIcon className="ml-2 rotate-180" />
  </a>
);

export default ShowMoreAnchor;
