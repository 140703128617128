import { createContext, MutableRefObject, useContext } from "react";

import {
  FeedFilters,
  OrderByEnum,
  OrderSortEnum,
  PostType,
  PremiumContent,
  ViewTypes,
  PostFormValues,
} from "@/types/fanClub/fanClub.types";

import { PlayerProfileType } from "@/types/player/player.types";
import { FanClubCard } from "@/types/card/card.types";

interface FanClubContextType {
  posts: PostType[];
  isPostsLoading: boolean;
  isMuted: boolean;
  isMember: boolean;
  isPremiumContentAllowed: boolean;
  isAthleteFeedAllowed: boolean;
  isAthleteChatAllowed: boolean;
  filterBy: FeedFilters;
  playerProfile: PlayerProfileType;
  setFilterBy: (value: FeedFilters) => void;
  orderBy: OrderByEnum;
  setOrderBy: (value: OrderByEnum) => void;
  orderSort: OrderSortEnum;
  setOrderSort: (value: OrderSortEnum) => void;
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  scrollerContainerRef: MutableRefObject<HTMLDivElement | null>;
  handlePostsScroll: () => void;
  handlePremiumContentScroll: () => void;
  sendFeedMessage: (type: string, payload: string) => void;
  premiumContent: PremiumContent[];
  isPremiumContentLoading: boolean;
  isOwnLimitedCards: boolean;
  cards: FanClubCard[];
  currentCard: FanClubCard | undefined;
  setCurrentCard: (card: FanClubCard | undefined) => void;
  setCurrentView: (view: ViewTypes) => void;

  // Manager
  submitPost: (values: PostFormValues) => Promise<void>;
  uploadProgress: { [uuid: string]: number };
  isUploadInProgress: boolean;

  // Newsletters
  newsletterSignUpStatus: { isSuccess: boolean; isLoading: boolean; isError: boolean };
  handleNewsletterSignUp: (payload: {
    email: string;
    playerUuid: string;
    fullName?: string;
  }) => void;

  commentUuid?: string;
  postUuid?: string;
  premiumContentUuid?: string;
}

const FanClubContext = createContext<FanClubContextType | undefined>(undefined);

export function useFanClub(): FanClubContextType {
  const context = useContext(FanClubContext);

  if (!context) {
    throw new Error("useFanClub must be used within a FanClubProvider");
  }

  return context;
}

export default FanClubContext;
