/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from "react";

import { useFormik } from "formik";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import { useLoginMutation, useSignupMutation, useGetUserLocationMutation } from "@/api/userApi";
import { useGeneralUi } from "@/providers/generalUi";

import SocialNetworksAuth from "./SocialNetworksAuth";

import { toast } from "../utils/toast";
import { login as loginUser, selectIsAuthorized } from "../reducers/user.slice";
import { Country } from "../types/countries.enum";
import _ from "lodash";

const countryOptions = (Object.entries(Country) as [string, Country][]).map(([name, code]) => {
  return {
    name: `${name}`,
    value: code,
  };
});

const SignUpForm = ({ email }: { email: string | null }) => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { isMobileApp } = useGeneralUi();

  const isAuthorized = useAppSelector(selectIsAuthorized);
  const [getLocation] = useGetUserLocationMutation();
  const [userCountry, setUserCountry] = useState<string>("");

  const getUserCountry = useCallback(async () => {
    const response = await getLocation({}).unwrap();
    setUserCountry(response?.country);
  }, [getLocation]);

  const [signup, signupStatus] = useSignupMutation();
  const [login] = useLoginMutation();

  useEffect(() => {
    isAuthorized && navigate("/");
  }, []);

  useEffect(() => {
    if (signupStatus.isSuccess && !email) {
      toast.success("Success! Please verify your email address...", {
        position: "top-right",
      });

      navigate("/check-email");
    }
  }, [signupStatus.isLoading]);

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      username: "",
      password: "",
      confirmPassword: "",
      country: "",
      isActived: false,
      isOnboarded: true,
    },
    validate: (values) => {
      const errors: any = {};
      const requiredFields = [
        "email",
        "fullName",
        "username",
        "password",
        "confirmPassword",
        "country",
      ];

      requiredFields.forEach((reqField) => {
        if (!(values as any)[reqField]) {
          errors[reqField] = "Required";
        }
      });

      if (values.password && values.password.length < 8) {
        errors.password = "Must be longer than or equal to 8 characters";
      }

      if (_.isEqual(values.password, values.confirmPassword) === false) {
        errors.confirmPassword = "Is not equal Password";
      }

      return errors;
    },
    onSubmit: async (values) => {
      signup(values)
        .then((response: any) => {
          if (response?.error) {
            return;
          }
          const { email, password } = values;
          login({ emailOrUsername: email, password }).then((response: any) => {
            if (!response?.error) {
              dispatch(loginUser(response.data?.access_token));
              navigate("/");
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (email) {
      formik.setFieldValue("email", email);
      formik.setFieldValue("isActived", true);
    }
    getUserCountry();
  }, []);

  useEffect(() => {
    if (userCountry) {
      formik.setFieldValue("country", userCountry);
    }
  }, [userCountry]);

  return (
    <div className="flex flex-col w-full max-w-xl text-left gap-4" data-testid="sign-up-form">
      <div className="flex flex-col gap-3">
        <h4 className="xl:hidden text-heading font-medium">Create your Scope account</h4>
        <h2 className="hidden xl:flex text-heading font-medium">Create your Scope account</h2>
        <span className="text-body text-16 font-regular opacity-70">
          Sign up to Scope and begin to connect with your favorite athletes!
        </span>
      </div>
      <form onSubmit={formik.handleSubmit} className="form-control gap-6 w-full">
        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            autoComplete="on"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.email && formik.touched.email && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullName}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.fullName && formik.touched.fullName && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Username</span>
          </label>
          <input
            type="text"
            name="username"
            id="username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            autoComplete="off"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.username && formik.touched.username && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Password</span>
          </label>
          <input
            type="password"
            name="password"
            id="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            autoComplete="new-password"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.password && formik.touched.password && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Confirm password</span>
          </label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            autoComplete="new-password"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.confirmPassword &&
              formik.touched.confirmPassword &&
              " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Country</span>
          </label>
          <select
            className={`select select-bordered rounded-full text-white ${
              formik.errors.country && formik.touched.country && " !border-red-500 !text-red-900"
            }`}
            name="country"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
          >
            <option value="" disabled>
              Select country
            </option>
            {countryOptions.map((countryOption, index) => (
              <option key={index} value={countryOption.value}>
                {countryOption.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col pt-4">
          <button
            type="submit"
            data-testid="create-profile-btn"
            disabled={formik.isSubmitting || signupStatus.isLoading}
            className="btn btn-primary rounded-full"
          >
            Create profile
          </button>
        </div>
        {!isMobileApp && <SocialNetworksAuth />}
      </form>
    </div>
  );
};

export default SignUpForm;
