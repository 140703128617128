import React from "react";

import ProfileAvatar from "@/components/User/ProfileAvatar";

import { UserProfileType } from "@/types/user/profile.types";
import Container from "../UI/Container";

const MemberCard = ({ profile }: { profile: UserProfileType }) => {
  return (
    <Container className="flex flex-col items-center rounded-xl w-full px-10 py-6">
      <ProfileAvatar profile={profile} imageClass="w-20 h-20" />
      <div className="text-body text-16 font-bold mt-4">{profile.fullName}</div>
      <div className="text-body text-14 font-regular text-grayscale-500">{profile.username}</div>
    </Container>
  );
};

export default MemberCard;
