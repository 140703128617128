import { SCOPE_CHAT_MESSAGE_LENGTH } from "@/constants/scopeChat.constants";
import React, { ChangeEvent, useRef, useState } from "react";
import { useScopeChat } from "@/providers/scopeChat";

import { ScopeChatMessage } from "@/types/scopeChat/scopeChat.types";
import Button from "@/components/UI/Button";
import { EmojiReactionFilled } from "@/components/UI/Icons";
import EmojiPicker from "@emoji-mart/react";

import { EmojiData } from "@/components/UI/EmojiPickerModal";

const UpdateMessageForm = ({
  message,
  setIsEdit,
}: {
  message: ScopeChatMessage;
  setIsEdit: (value: boolean) => void;
}) => {
  const { handleMessageUpdate, isUpdatingMessage, isMuted } = useScopeChat();

  const isThread = !!message.parentId;

  const [messageText, setMessageText] = useState<string>(message.content);
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);

  const textareaRef = useRef<HTMLTextAreaElement>(document.createElement("textarea"));

  const handleTextChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
    setMessageText(event.target.value);
  };

  const handleUpdateClick = async () => {
    setIsEmojiPickerOpen(false);
    setIsEdit(false);
    handleMessageUpdate(message.id, { content: messageText });
  };

  const handleTextareaClick = (event: any) => {
    setCursorPosition(event.target.selectionStart);
  };

  const handleEmojiSelect = (selectedEmoji: EmojiData) => {
    const { native } = selectedEmoji;

    const newValue =
      messageText.slice(0, cursorPosition) + native + messageText.slice(cursorPosition);

    setMessageText(newValue);

    setTimeout(() => {
      textareaRef.current.focus();
      const newCursorPosition = cursorPosition + native.length;
      setCursorPosition(newCursorPosition);
      textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleKeyDown = () => {
    setCursorPosition(textareaRef.current.selectionStart);
  };

  return (
    <div className="flex flex-col relative w-full p-3 bg-transparent border border-neutral-white rounded-lg">
      {isEmojiPickerOpen && (
        <div className="absolute left-0 z-50">
          <EmojiPicker
            onEmojiSelect={handleEmojiSelect}
            onClickOutside={() => isEmojiPickerOpen && setIsEmojiPickerOpen(false)}
          />
        </div>
      )}
      <div className="flex w-full">
        <textarea
          ref={textareaRef}
          value={messageText}
          maxLength={SCOPE_CHAT_MESSAGE_LENGTH}
          onChange={handleTextChanged}
          onClick={handleTextareaClick}
          onKeyDown={handleKeyDown}
          disabled={isUpdatingMessage || isMuted}
          placeholder="Say something..."
          className="focus:border-none focus:outline-none w-full bg-transparent box-border resize-none border-none overflow-hidden text-body text-14 font-medium"
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-0">
          <div className="tooltip !tooltip-black w-8 h-8" data-tip="Add emoji reaction">
            <button
              className="btn btn-square btn-ghost rounded-md w-8 h-8 min-h-8"
              onClick={(event) => {
                event.stopPropagation();
                setIsEmojiPickerOpen((prev) => !prev);
              }}
            >
              <EmojiReactionFilled />
            </button>
          </div>
          <span className="text-body text-12 font-regular text-neutral-white-50">
            {messageText.length}/{SCOPE_CHAT_MESSAGE_LENGTH}
          </span>
        </div>
        <div className="flex gap-2">
          <Button
            className={`btn btn-outline btn-sm ${isThread ? "!btn-xs" : ""}`}
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            className={`btn btn-primary btn-sm ${isUpdatingMessage ? "loading" : ""} ${
              isThread ? "!btn-xs" : ""
            }`}
            onClick={handleUpdateClick}
            disabled={isMuted}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateMessageForm;
