import { createContext, useContext } from "react";

interface AppGatewayContextType {
  showInstallAppModal: () => void;
}

const AppGatewayContext = createContext<AppGatewayContextType | undefined>(undefined);

export const useAppGateway = (): AppGatewayContextType => {
  const context = useContext(AppGatewayContext);

  if (!context) {
    throw new Error("useAppGateway must be used within a AppGatewayProvider");
  }

  return context;
};

export default AppGatewayContext;
