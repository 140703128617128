export const randomString = (length = 8) => {
  return Math.random().toString(16).substring(2, length);
};

export const encodeGetParams = (params: object) =>
  Object.entries(params)
    .map((param) => param.map(encodeURIComponent).join("="))
    .join("&");

export const objectKeyToReadableString = (key: string): string =>
  key
    .replace(/[_-]/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
    .replace(/([A-Z])/g, (match, p1, offset) => (offset > 0 ? " " + match : match))
    .trim()
    .replace(/^\w/, (c) => c.toUpperCase());

export const snakeCaseToReadableString = (str: string) =>
  str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
