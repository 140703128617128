import { Button } from "@/types/app.types";
import React, { ReactNode } from "react";

const EmptySection = ({
  containerClassName,
  icon,
  iconClassName,
  title,
  titleClassName,
  text,
  textClassName,
  buttons,
}: {
  containerClassName?: string;
  icon?: ReactNode;
  iconClassName?: string;
  title?: string;
  titleClassName?: string;
  text?: string | ReactNode;
  textClassName?: string;
  buttons?: Button[];
}) => {
  return (
    <div
      className={`flex flex-col items-center w-full overflow-hidden xl:rounded-xl border-0 py-16 px-2 xl:max-w-[50%] mx-auto ${
        containerClassName ?? ""
      }`}
    >
      {icon && (
        <div className="avatar placeholder">
          <div className={`w-16 rounded-full ${iconClassName ?? ""}`}>{icon}</div>
        </div>
      )}
      {title && (
        <span
          className={`font-roobert font-bold text-xl text-white text-center ${
            titleClassName ?? ""
          }`}
        >
          {title}
        </span>
      )}
      {text && (
        <p
          className={`font-roobert text-base text-white opacity-70 text-center ${
            textClassName ?? ""
          }`}
        >
          {text}
        </p>
      )}
      {buttons && buttons.length > 0 && (
        <div className="w-full mt-3">
          {buttons.map(({ caption, onClick, type }: Button, index: number) => (
            <button
              key={`button-${index}`}
              onClick={onClick}
              className={`w-full xl:ml-[5px] xl:mr-[10px] btn ${type ?? "btn-primary"}`}
            >
              {caption}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmptySection;
