import React, { useEffect, useState } from "react";
import { batch } from "react-redux";
import { useGeneralUi } from "@/providers/generalUi";
import { useFanClub } from "@/providers/fanClub";

import CardMedia from "@/components/CardMedia";
import AthleteInfo from "../Common/AthleteInfo";
import MobileAthleteInfo from "../Mobile/MobileAthleteInfo";
import Container from "@/components/UI/Container";
import GetAccessButton from "@/components/Payment/GetAccessButton";
import CardSelector from "../Common/CardSelector";
import NewsletterSignUp from "../Common/NewsletterSignUp";

import { FanClubCard } from "@/types/card/card.types";

const CardsView = () => {
  const { playerProfile, isMember, cards, currentCard, setCurrentCard } = useFanClub();
  const { isMobile } = useGeneralUi();

  const [cardsForSelector, setCardsForSelector] = useState<
    { name: string; uuid: string; onClickHandler: (uuid: string) => void }[]
  >([]);

  const handleCardSelected = (uuid: string) => {
    setCurrentCard(cards.find((card) => card.uuid === uuid) || cards?.[0]);
  };

  useEffect(() => {
    const cardsForSelector = cards.map((tradeCard) => ({
      name: tradeCard.card.cardName,
      uuid: tradeCard.uuid,
      onClickHandler: handleCardSelected,
    }));

    batch(() => {
      setCardsForSelector(cardsForSelector);
    });
  }, [cards]);

  const MobileSelector = ({ currentCard }: { currentCard: FanClubCard }) => (
    <div className="flex flex-col gap-8 w-full items-center justify-start pb-8">
      {cards.length > 1 && (
        <CardSelector currentCardUuid={currentCard.uuid} cards={cardsForSelector} />
      )}
      {!currentCard.owned && (
        <>
          {!!currentCard.card.cardFeatures && (
            <span className="text-body text-16">{currentCard.card.cardFeatures}</span>
          )}
          <GetAccessButton />
        </>
      )}
    </div>
  );

  return (
    <div
      className={`flex w-full xl:h-full gap-4 xl:gap-0
        2k:px-64
        4k:px-[512px] ${isMobile && isMember ? "flex-col-reverse" : "flex-col"}`}
    >
      {currentCard?.uuid ? (
        <>
          {isMobile && isMember && <MobileSelector currentCard={currentCard} />}
          <div className={`xl:flex-1 relative ${isMobile ? "min-h-[calc(100dvh-100px)]" : ""}`}>
            <div className="absolute inset-0 flex flex-col xl:pb-4 4k:py-20">
              <div className="flex-1 h-[70%] 4k:h-[80%] relative">
                <div className="absolute inset-0">
                  <CardMedia tradeCard={currentCard} />
                </div>
              </div>
              {isMobile && !isMember && <MobileSelector currentCard={currentCard} />}
              {!isMobile && (
                <div className="flex flex-col gap-4 4k:gap-8 w-full h-[28%] 4k:h-[20%] items-center justify-start">
                  <div className="flex h-1/3 w-full justify-center items-center">
                    {cards.length > 1 && (
                      <CardSelector currentCardUuid={currentCard.uuid} cards={cardsForSelector} />
                    )}
                  </div>

                  {!currentCard.owned && (
                    <>
                      <div className="flex h-1/3 w-full justify-center items-center overflow-y-scroll scrollbar-hidden">
                        {!!currentCard.card.cardFeatures && (
                          <span className="text-body text-16 4k:text-[28px]">
                            {currentCard.card.cardFeatures}
                          </span>
                        )}
                      </div>
                      <div className="flex h-1/3 w-full justify-center items-center">
                        <GetAccessButton />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col h-full w-full justify-between items-center pb-10">
          <div className="flex-1 flex h-full w-full justify-center items-center">
            <Container className="p-16 w-full xl:w-auto justify-center items-center">
              <span className="text-title">{playerProfile.isNew ? "Coming Soon" : "Sold out"}</span>
            </Container>
          </div>
          {playerProfile.isNew && (
            <NewsletterSignUp playerName={playerProfile.name} playerUuid={playerProfile.uuid} />
          )}
        </div>
      )}
      <div className="flex w-full justify-center items-center">
        {isMobile ? <MobileAthleteInfo /> : <AthleteInfo />}
      </div>
    </div>
  );
};

export default CardsView;
