/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef } from "react";

export enum RECORD_STATUS {
  IDLE = "idle",
  RECORDING = "recording",
  PAUSED = "paused",
}

type record_status_type = RECORD_STATUS.RECORDING | RECORD_STATUS.PAUSED | RECORD_STATUS.IDLE;

let mediaRecorder: MediaRecorder;
let localStream: MediaStream;

export const useAudioRecorder = (setAudioFile: any, setAudioResult: any) => {
  const dataArray = useRef<Array<Blob>>([]);

  const [status, setStatus] = useState<record_status_type>(RECORD_STATUS.IDLE);
  const [audioResult, setLocalAudioResult] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [mimeType, setMimeType] = useState<string>("");

  const { timer, handleStartTimer, handlePauseTimer, handleResumeTimer, handleResetTimer } =
    useTimer();

  const startRecording = () => {
    if (status === RECORD_STATUS.IDLE) {
      try {
        setErrorMessage("");

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStreamObj: MediaStream) => {
            localStream = mediaStreamObj;
            mediaRecorder = new MediaRecorder(mediaStreamObj);
            mediaRecorder.start();
            mediaRecorder.onstart = () => {
              handleStartTimer();
              setMimeType(mediaRecorder.mimeType);
              setStatus(RECORD_STATUS.RECORDING);
            };
            mediaRecorder.ondataavailable = (event: BlobEvent) => {
              dataArray.current.push(event.data);
            };
          })
          .catch((error) => {
            setErrorMessage(error?.message);
          });
      } catch (error: any) {
        setErrorMessage(error?.message);
      }
    } else {
      return;
    }
  };

  const resumeRecording = () => {
    if (status === RECORD_STATUS.PAUSED) {
      mediaRecorder.resume();
      mediaRecorder.onresume = () => {
        handleResumeTimer();
        setStatus(RECORD_STATUS.RECORDING);
      };
    } else {
      return;
    }
  };

  const pauseRecording = () => {
    if (status === RECORD_STATUS.RECORDING) {
      mediaRecorder.pause();
      mediaRecorder.onpause = () => {
        handlePauseTimer();
        setStatus(RECORD_STATUS.PAUSED);
      };
    } else {
      return;
    }
  };

  const stopRecording = () => {
    if (status !== RECORD_STATUS.IDLE) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        handleResetTimer();
        const audioData = new Blob(dataArray.current, { type: mimeType });
        const audioFile = new File([audioData], "post_voice_note", {
          type: mimeType,
        });
        dataArray.current = [];
        setLocalAudioResult(window.URL.createObjectURL(audioData));

        setAudioResult && setAudioResult(window.URL.createObjectURL(audioData));
        setAudioFile && setAudioFile(audioFile);

        setStatus(RECORD_STATUS.IDLE);
        localStream.getAudioTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
      };
    } else {
      return;
    }
  };

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioResult,
    errorMessage,
    timer,
  };
};

export const useTimer = () => {
  const countRef = useRef<any>(null);
  const [timer, setTimer] = useState<number>(0);

  const handleStartTimer = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePauseTimer = () => {
    clearInterval(countRef.current);
  };

  const handleResumeTimer = () => {
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleResetTimer = () => {
    clearInterval(countRef.current);
    setTimer(0);
  };

  return {
    timer,
    handleStartTimer,
    handlePauseTimer,
    handleResumeTimer,
    handleResetTimer,
  };
};
