import React, { useEffect, useState } from "react";
import { useFanClub } from "@/providers/fanClub";
import { useScopeChat } from "@/providers/scopeChat";

import CardView from "../../Common/CardView";
import Placeholder from "../../Common/Placeholder";

import MessageList from "@/components/ScopeBlog/ScopeChat/MessageList/MessageList";
import { ChatInput } from "@/components/ScopeBlog/ScopeChat/Inputs/ChatInput";
import CenterSpinner from "@/components/UI/CenterSpinner";
import { ScopeChatGroupedMessages } from "@/types/scopeChat/scopeChat.types";

import { ViewTypes } from "@/types/fanClub/fanClub.types";

const ChatCard = () => {
  const { playerProfile, isMember, isAthleteChatAllowed, setCurrentView } = useFanClub();
  const { groupedMessages, handleMessagesScroll, isMessagesLoading } = useScopeChat();
  const [lastMessage, setLastMessage] = useState<ScopeChatGroupedMessages>({});

  useEffect(() => {
    if (typeof groupedMessages !== "undefined") {
      const newMessage: any = {};

      const lastMsgKey = Object.keys(groupedMessages).pop() || "";

      if (groupedMessages[lastMsgKey]) {
        newMessage[lastMsgKey] = [[...groupedMessages[lastMsgKey]].pop()];
      }
      setLastMessage(newMessage);
    }
  }, [groupedMessages]);

  return (
    <CardView
      bodyClassName="overflow-y-scroll scrollbar-hidden relative"
      title="Fan Club Chat"
      handleMaximize={() => setCurrentView(ViewTypes.CHAT)}
      disabled={!isMember || !isAthleteChatAllowed}
    >
      {isMember && isAthleteChatAllowed ? (
        isMessagesLoading ? (
          <CenterSpinner />
        ) : (
          <div className="flex-1 w-full h-full flex flex-col overflow-hidden">
            <div className="absolute bottom-0 w-full p-4 xl:p-6 z-10">
              <ChatInput cardView />
            </div>
            <MessageList
              groupedMessages={lastMessage}
              handleScroll={handleMessagesScroll}
              handleClickMessage={() => {
                setCurrentView(ViewTypes.CHAT);
              }}
              cardView
            />
          </div>
        )
      ) : (
        <Placeholder text={`Chat with ${playerProfile.name}`} />
      )}
    </CardView>
  );
};

export default ChatCard;
