import React from "react";

const AthleteStat = ({ title, value }: { title: string; value: string | number }) => (
  <div className="flex flex-col w-full items-start text-start">
    <span className="text-body text-12 xl:text-[1.3vh] text-neutral-white-50">{title}</span>
    <span className="text-body text-14 xl:text-[1.7vh] font-medium">{value}</span>
  </div>
);

export default AthleteStat;
