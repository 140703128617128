import React, { useEffect, useRef, useState } from "react";
import { batch } from "react-redux";

import Waveform from "@/components/UI/Waveform";
import CenterSpinner from "@/components/UI/CenterSpinner";
import { TransparentPauseIcon, TransparentPlayIcon } from "@/components/UI/Icons";

import { convertToString, convertToTimeFormat } from "@/helpers/audio.helpers";

const AudioPlayer = ({ src, isDraft }: { src: string; isDraft?: boolean }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);

  useEffect(() => {
    if (!audioRef.current) return;

    audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    audioRef.current.addEventListener("timeupdate", updateCurrentTime);

    return () => {
      if (!audioRef.current) return;

      audioRef.current.removeEventListener("ended", () => setIsPlaying(false));
      audioRef.current.removeEventListener("timeupdate", updateCurrentTime);
      audioRef.current.pause();
    };
  }, [isPlaying]);

  const updateCurrentTime = (event: any): void => {
    const { currentTime } = event.target;
    batch(() => {
      setCurrentTime(currentTime);
    });
  };

  return src && !isDraft ? (
    <div className="flex justify-between items-center w-full h-[80px] min-h-[80px] px-4 relative">
      <div className="absolute inset-y-0 flex justify-start items-center z-10">
        <div
          className="flex justify-center items-center rounded-full cursor-pointer p-0 bg-neutral-white-5 hover:bg-neutral-white-10"
          onClick={() => setIsPlaying((prev) => !prev)}
        >
          {isPlaying ? <TransparentPauseIcon /> : <TransparentPlayIcon />}
        </div>
      </div>
      <div className="flex-1 relative h-full">
        <div className="absolute inset-0 flex w-full xl:min-w-1/3 z-[5]">
          <Waveform
            ref={audioRef}
            src={src}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
          />
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 flex justify-end items-center px-4">
        <span className="min-w-[60px] w-16 text-body text-14 font-regular text-neutral-white-50">
          {convertToString(convertToTimeFormat(currentTime))}
        </span>
      </div>
    </div>
  ) : (
    <CenterSpinner />
  );
};

export default AudioPlayer;
