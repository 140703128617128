import { RootState } from "@/app/store";
import { FAN_CLUB_POSTS_STEP } from "@/constants/app.constants";
import { FanClubCard } from "@/types/card/card.types";
import {
  FeedFilters,
  OrderByEnum,
  OrderSortEnum,
  PostType,
  PremiumContent,
  ViewTypes,
} from "@/types/fanClub/fanClub.types";
import { createSlice } from "@reduxjs/toolkit";

export const fanClubSlice = createSlice({
  name: "fanClub",
  initialState: {
    cards: [],
    isOwnLimitedCards: false,
    currentCard: undefined,

    isReadyToFetchPosts: false,
    posts: [],
    postCount: 0,
    postLimit: FAN_CLUB_POSTS_STEP,
    filterBy: FeedFilters.ALL,
    orderBy: OrderByEnum.RECENT,
    orderSort: OrderSortEnum.DESC,
    searchQuery: "",
    postAutoplayUuid: undefined,

    ownerUuid: undefined,
    isMuted: true,
    isPremiumContentAllowed: false,
    isAthleteFeedAllowed: false,
    isAthleteChatAllowed: false,
    isMember: false,
    currentView: ViewTypes.CARDS,

    premiumContent: [],
    premiumContentCount: 0,
    premiumContentLimit: FAN_CLUB_POSTS_STEP,
    premiumContentAutoplayUuid: undefined,

    commentUuid: undefined,
    postUuid: undefined,
    premiumContentUuid: undefined,
    section: undefined,
  },
  reducers: {
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setIsOwnLimitedCards: (state, action) => {
      state.isOwnLimitedCards = action.payload;
    },
    setCurrentCard: (state, action) => {
      state.currentCard = action.payload;
    },
    setIsReadyToFetchPosts: (state, action) => {
      state.isReadyToFetchPosts = action.payload;
    },
    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    setPostCount: (state, action) => {
      state.postCount = action.payload;
    },
    setPostLimit: (state, action) => {
      state.postLimit = action.payload;
    },
    setFilterBy: (state, action) => {
      state.filterBy = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setOrderSort: (state, action) => {
      state.orderSort = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setPostAutoplayUuid: (state, action) => {
      state.postAutoplayUuid = action.payload;
    },

    setOwnerUuid: (state, action) => {
      state.ownerUuid = action.payload;
    },
    setIsMuted: (state, action) => {
      state.isMuted = action.payload;
    },
    setIsMember: (state, action) => {
      state.isMember = action.payload;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },

    setPremiumContent: (state, action) => {
      state.premiumContent = action.payload;
    },
    setPremiumContentCount: (state, action) => {
      state.premiumContentCount = action.payload;
    },
    setPremiumContentLimit: (state, action) => {
      state.premiumContentLimit = action.payload;
    },
    setPremiumContentAutoplayUuid: (state, action) => {
      state.premiumContentAutoplayUuid = action.payload;
    },
    setIsPremiumContentAllowed: (state, action) => {
      state.isPremiumContentAllowed = action.payload;
    },
    setIsAthleteChatAllowed: (state, action) => {
      state.isAthleteChatAllowed = action.payload;
    },
    setIsAthleteFeedAllowed: (state, action) => {
      state.isAthleteFeedAllowed = action.payload;
    },

    setCommentUuid: (state, action) => {
      state.commentUuid = action.payload;
    },
    setPostUuid: (state, action) => {
      state.postUuid = action.payload;
    },
    setPremiumContentUuid: (state, action) => {
      state.premiumContentUuid = action.payload;
    },
    setSection: (state, action) => {
      state.section = action.payload;
    },
  },
});

export const {
  setCards,
  setIsOwnLimitedCards,
  setCurrentCard,
  setIsReadyToFetchPosts,
  setPosts,
  setPostCount,
  setPostLimit,
  setFilterBy,
  setOrderBy,
  setOrderSort,
  setSearchQuery,
  setPostAutoplayUuid,
  setOwnerUuid,
  setIsMuted: setIsMuted,
  setIsPremiumContentAllowed,
  setIsAthleteChatAllowed,
  setIsAthleteFeedAllowed,
  setIsMember,
  setCurrentView,
  setPremiumContent,
  setPremiumContentCount,
  setPremiumContentLimit,
  setPremiumContentAutoplayUuid,
  setCommentUuid,
  setPostUuid,
  setPremiumContentUuid,
  setSection,
} = fanClubSlice.actions;

export const selectCards = (state: RootState): FanClubCard[] => state.fanClub.cards;
export const selectIsOwnLimitedCards = (state: RootState): boolean =>
  state.fanClub.isOwnLimitedCards;
export const selectCurrentCard = (state: RootState): FanClubCard | undefined =>
  state.fanClub.currentCard;

export const selectIsReadyToFetchPosts = (state: RootState): boolean =>
  state.fanClub.isReadyToFetchPosts;

export const selectPosts = (state: RootState): PostType[] => state.fanClub.posts;
export const selectPostCount = (state: RootState): number => state.fanClub.postCount;
export const selectPostLimit = (state: RootState): number => state.fanClub.postLimit;
export const selectFilterBy = (state: RootState): FeedFilters => state.fanClub.filterBy;
export const selectOrderBy = (state: RootState): OrderByEnum => state.fanClub.orderBy;
export const selectOrderSort = (state: RootState): OrderSortEnum => state.fanClub.orderSort;
export const selectSearchQuery = (state: RootState): string => state.fanClub.searchQuery;
export const selectPostAutoplayUuid = (state: RootState): string | undefined =>
  state.fanClub.postAutoplayUuid;

export const selectOwnerUuid = (state: RootState): string | undefined => state.fanClub.ownerUuid;
export const selectIsMember = (state: RootState): boolean => state.fanClub.isMember;
export const selectIsMuted = (state: RootState): boolean => state.fanClub.isMuted;
export const selectIsPremiumContentAllowed = (state: RootState): boolean =>
  state.fanClub.isPremiumContentAllowed;
export const selectIsAthleteChatAllowed = (state: RootState): boolean =>
  state.fanClub.isAthleteChatAllowed;
export const selectIsAthleteFeedAllowed = (state: RootState): boolean =>
  state.fanClub.isAthleteFeedAllowed;
export const selectCurrentView = (state: RootState): ViewTypes => state.fanClub.currentView;

export const selectPremiumContent = (state: RootState): PremiumContent[] =>
  state.fanClub.premiumContent;
export const selectPremiumContentCount = (state: RootState): number =>
  state.fanClub.premiumContentCount;
export const selectPremiumContentLimit = (state: RootState): number =>
  state.fanClub.premiumContentLimit;
export const selectPremiumContentAutoplayUuid = (state: RootState): string | undefined =>
  state.fanClub.premiumContentAutoplayUuid;

export const selectCommentUuid = (state: RootState): string | undefined =>
  state.fanClub.commentUuid;
export const selectPostUuid = (state: RootState): string | undefined => state.fanClub.postUuid;
export const selectPremiumContentUuid = (state: RootState): string | undefined =>
  state.fanClub.premiumContentUuid;
export const selectSection = (state: RootState): ViewTypes | undefined => state.fanClub.section;

export default fanClubSlice.reducer;
