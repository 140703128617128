import React, { ReactNode } from "react";
import "./InfoModal.styles.css";
import { CloseIcon } from "../Icons";
import { Button } from "@/types/app.types";

const InfoModal = ({
  onToggle,
  title,
  icon,
  body,
  buttons = [],
}: {
  onToggle: (isOpen: boolean) => void;
  title?: string;
  icon?: ReactNode;
  body?: ReactNode;
  buttons?: Button[];
}) => {
  return (
    <div className="modal info-modal backdrop-blur-md modal-open">
      <div className="modal-box text-center bg-grayscale-800 max-w-[480px] mx-auto">
        <div className="flex justify-between items-center">
          <span className="text-body text-16 font-medium xl:text-18">{title}</span>
          <button
            onClick={() => onToggle(false)}
            className="btn btn-sm btn-square btn-ghost p-0 m-0"
          >
            <CloseIcon />
          </button>
        </div>

        {icon && (
          <div className="avatar placeholder">
            <div className="w-16 rounded-full bg-grayscale-800">{icon}</div>
          </div>
        )}
        <div className="text-body text-14 font-regular py-4 text-start">{body}</div>

        <div className="modal-action gap-2">
          {buttons.length > 0 &&
            buttons.map(({ caption, onClick, type }: Button, index: number) => (
              <button
                key={`button-${index}`}
                onClick={onClick ?? (() => onToggle(false))}
                className={`btn ${type ?? "btn-primary"}`}
              >
                {caption ?? "OK"}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
