import React from "react";

import { CloseIcon } from "../Icons";

import "./Toast.styles.css";

interface ToastProps {
  closeToast: () => void;
  body: string;
  button: boolean;
  buttonCaption: string;
  buttonClass: string;
  buttonHandler: () => void;
}

const Toast = ({
  closeToast,
  body,
  button,
  buttonCaption,
  buttonClass,
  buttonHandler,
}: ToastProps) => {
  return (
    <div className="flex justify-between items-center w-full gap-2">
      <div className="justify-start text-start">
        <span className="text-body text-14 font-medium">{body}</span>
      </div>
      <div className="flex justify-between items-center gap-2">
        {button && (
          <button
            onClick={buttonHandler}
            className={`mx-2 btn btn-sm ${buttonClass} ${!buttonClass && "btn-outline"}`}
          >
            {buttonCaption} {!buttonCaption && "Button"}
          </button>
        )}
        <button onClick={closeToast}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default Toast;
