import React from "react";
import { ArrowIcon } from "@/components/UI/Icons";

const ShowMoreAnchor = ({ className, ...props }: any) => (
  <a
    href="#"
    className={`flex items-center text-caps text-13 font-bold hover:border-bottom hover:!border-grayscale-400 min-w-fit ${
      className ?? ""
    }`}
    {...props}
  >
    Show more
    <ArrowIcon className="ml-2" />
  </a>
);

export default ShowMoreAnchor;
