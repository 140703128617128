import React, { useState, MouseEvent } from "react";
import { useAppNavigate } from "@/app/hooks";

import { FilledPlayIcon, GradientNewIcon } from "@/components/UI/Icons";
import Button from "@/components/UI/Button";

import { PlayerProfileType } from "@/types/player/player.types";

const Player = ({ playerProfile }: { playerProfile: PlayerProfileType }) => {
  const navigate = useAppNavigate();

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleLearnMore = () => {
    navigate(`/fan-club/${playerProfile.urlAlias || playerProfile.uuid}`);
  };

  const handleFanClubPreviewClicked = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(`/fan-club/${playerProfile.urlAlias || playerProfile.uuid}/preview-video?learnMore`);
  };

  return (
    <div
      data-testid="player-profile-card"
      data-uuid={playerProfile.uuid}
      data-has-preview={!!playerProfile.previewVideo}
      className="flex flex-col w-[312px] max-w-[312px] h-full max-h-[450px] bg-grayscale-900 rounded-xl cursor-pointer"
    >
      <div
        className="rounded-lg box-border relative overflow-hidden m-2 !mb-0"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleLearnMore}
      >
        <div className="bg-[url('/img/card-backdrop.png')] blur-[10px] h-[390px] bg-center bg-no-repeat bg-cover">
          <div
            className="absolute inset-0 mix-blend-color"
            style={{ backgroundColor: "#FFFBD8" }}
          ></div>
        </div>
        <img
          src={playerProfile.thumbnails.lg || playerProfile.image}
          className="absolute w-full bottom-0 left-1/2 -translate-x-1/2 object-cover rounded-lg "
        />
        <div className="absolute w-full left-0 bottom-0 h-1/2 bg-gradient-to-t from-black to-transparent"></div>
        {isHovered ? (
          <div className="absolute inset-0 opacity-0 hover:opacity-100 transition-opacity duration-200 flex flex-col items-center justify-end gap-3 p-4">
            {playerProfile.previewVideo && (
              <Button
                data-testid="fan-club-preview-btn"
                className="btn w-full bg-neutral-white-pure-white !text-grayscale-1000 hover:!text-white"
                onClick={handleFanClubPreviewClicked}
              >
                <div className="flex gap-3 items-center">
                  <FilledPlayIcon />
                  Fan Club Preview
                </div>
              </Button>
            )}
            <Button className="btn btn-outline w-full" onClick={handleLearnMore}>
              Learn more
            </Button>
          </div>
        ) : (
          <div className="absolute bottom-0 hover:opacity-0 transition-opacity duration-200 flex flex-col p-4 gap-0">
            <div
              data-testid="player-profile-name"
              className="text-caps !font-huben text-lg xl:text-2xl text-white"
            >
              {playerProfile.name}
            </div>
            <div className="text-caps text-13 font-regular text-white opacity-50">
              Tennis player
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-end items-center p-4">
        {playerProfile.isNew && <GradientNewIcon />}
      </div>
    </div>
  );
};

export default Player;
