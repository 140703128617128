import React from "react";

import { TwitterIcon, GoogleIcon, FacebookIcon } from "./UI/Icons";

const SocialNetworksAuth = () => {
  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="text-center w-full">
        <span className="text-secondary uppercase !text-sm">Or Continue with</span>
      </div>
      <div className="flex flex-row gap-3 justify-between">
        {/* <a
        href={`${import.meta.env.VITE_BACKEND_URL}user/auth/twitter`}
        type="button"
        className="btn btn-active btn-ghost rounded-full grow mb-5 xl:mx-2"
      >
        <TwitterIcon className="xl:mr-2" />
        <span className="hidden xl:flex">Twitter</span>
      </a> */}
        <a
          href={`${import.meta.env.VITE_BACKEND_URL}user/auth/google`}
          type="button"
          className="btn btn-active btn-ghost rounded-full w-full"
        >
          <GoogleIcon className="xl:mr-2" />
          <span className="hidden xl:flex">Google</span>
        </a>
        {/* <a
          href={`${import.meta.env.VITE_BACKEND_URL}user/auth/facebook`}
          type="button"
          className="btn btn-active btn-ghost rounded-full grow mb-5 xl:mx-2"
        >
          <FacebookIcon className="xl:mr-2" />
          <span className="hidden xl:flex">Facebook</span>
        </a> */}
      </div>
    </div>
  );
};

export default SocialNetworksAuth;
