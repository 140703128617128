import React from "react";

import AppLink from "@/components/AppLink";
import InstallPWAInfo from "@/components/InstallPWAInfo";

const InstallPWA = () => {
  return (
    <div className="flex justify-center items-center w-full xl:max-w-[50%] h-full mx-auto">
      <InstallPWAInfo>
        <AppLink to={"/"} className="btn btn-primary rounded-full btn-block">
          Return Home
        </AppLink>
      </InstallPWAInfo>
    </div>
  );
};

export default InstallPWA;
