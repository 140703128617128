import { RootState } from "@/app/store";
import { GroupedPlayerProfiles, PlayerProfileType } from "@/types/player/player.types";
import { createSlice } from "@reduxjs/toolkit";

export const scopeBlogSlice = createSlice({
  name: "scopeBlog",
  initialState: {
    playerProfile: undefined,
    groupedPlayerProfiles: {},
    playerProfiles: [] as PlayerProfileType[],
    isLoading: true,
  },
  reducers: {
    setPlayerProfile: (state, action) => {
      state.playerProfile = action.payload;
    },
    setPlayerProfiles: (
      state,
      action: {
        payload: GroupedPlayerProfiles;
      },
    ) => {
      state.playerProfiles = Object.values(action.payload)
        .reduce<PlayerProfileType[]>(
          (acc: PlayerProfileType[], curr: PlayerProfileType[] | undefined) => {
            const set = new Set(acc.map((profile) => profile.uuid));
            const uniqueProfiles = (curr || []).filter((profile) => !set.has(profile.uuid));
            return acc.concat(uniqueProfiles);
          },
          [] as PlayerProfileType[],
        )
        .sort((a, b) => a["name"].localeCompare(b["name"]));

      state.groupedPlayerProfiles = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setPlayerProfile, setPlayerProfiles, setIsLoading } = scopeBlogSlice.actions;

export const selectPlayerProfile = (state: RootState): PlayerProfileType | undefined =>
  state.scopeBlog.playerProfile;

export const selectGroupedPlayerProfiles = (
  state: RootState,
): { owned?: PlayerProfileType[]; managed?: PlayerProfileType[]; rest?: PlayerProfileType[] } =>
  state.scopeBlog.groupedPlayerProfiles;

export const selectPlayerProfiles = (state: RootState): PlayerProfileType[] =>
  state.scopeBlog.playerProfiles;

export const selectIsLoading = (state: RootState): boolean => state.scopeBlog.isLoading;

export default scopeBlogSlice.reducer;
