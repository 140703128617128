import React from "react";
import { useFanClub } from "@/providers/fanClub";

import Container from "@/components/UI/Container";
import ProfileAvatar from "@/components/User/ProfileAvatar";
import { GradientLockIcon } from "@/components/UI/Icons";
import AthleteStat from "./AthleteStat";
import ReactCountryFlag from "react-country-flag";

import { Country } from "@/types/countries.enum";
import { getAgeFromBirthDate } from "@/helpers/date.helpers";
import { getCountryByCode } from "@/helpers/country.helpers";
import { getHeightFt } from "@/helpers/player.helpers";

const AthleteInfo = () => {
  const { isMember, playerProfile } = useFanClub();

  const country = getCountryByCode(playerProfile?.nationality || "");

  return (
    <Container className="relative w-full p-6 select-none">
      <div className={`w-full flex items-center justify-between ${!isMember ? "pr-10" : ""}`}>
        <div className="flex items-center gap-6">
          <ProfileAvatar imageClass="w-16 h-16" profile={playerProfile} />
          <div className="flex flex-col">
            <h6 className="text-heading font-medium text-start" data-testid="player-profile-name">
              {playerProfile.name}
            </h6>
            <div className="flex items-center gap-2">
              {country && (
                <ReactCountryFlag
                  className="!w-5 rounded-sm"
                  title={country}
                  countryCode={Country[country as keyof typeof Country]}
                  svg
                />
              )}
              <span className="text-body text-14">{playerProfile.nationality}</span>
            </div>
          </div>
        </div>
        <div className="flex gap-10">
          <AthleteStat title="Members" value={playerProfile.membersCount} />
          <AthleteStat title="Age" value={getAgeFromBirthDate(playerProfile.birthday)} />
          <AthleteStat title="Height" value={getHeightFt(playerProfile?.heightCm)} />
          <AthleteStat title="Plays" value={playerProfile.activeHand.toUpperCase()} />
        </div>
        {!isMember && <GradientLockIcon className="absolute top-3 right-3" />}
      </div>
    </Container>
  );
};

export default AthleteInfo;
