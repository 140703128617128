import React, { useRef, useState } from "react";
import { useAppNavigate } from "@/app/hooks";
import { useClickOutside } from "@/app/hooks";
import { useProfile } from "@/providers/profile";

import ProfileAvatar from "@/components/User/ProfileAvatar";
import { CaretIcon } from "@/components/UI/Icons";

import { UserProfileType } from "@/types/user/profile.types";
import { PlayerProfileType } from "@/types/player/player.types";

interface ProfileCardProps {
  userProfile?: UserProfileType;
  playerProfile?: PlayerProfileType;
}

const Profiles = () => {
  const {
    userProfile,
    managedPlayerProfiles,
    managedPlayerProfile,
    setManagedPlayerProfile,
    clearManagedPlayerProfile,
  } = useProfile();

  const [isProfileSelectorOpen, setIsProfileSelectorOpen] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const ProfileCard = ({ userProfile, playerProfile }: ProfileCardProps) => {
    return (
      <div className="w-full flex items-center gap-3 px-2">
        <ProfileAvatar profile={playerProfile || userProfile} />
        <div className="w-[126px] flex flex-col text-start">
          <span className="w-full text-body text-16 font-medium truncate">
            {playerProfile
              ? playerProfile.name
              : userProfile
              ? `${userProfile.fullName}`
              : "Unknown"}
          </span>
          <span className="text-body text-14 font-regular text-neutral-white-70 truncate">
            {userProfile ? `@${userProfile.username}` : "Athlete profile"}
          </span>
        </div>
      </div>
    );
  };

  const ProfileSelector = () => {
    if (!userProfile) return null;

    const navigate = useAppNavigate();
    const profileSelectorRef = useRef(null);

    useClickOutside(profileSelectorRef, () => setIsProfileSelectorOpen(false));

    const handlePlayerSelected = async (playerUuid: string, playerUrlAlias: string | null) => {
      await setManagedPlayerProfile(playerUuid);
      navigate(`/fan-club/${playerUrlAlias || playerUuid}`);
      setIsProfileSelectorOpen(false);
    };

    const handleUserSelected = () => {
      clearManagedPlayerProfile();
      setIsProfileSelectorOpen(false);
    };

    return (
      <div
        ref={profileSelectorRef}
        className="flex flex-col max-h-[300px] w-full overflow-hidden overflow-y-scroll scrollbar-hidden -mt-1"
      >
        <div
          onClick={handleUserSelected}
          className="cursor-pointer hover:bg-neutral-white-7 rounded-lg py-1"
        >
          <ProfileCard userProfile={userProfile} />
        </div>
        {managedPlayerProfiles.map((player: PlayerProfileType) => (
          <div
            key={player.uuid}
            className="cursor-pointer hover:bg-neutral-white-7 rounded-lg py-1"
            onClick={() => handlePlayerSelected(player.uuid, player.urlAlias)}
          >
            <ProfileCard playerProfile={player} />
          </div>
        ))}
      </div>
    );
  };

  return userProfile ? (
    <div
      className="w-full flex flex-col"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isProfileSelectorOpen ? (
        <ProfileSelector />
      ) : (
        <>
          {isHovered && managedPlayerProfiles.length > 0 && (
            <div className="absolute top-2 right-2 translate-y-1/2">
              <button
                onClick={() => setIsProfileSelectorOpen((prev) => !prev)}
                className="btn btn-circle btn-sm bg-neutral-black-70"
              >
                <CaretIcon />
              </button>
            </div>
          )}
          <div className="flex w-full items-center">
            {managedPlayerProfile ? (
              <ProfileCard playerProfile={managedPlayerProfile} />
            ) : (
              <ProfileCard userProfile={userProfile} />
            )}
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default Profiles;
