import React from "react";
import { useFanClub } from "@/providers/fanClub";

import CommentList from "./CommentList";
import CommentForm from "./CommentForm";

import { PostType } from "@/types/fanClub/fanClub.types";

const CommentsContainer = ({
  isOpen,
  post,
  editable,
}: {
  isOpen: boolean;
  post: PostType;
  editable: boolean;
}) => {
  const { isMuted } = useFanClub();

  return (
    <>
      {isOpen && (
        <div className="w-full px-8 xl:pb-6">
          <div className="flex w-full justify-start uppercase">
            Total ({post.commentsCount}) comments
          </div>
          {!isMuted && <CommentForm postUuid={post.uuid} postOwnerUuid={post.owner.uuid} />}
          <CommentList
            comments={post?.comments}
            editable={editable}
            postUuid={post.uuid}
            postOwnerUuid={post.owner.uuid}
          />
        </div>
      )}
    </>
  );
};

export default CommentsContainer;
