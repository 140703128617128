import React, { useEffect, useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";
import { isIOS } from "react-device-detect";

import MobileNavbar from "@/components/Page/Mobile/MobileNavbar";
import MobileNavbarMenu from "@/components/Page/Mobile/MobileNavbarMenu";
import MobileFooter from "@/components/Page/Mobile/MobileFooter";
import Container from "@/components/UI/Container";

import { selectIsMobileMenuOpen, selectIsMobileProfileMenuOpen } from "@/reducers/ui.slice";

const MobileLayout = ({
  showNavbar,
  showFooter,
  bluredBackground,
  staticBackground,
  gradientBackground,
}: {
  showNavbar?: boolean;
  showFooter?: boolean;
  bluredBackground?: boolean;
  staticBackground?: boolean;
  gradientBackground?: boolean;
}) => {
  const {
    setHeader,
    useStaticBackground,
    useGradientBackground,
    useBluredBackground,
    isPWA,
    isIosMobileApp,
  } = useGeneralUi();

  const isMobileMenuOpen = useAppSelector(selectIsMobileMenuOpen);
  const isMobileProfileMenuOpen = useAppSelector(selectIsMobileProfileMenuOpen);

  useEffect(() => {
    if (!isIOS || isPWA || isIosMobileApp) return;

    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    const content = document.getElementById("ios_content");

    const noScrollHandler = (event: TouchEvent) => {
      event.preventDefault();
    };

    const focusInHandler = (event: FocusEvent) => {
      const { target } = event;

      if (target && (target as HTMLElement).className.includes("ios-no-scroll")) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        body.addEventListener("touchmove", noScrollHandler, { passive: false });
      } else {
        body.removeEventListener("touchmove", noScrollHandler);
      }
    };

    const focusOutHandler = () => body.removeEventListener("touchmove", noScrollHandler);

    const resizeHandler = () => {
      const height = `${window.visualViewport?.height}px`;

      window.scrollTo(0, 0);
      html.style.height = height;
      body.style.height = height;
      if (content) {
        content.style.height = height;
      }
    };

    body.addEventListener("focusin", focusInHandler, { passive: false });
    body.addEventListener("focusout", focusOutHandler, { passive: false });

    window.visualViewport && window.visualViewport.addEventListener("resize", resizeHandler);

    return () => {
      body.removeEventListener("focusin", focusInHandler);
      body.removeEventListener("focusout", focusOutHandler);

      window.visualViewport && window.visualViewport.addEventListener("resize", resizeHandler);
    };
  }, [isIOS]);

  useEffect(() => {
    useStaticBackground(!!staticBackground);
    useGradientBackground(!!gradientBackground);
    useBluredBackground(!!bluredBackground);
  }, [staticBackground, gradientBackground, bluredBackground]);

  useLayoutEffect(() => {
    if (!showNavbar) return;

    setHeader(
      isMobileMenuOpen ? (
        <Container
          className={`flex flex-col w-full h-full overflow-hidden p-0 ${
            isMobileProfileMenuOpen ? "justify-start" : "justify-between"
          }`}
          border="rounded-xl"
        >
          <MobileNavbarMenu />
        </Container>
      ) : (
        <MobileNavbar />
      ),
    );
  }, [showNavbar, isMobileMenuOpen, isMobileProfileMenuOpen]);

  return (
    <div className="flex flex-col w-full h-full relative">
      <div className="absolute inset-0 flex flex-col gap-6 w-full h-full">
        <Outlet />
        {showFooter && <MobileFooter />}
      </div>
    </div>
  );
};

export default MobileLayout;
