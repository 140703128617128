import { IThumbnails } from "../app.types";
import { Card, RecentUserCards } from "../card/card.types";
import { ExternalWallet } from "../crypto/crypto.types";
import { PlayerProfileType } from "../player/player.types";
import { ScopeChatUserTypes } from "../scopeChat/scopeChat.types";
import { WishlistItemType } from "../wishlist/wishlist.types";

export type UserProfileType = {
  blockedUserUuids: string[];
  id: number;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  email: string;
  fullName: string;
  bio: string;
  username: string;
  balance: number;
  isActived: boolean;
  isScopeAdmin: boolean;
  role: string;
  avatarUid: string;
  countBids: number;
  countPlayerCardBids: number;
  countUserCards: number;
  countTradeUserCards: number;
  stripeAccount: string;
  managedPlayers: PlayerProfileType[];
  wishlist: WishlistItemType[];
  country: string;
  settings: any;
  availablePlayers: PlayerProfileType[];
  thumbnails?: IThumbnails;
  presignedUrlForAvatarDownload?: string;
  isManager: boolean;
  isMuted: boolean;
  isOnboarded?: boolean;
  scopeChatType: ScopeChatUserTypes;
  recentCardsByCardUuid: RecentCards;
  connectedExternalWallet: ExternalWallet;
  defaultExternalWallet: ExternalWallet;
  useExternalWallet: boolean;
};

export interface RecentCards {
  [playerUuid: string]: RecentUserCards;
}

export interface UserCard {
  color: string;
  uuid: string;
  card: Card;
}

export interface BlockedUserProfileType {
  uuid: string;
  fullName: string;
  presignedUrlForAvatarDownload: string;
  thumbnails?: IThumbnails;
}

export interface MemberProfileType {
  uuid: string;
  isMuted: boolean;
  user: UserProfileType;
}

export interface UpdateUserProfileType {
  fullName: string;
  bio?: string;
  isMuted?: boolean;
  isScopeAdmin?: boolean;
  username: string;
  role: string;
  country: string;
  isOnboarded?: boolean;
}

export interface AllowedUpdateUserProfileType {
  fullName?: string;
  bio?: string;
  isMuted?: boolean;
  username?: string;
  role?: string;
  country?: string;
  isOnboarded?: boolean;
}

export enum UserRoles {
  USER = "user",
  ADMIN = "admin",
}
