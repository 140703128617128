import React, { useEffect, useState } from "react";
import { useProfile } from "@/providers/profile";
import { useGetEarningsMutation } from "@/api/playerApi";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Container from "@/components/UI/Container";
import EmptySection from "../UI/EmptySection";
import { EarningsIcon, CalendarIcon } from "../UI/Icons";
import { Option, Select } from "../UI/Select";

const EarningChart = () => {
  const { managedPlayerProfile } = useProfile();

  const [getEarnings] = useGetEarningsMutation();

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [yearEarnings, setYearEarnings] = useState<number>(0);
  const [yearOptions, setYearOptions] = useState<Option[]>([]);
  const [transactions, setTransactions] = useState<{ date: string; value: number }[]>([]);

  useEffect(() => {
    if (!managedPlayerProfile) {
      return;
    }

    getEarnings({ uuid: managedPlayerProfile.uuid, year }).then(({ data }: any) => {
      const { transactionsSumByDays, years, yearEarnings } = data;
      setYearEarnings(yearEarnings);

      const sortedYears = [...years].sort((a: number, b: number) => b - a);

      setYearOptions(sortedYears.map((year: number) => ({ value: year, label: year })));
      setTransactions(transactionsSumByDays);
    });
  }, [managedPlayerProfile, year]);

  const formatXAxis = (value: string, index: number) => {
    const date = new Date(value);
    const month = date.getMonth();

    if (index === 0 || month !== new Date(transactions[index - 1].date).getMonth()) {
      const formattedMonth = date.toLocaleString("default", { month: "short" });
      return formattedMonth;
    }

    return "";
  };

  return (
    <Container className="w-full" border="border border-neutral-white-20 rounded-xl flex-col">
      <div className="flex justify-between flex-col-reverse xl:flex-row w-full py-8 px-12 gap-8 mobile:px-4 mobile:py-4 mobile:flex-col mobile:items-start">
        <div className="flex flex-col items-start">
          <span className="text-caps text-13 font-medium text-neutral-white-50">
            In progress earnings in {year}
          </span>
          <span className="font-huben text-2xl">${yearEarnings?.toFixed(2) || 0}</span>
        </div>
        <Select
          value={year}
          onChange={(value) => setYear(Number(value))}
          leftAdornment={<CalendarIcon />}
          options={yearOptions}
        />
      </div>
      <div className="w-full h-[calc(100dvh-450px)] xl:h-[500px] p-4 overflow-x-scroll scrollbar-hidden">
        {transactions.length ? (
          <ResponsiveContainer width="100%" minWidth={500}>
            <AreaChart
              width={500}
              height={200}
              data={transactions}
              margin={{
                top: 10,
                right: 10,
                left: 10,
                bottom: 10,
              }}
            >
              <defs>
                <linearGradient id="earningsBackground" gradientTransform="rotate(135)">
                  <stop offset="0%" stopColor="rgba(221, 112, 98, 0.64)" />
                  <stop offset="100%" stopColor="rgba(221, 112, 98, 0.24)" />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="2 5" stroke="rgba(255, 255, 255, 0.1)" />
              <XAxis
                dataKey="date"
                interval={30}
                tick={{ stroke: "#919194", strokeWidth: 0.2 }}
                tickFormatter={formatXAxis}
                stroke="rgba(255, 255, 255, 0.4)"
                tickMargin={10}
                strokeDasharray="2 5"
              />
              <YAxis
                strokeDasharray="2 5"
                stroke="rgba(255, 255, 255, 0.4)"
                tickCount={10}
                tickFormatter={(value) =>
                  value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }
              />
              <Tooltip
                labelFormatter={(date) =>
                  new Date(date).toLocaleString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  })
                }
                formatter={(value) =>
                  value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }
              />
              <Area
                className="m-2"
                type="monotoneY"
                dataKey="value"
                dot={false}
                stroke="#DD7062"
                strokeWidth="2"
                fill="url(#earningsBackground)"
                name="Earned"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <EmptySection
            containerClassName="justify-center h-full"
            icon={<EarningsIcon />}
            title="No earnings Yet!"
            text="Only users with a Member Card will be able to view this content"
          />
        )}
      </div>
    </Container>
  );
};

export default EarningChart;
