import React, { ReactNode } from "react";
import { useGeneralUi } from "@/providers/generalUi";

import { CloseIcon } from "@/components/UI/Icons";
import Button from "@/components/UI/Button";

const SidebarHeader = ({
  label,
  handleBackClick,
}: {
  label: string | ReactNode;
  handleBackClick: () => void;
}) => {
  const { isMobile } = useGeneralUi();

  return !isMobile ? (
    <div className="flex justify-between items-center p-4 pb-0">
      <span className="text-caps text-13 font-heavy text-neutral-white-pure-white">{label}</span>
      <Button className="btn btn-ghost btn-square btn-sm rounded-md" onClick={handleBackClick}>
        <CloseIcon />
      </Button>
    </div>
  ) : null;
};

export default SidebarHeader;
