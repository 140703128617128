import React, { useEffect, useRef } from "react";
import "./CheckboxContainer.styles.css";

const CheckboxContainer = ({
  checked,
  label,
  onChange,
  isLoading,
  disabled,
  className,
  dataTestId,
  ...inputProps
}: {
  checked: boolean;
  label?: string;
  dataTestId?: string;
  onChange?: (value: any) => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}) => {
  const labelRef = useRef<HTMLSpanElement>(document.createElement("span"));

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.innerHTML = label || "";
    }
  }, [labelRef]);

  return (
    <div className={`checkbox-container ${className ?? ""}`} data-testid={dataTestId}>
      <label className="label cursor-pointer">
        <input
          type="checkbox"
          className={`mr-3`}
          disabled={disabled}
          {...{ checked, onChange, ...inputProps }}
        />
        <span className={`checkmark ${isLoading ? "loading" : ""}`}></span>
        <span ref={labelRef} className="label-text text-white text-sm"></span>
      </label>
    </div>
  );
};

export default CheckboxContainer;
