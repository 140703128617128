import React, { PropsWithChildren } from "react";

import { useProfile } from "../profile";

import { toast } from "@/utils/toast";

import BlockUserContext from "./BlockUserContext";
import { useBlockUserMutation, useMuteUserMutation, useUnmuteUserMutation } from "@/api/userApi";
import { useFullscreenModal } from "../fullscreenModal";
import ConfirmModal from "@/components/UI/ConfirmModal";
import { BlockUserIcon, MuteUserIcon, UnmuteUserIcon } from "@/components/UI/Icons";

const BlockUserProvider = ({ children }: PropsWithChildren) => {
  const { getUserProfile, isAdmin } = useProfile();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();

  const [blockUser, blockUserStatus] = useBlockUserMutation();
  const [muteUser, muteUserStatus] = useMuteUserMutation();
  const [unmuteUser, unmuteUserStatus] = useUnmuteUserMutation();

  const handleBlockUser = (uuid: string) => {
    setIsModalOpen(false);
    setModal(
      <ConfirmModal
        icon={<BlockUserIcon />}
        title="Block user!"
        body="This action will block selected user, are you sure?"
        onToggle={handleCloseModal}
        onConfirm={() =>
          blockUser(uuid)
            .unwrap()
            .then(async ({ message }) => {
              await getUserProfile(true);
              isAdmin &&
                toast.info(
                  "You have admin rights, blocking users have no effect for your account.",
                );

              toast.warning(message);
            })
            .finally(() => handleCloseModal())
        }
        confirmCaption="Block"
      />,
    );
    setIsModalOpen(true);
  };

  const handleMuteUser = (uuid: string) => {
    setIsModalOpen(false);
    setModal(
      <ConfirmModal
        icon={<MuteUserIcon />}
        title="Mute user!"
        body="This action will mute selected user, are you sure?"
        onToggle={handleCloseModal}
        onConfirm={() =>
          muteUser(uuid)
            .unwrap()
            .then(async ({ message }) => {
              toast.warning(message);
            })
            .finally(() => handleCloseModal())
        }
        confirmCaption="Mute"
      />,
    );
    setIsModalOpen(true);
  };

  const handleUnmuteUser = (uuid: string) => {
    setIsModalOpen(false);
    setModal(
      <ConfirmModal
        icon={<UnmuteUserIcon />}
        title="Unmute user!"
        body="This action will unmute selected user, are you sure?"
        onToggle={handleCloseModal}
        onConfirm={() =>
          unmuteUser(uuid)
            .unwrap()
            .then(async ({ message }) => {
              toast.warning(message);
            })
            .finally(() => handleCloseModal())
        }
        confirmCaption="Unmute"
      />,
    );
    setIsModalOpen(true);
  };

  return (
    <BlockUserContext.Provider
      value={{
        handleBlockUser,
        isHandlingBlockUser: blockUserStatus.isLoading,
        handleMuteUser,
        isHandlingMuteUser: muteUserStatus.isLoading,
        handleUnmuteUser,
        isHandlingUnmuteUser: unmuteUserStatus.isLoading,
      }}
    >
      {children}
    </BlockUserContext.Provider>
  );
};

export default BlockUserProvider;
