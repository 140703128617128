import React, { PropsWithChildren, useEffect, useState } from "react";

import { useProfile } from "../profile";
import useWeb3Wallet, { disabledState } from "@/app/hooks/useWeb3Wallet";
import {
  useDisconnectWalletsMutation,
  usePrepareMessageToSignMutation,
  useTransferNftMutation,
  useVerifySignatureMutation,
} from "@/api/userApi";

import { handleError, hexer } from "@/helpers/crypto.helpers";
import ExternalWalletContext from "./ExternalWalletContext";
import { toast } from "@/utils/toast";
import { MAINNET } from "@/constants/crypto.constants";
import { IS_CRYPTO_WALLET_DISABLED } from "@/constants/app.constants";

const ExternalWalletProvider = ({ children }: PropsWithChildren) => {
  const { connectedExternalWallet, getUserProfile } = useProfile();

  const {
    handleConnectWallet,
    activeAccountAddress,
    isConnected,
    walletInfo,
    walletProvider,
    disconnect,
  } = IS_CRYPTO_WALLET_DISABLED ? disabledState : useWeb3Wallet();

  const [isExternalWalletActive, setIsExternalWalletActive] = useState<boolean>(false);
  const [isVerifyWalletLoading, setIsVerifyWalletLoading] = useState<boolean>(false);

  const [disconnectWallets] = useDisconnectWalletsMutation();
  const [prepareMessageToSign] = usePrepareMessageToSignMutation();
  const [verifySignature] = useVerifySignatureMutation();
  const [transferNft] = useTransferNftMutation();

  useEffect(() => {
    if (!isConnected && connectedExternalWallet?.isConnected) {
      handleDisconnectWallet(true);
    }
  }, [isConnected]);

  useEffect(() => {
    if (!activeAccountAddress || !connectedExternalWallet) return;

    setIsExternalWalletActive(
      activeAccountAddress.toLocaleLowerCase() ===
        connectedExternalWallet.address.toLocaleLowerCase(),
    );
  }, [activeAccountAddress, connectedExternalWallet]);

  const handleDisconnectWallet = async (updateUserProfile?: boolean) => {
    disconnect();

    const provider = walletInfo?.name || connectedExternalWallet?.provider;

    provider &&
      (await disconnectWallets({
        provider,
      }));

    updateUserProfile && (await getUserProfile());
  };

  const sendTransaction = async (
    scopeWalletAddress: string,
    amountWei: string,
  ): Promise<{
    fromAddress: string;
    toAddress: string;
    value: string;
    transactionHash: string;
  }> => {
    if (!activeAccountAddress || !walletProvider?.request || IS_CRYPTO_WALLET_DISABLED)
      throw new Error("No active account found");

    const transactionHash = await walletProvider.request({
      method: "eth_sendTransaction",
      params: [
        {
          from: activeAccountAddress,
          to: scopeWalletAddress,
          value: amountWei,
        },
      ],
    });

    return {
      transactionHash,
      fromAddress: activeAccountAddress,
      toAddress: scopeWalletAddress,
      value: amountWei,
    };
  };

  const signMessage = async (message: string) => {
    if (!walletProvider?.request) return;

    const signature = await walletProvider.request({
      method: "personal_sign",
      params: [hexer(message), activeAccountAddress],
    });

    return signature;
  };

  const handleVerifySignature = async (signature: string) => {
    const provider = walletInfo?.name || connectedExternalWallet?.provider;

    if (!provider) return;

    verifySignature({ provider, signature })
      .unwrap()
      .then(async ({ isNew }) => {
        await getUserProfile(true);

        if (isNew) {
          toast.success("Wallet successfully connected");
        }
      })
      .catch(async (error) => {
        console.error("Error verify signature:", error);
        toast.error(handleError(error));
        setIsVerifyWalletLoading(false);
        await handleDisconnectWallet();
      });
  };

  const handleVerifyWallet = async () => {
    if (IS_CRYPTO_WALLET_DISABLED || !walletProvider) return;

    setIsVerifyWalletLoading(true);

    const response = await prepareMessageToSign({})
      .unwrap()
      .catch(async () => {
        handleDisconnectWallet();
        setIsVerifyWalletLoading(false);
        return;
      });

    signMessage(response.message)
      .then((signature) => handleVerifySignature(signature))
      .catch(async (error) => {
        toast.error(handleError(error));
        setIsVerifyWalletLoading(false);
      });
  };

  const handleTransferNft = async () => {
    const provider = walletInfo?.name || connectedExternalWallet?.provider;

    if (!connectedExternalWallet?.address || !provider || IS_CRYPTO_WALLET_DISABLED) return;

    await transferNft({
      provider,
      externalWalletAddress: connectedExternalWallet.address,
    })
      .unwrap()
      .catch((error) => {
        console.log(error);
        toast.error(handleError(error));
      });
  };

  const addChain = async () => {
    if (!walletProvider?.request) return;

    const params = [MAINNET];

    try {
      await walletProvider.request({ method: "wallet_addEthereumChain", params });
    } catch (error: any) {
      console.error("Failed to add Avlanche chain:", error);
      toast.error(handleError(error));
    }
  };

  return (
    <ExternalWalletContext.Provider
      value={{
        isConnected,
        isExternalWalletActive,
        isVerifyWalletLoading,
        activeAccountAddress,
        handleConnectWallet,
        handleDisconnectWallet,
        handleVerifyWallet,
        sendTransaction,
        handleTransferNft,
        addChain,
      }}
    >
      {children}
    </ExternalWalletContext.Provider>
  );
};

export default ExternalWalletProvider;
