import React, { useEffect, useState } from "react";
import { useProfile } from "@/providers/profile";

import Table from "@/components/UI/Table";
import ProfileAvatar from "../User/ProfileAvatar";
import { BlockUserIcon } from "@/components/UI/Icons";
import SearchInput from "../UI/SearchInput";

import { BlockedUserProfileType } from "@/types/user/profile.types";
import { ColumnType } from "@/components/UI/Table/Table.types";
import Button from "../UI/Button";
import ConfirmModal from "../UI/ConfirmModal";
import { useFullscreenModal } from "@/providers/fullscreenModal";

const BLOCKED_USERS_LIMIT = 10;

const BlockedUsers = () => {
  const {
    handleGetBlockedUsers,
    isBlockedUsersLoading,
    blockedUsers,
    blockedUsersTotal,
    handleUnblockUser: unblockUser,
  } = useProfile();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();

  const [skip, setSkip] = useState<number>(0);
  const [searchByName, setSearchByName] = useState<string>("");

  useEffect(() => {
    handleGetBlockedUsers({ skip, limit: BLOCKED_USERS_LIMIT, searchByName });
  }, [skip, searchByName]);

  const handleSearch = (searchByName: string) => {
    setSearchByName(searchByName);
  };

  const handleUnblockUser = async (uuid: string) => {
    setIsModalOpen(false);
    setModal(
      <ConfirmModal
        title="Unblock user!"
        body="This action will unblock selected user, are you sure?"
        onToggle={handleCloseModal}
        onConfirm={() => unblockUser(uuid).finally(() => handleCloseModal())}
        confirmCaption="Unblock"
      />,
    );
    setIsModalOpen(true);
  };

  const columns: ColumnType<BlockedUserProfileType>[] = [
    { key: "index", title: "#" },
    {
      key: "fullName",
      title: "Name",
      render: (profile) => (
        <div className="flex items-center space-x-3">
          <ProfileAvatar profile={profile} />
          <div className="font-bold">{profile.fullName}</div>
        </div>
      ),
    },
    {
      key: null,
      title: "Actions",
      render: (profile) => (
        <Button className="btn btn-primary btn-sm" onClick={() => handleUnblockUser(profile.uuid)}>
          Unblock
        </Button>
      ),
    },
  ];

  return (
    <div className="w-full h-full xl:scrollbar-hidden">
      <Table
        data={blockedUsers}
        columns={columns}
        filters={
          <SearchInput
            onSearch={handleSearch}
            placeholder="Search by name"
            containerClassName="mobile:w-full"
          />
        }
        isLoading={isBlockedUsersLoading}
        noRecordsIcon={<BlockUserIcon />}
        noRecordsText="You didn't block any users"
        total={blockedUsersTotal}
        rowsPerPage={BLOCKED_USERS_LIMIT}
        setSkip={setSkip}
        pagination
      />
    </div>
  );
};

export default BlockedUsers;
