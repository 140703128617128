import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppNavigate } from "@/app/hooks";

import ProshopProvider from "@/providers/ProshopProvider";
import AthletesSection from "@/components/ProShop/AthletesSection";

const Proshop = () => {
  const { playerUuid } = useParams();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (playerUuid) {
      navigate(`/fan-club/${playerUuid}`, { replace: true });
    }
  }, [playerUuid]);

  return (
    <ProshopProvider>
      <div className="w-full text-left px-4 py-6 xl:max-w-[1366px] m-auto xl:my-8">
        <div className="flex flex-col items-start xl:mt-12">
          <span
            className="text-white text-caps !font-huben text-16 xl:text-[32px]"
            data-testid="proshop-title"
          >
            Athlete Pro Shop
          </span>
          <p className="text-body text-16 font-regular py-2 xl:pb-4">
            Welcome to the Athlete Pro Shop, here you can purchase cards directly from athletes.
          </p>
        </div>
        <div className="w-full max-w-[1440px]">
          <AthletesSection />
        </div>
      </div>
    </ProshopProvider>
  );
};

export default Proshop;
