import React, { useEffect } from "react";

import { useGeneralUi } from "@/providers/generalUi";

import AppLink from "@/components/AppLink";
import Container from "@/components/UI/Container";
import { ShareIcon } from "@/components/UI/Icons";
import MobileHeader from "@/components/UI/MobileHeader";

const InstallPWAInstructions = () => {
  const { setHeader } = useGeneralUi();

  useEffect(() => {
    setHeader(<MobileHeader label="Install App" handleBackClick={() => window.history.back()} />);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full h-full gap-8">
      <AppLink className="w-3/4" to="/">
        <img src="/img/logos/scope_logo.png" />
      </AppLink>
      <span className="font-huben text-lg tracking-wider uppercase">Install Scope</span>

      <Container className="fixed bottom-0 p-6 w-full flex flex-col rounded-b-none">
        <div className="flex flex-col">
          <span className="text-body text-lg">Install The Scope App</span>
        </div>
        <div className="flex flex-col w-full text-body text-sm gap-4 text-start">
          <div className="flex items-center w-full text-start">
            1. Tap on
            <ShareIcon />
            from browser menu.
          </div>
          <div className="flex items-center w-full text-start gap-1">
            2. Scroll down and select
            <span className="badge badge-neutral rounded-md p-1">Add to Home Screen</span>
          </div>
          <div className="flex items-center w-full text-start gap-1">
            3. Look for the{" "}
            <img className="h-10 w-10 rounded-lg" src="icons/icon-192.png" alt="scope icon" />
            icon on your home screen.
          </div>
        </div>
      </Container>
    </div>
  );
};

export default InstallPWAInstructions;
