import { MutableRefObject, createContext, useContext } from "react";

import {
  ScopeChatGroupedMessages,
  ScopeChatMessage,
  ScopeChatMessageTypes,
  ScopeChatPollPayload,
  ScopeChatSearchPayload,
  ScopeChatSearchTypes,
  ScopeChatSidebarElements,
  ScopeChatThread,
  ScopeChatUserMetadata,
} from "@/types/scopeChat/scopeChat.types";

interface ChatContextType {
  isScopeManager: boolean;

  // Common
  messages: ScopeChatMessage[];
  messagesCount: number;
  isMessagesLoading: boolean;
  groupedMessages: ScopeChatGroupedMessages;
  isSendingMessage: boolean;
  isHandlingReaction: boolean;
  isUpdatingMessage: boolean;
  isHandlingPin: boolean;
  isHandlingVotePoll: boolean;
  isMuted: boolean;
  isUploadInProgress: boolean;
  handleScrollToBottom: boolean;
  containerRef: MutableRefObject<HTMLDivElement>;
  sidebarView: ScopeChatSidebarElements | undefined;
  channelId?: string;
  handleSendMessage: ({
    content,
    type,
    parentId,
    pollPayload,
    mediaFiles,
  }: {
    content: string;
    type: ScopeChatMessageTypes;
    parentId?: string;
    pollPayload?: ScopeChatPollPayload;
    mediaFiles?: { uuid: string; file: File }[];
    attachmentAspectRatio?: number;
  }) => void;
  handleMessageReaction: (messageId: string, reaction: string) => void;
  handleMessageUpdate: (messageId: string, values: Partial<ScopeChatMessage>) => void;
  handleMessagePin: (messageId: string, isPinned: boolean) => void;
  handleMessageDelete: (messageId: string) => void;
  handleVotePoll: (messageId: string, option: number) => void;
  setUserMetadata: (metadata: ScopeChatUserMetadata) => void;
  handleMessagesScroll: (containerRef: MutableRefObject<HTMLDivElement>) => Promise<void>;
  setHandleScrollToBottom: (value: boolean) => void;
  setSidebarView: (value: ScopeChatSidebarElements | undefined) => void;
  handleScrollToMessage: (messageId: string) => Promise<void>;
  uploadProgress: { [attachmentUuid: string]: number };

  // Search
  suggestions: { [type: string]: any[] };
  searchResults: ScopeChatGroupedMessages;
  searchResultsCount: number;
  isHandlingSearch: boolean;
  setIsSearchResultsVisible: (value: boolean) => void;
  handleSearch: (searchPayload: ScopeChatSearchPayload) => void;
  getSuggestions: (type: ScopeChatSearchTypes, prefix: string) => void;
  handleShowMessage: (message: ScopeChatMessage) => void;

  // Threads
  thread: ScopeChatThread;
  isHandlingThread: boolean;
  handleShowThread: (threadMessageId: string) => void;
  handleGetThread: (messageId: string) => void;
  setIsThreadVisible: (value: boolean) => void;
  handleThreadScroll: (containerRef: MutableRefObject<HTMLDivElement>) => Promise<void>;

  // Pinned messages
  pinnedMessages: ScopeChatGroupedMessages;
  isHandlingPinnedMessages: boolean;
  getPinnedMessages: () => void;
  handleShowPinnedMessages: () => void;
  setIsPinnedMessagesVisible: (value: boolean) => void;
}

const ScopeChatContext = createContext<ChatContextType | undefined>(undefined);

export function useScopeChat(): ChatContextType {
  const context = useContext(ScopeChatContext);

  if (!context) {
    throw new Error("useScopeChat must be used within a ScopeChatProvider");
  }

  return context;
}

export default ScopeChatContext;
