import React from "react";
import { useProfile } from "@/providers/profile";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import useWindowDimensions from "@/app/hooks/useWindowDimensions";

import ProfileAvatar from "@/components/User/ProfileAvatar";

import { PlayerProfileType } from "@/types/player/player.types";

import { CaretIcon } from "@/components/UI/Icons";
import {
  selectIsMobilePlayerProfileSelectorOpen,
  setIsMobilePlayerProfileSelectorOpen,
} from "@/reducers/ui.slice";
import { UserProfileType } from "@/types/user/profile.types";

const PlayerCard = ({
  profile,
  name,
  active,
  hideSelector,
}: {
  profile: PlayerProfileType | UserProfileType;
  name: string;
  active?: boolean;
  hideSelector?: boolean;
}) => {
  const {
    windowDimensions: { width },
  } = useWindowDimensions();

  return (
    <div className="relative flex items-center w-full cursor-pointer gap-4">
      <ProfileAvatar profile={profile} imageClass="!w-8 !h-8" />
      <span
        className="w-full text-body text-14 font-medium text-start !leading-[16px] truncate block"
        style={{
          width: `${(width * 0.45).toFixed(0)}px`,
        }}
      >
        {name}
      </span>
      {!hideSelector && (
        <div className="absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2 border border-neutral-white-40 rounded-full w-5 h-5 p-1">
          {active && <div className="bg-primary-500 rounded-full w-full h-full" />}
        </div>
      )}
    </div>
  );
};

const MobilePlayerSelector = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const {
    userProfile,
    managedPlayerProfiles,
    managedPlayerProfile,
    setManagedPlayerProfile,
    clearManagedPlayerProfile,
  } = useProfile();

  const isMobilePlayerProfileSelectorOpen = useAppSelector(selectIsMobilePlayerProfileSelectorOpen);

  const handlePlayerProfileClick = async (playerUuid: string, playerUrlAlias: string | null) => {
    dispatch(setIsMobilePlayerProfileSelectorOpen(false));
    await setManagedPlayerProfile(playerUuid);
    navigate(`/fan-club/${playerUrlAlias || playerUuid}`);
  };

  const handleClearProfile = () => {
    dispatch(setIsMobilePlayerProfileSelectorOpen(false));
    clearManagedPlayerProfile();
  };

  return (
    <div className="relative flex flex-col w-full z-10 border-bottom border-top hover:bg-neutral-white-5">
      <div
        className="flex w-full justify-between items-center px-4 min-h-[56px] hover:bg-neutral-white-5"
        onClick={() =>
          dispatch(setIsMobilePlayerProfileSelectorOpen(!isMobilePlayerProfileSelectorOpen))
        }
      >
        {managedPlayerProfile ? (
          <PlayerCard
            profile={managedPlayerProfile}
            name={managedPlayerProfile.name}
            hideSelector
          />
        ) : (
          <span className="text-caps text-13 font-heavy text-white">Select athlete profile</span>
        )}
        <CaretIcon className={isMobilePlayerProfileSelectorOpen ? "rotate-180" : ""} />
      </div>
      {isMobilePlayerProfileSelectorOpen && userProfile && (
        <div className="border-top">
          <div
            className="flex justify-between items-center w-full hover:bg-neutral-white-5 py-3 px-4"
            onClick={handleClearProfile}
          >
            <PlayerCard
              profile={userProfile}
              name={userProfile?.fullName}
              active={!managedPlayerProfile?.uuid}
            />
          </div>
          {managedPlayerProfiles.map((player: PlayerProfileType) => (
            <div
              key={player.uuid}
              className="flex justify-between items-center w-full hover:bg-neutral-white-5 py-3 px-4"
              onClick={() => handlePlayerProfileClick(player.uuid, player.urlAlias)}
            >
              <PlayerCard
                profile={player}
                name={player.name}
                active={managedPlayerProfile?.uuid === player.uuid}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobilePlayerSelector;
