import React from "react";
import { useFanClub } from "@/providers/fanClub";

import ReactCountryFlag from "react-country-flag";
import ProfileAvatar from "@/components/User/ProfileAvatar";
import CardView from "../../Common/CardView";
import AthleteStat from "../Common/AthleteStat";
import { GradientLockIcon } from "@/components/UI/Icons";

import { getCountryByCode } from "@/helpers/country.helpers";
import { getAgeFromBirthDate } from "@/helpers/date.helpers";
import { getHeightFt } from "@/helpers/player.helpers";
import { Country } from "@/types/countries.enum";

const AthleteInfoCard = () => {
  const { isMember, playerProfile } = useFanClub();

  const country = getCountryByCode(playerProfile?.nationality || "");

  return (
    <CardView containerClassName="p-6 select-none">
      <div
        className={`w-full h-full flex items-center justify-between ${!isMember ? "pr-10" : ""}`}
      >
        <div className="flex flex-col justify-between gap-[1vh]">
          <ProfileAvatar
            imageClass="w-[7vh] h-[7vh] max-w-[64px] max-h-[64px]"
            profile={playerProfile}
          />
          <div className="flex flex-col">
            <h6 className="text-heading font-medium text-start">{playerProfile.name}</h6>
          </div>
          <div className="flex items-center gap-2">
            {country && (
              <ReactCountryFlag
                className="!w-5 rounded-sm"
                title={country}
                countryCode={Country[country as keyof typeof Country]}
                svg
              />
            )}
            <span className="text-body text-14">{playerProfile.nationality}</span>
          </div>
        </div>
        <div className="flex flex-col w-1/2 h-full justify-between">
          <AthleteStat title="Members" value={playerProfile.membersCount} />
          <AthleteStat title="Age" value={getAgeFromBirthDate(playerProfile.birthday)} />
          <AthleteStat title="Height" value={getHeightFt(playerProfile?.heightCm)} />
          <AthleteStat title="Plays" value={playerProfile.activeHand.toUpperCase()} />
        </div>
      </div>
      {!isMember && <GradientLockIcon className="absolute top-3 right-3" />}
    </CardView>
  );
};

export default AthleteInfoCard;
