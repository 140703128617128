import { useClickOutside } from "@/app/hooks";
import React, { InputHTMLAttributes, ReactNode, useRef } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  containerClassName?: string;
}

const Input = ({ leftAdornment, rightAdornment, containerClassName, ...props }: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(document.createElement("input"));
  const containerRef = useRef<HTMLDivElement>(document.createElement("div"));

  useClickOutside(containerRef, () => containerRef.current.classList.toggle("border-white"));

  return (
    <div
      ref={containerRef}
      className={`cursor-text flex w-full items-center rounded-full py-1 pl-4 pr-3 gap-4 bg-neutral-white-5 border border-neutral-white-12 ${
        containerClassName ?? ""
      }`}
      onClick={() => {
        inputRef.current.focus();
        containerRef.current.classList.add("border-white");
      }}
    >
      {leftAdornment}
      <input
        ref={inputRef}
        className="bg-transparent focus:outline-none w-full text-body text-14 font-medium text-neutral-white-pure-white"
        {...props}
      />
      {rightAdornment}
    </div>
  );
};

export default Input;
