import React, { useEffect, useState } from "react";

import { BlockedUserProfileType, UserProfileType } from "@/types/user/profile.types";

import { PlayerProfileType } from "@/types/player/player.types";
import { ScopeIcon } from "../UI/Icons";
import { getImageSource } from "@/helpers/thumbnails.helpers";
import { batch } from "react-redux";
import { DEFAULT_PROFILE_URL } from "@/constants/app.constants";
import { isPlayerProfileType, isUserProfileType } from "@/helpers/profile.helpers";

const ProfileAvatar = ({
  profile,
  scopeProfile,
  containerClass,
  imageClass,
}: {
  profile?: PlayerProfileType | UserProfileType | BlockedUserProfileType;
  scopeProfile?: boolean;
  containerClass?: string;
  imageClass?: string;
}) => {
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [placeholder, setPlaceholder] = useState<string>("AP");

  useEffect(() => {
    if (isUserProfileType(profile)) {
      batch(() => {
        const firstLetter = profile.fullName?.charAt(0) || "U";
        const secondLetter = profile.fullName?.charAt(1) || "P";
        setPlaceholder(`${firstLetter.toUpperCase()}${secondLetter.toUpperCase()}`);
        setAvatarUrl(
          getImageSource(profile.presignedUrlForAvatarDownload || "", profile.thumbnails, "lg"),
        );
      });
    } else if (isPlayerProfileType(profile)) {
      batch(() => {
        setPlaceholder("AP");
        setAvatarUrl(getImageSource(profile.image || "", profile.thumbnails));
      });
    }
  }, [profile]);

  return (
    <div className={`avatar ${!avatarUrl ? "placeholder" : ""} ${containerClass ?? ""}`}>
      {avatarUrl ? (
        <figure className="m-0 p-0">
          <div
            className={`w-12 h-12 rounded-full overflow-hidden ${imageClass}`}
            style={
              isPlayerProfileType(profile) && profile.backgroundColors
                ? {
                    background: `linear-gradient(to right, ${
                      profile.backgroundColors.primary || "#ecc1a9"
                    }, ${profile.backgroundColors.secondary || "#9ecfbb"})`,
                  }
                : {}
            }
          >
            <img
              src={avatarUrl}
              onError={() => setAvatarUrl(DEFAULT_PROFILE_URL)}
              data-testid="user-avatar-img"
            />
          </div>
        </figure>
      ) : (
        <div
          className={`bg-grayscale-900 text-neutral-content rounded-full w-12 h-12 m-0 p-0 ${imageClass}`}
        >
          {scopeProfile ? (
            <ScopeIcon className="w-14 h-14" />
          ) : (
            <span className="text-xl" data-testid="user-avatar-text">
              {placeholder}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
