import React from "react";

import { TableRowCell } from "./TableRowCell";
import { RowProps } from "./Table.types";

export const TableRow = <T,>({ data, columns, className, onRowClick }: RowProps<T>) => {
  const rowClass = onRowClick ? "cursor-pointer" : "";

  return (
    <>
      {data.map((item: { index: number } & any, itemIndex) => {
        const additionalClass = typeof className === "function" ? className(item) : className;

        return (
          <tr
            key={`table-body-${itemIndex}`}
            className={`${rowClass} ${additionalClass ?? ""}`}
            onClick={onRowClick && (() => onRowClick(item))}
          >
            {columns.map((column, columnIndex) => (
              <TableRowCell
                key={`table-row-cell-${columnIndex}`}
                item={{ ...item, index: itemIndex + 1 }}
                column={column}
              />
            ))}
          </tr>
        );
      })}
    </>
  );
};
