import React from "react";

import { useGeneralUi } from "@/providers/generalUi";
import { useFanClub } from "@/providers/fanClub";

import Feed from "../Feed/Feed";
import SectionView from "../../Common/SectionView";
import Button from "@/components/UI/Button";
import { CloseIcon } from "@/components/UI/Icons";
import Placeholder from "../../Common/Placeholder";

import { ViewTypes } from "@/types/fanClub/fanClub.types";

const StreamWrapper = () => {
  const { isMember, isAthleteFeedAllowed, playerProfile } = useFanClub();

  return isMember && isAthleteFeedAllowed ? (
    <Feed />
  ) : (
    <div className="flex w-full h-full justify-center items-center">
      <Placeholder text={`Chat with ${playerProfile.name}`} />
    </div>
  );
};
const FeedView = () => {
  const { isMobile } = useGeneralUi();
  const { setCurrentView } = useFanClub();

  return isMobile ? (
    <>
      <StreamWrapper />
    </>
  ) : (
    <SectionView
      title="Athlete Feed"
      bodyClassName="overflow-hidden"
      containerClassName="2xl:max-w-[778px]"
      titleAction={
        <Button
          className="btn btn-ghost btn-square btn-sm rounded-md"
          onClick={() => setCurrentView(ViewTypes.CARDS)}
        >
          <CloseIcon />
        </Button>
      }
    >
      <StreamWrapper />
    </SectionView>
  );
};

export default FeedView;
