import { createContext, useContext } from "react";
import {
  AllowedUpdateUserProfileType,
  BlockedUserProfileType,
  UserProfileType,
} from "@/types/user/profile.types";
import { PlayerProfileType } from "@/types/player/player.types";
import { NotificationSettingsPayload } from "@/types/notification/notification.types";
import { TradeCard } from "@/types/card/cardUserList.types";
import { ExternalWallet } from "@/types/crypto/crypto.types";

interface ProfileContextType {
  authToken: string | null;
  isAuthorized: boolean;
  isManager: boolean;
  isNew: boolean;
  isEulaAgreed: boolean;
  isInstallAppInformed: boolean;
  isAdmin: boolean;
  userProfile: UserProfileType | undefined;
  isUserProfileLoading: boolean;
  managedPlayerProfile: PlayerProfileType | undefined;
  isPlayerProfileLoading: boolean;
  isUserCardsLoading: boolean;
  isUserProfileUpdating: boolean;
  isUserProfileDeleting: boolean;
  isHandlingUnblockUser: boolean;
  isBlockedUsersLoading: boolean;
  userCards: TradeCard[];
  blockedUsers: BlockedUserProfileType[];
  blockedUsersTotal: number;
  managedPlayerProfiles: PlayerProfileType[];
  setUserAvatarStatus: { isLoading: boolean; isSuccess: boolean; isError: boolean };
  removeUserAvatarStatus: { isLoading: boolean; isSuccess: boolean; isError: boolean };
  isCheckForFreeCardLoading: boolean;
  connectedExternalWallet?: ExternalWallet;
  getUserProfile: (withAddons?: boolean) => Promise<UserProfileType>;
  getUserCards: () => void;
  updateUserProfile: (values: AllowedUpdateUserProfileType) => Promise<any>;
  deleteUserProfile: () => Promise<void>;
  setUserAvatar: (formData: FormData) => Promise<void>;
  removeUserAvatar: () => void;
  setManagedPlayerProfile: (playerUuid: string) => Promise<void>;
  clearManagedPlayerProfile: () => void;
  logOut: () => void;
  updateNotificationSettings: (values: NotificationSettingsPayload) => Promise<any>;
  handleExplore: (isNew: boolean, isEulaAgreed: boolean) => void;
  handleUnblockUser: (uuid: string) => Promise<void>;
  handleGetBlockedUsers: (params: {
    skip?: number;
    limit?: number;
    searchByName?: string;
  }) => Promise<void>;
  handleMuteUser: () => void;
  handleInstallAppInformed: () => void;
}

export const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

export function useProfile(): ProfileContextType {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error("useProfile must be used within a ProfileProvider");
  }

  return context;
}
