import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./common";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + "notification-center/fcm/";

export const fcmApi = createApi({
  reducerPath: "fcmApi",
  baseQuery: baseQuery(BASE_URL),
  endpoints: (builder) => ({
    subscribe: builder.mutation({
      query: (body: { userUuid: string; token: string }) => ({
        url: "/subscribe",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSubscribeMutation } = fcmApi;
