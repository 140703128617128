import { RootState } from "@/app/store";
import { createSlice } from "@reduxjs/toolkit";
import {
  ScopeChatGroupedMessages,
  ScopeChatMessage,
  ScopeChatSidebarElements,
  ScopeChatSuggestionTypes,
  ScopeChatThread,
} from "@/types/scopeChat/scopeChat.types";

export const scopeChatSlice = createSlice({
  name: "scopeChat",
  initialState: {
    messages: [],
    messagesCount: 0,
    groupedMessages: {},
    skip: 0,
    hasNextPage: true,
    lastMessageDate: undefined,
    isLoading: true,
    sidebarView: undefined,

    // Search
    suggestions: {},
    searchResults: {},
    searchResultsCount: 0,

    // Thread
    thread: {},

    // Pinned messages
    pinnedMessages: {},

    // Init
    messageId: undefined,
  },
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
      state.messagesCount = action.payload.length;
    },
    setGroupedMessages: (state, action) => {
      state.groupedMessages = action.payload;
    },
    setSkip: (state, action) => {
      state.skip = action.payload;
    },
    setHasNextPage: (state, action) => {
      state.hasNextPage = action.payload;
    },
    setLastMessageDate: (state, action) => {
      state.lastMessageDate = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSidebarView: (state, action) => {
      state.sidebarView = action.payload;
    },
    // Search
    setSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload.groupedMessages;
      state.searchResultsCount = action.payload.count;
    },
    // Thread
    setThread: (state, action) => {
      state.thread = action.payload;
    },
    // Pinned messages
    setPinnedMessages: (state, action) => {
      state.pinnedMessages = action.payload;
    },

    setMessageId: (state, action) => {
      state.messageId = action.payload;
    },
  },
});

export const {
  setMessages,
  setGroupedMessages,
  setSkip,
  setHasNextPage,
  setLastMessageDate,
  setIsLoading,
  setSidebarView,
  // Search
  setSuggestions,
  setSearchResults,
  // Thread
  setThread,
  // Pinned messages
  setPinnedMessages,

  setMessageId,
} = scopeChatSlice.actions;

export const selectMessages = (state: RootState): ScopeChatMessage[] => state.scopeChat.messages;
export const selectMessagesCount = (state: RootState): number => state.scopeChat.messagesCount;
export const selectGroupedMessages = (state: RootState): ScopeChatGroupedMessages =>
  state.scopeChat.groupedMessages;
export const selectSkip = (state: RootState): number => state.scopeChat.skip;
export const selectHasNextPage = (state: RootState): boolean => state.scopeChat.hasNextPage;
export const selectLastMessageDate = (state: RootState): string | undefined =>
  state.scopeChat.lastMessageDate;
export const selectIsLoading = (state: RootState): boolean => state.scopeChat.isLoading;
export const selectSidebarView = (state: RootState): ScopeChatSidebarElements | undefined =>
  state.scopeChat.sidebarView;

// Search
export const selectSuggestions = (state: RootState): ScopeChatSuggestionTypes =>
  state.scopeChat.suggestions;
export const selectSearchResults = (state: RootState): ScopeChatGroupedMessages =>
  state.scopeChat.searchResults;
export const selectSearchResultsCount = (state: RootState): number =>
  state.scopeChat.searchResultsCount;

// Thread
export const selectThread = (state: RootState): ScopeChatThread => state.scopeChat.thread;

// Pinned messages
export const selectPinnedMessages = (state: RootState): ScopeChatGroupedMessages =>
  state.scopeChat.pinnedMessages;

export const selectMessageId = (state: RootState): string | undefined => state.scopeChat.messageId;

export default scopeChatSlice.reducer;
