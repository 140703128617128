import React, { useEffect } from "react";
import { useNotifications } from "@/app/hooks/useNotifications";

import EmptySection from "@/components/UI/EmptySection";
import { InboxIcon } from "@/components/UI/Icons";
import NotificationItem from "@/components/NotificationCenter/NotificationItem";

import {
  GroupedNotificationsType,
  NotificationType,
} from "@/types/notification/notification.types";
import { objectKeyToReadableString } from "@/helpers/string.helpers";

const NotificationCenter = () => {
  const { notifications, markAllAsRead } = useNotifications() || {};

  useEffect(() => {
    markAllAsRead();
  }, []);

  return (
    <div className="w-full text-left px-5 notification-center xl:px-16 xl:max-w-5xl m-auto my-8">
      <div className="flex flex-col items-start w-full gap-2">
        {notifications ? (
          Object.keys(notifications)
            .filter((key) => notifications[key as keyof GroupedNotificationsType].length)
            .map((key: string) => (
              <React.Fragment key={key}>
                <span className="uppercase my-2 text-white">{objectKeyToReadableString(key)}</span>
                {notifications[key as keyof GroupedNotificationsType].map(
                  (notification: NotificationType, index: number) => (
                    <NotificationItem key={index} notification={notification} fullView />
                  ),
                )}
              </React.Fragment>
            ))
        ) : (
          <EmptySection
            icon={<InboxIcon />}
            title="No Activity"
            text="You have no activity at the moment, but we can totally change that."
          />
        )}
      </div>
    </div>
  );
};

export default NotificationCenter;
