import PinnedMessagesView from "@/components/ScopeBlog/ScopeChat/Sidebar/PinnedMessagesView";
import SearchView from "@/components/ScopeBlog/ScopeChat/Sidebar/SearchView";
import ThreadView from "@/components/ScopeBlog/ScopeChat/Sidebar/ThreadView";
import { ScopeChatSidebarElements, ScopeChatUserTypes } from "@/types/scopeChat/scopeChat.types";

export const SCOPE_CHAT_PUBLIC_MESSAGES_COUNT = 50;
export const SCOPE_CHAT_INPUT_CONTAINER_HEIGHT = 48;
export const SCOPE_CHAT_TEXTAREA_HEIGHT = 22;
export const SCOPE_CHAT_MEDIA_HEIGHT_LIMIT = 500;
export const SCOPE_CHAT_MOBILE_MESSAGE_OFFSET = 80;
export const SCOPE_CHAT_MESSAGE_OFFSET = 68;

export const SCOPE_CHAT_USER_TYPE_COLORS = {
  [ScopeChatUserTypes.ADMIN]: "#FF4536",
  [ScopeChatUserTypes.ATHLETE]: "#75FF5F",
  [ScopeChatUserTypes.CARD_HOLDER]: "#E338FF",
  [ScopeChatUserTypes.REGULAR]: "none",
};

export const SCOPE_CHAT_LIKE_REACTION = "❤️";

export const SCOPE_CHAT_REACTION_INSTANCES_LIMIT = 99;

export const SCOPE_CHAT_MESSAGE_LENGTH = 300;
export const SCOPE_CHAT_POLL_OPTION_LENGTH = 50;

export const SCOPE_CHAT_SIDEBAR_VIEWS = {
  [ScopeChatSidebarElements.SEARCH]: SearchView,
  [ScopeChatSidebarElements.THREAD]: ThreadView,
  [ScopeChatSidebarElements.PINNED_MESSAGES]: PinnedMessagesView,
};

export const SCOPE_CHAT_CHANNEL_ID = "scope_chat_channel";
