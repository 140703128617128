import React, { useLayoutEffect } from "react";

import { useLocation } from "react-router-dom";
import { useAppSelector, useAppNavigate } from "@/app/hooks";
import { useFanClub } from "@/providers/fanClub";
import { useGeneralUi } from "@/providers/generalUi";

import PremiumContentCard from "./Cards/PremiumContentCard";
import FeedCard from "./Cards/FeedCard";
import ChatCard from "./Cards/ChatCard";
import MobileViewSelector from "./Mobile/MobileViewSelector";
import MobileHeader from "@/components/UI/MobileHeader";

import { FAN_CLUB_VIEWS } from "@/constants/fanClub.constants";
import { selectCurrentView } from "@/reducers/fanClub.slice";
import { selectIsCommentsOpen } from "@/reducers/mobileFanClubUi.slice";

const MobileAthleteFanClub = () => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const { isMember } = useFanClub();
  const { setHeader } = useGeneralUi();

  const currentView = useAppSelector(selectCurrentView);
  const isCommentsOpen = useAppSelector(selectIsCommentsOpen);

  const CurrentView = FAN_CLUB_VIEWS[currentView].element;

  useLayoutEffect(() => {
    setHeader(<MobileHeader label="Fan Club" handleBackClick={() => navigate("/")} />);
  }, [location?.key]);

  return (
    <div className="flex flex-col w-full h-full gap-4">
      {isMember && !isCommentsOpen && <MobileViewSelector />}
      <div className="flex flex-col items-center justify-between gap-4 w-full h-full overflow-y-scroll">
        <CurrentView />
        {!isMember ? (
          <>
            <PremiumContentCard />
            <FeedCard />
            <ChatCard />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default MobileAthleteFanClub;
