import React, { useEffect, useState } from "react";

interface PageProps {
  className: string;
  label: string;
  pageNumber: number;
  changeCurrentPage: (value: number) => void;
}

const Page = ({ className, label, pageNumber, changeCurrentPage }: PageProps) => {
  return (
    <li className={className} onClick={() => changeCurrentPage(pageNumber)}>
      <a>{label}</a>
    </li>
  );
};

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  changeCurrentPage: (value: number) => void;
  numberOfPagesNextToActivePage?: number;
}

const Pagination = ({
  currentPage,
  totalPages,
  changeCurrentPage,
  numberOfPagesNextToActivePage = 1,
}: PaginationProps) => {
  const delta = numberOfPagesNextToActivePage;
  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const pages = [];
  const pagesWithEllipsis = [];
  let ellipsis = undefined;
  let isEllipsisIncludes = false;

  for (let page = 1; page <= totalPages; page++) {
    if (page === 1 || page === totalPages || (page >= left && page < right)) {
      pages.push(page);
    }
  }

  for (const page of pages) {
    if (ellipsis && page - ellipsis !== 1) {
      pagesWithEllipsis.push(
        <li
          key={isEllipsisIncludes ? -1 : 0}
          className="cursor-default pointer-events-none px-3 py-2 leading-tight bg-grayscale-900 border-gray-700 hover:bg-grayscale-800 hover:text-white"
        >
          <a>...</a>
        </li>,
      );
      isEllipsisIncludes = true;
    }
    pagesWithEllipsis.push(
      <li
        key={page}
        className={`cursor-pointer px-3 py-2 leading-tight bg-grayscale-900 border-gray-700 hover:bg-grayscale-800 hover:text-white ${
          currentPage === page &&
          "!text-white !bg-primary-500 hover:bg-primary-500 cursor-default pointer-events-none"
        }`}
        onClick={(e) => {
          e.preventDefault();
          changeCurrentPage(page);
        }}
      >
        <a>{page}</a>
      </li>,
    );
    ellipsis = page;
  }

  return <>{pagesWithEllipsis}</>;
};

interface TablePaginationProps {
  total: number;
  rowsPerPage: number;
  setSkip: (value: number) => void;
  setLimit: (value: number) => void;
}

export const TablePagination = ({
  total,
  rowsPerPage,
  setSkip,
  setLimit,
}: TablePaginationProps) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    const skip = (page - 1) * rowsPerPage;
    setSkip(skip);
    setLimit(skip + rowsPerPage);
  };

  useEffect(() => {
    if (total) {
      const totalPages = Math.ceil(total / rowsPerPage);

      setTotalPages(totalPages);
    }
  }, [total]);

  return totalPages > 1 ? (
    <div className="flex w-full justify-end">
      <ul className="flex text-body text-14 font-regular text-grayscale-500">
        <Page
          className={`cursor-pointer px-3 py-2 ml-0 leading-tight rounded-l-lg bg-grayscale-900 border-gray-700 hover:bg-grayscale-800 hover:text-white ${
            currentPage === 1 && "cursor-default pointer-events-none"
          }`}
          label="Previous"
          pageNumber={currentPage - 1}
          changeCurrentPage={handleChangePage}
        />
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          changeCurrentPage={handleChangePage}
        />
        <Page
          className={`cursor-pointer px-3 py-2 ml-0 leading-tight rounded-r-lg bg-grayscale-900 border-gray-700 hover:bg-grayscale-800 hover:text-white ${
            currentPage === totalPages && "cursor-default pointer-events-none"
          }`}
          label="Next"
          pageNumber={currentPage + 1}
          changeCurrentPage={handleChangePage}
        />
      </ul>
    </div>
  ) : null;
};
