import React from "react";

import Spinner from "@/components/UI/Spinner";

const Loading = ({ blur = 12, className }: { blur?: number; className?: string }) => {
  return (
    <div
      className={`fixed ios-inset-0 flex w-full h-full justify-center items-center gap-4 z-[999999] backdrop-blur-lg ${
        className ?? ""
      }`}
      style={{
        backdropFilter: `blur(${blur}px) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)`,
      }}
    >
      <img className="navbar-logo" src="/img/logos/scope_logo.png" />
      <Spinner />
    </div>
  );
};

export default Loading;
