import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { GroupedNotificationsType } from "@/types/notification/notification.types";

export const EMPTY_NOTIFICATIONS = {
  latest: [],
  oldest: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notificationItems: EMPTY_NOTIFICATIONS,
    unreadedCount: 0,
    isNotificationModalOpen: false,
  },
  reducers: {
    setNotificationItems: (state, action) => {
      state.notificationItems = action.payload;
    },
    setUnreadedCount: (state, action) => {
      state.unreadedCount = action.payload;
    },
    setIsNotificationModalOpen: (state, action) => {
      state.isNotificationModalOpen = action.payload;
    },
  },
});

export const { setNotificationItems, setUnreadedCount, setIsNotificationModalOpen } =
  notificationsSlice.actions;

export const selectNotificationItems = (state: RootState): GroupedNotificationsType =>
  state.notifications.notificationItems;

export const selectUnreadedCount = (state: RootState): number => state.notifications.unreadedCount;

export const selectIsNotificationModalOpen = (state: RootState): boolean =>
  state.notifications.isNotificationModalOpen;

export default notificationsSlice.reducer;
