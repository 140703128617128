import React from "react";
import { useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import { MEMBERS_PAGE_LIMIT, usePlayer } from "@/app/hooks/usePlayer";

import EmptySection from "@/components/UI/EmptySection";
import ShowLessAnchor from "@/components/UI/ShowLessAnchor";
import { RosterIcon } from "@/components/UI/Icons";
import Table from "@/components/UI/Table";
import SearchInput from "@/components/UI/SearchInput";
import ProfileAvatar from "@/components/User/ProfileAvatar";

import { MemberProfileType } from "@/types/user/profile.types";

const Members = () => {
  const navigate = useAppNavigate();

  const {
    fetchMembers,
    members,
    setSkip,
    membersTotal,
    toggleReadOnly,
    isToggleReadOnlyLoading,
    membersSearchQuery,
    setSearchQuery,
  } = usePlayer();
  const { managedPlayerProfile } = useProfile();

  if (!managedPlayerProfile) {
    navigate("/dashboard");
  }

  const handleChangeIsMuted = (memberUuid: string) => {
    toggleReadOnly({ ownerUuid: managedPlayerProfile?.uuid || "", memberUuid }).then(() => {
      fetchMembers();
    });
  };

  const columns = [
    {
      key: null,
      title: "Name",
      render: (profile: MemberProfileType) => (
        <div className="flex items-center space-x-3">
          <ProfileAvatar profile={profile.user} />
          <div className="font-bold">{profile.user.fullName}</div>
        </div>
      ),
    },
    {
      key: null,
      title: "Username",
      render: (profile: MemberProfileType) => (
        <div className="font-bold">{profile.user.username}</div>
      ),
    },
    {
      key: null,
      title: "Email",
      render: (profile: MemberProfileType) => <div className="font-bold">{profile.user.email}</div>,
    },
    {
      key: null,
      title: "Country",
      render: (profile: MemberProfileType) => (
        <div className="font-bold">{profile.user.country}</div>
      ),
    },
    {
      key: null,
      title: "Muted",
      width: 0,
      render: (profile: MemberProfileType) => (
        <div className="flex w-full justify-center items-center">
          <input
            type="checkbox"
            className={`toggle toggle-primary ${isToggleReadOnlyLoading ? "loading" : ""}`}
            checked={profile.isMuted}
            onChange={() => handleChangeIsMuted(profile.uuid)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full text-left px-5 m-auto my-8">
      <div className="flex justify-between sm:p-1 w-full items-center my-12">
        <span className="text-title">Fan Club members</span>
        <ShowLessAnchor onClick={() => navigate("/dashboard")} />
      </div>
      <div className="w-full pb-4">
        <SearchInput
          value={membersSearchQuery}
          onSearch={setSearchQuery}
          containerClassName="sm:max-w-[260px]"
          placeholder="Search"
        />
      </div>
      {members?.length ? (
        <div className="flex flex-col w-full overflow-x-auto xl:scrollbar-hidden">
          <Table
            data={members}
            columns={columns}
            total={membersTotal}
            rowsPerPage={MEMBERS_PAGE_LIMIT}
            setSkip={setSkip}
            pagination
          />
        </div>
      ) : (
        <EmptySection
          icon={<RosterIcon />}
          title="Looks like you do not have Fan Club members yet."
          text="Wait, somebody will buy your member card."
        />
      )}
    </div>
  );
};

export default Members;
