import { useState } from "react";
import { useAddUploadMutation, useUploadChunkMutation } from "@/api/uploaderApi";

import { TargetTypes } from "@/types/uploader.types";

const CHUNK_SIZE = 1024 * 1024;

export const useUploader = () => {
  const [uploadProgress, setUploadProgress] = useState<{ [uuid: string]: number }>({});
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);

  const [addUpload] = useAddUploadMutation();
  const [uploadChunk] = useUploadChunkMutation();

  const uploadFile = async (targetType: TargetTypes, payload: { uuid: string; file: File }) => {
    const { file, uuid } = payload;

    if (!file) return;

    const upload = await addUpload({
      targetType,
      mimetype: file.type,
      numberOfChunks: Math.ceil(file.size / CHUNK_SIZE),
      chunkSize: CHUNK_SIZE.toString(),
      totalFileSize: file.size.toString(),
      targetUuid: uuid,
      extension: file.name.split(".").pop() || "_",
    });

    const { id: uploadId } = (upload as any).data;

    const fileSize = file.size;

    setUploadProgress((prev) => ({ ...prev, [uuid]: 0 }));

    for (let i = 0, chunkIndex = 0; i < fileSize; i += CHUNK_SIZE) {
      const data = new FormData();

      data.append("file", file.slice(i, i + CHUNK_SIZE), "file");

      await uploadChunk({ data, uploadId, chunkIndex: chunkIndex++ });

      setUploadProgress((prev) => ({
        ...prev,
        [uuid]: Math.ceil(((i + CHUNK_SIZE) / fileSize) * 100),
      }));

      if (i === 0) continue;
    }

    setUploadProgress((prev) => ({
      ...prev,
      [uuid]: 100,
    }));
  };

  const uploadFiles = async (
    files: {
      targetType: TargetTypes;
      payload: { uuid: string; file: File };
    }[],
  ) => {
    setIsUploadInProgress(true);

    await Promise.all(files.map((file) => uploadFile(file.targetType, file.payload)));

    setIsUploadInProgress(false);
  };

  return { uploadProgress, isUploadInProgress, setIsUploadInProgress, uploadFile, uploadFiles };
};
