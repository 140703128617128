import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGeneralUi } from "@/providers/generalUi";
import { useAppNavigate } from "@/app/hooks";

import SignUpForm from "@/components/SignUpForm";
import Button from "@/components/UI/Button";
import MobilePublicNavbar from "@/components/Page/Mobile/MobilePublicNavbar";
import PublicNavbar from "@/components/Page/PublicNavbar";
import AppLink from "@/components/AppLink";

const SignUp = () => {
  const [params] = useSearchParams();
  const navigate = useAppNavigate();

  const { setHeader } = useGeneralUi();

  const email = params.get("email");

  const handleSignInClick = () => {
    navigate("/login");
  };

  useEffect(() => {
    setHeader(null);
  }, []);

  return (
    <div className="flex flex-col xl:p-10 h-full">
      <MobilePublicNavbar>
        <Button onClick={handleSignInClick} className="text-caps text-13 font-medium text-white">
          Sign in
        </Button>
      </MobilePublicNavbar>
      <PublicNavbar>
        Already have an account?
        <AppLink to={"/login"} className="underline">
          Sign in
        </AppLink>
      </PublicNavbar>
      <div className="flex-1 flex w-full justify-center p-4 overflow-hidden overflow-y-scroll scrollbar-hidden">
        <SignUpForm email={email} />
      </div>
    </div>
  );
};

export default SignUp;
