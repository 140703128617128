import React from "react";

import MediaGrid from "@/components/UI/MediaGrid";
import MediaElement from "@/components/UI/MediaElement";

const MediaGridWrapper = ({ media, minified }: { media: any[]; minified?: boolean }) => {
  const mediaToShow = minified ? media.slice(0, 1) : media;

  return (
    <MediaGrid
      renderMediaComponents={(isFullscreen: boolean, activeIndex?: number) =>
        mediaToShow.map(
          ({ isDraft, mimetype, presignedUrl, thumbnails, videoThumbnails }, index) => (
            <MediaElement
              key={`media-${index}`}
              index={index}
              isDraft={isDraft}
              presignedUrl={presignedUrl}
              mimetype={mimetype}
              thumbnails={thumbnails}
              videoThumbnails={videoThumbnails}
              isFullscreen={isFullscreen}
              activeIndex={activeIndex}
            />
          ),
        )
      }
    />
  );
};

export default MediaGridWrapper;
