import Toast from "@/components/UI/Toast";
import React from "react";
import { toast as toastify } from "react-toastify";

export const toasty = (body: string, options?: any) => {
  options = {
    ...options,
    hideProgressBar: true,
    closeButton: false,
    position: toastify.POSITION.BOTTOM_RIGHT,
    className: "flex w-full !bg-grayscale-800 rounded-xl",
  };
  return toastify(<Toast body={body} {...options?.customToast} />, options);
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace toast {
  export const success = (body: string, options?: any) => {
    return toasty(body, { ...options, type: "success" });
  };

  export const info = (body: string, options?: any) => {
    return toasty(body, { ...options, type: "info" });
  };

  export const error = (body: string, options?: any) => {
    return toasty(body, { ...options, type: "error" });
  };

  export const warning = (body: string, options?: any) => {
    return toasty(body, { ...options, type: "warning" });
  };
}
