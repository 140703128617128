import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { Payment, PaymentMethods } from "@/types/payment/payment.types";
import { useFullscreenModal } from "./fullscreenModal";
import { toast } from "@/utils/toast";
import InfoModal from "@/components/UI/InfoModal";
import { CheckInACircleIcon, CreepyFaceIcon } from "@/components/UI/Icons";
import { PlayerCard } from "@/types/card/card.types";
import { usePayment } from "./payment";

interface PaymentFormContextType {
  tradeCard: PlayerCard;
  payment: Payment | undefined;
  paymentMethod: PaymentMethods | undefined;
  setPaymentMethod: (paymentMethod: PaymentMethods | undefined) => void;
  handleOnSuccess: (message?: string) => void;
  handleOnFailure: (message?: string) => void;
  isLoading: boolean;
}

const PaymentFormContext = createContext<PaymentFormContextType | undefined>(undefined);

export function usePaymentForm(): PaymentFormContextType {
  const context = useContext(PaymentFormContext);

  if (!context) {
    throw new Error("usePaymentForm must be used within a PaymentFormProvider");
  }

  return context;
}

const PaymentFormProvider = ({
  children,
  tradeCard,
}: { tradeCard: PlayerCard } & PropsWithChildren) => {
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { handlePreparePayment, payment, isLoading: isLoading } = usePayment();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>();

  const handleOnSuccess = (message?: string) => {
    toast.success("Payment is successfully completed!");

    setIsModalOpen(false);
    setModal(
      <InfoModal
        icon={<CheckInACircleIcon />}
        title="Blockchain transaction started!"
        body={message || "You will recieve notification on transaction complete..."}
        onToggle={handleCloseModal}
        buttons={[
          {
            onClick: handleCloseModal,
            caption: "Close",
          },
        ]}
      />,
    );
    setIsModalOpen(true);
  };

  const handleOnFailure = (message?: string) => {
    toast.error("Failed to process payment!");

    setIsModalOpen(false);
    setModal(
      <InfoModal
        icon={<CreepyFaceIcon width={48} height={48} />}
        title="Payment failed!"
        body={message || "An unexpected error occured during payment process..."}
        onToggle={handleCloseModal}
        buttons={[
          {
            onClick: handleCloseModal,
            caption: "Close",
          },
        ]}
      />,
    );
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!paymentMethod) return;

    handlePreparePayment(paymentMethod, tradeCard);
  }, [paymentMethod]);

  return (
    <PaymentFormContext.Provider
      value={{
        tradeCard,
        payment,
        paymentMethod,
        setPaymentMethod,
        handleOnSuccess,
        handleOnFailure,
        isLoading,
      }}
    >
      {children}
    </PaymentFormContext.Provider>
  );
};

export default PaymentFormProvider;
