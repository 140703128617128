import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector, useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";
import useIsTabActive from "@/app/hooks/useIsTabActive";

import Button from "@/components/UI/Button";
import CenterSpinner from "@/components/UI/CenterSpinner";
import { CloseIcon } from "@/components/UI/Icons";

import { selectPlayerProfiles } from "@/reducers/scopeBlog.slice";
import WebGL from "three/examples/jsm/capabilities/WebGL";
import { FanClubCard, PlayerCard } from "@/types/card/card.types";
import Card3D from "@/components/Card3D";
import { PlayerProfileType } from "@/types/player/player.types";

const CardViewer = () => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const doesAnyHistoryEntryExist = location.key !== "default";
  const { cardUuid } = useParams();
  const isTabActive = useIsTabActive();
  const isWebGLAvailable = WebGL.isWebGLAvailable();
  const { userProfile } = useProfile();

  const playerProfiles = useAppSelector(selectPlayerProfiles);

  const [tradeCard, setTradeCard] = useState<FanClubCard>();

  useEffect(() => {
    if (!cardUuid) return;

    let tradeCard: FanClubCard | PlayerCard | undefined;

    if (userProfile) {
      Object.values(userProfile.recentCardsByCardUuid).some((userCards: any) => {
        tradeCard = userCards?.[cardUuid];

        return !!tradeCard;
      });
    }

    if (!tradeCard) {
      playerProfiles.some((playerProfile: PlayerProfileType) => {
        tradeCard = playerProfile.tradeCards?.[cardUuid];

        return !!tradeCard;
      });
    }

    setTradeCard(tradeCard as FanClubCard);
  }, [playerProfiles]);

  return (
    <div className="fixed ios-inset-0 overflow-hidden z-[65]">
      <div className="absolute inset-0 flex flex-col items-center justify-between w-full h-full overflow-hidden">
        <div className="flex w-full justify-end items-center px-4 py-2 xl:px-[60px] xl:py-8 border-bottom !p-4 !border-none">
          <Button
            className="btn btn-ghost btn-square btn-sm rounded-md"
            onClick={() => (doesAnyHistoryEntryExist ? navigate(-1) : navigate("/"))}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex-1 flex xl:pt-10 w-full justify-center overflow-hidden overflow-y-scroll scrollbar-hidden p-0 xl:!p-6">
          {tradeCard ? (
            <div className="flex justify-center w-full h-full">
              {isWebGLAvailable ? (
                <Card3D
                  card={tradeCard.card}
                  color={tradeCard.color}
                  fullscreen
                  isActive={isTabActive}
                />
              ) : (
                <img
                  src={tradeCard.card.image}
                  className="w-full h-full object-contain rounded-2xl"
                />
              )}
            </div>
          ) : (
            <CenterSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardViewer;
