import React, { BaseSyntheticEvent, ReactNode, useCallback } from "react";
import { CloseIcon } from "./Icons";

export const ConfirmModal = ({
  onToggle,
  body,
  title,
  confirmCaption,
  cancelCaption,
  icon,
  onConfirm,
}: {
  onToggle: (isOpen: boolean) => void;
  title: string;
  body?: string;
  confirmCaption?: string;
  cancelCaption?: string;
  icon?: ReactNode;
  onConfirm: () => void;
}) => {
  const clickHandler = useCallback(
    (e: BaseSyntheticEvent) => {
      if (e.target.id === "confirmModal") {
        onToggle(false);
      }
    },
    [onToggle],
  );

  return (
    <>
      <div onClick={clickHandler} className="modal backdrop-blur-md modal-open">
        <div className="modal-box text-start bg-grayscale-800">
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              {icon && icon}
              <span className="text-body text-16 font-medium xl:text-18">{title}</span>
            </div>
            <button
              onClick={() => onToggle(false)}
              className="btn btn-sm btn-square btn-ghost p-0 m-0"
            >
              <CloseIcon />
            </button>
          </div>
          <p className="text-body text-14 font-regular text-neutral-white-70 py-4">{body}</p>

          <div className="modal-action ">
            <button
              onClick={() => onToggle(false)}
              className="btn btn-ghost rounded-full text-body text-14 font-bold uppercase !text-grayscale-500"
            >
              {cancelCaption ? cancelCaption : "Cancel"}
            </button>
            <button onClick={() => onConfirm()} className="btn btn-primary rounded-full px-5">
              {confirmCaption ? confirmCaption : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmModal;
