import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";

import Button from "@/components/UI/Button";

const PurchaseSuccess = () => {
  const navigate = useAppNavigate();
  const { state } = useLocation();
  const { getUserProfile } = useProfile();

  useEffect(() => {
    if (!state) {
      navigate("/");
    }

    return () => {
      getUserProfile();
    };
  }, [state]);

  const handleAccessFanClub = () => {
    const { playerUuid } = state || {};

    navigate(`/fan-club/${playerUuid}`);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-[600px] mobile:h-full text-left px-4 py-6 xl:px-10 mx-auto xl:mt-48">
      <div className="flex flex-col gap-10">
        <h4 className="xl:hidden text-heading font-medium text-center">Success!</h4>
        <h2 className="hidden xl:flex text-heading font-medium">Success!</h2>
        <span className="text-body text-16 font-regular opacity-70">
          You&apos;ve successfully purchased an access pass. Enjoy!
        </span>
      </div>
      <div className="form-control w-full py-14 xl:py-10">
        <Button className="btn btn-primary rounded-full" onClick={handleAccessFanClub}>
          Go to fan club
        </Button>
      </div>
    </div>
  );
};

export default PurchaseSuccess;
