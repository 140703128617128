import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isMobileMenuOpen: false,
    isMobileBottomMenuOpen: false,
    isMobileProfileMenuOpen: false,
    isMobilePlayerProfileSelectorOpen: false,
    isFullscreen3DCardModalOpen: false,
    isGlobalLoading: true,
    isFullscreenModalOpen: false,
    isModalOpen: false,
    dynamicBackground: null,
    staticBackground: null,
  },
  reducers: {
    setIsMobileMenuOpen: (state, action) => {
      state.isMobileMenuOpen = action.payload;
    },
    setIsMobileBottomMenuOpen: (state, action) => {
      state.isMobileBottomMenuOpen = action.payload;
    },
    setIsMobileProfileMenuOpen: (state, action) => {
      state.isMobileProfileMenuOpen = action.payload;
    },
    setIsMobilePlayerProfileSelectorOpen: (state, action) => {
      state.isMobilePlayerProfileSelectorOpen = action.payload;
    },
    setIsFullscreen3DCardModalOpen: (state, action) => {
      state.isFullscreen3DCardModalOpen = action.payload;
    },
    setIsGlobalLoading: (state, action) => {
      state.isGlobalLoading = action.payload;
    },
    setIsFullscreenModalOpen: (state, action) => {
      state.isFullscreenModalOpen = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setDynamicBackground: (state, action) => {
      state.dynamicBackground = action.payload;
    },
    setStaticBackground: (state, action) => {
      state.staticBackground = action.payload;
    },
  },
});

export const {
  setIsMobileMenuOpen,
  setIsMobileBottomMenuOpen,
  setIsMobileProfileMenuOpen,
  setIsMobilePlayerProfileSelectorOpen,
  setIsFullscreen3DCardModalOpen,
  setIsGlobalLoading,
  setIsFullscreenModalOpen,
  setIsModalOpen,
  setDynamicBackground,
  setStaticBackground,
} = uiSlice.actions;

export const selectIsMobileMenuOpen = (state: RootState): boolean => state.ui.isMobileMenuOpen;

export const selectIsMobileBottomMenuOpen = (state: RootState): boolean =>
  state.ui.isMobileBottomMenuOpen;

export const selectIsMobileProfileMenuOpen = (state: RootState): boolean =>
  state.ui.isMobileProfileMenuOpen;

export const selectIsMobilePlayerProfileSelectorOpen = (state: RootState): boolean =>
  state.ui.isMobilePlayerProfileSelectorOpen;

export const selectIsFullscreen3DCardModalOpen = (state: RootState): boolean =>
  state.ui.isFullscreen3DCardModalOpen;

export const selectIsGlobalLoading = (state: RootState): boolean => state.ui.isGlobalLoading;

export const selectIsFullscreenModalOpen = (state: RootState): boolean =>
  state.ui.isFullscreenModalOpen;
export const selectIsModalOpen = (state: RootState): boolean => state.ui.isModalOpen;

export const selectDynamicBackground = (state: RootState): string =>
  state.ui.dynamicBackground || "";

export const selectStaticBackground = (state: RootState): string => state.ui.staticBackground || "";

export default uiSlice.reducer;
