import React, { HTMLAttributes, PropsWithChildren, ForwardRefRenderFunction } from "react";

interface ExtendedProps extends HTMLAttributes<HTMLDivElement> {
  border?: string;
}

type ContainerProps = PropsWithChildren<ExtendedProps>;

const Container: ForwardRefRenderFunction<HTMLDivElement, ContainerProps> = (
  { children, className, border, ...rest },
  ref,
) => {
  return (
    <div
      ref={ref}
      className={`xl:m-[1px] flex items-center gap-2 bg-element-background backdrop-blur-[50px] text-neutral-white-pure-white 
				${className ?? ""}
				${border ?? "border border-neutral-white-20 rounded-[20px]"}
			`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default React.forwardRef(Container);
