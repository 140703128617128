import { PlayerCard } from "@/types/card/card.types";
import { Payment, PaymentMethods } from "@/types/payment/payment.types";
import { createContext, useContext } from "react";

interface PaymentContextType {
  payment: Payment | undefined;
  checkAvailabilityToBuyCard: (serialNumberGenerationId: number) => Promise<void>;
  handlePreparePayment: (paymentMethod: PaymentMethods, tradeCard: PlayerCard) => Promise<void>;
  handleProcessPayment: (
    onSuccessCallback?: () => any,
    onErrorCallback?: () => any,
  ) => Promise<void>;
  isPaymentProcessing: boolean;
  isPaymentSuccessed: boolean;
  isPaymentFailed: boolean;
  handleApplyCoupon: (code: string) => Promise<void>;
  handleSetTransaction: (payload: {
    fromAddress: string;
    toAddress: string;
    value: string;
    transactionHash: string;
  }) => Promise<void>;
  isLoading: boolean;
  isDisabled: boolean;
}

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export function usePayment(): PaymentContextType {
  const context = useContext(PaymentContext);

  if (!context) {
    throw new Error("usePayment must be used within a PaymentProvider");
  }

  return context;
}

export default PaymentContext;
