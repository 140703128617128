import React, { FormEvent, useEffect, useState } from "react";
import { useGeneralUi } from "@/providers/generalUi";

import Button from "@/components/UI/Button";
import { CircleCloseIcon, HandleIcon, PlusIcon } from "@/components/UI/Icons";
import Input from "@/components/UI/Input";
import Textarea from "@/components/UI/Textarea";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import {
  SCOPE_CHAT_MESSAGE_LENGTH,
  SCOPE_CHAT_POLL_OPTION_LENGTH,
} from "@/constants/scopeChat.constants";
import { ScopeChatPollPayload } from "@/types/scopeChat/scopeChat.types";

const reorderOptions = (options: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(options);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggableOptionList = ({
  options,
  setOptions,
  onDragEnd,
}: {
  options: string[];
  setOptions: (options: string[]) => void;
  onDragEnd: ({ destination, source }: DropResult) => void;
}) => {
  const { isMobile } = useGeneralUi();

  const handleChangeOption = (index: number, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  return isMobile ? (
    <>
      {options.map((option, index) => (
        <div key={index} className="flex items-center">
          <Input
            value={option}
            maxLength={SCOPE_CHAT_POLL_OPTION_LENGTH}
            onChange={(event) => handleChangeOption(index, event.target.value)}
            placeholder={`Option ${index + 1}`}
            required
            rightAdornment={
              <Button
                className="btn btn-ghost btn-circle btn-sm rounded-full p-0 m-0"
                onClick={() => handleRemoveOption(index)}
              >
                <CircleCloseIcon />
              </Button>
            }
          />
        </div>
      ))}
    </>
  ) : (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="flex flex-col gap-4"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {options.map((option, index) => (
                <Draggable key={index} draggableId={`${index}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      className="flex -ml-6 items-center"
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <HandleIcon />
                      <Input
                        value={option}
                        maxLength={SCOPE_CHAT_POLL_OPTION_LENGTH}
                        onChange={(event) => handleChangeOption(index, event.target.value)}
                        placeholder={`Option ${index + 1}`}
                        required
                        rightAdornment={
                          <Button
                            className="btn btn-ghost btn-circle btn-sm rounded-full p-0 m-0"
                            onClick={() => handleRemoveOption(index)}
                          >
                            <CircleCloseIcon />
                          </Button>
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const PollForm = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (poll: ScopeChatPollPayload) => void;
  onCancel: () => void;
}) => {
  const [question, setQuestion] = useState<string>("");
  const [options, setOptions] = useState<string[]>(["", ""]);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (options.length < 2) {
      setError("There must be two or more options...");
    } else {
      setError("");
    }
  }, [options.length]);
  const handleDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    setOptions(reorderOptions(options, source.index, destination.index));
  };

  const handleAddOption = () => {
    const updatedOptions = [...options, ""];
    setOptions(updatedOptions);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (options.length < 2) return;

    onSubmit({ question, options });
  };

  return (
    <form className="flex flex-col w-full items-center xl:pt-10" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 w-full h-full justify-between max-w-[784px] px-4 xl:px-0 pb-6 overflow-hidden overflow-y-scroll scrollbar-hidden">
        <div className="flex-1 flex flex-col gap-4 w-full xl:pl-6">
          <div className="flex flex-col text-start gap-2.5">
            <span className="text-caps text-13 font-medium text-grayscale-300 pl-1">
              Add Question
            </span>
            <Textarea
              value={question}
              maxLength={SCOPE_CHAT_MESSAGE_LENGTH}
              onChange={(event) => setQuestion(event.target.value)}
              placeholder="Add content to your post..."
              required
            />
          </div>
          {error && (
            <span className="text-body text-12 text-warning min-h-0 h-3 mt-[2px]">{error}</span>
          )}
          {!!options.length && (
            <DraggableOptionList
              options={options}
              setOptions={setOptions}
              onDragEnd={handleDragEnd}
            />
          )}
          <div className="flex gap-2 w-full items-center cursor-pointer" onClick={handleAddOption}>
            <PlusIcon />
            <span className="text-caps text-13 font-heavy text-grayscale-600">Add option</span>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center items-center border-top">
        <div className="flex w-full max-w-[784px] justify-end items-center gap-4 xl:gap-8 xl:py-8 px-4 py-6">
          <Button
            className="btn btn-outline flex-1 xl:flex-none xl:w-[190px] rounded-full !text-button !text-14 !font-bold"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn btn-primary flex-1 xl:flex-none xl:flex-shrink-0 xl:w-[190px] rounded-full !text-button !text-14 !font-bold"
          >
            Create
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PollForm;
