import React from "react";

import { AudioRecorder, RECORD_STATUS } from "@/components/UI/AudioRecorder";

const createFileList = (file: File): FileList => {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);
  return dataTransfer.files;
};

const VoiceRecorderForm = ({
  handleOnMediaInputChange,
}: {
  handleOnMediaInputChange: (files: FileList | null, singleFile?: boolean) => void;
}) => {
  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">Or record new one</span>
      </label>
      <AudioRecorder
        setAudioFile={(audioFile: any) => handleOnMediaInputChange(createFileList(audioFile), true)}
        setAudioResult={() => null}
        render={({
          timer,
          stopRecording,
          startRecording,
          pauseRecording,
          resumeRecording,
          status,
          errorMessage,
        }) => (
          <div className="flex">
            <div className="flex-row w-full">
              <span>{new Date(timer * 1000).toISOString().substr(11, 8)}</span>
              <p className="text-body text-14">{errorMessage}</p>
              <div className="join join-horizontal">
                <button
                  type="button"
                  className={`join-item btn ${status === RECORD_STATUS.PAUSED ? "btn-active" : ""}`}
                  onClick={status === RECORD_STATUS.RECORDING ? pauseRecording : resumeRecording}
                >
                  <i
                    className={`fas fa-${status === RECORD_STATUS.RECORDING ? "pause" : "play"}`}
                  ></i>
                </button>
                <button
                  type="button"
                  className={`join-item btn ${
                    status === RECORD_STATUS.RECORDING ? "btn-active" : ""
                  }`}
                  onClick={startRecording}
                >
                  <i className={"fas fa-microphone"}></i>
                </button>
                <button
                  type="button"
                  className={`join-item btn ${status === RECORD_STATUS.IDLE ? "btn-active" : ""}`}
                  onClick={stopRecording}
                >
                  <i className={"fas fa-stop"}></i>
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};
export default VoiceRecorderForm;
