import React, { useEffect, useRef, useState } from "react";
import { batch } from "react-redux";

import Waveform from "@/components/UI/Waveform";
import CenterSpinner from "@/components/UI/CenterSpinner";
import {
  TransparentPauseIcon,
  TransparentPlayIcon,
  TransparentRevertIcon,
  TransparentStopIcon,
} from "@/components/UI/Icons";

import { convertToString, convertToTimeFormat } from "@/helpers/audio.helpers";
import { getLocalMediaUrl } from "@/helpers/file.helpers";

const AudioPlayer = ({
  audioFile,
  setAudioFile,
}: {
  audioFile: File;
  setAudioFile: (audioFile: File | undefined) => void;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [audioFileUrl, setAudioFileUrl] = useState<string>("");
  const [audioFileMimetype, setAudioFileMimetype] = useState<string>("");
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);

  useEffect(() => {
    if (!audioRef.current) return;

    audioRef.current.addEventListener("ended", () => setIsPlaying(false));
    audioRef.current.addEventListener("timeupdate", updateCurrentTime);

    return () => {
      if (!audioRef.current) return;

      audioRef.current.removeEventListener("ended", () => setIsPlaying(false));
      audioRef.current.removeEventListener("timeupdate", updateCurrentTime);
      audioRef.current.pause();
    };
  }, [isPlaying]);

  useEffect(() => {
    setAudioFileUrl("");
    setAudioFileMimetype(audioFile.type);
    getLocalMediaUrl(audioFile, (result) => {
      setAudioFileUrl(result);
    });
  }, [audioFile]);

  const updateCurrentTime = (event: any): void => {
    const { currentTime } = event.target;
    batch(() => {
      setCurrentTime(currentTime);
    });
  };

  return audioFileUrl ? (
    <div className="flex flex-col justify-center items-center w-full gap-4">
      <div className="flex flex-col gap-2 items-center w-full">
        <Waveform
          ref={audioRef}
          src={audioFileUrl}
          mimetype={audioFileMimetype}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
        <span className="text-body text-14 font-regular text-neutral-white-pure-white">
          {convertToString(convertToTimeFormat(currentTime))}
        </span>
      </div>
      <div className="flex justify-between items-center gap-4">
        <div className="flex justify-center items-center rounded-full cursor-pointer p-0 w-10 h-10 bg-neutral-white-5 hover:bg-neutral-white-10 opacity-0">
          <TransparentPauseIcon className="!w-10 !h-10" />
        </div>

        <div
          className={`flex justify-center items-center rounded-full cursor-pointer p-0 w-12 h-12  ${
            isPlaying
              ? "bg-white hover:bg-neutral-white-70"
              : "bg-neutral-white-5 hover:bg-neutral-white-10"
          }`}
          onClick={() => setIsPlaying((prev) => !prev)}
        >
          {isPlaying ? (
            <TransparentStopIcon className="!w-12 !h-12" fill="black" />
          ) : (
            <TransparentPlayIcon className="!w-12 !h-12" />
          )}
        </div>
        <div
          className="flex justify-center items-center rounded-full cursor-pointer p-0 w-10 h-10 bg-neutral-white-5 hover:bg-neutral-white-10"
          onClick={() => setAudioFile(undefined)}
        >
          <TransparentRevertIcon />
        </div>
      </div>
    </div>
  ) : (
    <CenterSpinner />
  );
};

export default AudioPlayer;
