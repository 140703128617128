import React, { useEffect } from "react";
import { useProfile } from "@/providers/profile";
import { useScopeChat } from "@/providers/scopeChat";
import { useGeneralUi } from "@/providers/generalUi";

import MessageList from "../MessageList/MessageList";
import { ChatInput } from "../Inputs/ChatInput";
import CenterSpinner from "@/components/UI/CenterSpinner";
import SidebarHeader from "./SidebarHeader";
import MobileHeader from "@/components/UI/MobileHeader";

const ThreadView = () => {
  const { isAuthorized } = useProfile();
  const { setIsThreadVisible, thread, handleThreadScroll } = useScopeChat();
  const { setHeader } = useGeneralUi();

  useEffect(() => {
    setHeader(<MobileHeader label={label} handleBackClick={() => setIsThreadVisible(false)} />);
  }, [thread.threadName]);

  const label = (
    <>
      Thread{" "}
      <span className="text-body text-14 font-medium text-accent-500 truncate">
        {thread.threadName}
      </span>
    </>
  );

  return (
    <>
      <SidebarHeader label={label} handleBackClick={() => setIsThreadVisible(false)} />
      <div
        data-testid="thread-block"
        className="flex-1 flex flex-col justify-between overflow-hidden"
      >
        <div className="flex-1 flex xl:p-4 w-full h-full overflow-hidden">
          {thread.groupedMessages ? (
            <MessageList
              groupedMessages={thread.groupedMessages || {}}
              handleScroll={isAuthorized ? handleThreadScroll : undefined}
              threadView
            />
          ) : (
            <CenterSpinner />
          )}
        </div>
        <div className="w-full xl:px-6 p-4 z-10 border-top">
          <ChatInput messageId={thread.messageId} thread />
        </div>
      </div>
    </>
  );
};

export default ThreadView;
