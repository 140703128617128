import { NavigateOptions, To } from "react-router-dom";
import { ProfileTypes } from "./profile-types.enum";

export interface INavbarLink {
  title: string;
  path: string;
  activePaths: string[];
  profileTypes: ProfileTypes[];
  public: boolean;
  badge?: string;
  testId?: string;
  isActive?: boolean;
  isHidden?: boolean;
}

export interface IProfileMenuItem {
  type: string;
  path?: string;
  Icon?: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => JSX.Element;
  title?: string;
  testId?: string;
  action?: () => void;
  hidden?: boolean | ((value: boolean) => boolean);
}

export enum FooterLinkTypeEnum {
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export interface IFooterLink {
  title: string;
  Icon?: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => JSX.Element;
  path: string;
  type: FooterLinkTypeEnum;
  public: boolean;
}

export interface IThumbnails {
  [type: string]: string;
}

export interface IPlatformSettings {
  defaultBackground: IBackgroundSettings;
  maintenanceMode: boolean;
}

export interface IBackgroundSettings {
  dynamicBackground: string;
  staticBackground: string;
  thumbnails: IThumbnails;
}

export enum WebSocketSharedChannels {
  JOIN_REQUEST = "join_request",
  JOIN_REQUEST_FEED = "join_request_feed",
  JOIN_REQUEST_PREMIUM_CONTENT = "join_request_premium_content",
}

export type AppNavigateFunction = {
  (delta: number): void;
  (to: To, options?: NavigateOptions): void;
};

export interface Button {
  caption: string;
  onClick: () => void;
  type?: ButtonTypes;
}

export enum ButtonTypes {
  PRIMARY = "btn-primary",
  SECONDARY = "btn-secondary",
  SUCCESS = "btn-success",
  INFO = "btn-info",
  ERROR = "btn-error",
  WARNING = "btn-warning",
  GHOST = "btn-ghost",
}
