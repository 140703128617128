import React from "react";

import { GradientLockIcon } from "@/components/UI/Icons";
import GetAccessButton from "@/components/Payment/GetAccessButton";

const Placeholder = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-[2vh]">
      <div className="flex flex-col items-center gap-[2vh]">
        <GradientLockIcon className="w-[3vh] h-[3vh]" />
        <span className="text-body text-[2vh] font-medium">{text}</span>
      </div>
      <GetAccessButton className="btn btn-outline min-h-[3vh] h-[5vh] mx-auto px-10" />
    </div>
  );
};

export default Placeholder;
