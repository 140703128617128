export enum DateTypes {
  long,
  longWithShortMonth,
  longWithShortMonthWithTime,
}

const getDaySuffix = (day: number) => {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return "st";
    case 2:
    case 22:
      return "nd";
    case 3:
    case 23:
      return "rd";
    default:
      return "th";
  }
};

export const getYear = (date: Date) => date.toLocaleString("en-US", { year: "numeric" });

const longFormat = (date: Date, shortMonth: boolean, withTime: boolean) => {
  const month = date.toLocaleString("en-US", {
    month: shortMonth ? "short" : "long",
  });
  const day = date.toLocaleString("en-US", { day: "numeric" });
  const year = date.toLocaleString("en-US", { year: "numeric" });
  const daySuffix = getDaySuffix(parseInt(day));
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  return `${month} ${day}${daySuffix}, ${year} ${withTime ? time : ""}`;
};

export const formatDate = (type: number, date: Date) => {
  switch (type) {
    case DateTypes.long:
      return longFormat(date, false, false);
    case DateTypes.longWithShortMonth:
      return longFormat(date, true, false);
    case DateTypes.longWithShortMonthWithTime:
      return longFormat(date, true, true);
  }

  return "";
};

export const fromNow = (
  date: string | number | Date,
  nowDate: any = Date.now(),
  rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" }),
) => {
  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const WEEK = 7 * DAY;
  const MONTH = 30 * DAY;
  const YEAR = 365 * DAY;
  const intervals = [
    { ge: YEAR, divisor: YEAR, unit: "year" },
    { ge: MONTH, divisor: MONTH, unit: "month" },
    { ge: WEEK, divisor: WEEK, unit: "week" },
    { ge: DAY, divisor: DAY, unit: "day" },
    { ge: HOUR, divisor: HOUR, unit: "hour" },
    { ge: MINUTE, divisor: MINUTE, unit: "minute" },
    { ge: 30 * SECOND, divisor: SECOND, unit: "seconds" },
    { ge: 0, divisor: 1, text: "just now" },
  ];
  const now = typeof nowDate === "object" ? nowDate.getTime() : new Date(nowDate).getTime();
  const diff = now - (typeof date === "object" ? date : new Date(date)).getTime();
  const diffAbs = Math.abs(diff);
  for (const interval of intervals) {
    if (diffAbs >= interval.ge) {
      const x = Math.round(Math.abs(diff) / interval.divisor);
      const isFuture = diff < 0;
      return interval.unit
        ? rft.format(isFuture ? x : -x, interval.unit as Intl.RelativeTimeFormatUnit)
        : interval.text;
    }
  }
};
