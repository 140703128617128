export const CM_IN_INCHES = 2.54;

export const getFirstName = (name: string) => {
  const nameWords = name.split(" ");
  return nameWords[0];
};

export const getLastName = (name: string) => {
  const nameWords = name.split(" ");
  return nameWords.splice(1).join(" ");
};

export const getHeightFt = (heightCm: number | undefined) => {
  if (!heightCm) {
    return "Unknown";
  }
  const inches = heightCm / CM_IN_INCHES;
  const feets = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);

  return `${feets}' ${remainingInches}"`;
};
