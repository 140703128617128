import React from "react";
import { useAppNavigate } from "@/app/hooks";

import { ScopeIcon } from "@/components/UI/Icons";

const ChatInputPlaceholder = () => {
  const navigate = useAppNavigate();

  return (
    <div className="bg-neutral-white-5 backdrop-blur-[17.5px] h-content flex items-center justify-between px-4 xl:px-6 py-3 box-border rounded-lg gap-2">
      <div className="flex flex-1 gap-4 items-center">
        <ScopeIcon className="w-8 h-8" />
        <span className="text-body text-12 text-start xl:text-16 text-white">
          Sign up to experience the best of Scope Chat...
        </span>
      </div>
      <div className="flex">
        <button
          className="btn btn-xs xl:btn-sm btn-outline bg-white !text-black"
          onClick={() => navigate("/sign-up")}
        >
          Sign up
        </button>
      </div>
    </div>
  );
};

export default ChatInputPlaceholder;
