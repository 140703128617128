import { IThumbnails } from "../app.types";

export interface ScopeChatMessage {
  channelId: string;
  id: string;
  content: string;
  owner: ScopeChatOwner;
  createdAt: string;
  type: ScopeChatMessageTypes;
  reactions: ScopeChatSortedReaction[];
  attachments: ScopeChatAttachment[];
  likes: ScopeChatMessageLikes;
  hasThreadMessages: boolean;
  threadMessagesCount: number;
  isDraft?: boolean;
  poll?: ScopeChatPoll;
  parentId?: string;
  isPinned?: boolean;
  isEdited?: boolean;
}

export interface ScopeChatGroupedMessages {
  [date: string]: ScopeChatMessage[];
}

export interface ScopeChatThread {
  channelId?: string;
  messageId?: string;
  threadName?: string;
  lastMessageDate?: string;
  toMessageDate?: string;
  hasNextPage?: boolean;
  messages?: ScopeChatMessage[];
  groupedMessages?: ScopeChatGroupedMessages;
}

export interface ScopeChatAttachment {
  videoThumbnails?: { [x: string]: string };
  thumbnails?: { [x: string]: string };
  attachmentAspectRatio?: number;
  presignedUrl: string;
  isDraft: boolean;
  mimetype: string;
  uuid: string;
}

export interface ScopeChatPoll {
  _id: string;
  question: string;
  options: string[];
  votes: ScopeChatPollVote[];
}

export interface ScopeChatPollVote {
  ownerUuid: string;
  option: number;
}

export interface ScopeChatOwner {
  externalUuid: string;
  name: string;
  type: ScopeChatUserTypes;
  thumbnails?: IThumbnails;
  presignedUrl?: string;
}

export interface SuggestedOwner {
  externalUuid: string;
  name: string;
  presignedUrl?: string;
  thumbnails?: IThumbnails;
}

export interface ScopeChatReaction {
  ownerUuid: string;
  content: string;
}

export interface ScopeChatSortedReaction {
  content: string;
  count: number;
}

export interface ScopeChatMessageLikes {
  ownerUuids: string[];
  count: number;
}

export enum ScopeChatUserTypes {
  REGULAR = "regular",
  ADMIN = "admin",
  ATHLETE = "athlete",
  CARD_HOLDER = "card_holder",
}

export interface ScopeChatUserMetadata {
  externalUuid: string;
  name: string;
  type: ScopeChatUserTypes;
}

export interface ScopeChatMessagePayload {
  channelId: string;
  content: string;
  ownerUuid: string;
  type: ScopeChatMessageTypes;
  isDraft?: boolean;
  parentId?: string;
  attachmentUuid?: string;
  attachmentAspectRatio?: number;
  pollPayload?: ScopeChatPollPayload;
  attachmentUuids?: string[];
}

export interface ScopeChatPollPayload {
  question: string;
  options: string[];
}

export interface ScopeChatAttachmentPayload {
  uuid: string;
}

export interface ScopeChatReactionPayload {
  channelId?: string;
  messageId: string;
  content: string;
  ownerUuid: string;
}

export interface ScopeChatPinPayload {
  channelId?: string;
  ownerUuid: string;
  messageId: string;
  isPinned: boolean;
}

export interface ScopeChatPollVotePayload {
  channelId?: string;
  messageId: string;
  ownerUuid: string;
  option: number;
}

export interface ScopeChatUpdateMessagePayload extends ScopeChatMessage {
  messageId: string;
  ownerUuid: string;
}

export enum ScopeChatSearchTypes {
  KEYWORDS = "keywords",
  FROM = "from",
  TYPE = "type",
  BEFORE = "before",
  AFTER = "after",
  ON = "on",
}

export enum ScopeChatSearchSuggestionTypes {
  FROM = "from",
  TYPE = "type",
}

export type ScopeChatSuggestionTypes = {
  [K in keyof typeof ScopeChatSearchSuggestionTypes]?: any[];
};

export type ScopeChatSearchPayloadBase = {
  [key in ScopeChatSearchTypes]?: ScopeChatSearchItem;
};

export type ScopeChatSearchPayload = ScopeChatSearchPayloadBase & {
  query?: string;
  channelId?: string;
};

export interface ScopeChatSearchItem {
  value: string;
  displayValue: string;
}

export enum ScopeChatMessageTypes {
  MEDIA = "media",
  // PHOTO = "photo",
  // VIDEO = "video",
  VOICE = "voice",
  TEXT = "text",
  POLL = "poll",
}

export enum ScopeChatThreadActions {
  ADD = "add",
  UPDATE = "update",
  DELETE = "delete",
}

export enum ScopeChatSidebarElements {
  SEARCH = "search",
  THREAD = "thread",
  PINNED_MESSAGES = "pinned_messages",
}

export type ScopeChatAttachmentMenuType = {
  [K in ScopeChatMessageTypes]?: {
    icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => JSX.Element;
    label: string;
    value: ScopeChatMessageTypes;
    action: () => void;
  };
};

export enum ScopeChatEvents {
  MESSAGES = "messages",
  SUGGESTIONS = "suggestions",
  SEARCH = "search",
  DRAFTS = "drafts",
  UPDATE_MESSAGE = "update_message",
  NEW_MESSAGE = "new_message",
  HANDLE_GET_SUGGESTIONS = "handle_get_suggestions",
  HANDLE_SEARCH = "handle_search",
  HANDLE_GET_PINNED_MESSAGES = "handle_get_pinned_messages",
  HANDLE_REACTION = "handle_reaction",
  HANDLE_PIN_MESSAGE = "handle_pin_message",
  HANDLE_VOTE_POLL = "handle_vote_poll",
  HANDLE_UPDATE_MESSAGE = "handle_update_message",
  HANDLE_DELETE_MESSAGE = "handle_delete_message",
  DELETE_MESSAGE = "delete_message",
  HANDLE_GET_THREAD = "handle_get_thread",
  THREAD = "thread",
  PINNED_MESSAGES = "pinned_messages",
  SET_USER_METADATA = "set_user_metadata",
  EXCEPTION = "exception",
  JOIN_CHANNEL = "join_channel",
}
