import React, { useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import { useProfile } from "@/providers/profile";

import {
  ProfileTab,
  BillingTab,
  ConnectWalletTab,
  NotificationsTab,
  ChangePasswordTab,
  AuthTab,
  DeleteAccountTab,
  BlockedUsers,
} from "@/components/AccountSettingsTabs";
import AppLink from "@/components/AppLink";

const TABS = [
  {
    id: "profile",
    component: ProfileTab,
    label: "Profile",
    dataTestId: "account-tab-profile",
  },
  {
    id: "billing",
    component: BillingTab,
    label: "Billing",
    dataTestId: "account-tab-billing",
  },
  // {
  //   id: "connect-wallet",
  //   component: ConnectWalletTab,
  //   label: "Connect wallet",
  //   dataTestId: "account-tab-connect-wallet",
  // },
  {
    id: "notifications",
    component: NotificationsTab,
    label: "Notifications",
    dataTestId: "account-tab-notifications",
  },
  {
    id: "change-password",
    component: ChangePasswordTab,
    label: "Change Password",
    dataTestId: "account-tab-change-password",
  },
  /*
  {
    id: "auth",
    component: AuthTab,
    label: "2-Factor Auth",
  },
  */
  {
    id: "delete_account",
    component: DeleteAccountTab,
    label: "Delete Account",
    dataTestId: "account-tab-delete-account",
  },
  {
    id: "blocked_users",
    component: BlockedUsers,
    label: "Blocked Users",
    dataTestId: "account-tab-blocked-users",
  },
];

const AccountSettings = () => {
  const { userProfile } = useProfile();
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  const [currentTab, setCurrentTab] = useState(TABS.find((tab) => tab.id === section) || TABS[0]);

  useEffect(() => {
    setCurrentTab(TABS.find((tab) => tab.id === section) || TABS[0]);
  }, [section]);

  const { component: TabComponent } = currentTab;

  return (
    <div className="flex flex-col w-full xl:h-full text-left px-4 py-6 xl:max-w-[1366px] mx-auto xl:overflow-hidden">
      <div className="flex flex-col items-start mb-6 ">
        <span className="text-white text-caps !font-huben text-16 xl:text-[32px]">
          Account Settings
        </span>
      </div>
      <div role="tablist" className="tabs tabs-bordered overflow-x-auto">
        {TABS.map((tab) => (
          <AppLink
            key={tab.id}
            role="tab"
            data-testid={tab.dataTestId}
            to={`/account-settings?section=${tab.id}`}
            className={`tab px-4 cursor-pointer text-caps text-13 xl:text-15 font-heavy xl:font-bold
                      text-white whitespace-nowrap tab-bordered border-transparent ${
                        tab.id === currentTab.id ? "tab-active !border-white" : ""
                      }`}
          >
            {tab.label}
          </AppLink>
        ))}
      </div>
      <div className="flex-1 h-full overflow-hidden overflow-y-auto pt-4">
        {userProfile && <TabComponent />}
      </div>
    </div>
  );
};

export default AccountSettings;
