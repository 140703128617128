export const MAINNET = {
  chainId: "0xA86A",
  chainName: "Avalanche Mainnet C-Chain",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
  blockExplorerUrls: ["https://snowtrace.io/"],
};

export const WALLET_ERRORS: { [key: string]: string } = {
  "-32002": "Access request was already sent, please open your wallet extension to approve it.",
  "4001": "Access request rejected by user.",
};

export const DEFAULT_BLOCKCHAIN_ID = import.meta.env.VITE_BLOCKCHAIN_ID;
