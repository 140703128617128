import { WALLET_ERRORS } from "@/constants/crypto.constants";
import { WalletError } from "@/types/crypto/crypto.types";

export const formatBalance = (rawBalance: string) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(2);
  return balance;
};

export const formatChainAsNum = (chainIdHex: string) => {
  const chainIdNum = parseInt(chainIdHex);
  return chainIdNum;
};

export const formatAddress = (addr: string) => {
  return `${addr.substring(0, 8)}...`;
};

export const hexer = (input: string) => {
  const utf8 = toUTF8Array(input);
  const hex = utf8.map((n) => n.toString(16));
  return "0x" + hex.join("");
};

const toUTF8Array = (payload: string) => {
  const utf8 = [];
  for (let i = 0; i < payload.length; i++) {
    let charcode = payload.charCodeAt(i);
    if (charcode < 0x80) utf8.push(charcode);
    else if (charcode < 0x800) {
      utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
    } else if (charcode < 0xd800 || charcode >= 0xe000) {
      utf8.push(0xe0 | (charcode >> 12), 0x80 | ((charcode >> 6) & 0x3f), 0x80 | (charcode & 0x3f));
    } else {
      i++;
      charcode = 0x10000 + (((charcode & 0x3ff) << 10) | (payload.charCodeAt(i) & 0x3ff));
      utf8.push(
        0xf0 | (charcode >> 18),
        0x80 | ((charcode >> 12) & 0x3f),
        0x80 | ((charcode >> 6) & 0x3f),
        0x80 | (charcode & 0x3f),
      );
    }
  }
  return utf8;
};

export class NoWalletExtensionInstalledException extends Error {
  constructor() {
    super("Please install wallet extension to connect your wallets.");
  }
}

export const handleError = (error: WalletError) => {
  const errorCode = (error?.code || "").toString();

  return WALLET_ERRORS?.[errorCode] || error?.message || "Unexpected error.";
};

export const shortenHexAddress = (hexAddress: string): string => {
  if (!hexAddress.startsWith("0x") || hexAddress.length < 9) {
    return "Invalid hex address";
  }

  return `${hexAddress.slice(0, 6)}...${hexAddress.slice(-4)}`;
};
