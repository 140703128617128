import React from "react";
import { useProfile } from "@/providers/profile";

import InstallPWAInfo from "../InstallPWAInfo";
import Button from "../UI/Button";

const InstallAppModal = ({ onToggle }: { onToggle?: () => void }) => {
  const { handleInstallAppInformed } = useProfile();

  return (
    <div className="modal backdrop-blur-md modal-open">
      <div className="modal-box p-0 bg-transparent overflow-visible">
        <InstallPWAInfo>
          <Button
            className="btn btn-primary btn-block"
            onClick={() => {
              handleInstallAppInformed();
              onToggle && onToggle();
            }}
          >
            Close
          </Button>
        </InstallPWAInfo>
      </div>
    </div>
  );
};

export default InstallAppModal;
