/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { useProfile } from "@/providers/profile";
import { useAppNavigate } from "@/app/hooks";

import { Country } from "../types/countries.enum";

const countryOptions = (Object.entries(Country) as [string, Country][]).map(([name, code]) => {
  return {
    name: `${name}`,
    value: code,
  };
});

const OnboardForm = () => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const { state } = location;

  const { userProfile, updateUserProfile, isUserProfileUpdating } = useProfile();

  useEffect(() => {
    if (userProfile?.isOnboarded) {
      if (state?.from) {
        const { pathname, search } = state.from;
        navigate(`${pathname}${search ?? ""}`);
      }

      navigate("/");
    }
  }, [userProfile?.isOnboarded]);

  const formik = useFormik({
    initialValues: {
      email: userProfile?.email ?? "",
      fullName: userProfile?.fullName ?? "",
      username: userProfile?.username ?? "",
      country: userProfile?.country ?? "",
      isOnboarded: true,
    },
    validate: (values) => {
      const errors: any = {};
      const requiredFields = ["email", "fullName", "username", "country"];

      requiredFields.forEach((reqField) => {
        if (!(values as any)[reqField]) {
          errors[reqField] = "Required";
        }
      });

      return errors;
    },
    onSubmit: async (values) => {
      updateUserProfile(values).catch((error) => {
        console.log(error);
      });
    },
  });

  return (
    <div className="flex flex-col w-full max-w-xl justify-center text-left gap-4">
      <div className="flex flex-col gap-3">
        <h4 className="xl:hidden text-heading font-medium">Create your Scope account</h4>
        <h2 className="hidden xl:flex text-heading font-medium">Create your Scope account</h2>
        <span className="text-body text-16 font-regular opacity-70">
          Sign up to Scope and begin to connect with your favorite athletes!
        </span>
      </div>
      <form onSubmit={formik.handleSubmit} className="form-control gap-6 w-full">
        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            autoComplete="on"
            readOnly={!!userProfile?.email}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.email && formik.touched.email && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Name</span>
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullName}
            readOnly={!!userProfile?.fullName}
            className={`input input-bordered w-full rounded-full ${
              formik.errors.fullName && formik.touched.fullName && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Username</span>
          </label>
          <input
            type="text"
            name="username"
            id="username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            autoComplete="off"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.username && formik.touched.username && " !border-red-500 !text-red-900"
            }`}
          />
        </div>

        <div className="flex flex-col">
          <label className="label uppercase">
            <span className="label-text">Country</span>
          </label>
          <select
            className={`select select-bordered rounded-full text-white ${
              formik.errors.country && formik.touched.country && " !border-red-500 !text-red-900"
            }`}
            name="country"
            id="country"
            onChange={formik.handleChange}
            value={formik.values.country}
          >
            <option value="" disabled>
              Select country
            </option>
            {countryOptions.map((countryOption, index) => (
              <option key={index} value={countryOption.value}>
                {countryOption.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col pt-4">
          <button
            type="submit"
            disabled={formik.isSubmitting || isUserProfileUpdating}
            className="btn btn-primary rounded-full"
          >
            Update profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default OnboardForm;
