import React, { PropsWithChildren, ReactNode, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useGeneralUi } from "../generalUi";

import FullscreenModalContext, { FullscreenModalType } from "./FullscreenModalContext";

import { CloseIcon } from "../../components/UI/Icons";
import Button from "../../components/UI/Button";

import {
  selectIsFullscreenModalOpen,
  selectIsModalOpen,
  setIsFullscreenModalOpen as setIsFullscreenModalOpenAction,
  setIsModalOpen as setIsModalOpenAction,
} from "@/reducers/ui.slice";

const FullscreenModalProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  const { useGradientBackground, useBluredBackground } = useGeneralUi();

  const isFullscreenModalOpen = useAppSelector(selectIsFullscreenModalOpen);
  const isModalOpen = useAppSelector(selectIsModalOpen);

  const setIsFullscreenModalOpen = (value: boolean) => {
    value && dispatch(setIsModalOpenAction(false));
    dispatch(setIsFullscreenModalOpenAction(value));
    useBluredBackground(value);
    useGradientBackground(value);
  };

  const setIsModalOpen = (value: boolean) => {
    value && dispatch(setIsFullscreenModalOpenAction(false));
    dispatch(setIsModalOpenAction(value));
    useBluredBackground(value);
    useGradientBackground(value);
  };

  const handleCloseModal = () => {
    setModal(null);
    dispatch(setIsModalOpenAction(false));
    useBluredBackground(false);
    useGradientBackground(false);
  };

  const [modal, setModal] = useState<ReactNode | null>(null);

  const [headerClass, setHeaderClass] = useState<string>("");
  const [bodyClass, setBodyClass] = useState<string>("");

  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string | ReactNode | null>(null);
  const [cancelHandler, setCancelHandler] = useState<() => void>(
    () => () => setIsFullscreenModalOpen(false),
  );

  const setFullscreenModal = (modalProps: FullscreenModalType) => {
    const { title, body, cancelHandler, headerClass, bodyClass } = modalProps;

    batch(() => {
      setTitle(title);
      setBody(body);
      setHeaderClass(headerClass || "");
      setBodyClass(bodyClass || "");
      setCancelHandler((prev) =>
        cancelHandler
          ? () => {
              cancelHandler();
              setIsFullscreenModalOpen(false);
              setBody(null);
              setTitle("");
            }
          : prev,
      );
    });
  };

  return (
    <FullscreenModalContext.Provider
      value={{
        setFullscreenModal,
        setIsFullscreenModalOpen,
        setModal,
        setIsModalOpen,
        handleCloseModal,
      }}
    >
      {isFullscreenModalOpen && (
        <>
          <div className="fixed ios-inset-0 overflow-hidden z-[65] backdrop-blur-lg">
            <div className="absolute inset-0 flex flex-col items-center justify-between w-full h-full overflow-hidden">
              <div
                className={`flex w-full justify-between items-center px-4 py-2 xl:px-[60px] xl:py-8 border-bottom ${
                  headerClass ?? ""
                }`}
              >
                <span className="text-body xl:text-headline text-16 text-neutral-white-pure-white xl:uppercase">
                  {title}
                </span>
                <Button
                  className="btn btn-ghost btn-square btn-sm rounded-md"
                  onClick={() => cancelHandler()}
                >
                  <CloseIcon />
                </Button>
              </div>
              <div
                className={`flex-1 flex w-full justify-center overflow-hidden overflow-y-scroll scrollbar-hidden ${
                  bodyClass ?? ""
                }`}
              >
                {body}
              </div>
            </div>
          </div>
        </>
      )}
      {isModalOpen && modal}
      <div className="w-full h-full">{children}</div>
    </FullscreenModalContext.Provider>
  );
};

export default FullscreenModalProvider;
