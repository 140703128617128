import React, { useEffect, useRef, useState } from "react";
import { batch } from "react-redux";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import { useFanClub } from "@/providers/fanClub";

import CardView from "../../Common/CardView";
import { PlayIcon } from "@/components/UI/Icons";
import CenterSpinner from "@/components/UI/CenterSpinner";

import { ViewTypes } from "@/types/fanClub/fanClub.types";
import { selectPremiumContent, setPremiumContentAutoplayUuid } from "@/reducers/fanClub.slice";

const Video = ({
  url,
  fallBackSrc,
  handleClick,
}: {
  url: string;
  fallBackSrc?: string;
  handleClick: (event: React.MouseEvent) => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));

  return (
    <div className="flex w-full h-full overflow-hidden relative" onClick={handleClick}>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer">
        <PlayIcon />
      </div>
      <video
        ref={videoRef}
        className="w-full h-full object-cover -z-10"
        onError={() => {
          if (!fallBackSrc || fallBackSrc === videoRef.current.src) return;

          videoRef.current.src = fallBackSrc;
        }}
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
};

const PremiumContentCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const {
    playerProfile,
    isMember,
    isPremiumContentAllowed,
    isPremiumContentLoading,
    setCurrentView,
  } = useFanClub();

  const [readyToShowPreview, setReadyToShowPreview] = useState<boolean>(false);

  useEffect(() => {
    setReadyToShowPreview(false);

    if (!playerProfile.previewVideo) return;

    const timeout = setTimeout(() => setReadyToShowPreview(true), 10);

    return () => {
      clearTimeout(timeout);
    };
  }, [playerProfile.previewVideo]);

  const premiumContent = useAppSelector(selectPremiumContent);

  const recentItem = premiumContent.find((item) => !item.isDraft);

  const handleFanClubPreviewClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`${location.pathname}/preview-video`);
  };

  return (
    <CardView
      title="Premium Content"
      containerClassName="max-h-[33%] h-[33%]"
      handleMaximize={(event) =>
        isMember && isPremiumContentAllowed && premiumContent.length
          ? setCurrentView(ViewTypes.PREMIUM_CONTENT)
          : playerProfile.previewVideo
          ? handleFanClubPreviewClick(event)
          : undefined
      }
      disabled={(!premiumContent.length && !playerProfile.previewVideo) || isPremiumContentLoading}
    >
      {isPremiumContentLoading ? (
        <CenterSpinner />
      ) : isMember && isPremiumContentAllowed && recentItem ? (
        <Video
          url={recentItem.videoThumbnails?.md || recentItem.presignedUrl}
          fallBackSrc={recentItem.presignedUrl}
          handleClick={() =>
            batch(() => {
              setCurrentView(ViewTypes.PREMIUM_CONTENT);
              dispatch(setPremiumContentAutoplayUuid(recentItem.uuid));
            })
          }
        />
      ) : playerProfile.previewVideo && readyToShowPreview ? (
        <Video url={playerProfile.previewVideo} handleClick={handleFanClubPreviewClick} />
      ) : (
        !playerProfile.previewVideo && "Coming soon..."
      )}
    </CardView>
  );
};

export default PremiumContentCard;
