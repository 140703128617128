import React, { PropsWithChildren, createContext, useContext, useState } from "react";

import EmojiPicker from "@emoji-mart/react";
import useWindowDimensions from "@/app/hooks/useWindowDimensions";

interface EmojiPickerContextType {
  setOnEmojiSelect: (fn: (emoji: EmojiData) => void) => void;
  handleOpenEmojiPicker: (event: React.MouseEvent) => void;
}

export interface EmojiData {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: any;
  shortcodes: string;
}

const EmojiPickerContext = createContext<EmojiPickerContextType | undefined>(undefined);

export const useEmojiPicker = (): EmojiPickerContextType => {
  const context = useContext(EmojiPickerContext);

  if (!context) {
    throw new Error("useEmojiPicker must be used within a EmojiPickerProvider");
  }

  return context;
};

const HORIZONTAL_MARGIN = 24;
const VERTICAL_MARGIN = 72;

const EmojiPickerProvider = ({ children }: PropsWithChildren) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [emojiPickerPosition, setEmojiPickerPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [onEmojiSelect, setOnEmojiSelect] = useState<(emoji: EmojiData) => void | null>();

  const {
    isMobile,
    windowDimensions: { width, height },
  } = useWindowDimensions();

  const handleEmojiSelect = (emoji: EmojiData) => {
    onEmojiSelect && onEmojiSelect(emoji);
    setIsEmojiPickerOpen(false);
  };

  const handleOpenEmojiPicker = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!isMobile) {
      const emojiPickerWidth = 352;
      const emojiPickerHeight = 435;
      const { clientX, clientY } = event;

      let x = clientX;
      let y = clientY;

      if (clientX + emojiPickerWidth + HORIZONTAL_MARGIN > width) {
        x = width - emojiPickerWidth - HORIZONTAL_MARGIN;
      }
      if (clientY + emojiPickerHeight + VERTICAL_MARGIN > height) {
        y = height - emojiPickerHeight - VERTICAL_MARGIN;
      }
      setEmojiPickerPosition({ x, y });
    }

    setIsEmojiPickerOpen(true);
  };

  return (
    <EmojiPickerContext.Provider
      value={{
        setOnEmojiSelect,
        handleOpenEmojiPicker,
      }}
    >
      {isEmojiPickerOpen && (
        <div
          style={{
            position: "fixed",
            left: `${emojiPickerPosition.x}px`,
            top: isMobile ? "" : `${emojiPickerPosition.y}px`,
            bottom: isMobile ? 0 : "",
            width: isMobile ? "100%" : "",
          }}
          className="z-50 flex justify-center"
        >
          <EmojiPicker
            theme="dark"
            onEmojiSelect={handleEmojiSelect}
            onClickOutside={() => isEmojiPickerOpen && setIsEmojiPickerOpen(false)}
          />
        </div>
      )}
      {children}
    </EmojiPickerContext.Provider>
  );
};

export default EmojiPickerProvider;
