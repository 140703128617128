import {
  configureStore,
  ThunkAction,
  Action,
  Dispatch,
  AnyAction,
  Middleware,
  isRejectedWithValue,
} from "@reduxjs/toolkit";

import { balanceHistoryApi } from "../api/balanceHistoryApi";
import { bidApi } from "../api/bidApi";
import { cardApi } from "../api/cardApi";
import { cardPlayerApi } from "../api/cardPlayerApi";
import { cardUserApi } from "../api/cardUserApi";
import { cardUserTradeApi } from "../api/cardUserTradeApi";
import { playerApi } from "../api/playerApi";
import { playerBidApi } from "../api/playerBidApi";
import { transactionHistoryApi } from "../api/transactionHistoryApi";
import { userApi } from "../api/userApi";
import { waitlistApi } from "../api/waitlistApi";
import { wishlistApi } from "../api/wishlistApi";
import { fanClubApi } from "../api/fanClubApi";
import { fanClubManagerApi } from "../api/fanClubManagerApi";
import { stripeApi } from "../api/stripeApi";
import { inviteApi } from "@/api/inviteApi";
import { uploaderApi } from "@/api/uploaderApi";
import { scopeChatApi } from "@/api/scopeChatApi";
import { platformSettingsApi } from "@/api/platformSettingsApi";
import { paymentsApi } from "@/api/paymentsApi";
import { fcmApi } from "@/api/fcmApi";
import { reportApi } from "@/api/reportApi";

import uiReducer, { uiSlice } from "../reducers/ui.slice";
import userReducer, { userSlice, logout } from "../reducers/user.slice";
import playerReducer, { playerSlice } from "../reducers/player.slice";
import proshopReducer, { proshopSlice } from "@/reducers/proshop.slice";
import wishlistReducer, { wishlistSlice } from "@/reducers/wishlist.slice";
import notificationsReducer, { notificationsSlice } from "@/reducers/notifications.slice";
import mobileFanClubUiReducer, { mobileFanClubUiSlice } from "@/reducers/mobileFanClubUi.slice";
import fanClubReducer, { fanClubSlice } from "@/reducers/fanClub.slice";
import scopeBlogReducer, { scopeBlogSlice } from "@/reducers/scopeBlog.slice";
import scopeChatReducer, { scopeChatSlice } from "@/reducers/scopeChat.slice";
import platformSettingsReducer, { platformSettingsSlice } from "@/reducers/platformSettings.slice";

import { saveToLocalStorage } from "../utils/localStorage";
import { history } from "@/helpers/history.helpers";
import { handleErrors } from "@/utils/fetchQueryErrorHandler";

const reducer = {
  [userSlice.name]: userReducer,
  [playerSlice.name]: playerReducer,
  [proshopSlice.name]: proshopReducer,
  [wishlistSlice.name]: wishlistReducer,
  [notificationsSlice.name]: notificationsReducer,
  [fanClubSlice.name]: fanClubReducer,
  [mobileFanClubUiSlice.name]: mobileFanClubUiReducer,
  [uiSlice.name]: uiReducer,
  [scopeBlogSlice.name]: scopeBlogReducer,
  [scopeChatSlice.name]: scopeChatReducer,
  [platformSettingsSlice.name]: platformSettingsReducer,

  // API
  [userApi.reducerPath]: userApi.reducer,
  [cardApi.reducerPath]: cardApi.reducer,
  [playerApi.reducerPath]: playerApi.reducer,
  [cardUserApi.reducerPath]: cardUserApi.reducer,
  [cardUserTradeApi.reducerPath]: cardUserTradeApi.reducer,
  [bidApi.reducerPath]: bidApi.reducer,
  [playerBidApi.reducerPath]: playerBidApi.reducer,
  [balanceHistoryApi.reducerPath]: balanceHistoryApi.reducer,
  [cardPlayerApi.reducerPath]: cardPlayerApi.reducer,
  [transactionHistoryApi.reducerPath]: transactionHistoryApi.reducer,
  [waitlistApi.reducerPath]: waitlistApi.reducer,
  [fanClubApi.reducerPath]: fanClubApi.reducer,
  [fanClubManagerApi.reducerPath]: fanClubManagerApi.reducer,
  [wishlistApi.reducerPath]: wishlistApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
  [inviteApi.reducerPath]: inviteApi.reducer,
  [uploaderApi.reducerPath]: uploaderApi.reducer,
  [scopeChatApi.reducerPath]: scopeChatApi.reducer,
  [platformSettingsApi.reducerPath]: platformSettingsApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [fcmApi.reducerPath]: fcmApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
};

const handleUnauthenticated =
  ({ dispatch }: { dispatch: Dispatch }) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const { meta, payload } = action;

    if (payload?.status === 401) {
      if (meta?.arg?.endpointName !== "login") {
        dispatch(logout());
        if (history.navigate) {
          history.navigate("/login", { state: { from: history.location } });
        } else {
          window.location.replace("/login");
        }
      }
      next(action);
    } else {
      next(action);
    }
  };

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { payload } = action;
    handleErrors(payload);

    const statusCode = payload?.status || payload?.data?.statusCode;

    if (statusCode >= 500 || statusCode === "FETCH_ERROR") {
      if (history.navigate) {
        history.navigate("/error", { state: { from: history.location } });
      } else {
        window.location.replace("/error");
      }
    }
  }
  return next(action);
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      handleUnauthenticated,
      rtkQueryErrorLogger,
      userApi.middleware,
      cardApi.middleware,
      playerApi.middleware,
      cardUserApi.middleware,
      cardUserTradeApi.middleware,
      bidApi.middleware,
      playerBidApi.middleware,
      balanceHistoryApi.middleware,
      cardPlayerApi.middleware,
      transactionHistoryApi.middleware,
      waitlistApi.middleware,
      fanClubApi.middleware,
      fanClubManagerApi.middleware,
      wishlistApi.middleware,
      stripeApi.middleware,
      inviteApi.middleware,
      uploaderApi.middleware,
      scopeChatApi.middleware,
      platformSettingsApi.middleware,
      paymentsApi.middleware,
      fcmApi.middleware,
    ),
});

store.subscribe(() => saveToLocalStorage("authToken", store.getState().user.authToken));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
