import React, { MutableRefObject, useEffect, useRef } from "react";
import { useScopeChat } from "@/providers/scopeChat";

import MessageItem from "./MessageItem";
import Spinner from "@/components/UI/Spinner";

import { ScopeChatGroupedMessages, ScopeChatMessage } from "@/types/scopeChat/scopeChat.types";

const NOW = new Date();
const TODAY = new Date().toISOString().split("T")[0];
const YESTERDAY = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString().split("T")[0];

const MessageList = ({
  groupedMessages,
  threadView,
  searchView,
  pinnedView,
  handleClickMessage,
  handleScroll,
  cardView,
}: {
  groupedMessages: ScopeChatGroupedMessages;
  threadView?: boolean;
  searchView?: boolean;
  pinnedView?: boolean;
  cardView?: boolean;
  handleClickMessage?: (message: ScopeChatMessage) => void;
  handleScroll?: (containerRef: MutableRefObject<HTMLDivElement>) => void;
}) => {
  const {
    isMessagesLoading,
    messagesCount,
    isHandlingSearch,
    isHandlingThread,
    handleScrollToBottom,
    setHandleScrollToBottom,
  } = useScopeChat();

  const containerRef = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    const currentScrollPosition = Math.abs(containerRef.current.scrollTop);

    if (handleScrollToBottom && containerRef.current && currentScrollPosition < 100) {
      scrollToBottom();
      setHandleScrollToBottom(false);
    }
  }, [handleScrollToBottom, containerRef.current]);

  useEffect(() => {
    if (!containerRef.current || !handleScroll) return;

    containerRef.current.removeEventListener("scroll", () => handleScroll(containerRef));
    containerRef.current.addEventListener("scroll", () => handleScroll(containerRef));

    return () => {
      if (!containerRef.current || !handleScroll) return;
      containerRef.current.removeEventListener("scroll", () => handleScroll(containerRef));
    };
  }, []);

  const scrollToBottom = (behavior: ScrollBehavior = "auto" as ScrollBehavior) => {
    setTimeout(
      () => containerRef.current.scrollTo({ top: containerRef.current.scrollHeight, behavior }),
      200,
    );
  };

  const sidebarView = pinnedView || searchView || threadView;

  return (
    <div
      ref={containerRef}
      data-testid="chat-messages-list"
      className="flex-1 flex w-full overflow-hidden overflow-y-scroll scrollbar-hidden flex-col-reverse"
    >
      {handleScrollToBottom && !sidebarView && <div className="absolute blur inset-0"></div>}
      {searchView && isHandlingSearch && (
        <div className="flex pt-5 w-full justify-center items-center">
          <Spinner />
        </div>
      )}
      <div className="flex flex-col gap-0 w-full">
        {Object.keys(groupedMessages)
          .sort((a, b) => {
            const dateA = new Date(a);
            const dateB = new Date(b);

            return dateA.getTime() - dateB.getTime();
          })
          .map((date) => {
            const groupDate = new Date(date);
            const groupDateShort = groupDate.toISOString().split("T")[0];
            const isToday = groupDateShort === TODAY;
            const isYesterday = groupDateShort === YESTERDAY;
            const isNotOlderThanAWeek =
              NOW.getTime() - groupDate.getTime() < 7 * 24 * 60 * 60 * 1000;
            const isLastYear = groupDate.getFullYear() < NOW.getFullYear();

            let formattedDate: string;
            let formattedMessageDate: string;

            if (isToday) {
              formattedDate = formattedMessageDate = "Today";
            } else if (isYesterday) {
              formattedDate = formattedMessageDate = "Yesterday";
            } else if (isLastYear) {
              formattedDate = groupDate.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              formattedMessageDate = groupDate.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              });
            } else if (isNotOlderThanAWeek) {
              formattedDate = formattedMessageDate = groupDate.toLocaleDateString("en-US", {
                weekday: "long",
              });
            } else {
              formattedDate = groupDate.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
              });
              formattedMessageDate = groupDate.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              });
            }

            return (
              <div key={date} className="flex flex-col w-full h-full ">
                {!pinnedView && (
                  <div className="divider before:w-1/2 after:w-1/2 xl:before:w-1/12 xl:after:w-11/12 flex-row justify-start">
                    <span className="text-body text-16 font-bold text-neutral-white-60">
                      {formattedDate}
                    </span>
                  </div>
                )}
                <div className="flex flex-col gap-0 w-full">
                  {[...groupedMessages[date]]
                    .sort((a, b) => {
                      const dateA = new Date(a.createdAt);
                      const dateB = new Date(b.createdAt);

                      return dateA.getTime() - dateB.getTime();
                    })
                    .map((message) => (
                      <MessageItem
                        key={message.id}
                        message={message}
                        date={formattedMessageDate}
                        handleClickMessage={handleClickMessage}
                        threadView={threadView}
                        searchView={searchView}
                        pinnedView={pinnedView}
                        cardView={cardView}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        {!sidebarView && <div className="bg-transparent py-10"></div>}
      </div>
      {!searchView &&
        !pinnedView &&
        ((!threadView && isMessagesLoading && !!messagesCount) ||
          (threadView && isHandlingThread)) && (
          <div className="flex pt-5 w-full justify-center items-center">
            <Spinner />
          </div>
        )}
    </div>
  );
};

export default MessageList;
