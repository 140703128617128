import React, { useEffect } from "react";
import { useGeneralUi } from "@/providers/generalUi";

import { Outlet } from "react-router-dom";
import Navbar from "@/components/Page/Navbar";

const PublicPagesLayout = ({
  showNavbar,
  staticBackground,
  gradientBackground,
  bluredBackground,
}: {
  showNavbar?: boolean;
  staticBackground?: boolean;
  gradientBackground?: boolean;
  bluredBackground?: boolean;
}) => {
  const { useStaticBackground, useGradientBackground, useBluredBackground } = useGeneralUi();

  useEffect(() => {
    useStaticBackground(!!staticBackground);
    useGradientBackground(!!gradientBackground);
    useBluredBackground(!!bluredBackground);
  }, [staticBackground, gradientBackground]);

  return (
    <>
      {showNavbar && <Navbar />}
      <div className="xl:page w-full h-full relative">
        <div className="absolute inset-0 z-10 w-full h-full overflow-hidden overflow-y-scroll scrollbar-hidden">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default PublicPagesLayout;
