import React from "react";

import { batch } from "react-redux";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { useFanClub } from "@/providers/fanClub";

import CommentList from "../Feed/Comment/CommentList";
import CommentForm from "../Feed/Comment/CommentForm";
import SectionView from "../../Common/SectionView";
import { CloseIcon } from "@/components/UI/Icons";
import Button from "@/components/UI/Button";

import { ViewTypes } from "@/types/fanClub/fanClub.types";
import {
  selectSelectedPost,
  setIsCommentsOpen,
  setSelectedPost,
} from "@/reducers/mobileFanClubUi.slice";

const MobileCommentsView = () => {
  const dispatch = useAppDispatch();
  const { setCurrentView } = useFanClub();

  const selectedPost = useAppSelector(selectSelectedPost);

  const handleCloseMobileComments = () => {
    batch(() => {
      dispatch(setSelectedPost(null));
      dispatch(setIsCommentsOpen(false));
      setCurrentView(ViewTypes.FEED);
    });
  };

  return selectedPost ? (
    <SectionView
      title="Comments"
      titleAction={
        <Button
          className="btn btn-ghost btn-square btn-sm rounded-md"
          onClick={handleCloseMobileComments}
        >
          <CloseIcon />
        </Button>
      }
    >
      <div className="relative w-full h-full overflow-hidden overflow-y-scroll scrollbar-hidden flex flex-col">
        <div className="flex-1 w-full overflow-hidden overflow-y-scroll scrollbar-hidden px-2">
          <CommentList
            comments={selectedPost?.comments}
            editable={false}
            postUuid={selectedPost.uuid}
            postOwnerUuid={selectedPost.owner.uuid}
          />
        </div>
        <div className="w-full min-h-16 border-top">
          <CommentForm postUuid={selectedPost.uuid} postOwnerUuid={selectedPost.owner.uuid} />
        </div>
      </div>
    </SectionView>
  ) : null;
};

export default MobileCommentsView;
