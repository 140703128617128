/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useLoginMutation } from "../api/userApi";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppNavigate } from "../app/hooks";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useGeneralUi } from "@/providers/generalUi";

import SocialNetworksAuth from "./SocialNetworksAuth";
import InfoModal from "./UI/InfoModal";
import { CheckInACircleIcon } from "./UI/Icons";

import { login as loginUser } from "../reducers/user.slice";
import { setIsGlobalLoading } from "@/reducers/ui.slice";
import { batch } from "react-redux";
import { EMAIL_NOT_VERIFIED_REASON } from "@/constants/app.constants";

interface ErrorData {
  reason?: string;
}

const LoginForm = () => {
  const navigate = useAppNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { isMobileApp } = useGeneralUi();

  const [login, { isLoading, isSuccess, isError, data, error }] = useLoginMutation();

  useEffect(() => {
    if (isError && error) {
      const errorData = "data" in error ? (error.data as ErrorData) : {};

      if (errorData?.reason === EMAIL_NOT_VERIFIED_REASON) {
        setModal(
          <InfoModal
            icon={<CheckInACircleIcon />}
            body="Please confirm your email"
            onToggle={handleCloseModal}
            buttons={[
              {
                onClick: handleCloseModal,
                caption: "Close",
              },
            ]}
          />,
        );
        setIsModalOpen(true);
      }
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      batch(() => {
        dispatch(loginUser(data?.access_token));
        dispatch(setIsGlobalLoading(true));
      });

      const { state } = location;

      if (state?.from) {
        const { pathname, search } = state.from;
        navigate(`${pathname}${search ?? ""}`);
      }
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: {
      emailOrUsername: "",
      password: "",
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.emailOrUsername) {
        errors.emailOrUsername = "Required";
      }

      if (!values.password) {
        errors.password = "Required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      await login(values);
    },
  });

  return (
    <div className="flex flex-col w-full max-w-xl text-left gap-4" data-testid="login-form">
      <div className="flex flex-col gap-3">
        <h4 className="xl:hidden text-heading font-medium">Log in to your Scope account</h4>
        <h2 className="hidden xl:flex text-heading font-medium">Log in to your Scope account</h2>
        <span className="text-body text-16 font-regular opacity-70">
          Log in to access your fan clubs.
        </span>
      </div>
      <form onSubmit={formik.handleSubmit} className="form-control gap-6 w-full">
        <div className="flex flex-col">
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">
              Username Or Email
            </span>
          </label>
          <input
            data-testid="login-username-email-input"
            type="text"
            name="emailOrUsername"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.emailOrUsername}
            autoComplete="username"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.emailOrUsername &&
              formik.touched.emailOrUsername &&
              " !border-red-500 !text-red-900"
            }`}
          />
        </div>
        <div className="flex flex-col">
          <label className="label">
            <span className="text-caps text-13 font-medium text-white opacity-70">
              Current password
            </span>
          </label>
          <input
            type="password"
            name="password"
            data-testid="login-password-input"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            autoComplete="current-password"
            className={`input input-bordered w-full rounded-full ${
              formik.errors.password && formik.touched.password && " !border-red-500 !text-red-900"
            }`}
          />
        </div>
        <div className="flex flex-col">
          <a
            data-testid="forgot-password-btn"
            className="text-body text-14 font-medium cursor-pointer opacity-50"
            onClick={() => navigate("/resetPasswordRequest")}
          >
            Forgot password?
          </a>
        </div>
        <div className="flex flex-col">
          <button
            type="submit"
            data-testid="login-btn"
            disabled={formik.isSubmitting || isLoading}
            className="btn btn-primary rounded-full"
          >
            Sign in
          </button>
        </div>
        {!isMobileApp && <SocialNetworksAuth />}
      </form>
    </div>
  );
};

export default LoginForm;
