import React from "react";
import InfoModal from "../UI/InfoModal";

const MutedModal = ({ onToggle }: { onToggle: () => void }) => {
  return (
    <InfoModal
      title="You have been muted!"
      body={
        <span className="text-start self-start">
          You have been muted and can no longer post messages due to a violation of our{" "}
          <a
            className="link link-primary"
            href="https://www.scopesports.io/docs/terms-and-conditions"
            target="_blank"
          >
            terms of service
          </a>
          . If you believe this is a mistake or wish to appeal, please contact us at{" "}
          <a className="link link-primary" href="mailto:info@scopetennis.com">
            info@scopetennis.com
          </a>
          .
        </span>
      }
      onToggle={onToggle}
      buttons={[
        {
          onClick: onToggle,
          caption: "OK",
        },
      ]}
    />
  );
};

export default MutedModal;
