import CardsView from "@/components/ScopeBlog/FanClub/Views/CardsView";
import ChatView from "@/components/ScopeBlog/FanClub/Views/ChatView";
import FeedView from "@/components/ScopeBlog/FanClub/Views/FeedView";
import PremiumContentView from "@/components/ScopeBlog/FanClub/Views/PremiumContentView";
import { ViewTypes } from "@/types/fanClub/fanClub.types";

export const FAN_CLUB_VIEWS = {
  [ViewTypes.CARDS]: { label: "Athlete Info", element: CardsView },
  [ViewTypes.CHAT]: {
    label: "Athlete Chat",
    element: ChatView,
  },
  [ViewTypes.FEED]: {
    label: "Athlete Feed",
    element: FeedView,
  },
  [ViewTypes.PREMIUM_CONTENT]: {
    label: "Premium Content",
    element: PremiumContentView,
  },
};

export const POST_LENGTH = 1000;
export const MAX_FILES_COUNT = 10;
