import React, { useRef, useState } from "react";

import { batch } from "react-redux";
import { useProfile } from "@/providers/profile";
import { useFanClub } from "@/providers/fanClub";
import { useFormik } from "formik";

import CenterSpinner from "@/components/UI/CenterSpinner";
import Button from "@/components/UI/Button";
import { CloseIcon, PlayIcon } from "@/components/UI/Icons";
import PollForm from "./PollForm";
import VoiceRecorderForm from "./VoiceRecorderForm";

import { PostFormValues, PostType, PostTypes } from "@/types/fanClub/fanClub.types";
import { MAX_FILES_COUNT, POST_LENGTH } from "@/constants/fanClub.constants";

import { snakeCaseToReadableString } from "@/helpers/string.helpers";
import { getLocalMediaUrl } from "@/helpers/file.helpers";
import { generateUuid } from "@/helpers/uuid.helpers";
import { toast } from "@/utils/toast";
import AudioPlayer from "../AudioPlayer";
import { isAudio } from "@/helpers/media.helpers";

const PostForm = ({
  post,
  cancelHandler,
  setIsVisible,
}: {
  post?: PostType;
  cancelHandler?: () => void;
  setIsVisible?: (value: boolean) => void;
}) => {
  const isEdit = post && post.uuid;
  const postAttachments = post
    ? post.attachments.map(({ presignedUrl, videoThumbnails, mimetype, uuid }) => ({
        presignedUrl: videoThumbnails?.md || presignedUrl,
        uuid,
        mimetype,
        isLoading: false,
      }))
    : [];

  const { managedPlayerProfile } = useProfile();
  const { submitPost, uploadProgress, isUploadInProgress } = useFanClub();

  const [mediaPreviews, setMediaPreviews] =
    useState<{ uuid: string; presignedUrl: string; isLoading: boolean; mimetype: string }[]>(
      postAttachments,
    );

  const mediaInputRef = useRef<HTMLInputElement>(document.createElement("input"));

  const prepareInitialValues = () => {
    const initialValues: PostFormValues = {
      postUuid: post?.uuid,
      body: post ? post.body : "",
      type: post ? post.type : PostTypes.MEDIA,
      media: [],
      attachments: post ? postAttachments : [],
      removeAttachmentUuids: [],
    };

    if (post?.type === PostTypes.POLL) {
      try {
        const poll = JSON.parse(post.body);
        initialValues.body = poll?.question;
        initialValues.answers = poll.answers.map((answer: { text: any }) => answer.text);
        initialValues.votes = poll.answers.map((answer: { votes: any }) => answer.votes);
      } catch (error) {
        console.log(error);
      }
    }

    return initialValues;
  };

  const formik = useFormik({
    initialValues: prepareInitialValues(),
    validate: (values: any) => {
      const errors: any = {};
      const requiredFields = ["body", "type"];
      requiredFields.forEach((reqField) => {
        if (!values[reqField]) {
          errors[reqField] = "Required";
        }
        if (values.body.length > POST_LENGTH) {
          errors["body"] = `Max length ${POST_LENGTH} chars`;
        }
        if (values.type === PostTypes.POLL && values?.answers?.length) {
          if (values?.answers?.length < 2) {
            errors["answerCount"] = "There must be two or more options";
          }
          values.answers.forEach((answer: any, index: number) => {
            if (!answer) {
              if (!errors["answers"]) errors["answers"] = [];
              errors["answers"][index] = "Required";
            }
          });
        }
      });
      if (
        !isEdit &&
        [PostTypes.MEDIA, PostTypes.VOICE_NOTE].includes(values.type) &&
        !values.media.length
      ) {
        errors["media"] = "Required";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      await submitPost(values);
      resetForm();
      cancelHandler && cancelHandler();
      setIsVisible && setIsVisible(false);
    },
  });

  const handleChangePostType = (postType: PostTypes) => {
    mediaInputRef.current.value = "";

    batch(() => {
      formik.setFieldValue("media", []);
      formik.setFieldValue("type", postType);
      formik.setFieldValue("removeAttachmentUuids", [
        ...formik.values.removeAttachmentUuids,
        ...postAttachments.map((attachment) => attachment.uuid),
      ]);
      setMediaPreviews([]);
    });
  };

  const handleOnMediaInputChange = (files: FileList | null, singleFile?: boolean) => {
    if (files?.length) {
      const newFiles = Array.from(files);
      const existingFiles = singleFile ? [] : formik.values.media;

      const filesToAdd = newFiles
        .filter(
          (newFile) =>
            !existingFiles.some(
              ({ file }) =>
                file &&
                file.name === newFile.name &&
                file.size === newFile.size &&
                file.lastModified === newFile.lastModified,
            ),
        )
        .map((file) => ({ uuid: generateUuid(), file }));

      const totalFilesCount = existingFiles.length + filesToAdd.length;

      if (totalFilesCount > MAX_FILES_COUNT) {
        toast.warning(`You can only upload a maximum of ${MAX_FILES_COUNT} files`);
        filesToAdd.splice(MAX_FILES_COUNT - existingFiles.length);
      }

      batch(() => {
        formik.setFieldValue("media", [...existingFiles, ...filesToAdd]);
        filesToAdd.forEach(({ uuid, file }) => {
          setMediaPreviews((prev) => [
            ...(singleFile ? [] : prev),
            { uuid, presignedUrl: "", isLoading: true, mimetype: "" },
          ]);

          getLocalMediaUrl(file, (result: string) => {
            setMediaPreviews((prev) =>
              prev.map((preview) =>
                preview.uuid === uuid
                  ? { uuid, presignedUrl: result, isLoading: false, mimetype: file.type }
                  : preview,
              ),
            );
          });
        });
      });

      if (mediaInputRef.current) mediaInputRef.current.value = "";
    }
  };

  const handleRemoveMedia = (index: number) => {
    const updatedMediaPreviews = [...mediaPreviews];

    const removedAttachmentUuid = updatedMediaPreviews.splice(index, 1)?.[0].uuid;

    const media = Array.from(formik.values.media || []).filter(
      ({ uuid }) => uuid !== removedAttachmentUuid,
    );

    const shouldUpdateRemoveAttachmentUuids = formik.values.attachments.some(
      (attachment) => attachment.uuid === removedAttachmentUuid,
    );

    batch(() => {
      formik.setFieldValue("media", media);
      shouldUpdateRemoveAttachmentUuids &&
        formik.setFieldValue("removeAttachmentUuids", [
          ...formik.values.removeAttachmentUuids,
          removedAttachmentUuid,
        ]);
      setMediaPreviews(updatedMediaPreviews);
    });
  };

  return (
    <>
      {managedPlayerProfile ? (
        <div
          className={`card w-full bg-grayscale-900 shadow-xl overflow-hidden overflow-y-scroll scrollbar-hidden ${
            isEdit ? "max-w-3xl" : ""
          }`}
        >
          <div className="card-body">
            <p className="text-lg">{isEdit ? "Edit Post" : ""}</p>
            <form onSubmit={formik.handleSubmit} className="form-control w-full gap-4">
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Select type of a post</span>
                </label>
                <div role="tablist" className="tabs tabs-bordered overflow-x-auto">
                  {(Object.keys(PostTypes) as Array<keyof typeof PostTypes>).map((postType) => (
                    <a
                      key={postType}
                      className={`tab whitespace-nowrap ${
                        formik.values.type === PostTypes[postType] ? "tab-active !border-white" : ""
                      }`}
                      onClick={() => handleChangePostType(PostTypes[postType])}
                    >
                      {snakeCaseToReadableString(PostTypes[postType])}
                    </a>
                  ))}
                </div>
              </div>
              <div className="form-control mb-2">
                <label className="label">
                  <span className="label-text">Description</span>
                </label>
                <textarea
                  className="textarea textarea-bordered"
                  name="body"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.body}
                  placeholder="Enter post here..."
                ></textarea>
                <span className="text-body text-12 text-warning min-h-0 h-3 mt-[2px]">
                  {formik.errors.body && formik.touched.body ? "Required" : ""}
                </span>
              </div>

              {formik.values.type === PostTypes.POLL && <PollForm formik={formik} />}

              {formik.values.type === PostTypes.MEDIA && (
                <div className="form-control">
                  <input
                    ref={mediaInputRef}
                    type="file"
                    multiple
                    className="hidden"
                    accept="image/*, video/*"
                    onChange={(event) => handleOnMediaInputChange(event.target.files)}
                  />
                  <div className="grid grid-cols-2 xl:grid-cols-5 gap-2">
                    {mediaPreviews.map(({ uuid, presignedUrl, isLoading, mimetype }, index) => (
                      <div key={`preview-${index}`} className="relative w-full aspect-square">
                        {isLoading && (
                          <div className="absolute inset-0 backdrop-blur-lg">
                            <CenterSpinner />
                          </div>
                        )}
                        {!!mimetype.match("image") && (
                          <img
                            className="object-contain w-full h-full aspect-square"
                            src={presignedUrl}
                          />
                        )}
                        {!!mimetype.match("video") && (
                          <div className="flex w-full h-full aspect-square relative items-center justify-center">
                            <div className="absolute inset-0 flex justify-center items-center">
                              <PlayIcon className="!w-8 !h-8" />
                            </div>
                            <video
                              className="w-full h-full aspect-square object-contain"
                              preload="metadata"
                              src={`${presignedUrl}#t=0.1`}
                            />
                          </div>
                        )}
                        {!!mimetype.match("audio") && (
                          <AudioPlayer
                            postUuid="new_post"
                            src={presignedUrl}
                            mediaMimetype={mimetype}
                            minified
                          />
                        )}
                        {isUploadInProgress && !!uploadProgress?.[uuid] && (
                          <progress
                            className="absolute bottom-0 left-0 w-full progress progress-primary"
                            value={uploadProgress[uuid] / 100}
                          />
                        )}
                        <div className="absolute top-0 right-0 min-h-min h-6">
                          <Button
                            className="btn btn-xs btn-circle hover:bg-neutral-black-90 p-0 m-0"
                            onClick={() => handleRemoveMedia(index)}
                            disabled={isUploadInProgress}
                          >
                            <CloseIcon />
                          </Button>
                        </div>
                      </div>
                    ))}
                    {mediaPreviews.length < MAX_FILES_COUNT && !isUploadInProgress && (
                      <Button
                        className="btn btn-outline btn-square w-full h-full aspect-square rounded-md"
                        onClick={() => mediaInputRef.current.click()}
                        disabled={isUploadInProgress}
                      >
                        +
                      </Button>
                    )}
                  </div>

                  <span className="text-body text-12 text-warning min-h-0 h-3 mt-[2px]">
                    {formik.errors.media && formik.touched.media ? "Required" : ""}
                  </span>
                </div>
              )}

              {formik.values.type === PostTypes.VOICE_NOTE && (
                <div className="form-control">
                  {mediaPreviews.map(({ uuid, presignedUrl, isLoading, mimetype }, index) => (
                    <div key={`preview-${index}`} className="relative w-full">
                      {isLoading && (
                        <div className="absolute inset-0 backdrop-blur-lg">
                          <CenterSpinner />
                        </div>
                      )}
                      {isAudio({ mimetype }) && (
                        <AudioPlayer
                          postUuid="new_post"
                          src={presignedUrl}
                          mediaMimetype={mimetype}
                        />
                      )}
                      {isUploadInProgress && !!uploadProgress?.[uuid] && (
                        <progress
                          className="absolute bottom-0 left-0 w-full progress progress-primary"
                          value={uploadProgress[uuid] / 100}
                        />
                      )}
                      <div className="absolute top-0 right-0 min-h-min h-6">
                        <Button
                          className="btn btn-xs btn-circle hover:bg-neutral-black-90 p-0 m-0"
                          onClick={() => handleRemoveMedia(index)}
                          disabled={isUploadInProgress}
                        >
                          <CloseIcon />
                        </Button>
                      </div>
                    </div>
                  ))}

                  <span className="text-body text-12 text-warning min-h-0 h-3 mt-[2px]">
                    {formik.errors.media && formik.touched.media ? "Required" : ""}
                  </span>
                </div>
              )}

              {formik.values.type === PostTypes.VOICE_NOTE && (
                <>
                  <input
                    ref={mediaInputRef}
                    type="file"
                    multiple
                    accept="audio/mpeg, audio/x-m4a, audio/aac, audio/wav"
                    onChange={(event) => handleOnMediaInputChange(event.currentTarget.files, true)}
                  />
                  <VoiceRecorderForm handleOnMediaInputChange={handleOnMediaInputChange} />
                </>
              )}

              <div className="flex mobile:flex-col gap-4 items-center w-full">
                <button
                  type="submit"
                  className="btn btn-accent w-full flex-auto"
                  disabled={isUploadInProgress}
                >
                  {isEdit ? "Save" : "Share"}
                </button>
                {isEdit && (
                  <button
                    type="button"
                    disabled={isUploadInProgress}
                    className="btn btn-warning flex-auto"
                    onClick={cancelHandler}
                  >
                    <span className="">Cancel</span>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PostForm;
