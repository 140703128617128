import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";

import { batch } from "react-redux";

import { FilledPauseIcon, FilledPlayIcon, MaximizeIcon } from "../Icons";
import Button from "../Button";
import CenterSpinner from "../CenterSpinner";

import screenfull from "screenfull";
import { convertToString, convertToTimeFormat } from "@/helpers/audio.helpers";
import debounce from "lodash/debounce";

const VideoPlayer_v3 = ({
  src,
  autoPlay,
  fallBackSrc,
  controls = true,
  showPlayIcon,
  ...rest
}: {
  src: string;
  autoPlay?: boolean;
  fallBackSrc?: string;
  controls?: boolean;
  showPlayIcon?: boolean;
  [x: string]: unknown;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProgressDragging, setIsProgressDragging] = useState(false);
  const [playing, setPlaying] = useState(!!autoPlay);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [formattedVideoDuration, setFormattedVideoDuration] = useState("00:00:00");
  const [played, setPlayed] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));
  const sourceRef = useRef<HTMLSourceElement>(document.createElement("source"));

  useEffect(() => {
    if (autoPlay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [autoPlay]);

  const handleOnLoaded = useCallback((event: SyntheticEvent<HTMLVideoElement, Event>) => {
    batch(() => {
      setIsLoading(false);
      setVideoDuration(event.currentTarget.duration);
      setFormattedVideoDuration(
        convertToString(convertToTimeFormat(event.currentTarget.duration), {
          minutes: true,
          seconds: true,
        }),
      );
    });
  }, []);

  const handlePlayPause = useCallback(() => {
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying((prev) => !prev);
  }, [playing]);

  const debouncedSetCurrentTime = useCallback(
    debounce((time: number) => {
      videoRef.current.currentTime = time;
      setIsProgressDragging(false);
    }, 500),
    [],
  );

  const handleTimeUpdate = useCallback(
    (currentTime: number) => {
      if (isProgressDragging) return;

      batch(() => {
        setCurrentTime(currentTime);
        setProgress((currentTime / videoDuration) * 100 || 0);
      });
    },
    [isProgressDragging],
  );

  const handleProgressClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedTime = Number(event.target.value);

      batch(() => {
        setCurrentTime(selectedTime);
        setProgress((selectedTime / videoDuration) * 100);
        setIsProgressDragging(true);
      });

      debouncedSetCurrentTime(selectedTime);
    },
    [videoDuration, debouncedSetCurrentTime],
  );

  const handleToggleFullscreen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (videoRef.current.webkitEnterFullScreen) {
      videoRef.current.webkitEnterFullScreen();
    } else {
      screenfull.request(videoRef.current);
    }
  }, []);

  return (
    <div
      className="relative overflow-hidden w-full h-full flex flex-col"
      data-testid="video-player"
    >
      <div
        className="relative flex-1 aspect-video w-full bg-black overflow-hidden"
        onClick={handlePlayPause}
      >
        <video
          ref={videoRef}
          className="w-full h-full object-contain"
          preload="metadata"
          onLoadedData={handleOnLoaded}
          onPause={() => setPlaying(false)}
          onPlay={() => {
            setPlaying(true);
            setPlayed(true);
          }}
          onTimeUpdate={(event) => handleTimeUpdate(event.currentTarget.currentTime)}
          onError={() => {
            if (!fallBackSrc || fallBackSrc === videoRef.current.src) return;

            videoRef.current.src = fallBackSrc;
          }}
          loop
          {...rest}
        >
          <source ref={sourceRef} src={src} type="video/mp4" />
        </video>
      </div>
      {showPlayIcon && !played && (
        <div
          className="absolute inset-0 flex items-center justify-center"
          onClick={handlePlayPause}
        >
          <div className="flex items-center justify-center w-12 h-12 bg-element-background rounded-full">
            <FilledPlayIcon />
          </div>
        </div>
      )}
      {controls && (
        <div className={`flex flex-col justify-end controls-v2`}>
          <div className="flex w-full p-0 m-0 gap-1">
            <input
              className="absolute -translate-y-1/2 cursor-pointer"
              type="range"
              style={{
                background:
                  "linear-gradient(to right, #fff 0%, #fff " +
                  progress +
                  "%, rgba(0, 0, 0, 0.4) " +
                  progress +
                  "%, rgba(0, 0, 0, 0.4))",
              }}
              min={0}
              max={videoDuration}
              step={0.01}
              value={currentTime}
              onChange={handleProgressClick}
            />
          </div>
          <div className="flex items-center justify-between px-5 py-6 xl:py-[28px] h-6 max-h-6 xl:h-20 xl:max-h-[80px]">
            <div className="flex gap-4 items-center">
              <Button
                className="btn btn-sm btn-ghost !btn-square rounded-md"
                onClick={handlePlayPause}
              >
                {playing ? <FilledPauseIcon /> : <FilledPlayIcon />}
              </Button>
              <span className="text-body text-14 font-regular text-neutral-white-pure-white">
                {`${convertToString(convertToTimeFormat(currentTime), {
                  minutes: true,
                  seconds: true,
                })} / ${formattedVideoDuration}`}
              </span>
            </div>
            <Button
              className="btn btn-sm btn-ghost !btn-square rounded-md"
              onClick={handleToggleFullscreen}
            >
              <MaximizeIcon />
            </Button>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center backdrop-blur-lg">
          <CenterSpinner />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer_v3;
