import React, { ReactNode } from "react";

import Button from "@/components/UI/Button";
import Container from "@/components/UI/Container";
import { ArrowIcon } from "@/components/UI/Icons";

interface Buttons {
  icon: ReactNode;
  onClick: () => void;
}

const MobileHeader = ({
  label,
  handleBackClick,
  buttons,
  className,
  ...props
}: {
  label: string | ReactNode;
  handleBackClick: () => void;
  buttons?: Buttons[];
  className?: string;
  [x: string]: any;
}) => {
  const onBackClick = () => {
    handleBackClick();
  };

  return (
    <Container
      className={`flex items-center justify-between p-4 ${className ?? ""}`}
      border="rounded-xl"
      {...props}
    >
      <div className="flex items-center gap-3">
        <Button onClick={onBackClick} className="btn btn-square btn-ghost btn-sm h-6 w-6 !min-h-6">
          <ArrowIcon className="rotate-180 stroke-pure-white" />
        </Button>
        <span className="text-caps text-13 font-heavy text-neutral-white-pure-white text-start">
          {label}
        </span>
      </div>
      <div className="flex items-center justify-between h-6">
        {buttons && buttons.length > 0 && (
          <div className="w-full">
            {buttons.map(({ icon, onClick }: Buttons, index: number) => (
              <Button
                key={`button-${index}`}
                className="btn btn-square btn-ghost btn-sm h-6 w-6 !min-h-6"
                onClick={onClick}
              >
                {icon}
              </Button>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default MobileHeader;
