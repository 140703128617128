import React, { useState } from "react";
import { useProfile } from "@/providers/profile";
import { useGeneralUi } from "@/providers/generalUi";

import { ScopeIcon } from "@/components/UI/Icons";

const GreetingsModal = () => {
  const { isEulaAgreed, handleExplore } = useProfile();
  const { isMobileApp } = useGeneralUi();

  const [isEulaAgreedState, setIsEulaAgreedState] = useState<boolean>(isEulaAgreed);

  return (
    <div className="modal backdrop-blur-md modal-open">
      <div className="modal-box text-center bg-grayscale-900 max-w-[480px] mx-auto flex flex-col gap-4 p-4">
        <div className="relative flex items-center justify-center overflow-hidden rounded-lg border border-neutral-white-12 bg-grayscale-1000 py-[52px]">
          <ScopeIcon className="!w-10 !h-10 z-10" />
        </div>
        <div className="flex flex-col gap-2 items-start text-start">
          <span className="text-body text-16 font-medium text-neutral-white-pure-white">
            Welcome to Scope!
          </span>
          <span className="text-body text-14 font-regular text-neutral-white-70">
            {isMobileApp
              ? "Log in to your account to access your fan clubs. If you don’t own any access passes, go to scopesports.io to purchase them."
              : "Engage in the Scope Community with other fans and athletes. Explore different fan clubs to connect and co-create with your favorite players!"}
          </span>
        </div>

        <div className="form-control">
          <label className="label cursor-pointer gap-3 justify-start">
            <input
              type="checkbox"
              className="checkbox checkbox-primary"
              checked={isEulaAgreedState}
              onChange={(event) => setIsEulaAgreedState(event.target.checked)}
            />
            <span className="label-text text-start">
              I agree to{" "}
              <a
                className="link link-primary"
                href="https://www.scopesports.io/docs/terms-and-conditions"
                target="_blank"
              >
                terms and conditions
              </a>{" "}
              and{" "}
              <a
                className="link link-primary"
                href="https://www.scopesports.io/docs/privacy-policy"
                target="_blank"
              >
                privacy policy
              </a>
              .
            </span>
          </label>
        </div>

        <button
          onClick={() => handleExplore(false, isEulaAgreedState)}
          className="btn btn-primary btn-block !h-10 min-h-[40px] rounded-full"
          disabled={!isEulaAgreedState}
        >
          Explore
        </button>
      </div>
    </div>
  );
};

export default GreetingsModal;
