import React, { useEffect } from "react";

import { useAppNavigate } from "@/app/hooks";
import { usePlayer } from "@/app/hooks/usePlayer";
import { useProfile } from "@/providers/profile";

import MemberCard from "@/components/PlayerDashboard/MemberCard";
import EarningChart from "@/components/PlayerDashboard/EarningChart";
import ShowMoreAnchor from "@/components/UI/ShowMoreAnchor";

import { MemberProfileType } from "@/types/user/profile.types";

const PlayerDashboard = () => {
  const navigate = useAppNavigate();
  const { managedPlayerProfile } = useProfile();

  const { members, setSkip } = usePlayer();

  useEffect(() => {
    setSkip(0);
  }, []);

  return (
    <div className="flex flex-col items-center w-full xl:px-10 xl:pb-10">
      {managedPlayerProfile ? (
        <>
          <div className="flex justify-between py-6 xl:py-10 w-full items-center">
            <span className="text-white text-caps !font-huben text-16">Earnings</span>
          </div>
          <EarningChart />
          <div className="flex justify-between py-6 xl:py-10 w-full">
            <span className="text-white text-caps !font-huben text-16">Fan Club members</span>
            <ShowMoreAnchor
              className="mobile:hidden"
              onClick={() => navigate("/dashboard/members")}
            />
          </div>
          <div className="flex flex-col justify-between w-full gap-6">
            <div className="grid grid-cols-2 xl:grid-cols-6 2xl:grid-cols-8 gap-2 justify-center">
              {members?.length
                ? members.map((member: MemberProfileType) => (
                    <MemberCard key={member.user.email} profile={member.user} />
                  ))
                : null}
            </div>
            <div className="flex items-center w-full">
              <ShowMoreAnchor
                className="tablet:hidden"
                onClick={() => navigate("/dashboard/members")}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PlayerDashboard;
