import React, { useEffect } from "react";

import { useAppSelector, useAppNavigate } from "../app/hooks";
import { useGeneralUi } from "@/providers/generalUi";

import LoginForm from "@/components/LoginForm";
import MobilePublicNavbar from "@/components/Page/Mobile/MobilePublicNavbar";
import PublicNavbar from "@/components/Page/PublicNavbar";
import AppLink from "@/components/AppLink";

import { selectIsAuthorized, selectUserProfile } from "../reducers/user.slice";

const Login = () => {
  const navigate = useAppNavigate();

  const { setHeader } = useGeneralUi();

  const isAuthorized = useAppSelector(selectIsAuthorized);
  const userProfile = useAppSelector(selectUserProfile);

  useEffect(() => {
    if (isAuthorized && userProfile?.isOnboarded) {
      navigate("/");
    }
  }, [isAuthorized, userProfile]);

  useEffect(() => {
    setHeader(null);
  }, []);

  return (
    <div className="flex flex-col xl:p-10 h-full">
      <MobilePublicNavbar>
        <AppLink to="/sign-up" className="text-caps text-13 font-medium text-white">
          Sign up
        </AppLink>
      </MobilePublicNavbar>
      <PublicNavbar>
        <AppLink to="/sign-up" className="btn btn-primary btn-sm rounded-full">
          Sign up
        </AppLink>
      </PublicNavbar>
      <div className="flex-1 flex w-full justify-center py-4 xl:py-10 px-4 xl:mt-0">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
