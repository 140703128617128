import Button from "@/components/UI/Button";
import React from "react";

const CardSelector = ({
  currentCardUuid,
  cards,
}: {
  currentCardUuid: string;
  cards: { name: string; uuid: string; onClickHandler: (uuid: string) => void }[];
}) => {
  return (
    <div
      className="flex items-center justify-between border-neutral-white-20 bg-element-background backdrop-blur-[100px] overflow-hidden overflow-x-scroll scrollbar-hidden
      rounded-[16px] border p-2 gap-2 max-w-[90vw]
      xl:max-w-[70%] 
      4k:max-w-[70%] 4k:rounded-[32px] 4k:border-[2px] 4k:p-4 4k:gap-4"
    >
      {cards.map((card) => (
        <Button
          key={card.uuid}
          className={`flex w-full text-body font-bold items-center justify-center whitespace-nowrap 
            py-2.5 px-5 rounded-[12px] text-14 leading-5
            4k:py-5 4k:px-10 4k:rounded-3xl 4k:text-[28px] 4k:leading-10 ${
              currentCardUuid === card.uuid
                ? "bg-neutral-white-pure-white text-grayscale-1000 border border-neutral-white-12"
                : "text-neutral-white-pure-white"
            }`}
          onClick={() => card.onClickHandler(card.uuid)}
        >
          {card.name}
        </Button>
      ))}
    </div>
  );
};

export default CardSelector;
