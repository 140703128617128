/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from "react";

import { EmojiData } from "@/components/UI/EmojiPickerModal";
import { CloseIcon, EmojiReactionFilled } from "@/components/UI/Icons";
import EmojiPicker from "@emoji-mart/react";

const MobileCommentFormInput = ({
  formik,
  showCloseButton,
  cancelHandler,
  disabled,
}: {
  formik: any;
  showCloseButton?: boolean;
  disabled?: boolean;
  cancelHandler?: () => void;
}) => {
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(document.createElement("input"));

  const handleTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCursorPosition(event.target.selectionStart || 0);
    formik.handleChange(event);
  };

  const handleInputClick = (event: any) => {
    setCursorPosition(event.target.selectionStart);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> | any) => {
    if (event.key === "Enter" && !(event.shiftKey || formik.isSubmitting)) {
      event.preventDefault();
      formik.handleSubmit();
    }
    setCursorPosition(event.target.selectionStart || 0);
  };

  const handleToggleEmoji = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsEmojiPickerOpen((prev) => !prev);
    focusInput();
  };

  const focusInput = (offset = 0) => {
    setTimeout(() => {
      inputRef.current.focus();
      const newCursorPosition = cursorPosition + offset;
      setCursorPosition(newCursorPosition);
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleEmojiSelect = (selectedEmoji: EmojiData) => {
    const { native } = selectedEmoji;

    const newValue =
      formik.values.content.slice(0, cursorPosition) +
      native +
      formik.values.content.slice(cursorPosition);
    formik.setFieldValue("content", newValue);
    setIsEmojiPickerOpen(false);

    focusInput(native.length);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="flex gap-2 items-center w-full px-4 py-3">
      {isEmojiPickerOpen && (
        <div className="absolute left-1 z-50 bottom-16 w-full">
          <EmojiPicker onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
      <div className="flex relative rounded-full w-full bg-neutral-white-5 backdrop-blur-lg border border-neutral-white-12">
        <div className="flex items-center w-full min-h-[40px] xl:min-h-12">
          <input
            ref={inputRef}
            type="text"
            name="content"
            onClick={handleInputClick}
            onChange={handleTextChanged}
            onBlur={formik.handleBlur}
            onKeyDown={handleKeyDown}
            value={formik.values.content}
            placeholder="Add a comment..."
            className="ios-no-scroll pl-4 pr-1 py-2 xl:py-3 xl:leading-6 min-h-[40px] xl:min-h-12 w-full bg-transparent box-border
											overflow-hidden text-body text-14 font-medium focus:outline-none"
            disabled={disabled}
          />
        </div>
        <div className="flex justify-end items-start py-2 pr-3 gap-2">
          <button
            type="button"
            className="btn btn-circle btn-ghost w-6 h-6 min-h-6"
            onClick={handleToggleEmoji}
            disabled={disabled}
          >
            <EmojiReactionFilled />
          </button>
        </div>
      </div>
      {showCloseButton && (
        <button type="button" className="btn btn-ghost btn-circle btn-sm" onClick={cancelHandler}>
          <CloseIcon />
        </button>
      )}
    </form>
  );
};

export default MobileCommentFormInput;
