import React, {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector, useClickOutside } from "@/app/hooks";
import { batch } from "react-redux";

import {
  selectIsMobileBottomMenuOpen,
  setIsMobileBottomMenuOpen as setIsMobileBottomMenuOpenAction,
} from "@/reducers/ui.slice";

export interface MobileBottomMenuType {
  items: ReactNode;
  bodyClass?: string;
  onCloseMenu?: () => void;
}

interface MobileBottomMenuContextType {
  setIsMobileBottomMenuOpen: (value: boolean) => void;
  setMobileBottomMenu: (menuProps: MobileBottomMenuType) => void;
}

const MobileBottomMenuContext = createContext<MobileBottomMenuContextType | undefined>(undefined);

export const useMobileBottomMenu = (): MobileBottomMenuContextType => {
  const context = useContext(MobileBottomMenuContext);

  if (!context) {
    throw new Error("useMobileBottomMenu must be used within a MobileBottomMenuProvider");
  }

  return context;
};

const MobileBottomMenuProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  const isMobileBottomMenuOpen = useAppSelector(selectIsMobileBottomMenuOpen);

  const [items, setItems] = useState<string | ReactNode | null>(null);
  const [bodyClass, setBodyClass] = useState<string>("");
  const [onCloseMenu, setOnCloseMenu] = useState<() => void | null>();

  const menuRef = useRef<HTMLDivElement>(null);

  const handleCloseMenu = () => {
    onCloseMenu && onCloseMenu();
    setIsMobileBottomMenuOpen(false);
  };

  const setIsMobileBottomMenuOpen = (value: boolean) => {
    dispatch(setIsMobileBottomMenuOpenAction(value));
  };

  const setMobileBottomMenu = (menuProps: MobileBottomMenuType) => {
    const { items, bodyClass, onCloseMenu } = menuProps;

    batch(() => {
      setItems(items);
      setBodyClass(bodyClass || "");
      onCloseMenu && setOnCloseMenu(() => () => onCloseMenu());
    });
  };

  useClickOutside(menuRef, handleCloseMenu);

  return (
    <MobileBottomMenuContext.Provider
      value={{
        setIsMobileBottomMenuOpen,
        setMobileBottomMenu,
      }}
    >
      {isMobileBottomMenuOpen && (
        <div
          ref={menuRef}
          className={`absolute bottom-0 left-0 z-50 w-full bg-grayscale-900 ${bodyClass ?? ""}`}
        >
          {items}
        </div>
      )}
      {children}
    </MobileBottomMenuContext.Provider>
  );
};

export default MobileBottomMenuProvider;
