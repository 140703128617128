import React, { useFormik } from "formik";

import { useEffect } from "react";
import { useCreateResetPasswordMutation } from "../api/userApi";

import { GmailIcon, OutlookIcon } from "./UI/Icons";
import MobilePublicNavbar from "./Page/Mobile/MobilePublicNavbar";
import PublicNavbar from "./Page/PublicNavbar";
import AppLink from "./AppLink";

import { handleErrors } from "../utils/fetchQueryErrorHandler";

const ResetPasswordForm = () => {
  const [createResetPassword, { isLoading, isError, error, isSuccess }] =
    useCreateResetPasswordMutation();

  useEffect(() => {
    if (isError) {
      handleErrors(error);
    }
  }, [isLoading]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.email) {
        errors.email = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      await createResetPassword(values);
    },
  });

  return (
    <div className="flex flex-col xl:p-10 h-full">
      {!isSuccess && (
        <>
          <MobilePublicNavbar>
            <>
              <AppLink
                to="/login"
                className="text-caps text-13 font-medium text-white underline"
              >
                Sign in
              </AppLink>
              <AppLink
                to="/sign-up"
                className="text-caps text-13 font-medium text-white underline"
              >
                Sign up
              </AppLink>
            </>
          </MobilePublicNavbar>
          <PublicNavbar>
            Already have an account?
            <AppLink to={"/login"} className="underline">
              Sign in
            </AppLink>
          </PublicNavbar>
        </>
      )}
      <div className="w-full max-w-xl text-left m-auto mt-20 xl:mt-24 px-5">
        <div className="container">
          <div className="w-full ">
            {isSuccess ? (
              <>
                <div className="flex flex-col gap-3" data-testid="reset-link-sent">
                  <h4 className="xl:hidden text-heading font-medium">Reset Link Sent</h4>
                  <h2 className="hidden xl:flex text-heading font-medium">Reset Link Sent</h2>
                  <span className="text-body text-16 font-regular opacity-70">
                    We just sent a link to{" "}
                    <span className="text-secondary accent" data-testid="reset-sent-email">
                      {formik.values.email}
                    </span>
                    <br />
                    Please check your email for instructions to reset your password.
                  </span>
                </div>

                <div className="flex w-full flex-col xl:flex-row justify-between mt-6 xl:mt-10 gap-4 xl:gap-3">
                  <a
                    type="button"
                    href="https://www.gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-active btn-ghost rounded-full w-full xl:w-1/2"
                  >
                    <GmailIcon />
                    Open Gmail
                  </a>
                  <a
                    type="button"
                    className="btn btn-active btn-ghost rounded-full w-full xl:w-1/2"
                  >
                    <OutlookIcon />
                    Open Outlook
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col gap-3">
                  <h4 className="xl:hidden text-heading font-medium">Reset your password</h4>
                  <h2 className="hidden xl:flex text-heading font-medium">Reset your password</h2>
                  <span className="text-body text-16 font-regular opacity-70">
                    We will send instructions to the email address below to reset your password.
                  </span>
                </div>
                <form
                  data-testid="reset-password-form"
                  onSubmit={formik.handleSubmit}
                  className="form-control w-full mt-6 xl:mt-10 p-0"
                >
                  <label className="label">
                    <span className="text-caps text-13 font-medium text-white opacity-70">
                      Email Address
                    </span>
                  </label>
                  <input
                    data-testid="reset-email-field"
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={`input input-bordered w-full rounded-full ${
                      formik.errors.email &&
                      formik.touched.email &&
                      " !border-red-500 !text-red-900"
                    }`}
                  />
                  <button
                    data-testid="reset-password-btn"
                    type="submit"
                    disabled={formik.isSubmitting || isLoading}
                    className="btn btn-primary rounded-full mt-6 xl:mt-10"
                  >
                    Get a Reset Link
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
