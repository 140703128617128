import React from "react";

import { useProfile } from "@/providers/profile";
import { useScopeChat } from "@/providers/scopeChat";
import { useGeneralUi } from "@/providers/generalUi";

import SearchInput from "./Inputs/Search/SearchInput";
import MessageList from "./MessageList/MessageList";
import { ChatInput, ChatInputPlaceholder } from "./Inputs/ChatInput";
import CenterSpinner from "@/components/UI/CenterSpinner";
import SectionView from "../Common/SectionView";

const MainView = () => {
  const { isAuthorized } = useProfile();
  const { isMobile } = useGeneralUi();
  const { groupedMessages, handleMessagesScroll, sidebarView } = useScopeChat();

  return (
    <SectionView
      title={isMobile ? "" : "Scope Chat"}
      containerClassName={`!max-w-full h-full flex-col justify-between relative ${
        sidebarView ? "hidden xl:flex" : ""
      }`}
    >
      <div className="flex-1 w-full h-full flex flex-col overflow-hidden">
        <SearchInput />
        <div className="absolute bottom-0 w-full p-4 xl:p-6 z-20">
          {isAuthorized ? <ChatInput /> : <ChatInputPlaceholder />}
        </div>
        {groupedMessages ? (
          <MessageList
            groupedMessages={groupedMessages}
            handleScroll={isAuthorized ? handleMessagesScroll : undefined}
          />
        ) : (
          <CenterSpinner />
        )}
      </div>
    </SectionView>
  );
};

export default MainView;
