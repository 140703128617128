export const isVideo = (entity: { mimetype?: string }) => {
  return entity?.mimetype && entity.mimetype.match("video");
};

export const isImage = (entity: { mimetype?: string }) => {
  return entity?.mimetype && entity.mimetype.match("image");
};

export const isAudio = (entity: { mimetype?: string }) => {
  return entity?.mimetype && entity.mimetype.match("audio");
};
