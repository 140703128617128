import React from "react";

import CommentForm from "./CommentForm";
import { CommentType } from "@/types/fanClub/fanClub.types";
import Container from "@/components/UI/Container";

interface EditCommentModalProps {
  onToggle: () => void;
  comment: CommentType;
  postUuid: string;
  postOwnerUuid: string;
}

const EditCommentModal = ({
  onToggle,
  comment,
  postUuid,
  postOwnerUuid,
}: EditCommentModalProps) => {
  return (
    <Container className="w-full flex-col p-4">
      <span className="text-lg">Edit comment</span>
      <CommentForm
        comment={comment}
        cancelHandler={onToggle}
        postUuid={postUuid}
        postOwnerUuid={postOwnerUuid}
      />
    </Container>
  );
};

export default EditCommentModal;
