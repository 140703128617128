import React from "react";
import { useGeneralUi } from "@/providers/generalUi";

import CommentItem from "./CommentItem";

import { CommentType } from "@/types/fanClub/fanClub.types";

const CommentList = ({
  comments,
  postUuid,
  postOwnerUuid,
  replies,
  editable,
}: {
  comments: CommentType[];
  postUuid: string;
  postOwnerUuid: string;
  replies?: boolean;
  editable: boolean;
}) => {
  const { isMobile } = useGeneralUi();

  return (
    <div
      className={`flex ${
        isMobile ? "h-full flex-col-reverse overflow-hidden overflow-y-scroll" : "flex-col"
      }`}
    >
      {comments &&
        comments.map((comment) => (
          <CommentItem
            key={comment.uuid}
            comment={comment}
            postUuid={postUuid}
            postOwnerUuid={postOwnerUuid}
            editable={editable}
            replies={replies}
          />
        ))}
    </div>
  );
};

export default CommentList;
