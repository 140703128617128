import { useEffect, useState } from "react";

export default function useOnScreen(elementRef: any) {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);

  const handleIntersection = ([entry]: any) => {
    setIntersecting(entry.isIntersecting);
  };

  useEffect(() => {
    const options = {
      threshold: 0.2, // Trigger when 20% of the element is visible
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [elementRef.current]);

  return isIntersecting;
}
