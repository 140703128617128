import { FRONTEND_URL } from "@/constants/app.constants";
import * as Sentry from "@sentry/react";

export const initSentry = () =>
  (import.meta.env.VITE_NODE_ENV === "production" || import.meta.env.VITE_NODE_ENV === "stage") &&
  Sentry.init({
    dsn:
      import.meta.env.VITE_SENTRY_DSN ||
      "https://ca259db48b5a4ed09dcbe3eda9553107@o4505488393437184.ingest.sentry.io/4505488394616832",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [FRONTEND_URL],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
