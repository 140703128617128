import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";
import { useProfile } from "@/providers/profile";

import ProfileAvatar from "@/components/User/ProfileAvatar";
import { GradientScopeIcon } from "@/components/UI/Icons";
import Container from "@/components/UI/Container";

import { PlayerProfileType } from "@/types/player/player.types";
import {
  selectPlayerProfile,
  setPlayerProfile,
  selectGroupedPlayerProfiles,
} from "@/reducers/scopeBlog.slice";

const PlayerItem = ({
  playerProfile,
  isActive,
  onClick,
}: {
  playerProfile: PlayerProfileType;
  isActive: boolean;
  onClick: (playerProfile: PlayerProfileType) => void;
}) => {
  return (
    <div
      data-testid={playerProfile.uuid}
      className={`flex relative px-1 xl:px-0 2xl:px-1 w-full xl:w-10 2xl:w-full xl:rounded-full 2xl:rounded-none overflow-visible ${
        isActive
          ? "xl:ring-offset-grayscale-1000 xl:ring-white xl:ring-1 xl:ring-offset-4 2xl:ring-offset-transparent 2xl:ring-offset-0 2xl:ring-0"
          : "xl:opacity-50 2xl:opacity-100"
      }`}
      onClick={() => onClick(playerProfile)}
    >
      {isActive && <ActiveItemBackground />}
      <div className="flex w-full items-center cursor-pointer gap-3 p-1 xl:p-0">
        <ProfileAvatar
          profile={playerProfile}
          imageClass="!w-8 !h-8 xl:!w-10 xl:!h-10 2xl:!w-8 2xl:!h-8"
        />
        <div className="card-body p-0 gap-0 items-start xl:hidden 2xl:flex">
          <span className="text-body text-16 font-medium text-start truncate block w-[160px]">
            {playerProfile.name}
          </span>
        </div>
      </div>
    </div>
  );
};

const ScopeItem = () => {
  return (
    <div className="flex w-full items-center cursor-pointer gap-3">
      <GradientScopeIcon className="!w-8 !h-8 xl:!w-10 xl:!h-10 2xl:!w-8 2xl:!h-8" />
      <div className="card-body p-0 gap-0 items-start xl:hidden 2xl:flex">
        <span className="text-body text-16 font-medium text-start truncate block w-[160px]">
          Scope Chat
        </span>
      </div>
    </div>
  );
};

const ActiveItemBackground = () => (
  <div className="absolute top-0 left-0 bottom-0 right-0 xl:-top-[4px] xl:-left-[8px] xl:-bottom-[4px] xl:-right-[8px] border border-neutral-white-12 bg-neutral-white-12 rounded-full flex xl:hidden 2xl:flex"></div>
);

const GroupLabel = ({ label, className }: { label: string; className?: string }) => (
  <div
    className={`w-full text-start xl:text-center 2xl:text-start text-button text-10 2xl:text-12 font-heavy text-neutral-white-50 items-center justify-start xl:justify-center 2xl:justify-start ${
      className ? className : ""
    }`}
  >
    {label}
  </div>
);

const BlogSelector = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const { managedPlayerProfile } = useProfile();

  const groupedPlayerProfiles = useAppSelector(selectGroupedPlayerProfiles);
  const playerProfile = useAppSelector(selectPlayerProfile);

  const [currentManagedPlayerProfile, setCurrentManagedPlayerProfile] =
    useState<PlayerProfileType>();

  const { uuid: currentPlayerUuid } = playerProfile || {};

  useEffect(() => {
    if (!managedPlayerProfile?.uuid) {
      setCurrentManagedPlayerProfile(undefined);
      return;
    }
    setCurrentManagedPlayerProfile(
      groupedPlayerProfiles.managed?.find((player) => player.uuid === managedPlayerProfile.uuid),
    );
  }, [managedPlayerProfile]);

  const handleScopeChatClick = () => {
    navigate("/scope-chat");
  };

  const handlePlayerFanClubClick = (playerProfile: PlayerProfileType) => {
    dispatch(setPlayerProfile(playerProfile));
    navigate(`/fan-club/${playerProfile.urlAlias || playerProfile.uuid}`);
  };

  return (
    <Container className="py-6 xl:py-0 px-4 xl:px-0 xl:sticky xl:min-w-[80px] xl:max-w-[80px] 2xl:min-w-[250px] 2xl:max-w-[250px] overflow-hidden overflow-y-scroll scrollbar-hidden flex flex-col h-full w-full">
      <div className="flex flex-col items-center justify-center text-white gap-4 w-full py-5 xl:pl-3 xl:pr-4 2xl:p-4 2xl:gap-5">
        <GroupLabel label="General Chat" className="flex mobile:hidden xl:hidden 2xl:flex" />
        <GroupLabel label="General" className="hidden 2xl:hidden xl:flex" />

        <div
          className={`flex relative xl:px-0 2xl:px-1 w-full xl:w-10 2xl:w-full xl:rounded-full 2xl:rounded-none overflow-visible ${
            currentPlayerUuid === undefined
              ? "xl:ring-offset-grayscale-1000 xl:ring-white xl:ring-1 xl:ring-offset-4 2xl:ring-offset-transparent 2xl:ring-offset-0 2xl:ring-0"
              : "xl:opacity-50 2xl:opacity-100"
          }`}
          onClick={handleScopeChatClick}
        >
          {currentPlayerUuid === undefined && <ActiveItemBackground />}
          <ScopeItem />
        </div>

        {!!groupedPlayerProfiles.managed?.length && !currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="Managed Athletes" className="flex xl:hidden 2xl:flex" />
            <GroupLabel label="Managed" className="hidden 2xl:hidden xl:flex" />

            {groupedPlayerProfiles.managed.map((playerProfile: PlayerProfileType) => (
              <PlayerItem
                key={playerProfile.uuid}
                playerProfile={playerProfile}
                isActive={currentPlayerUuid === playerProfile.uuid}
                onClick={handlePlayerFanClubClick}
              />
            ))}
          </>
        )}

        {!!groupedPlayerProfiles.owned?.length && !currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="My Athletes" className="flex xl:hidden 2xl:flex" />
            <GroupLabel label="Owned" className="hidden 2xl:hidden xl:flex" />

            {groupedPlayerProfiles.owned.map((playerProfile: PlayerProfileType) => (
              <PlayerItem
                key={playerProfile.uuid}
                playerProfile={playerProfile}
                isActive={currentPlayerUuid === playerProfile.uuid}
                onClick={handlePlayerFanClubClick}
              />
            ))}
          </>
        )}

        {!!groupedPlayerProfiles.rest?.length && !currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="Athletes" className="xl:flex" />

            {groupedPlayerProfiles.rest.map((playerProfile: PlayerProfileType) => (
              <PlayerItem
                key={playerProfile.uuid}
                playerProfile={playerProfile}
                isActive={currentPlayerUuid === playerProfile.uuid}
                onClick={handlePlayerFanClubClick}
              />
            ))}
          </>
        )}

        {currentManagedPlayerProfile?.uuid && (
          <>
            <GroupLabel label="Athletes" className="xl:flex" />

            <PlayerItem
              playerProfile={currentManagedPlayerProfile}
              isActive={currentPlayerUuid === currentManagedPlayerProfile.uuid}
              onClick={handlePlayerFanClubClick}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default BlogSelector;
