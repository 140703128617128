import React, { useState } from "react";
import { usePaymentForm } from "@/providers/PaymentFormProvider";
import { usePayment } from "@/providers/payment";

import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Button from "@/components/UI/Button";

import {
  Appearance,
  PaymentIntentResult,
  Stripe,
  StripeElements,
  loadStripe,
} from "@stripe/stripe-js";
import { StripePayment } from "@/types/payment/payment.types";
import { toast } from "@/utils/toast";
import { FRONTEND_URL } from "@/constants/app.constants";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string);

const appearance: Appearance = {
  variables: {
    colorPrimary: "#9C9998",
    colorText: "#9AADBA",
    colorDanger: "#df1b41",
    colorTextPlaceholder: "#9C9998",
    colorBackground: "#242424",
    fontSizeBase: "1rem",
    spacingUnit: "5px",
  },
  rules: {
    ".Label": {
      textTransform: "uppercase",
      color: "#A6ADBA",
    },
    ".Input": {
      borderRadius: "60px",
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      border: "1px solid rgba(255, 255, 255, 0.12)",
    },
  },
};

const PaymentForm = () => {
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const { handleProcessPayment, handleApplyCoupon, isPaymentProcessing } = usePayment();
  const { handleOnSuccess, handleOnFailure } = usePaymentForm();

  const [couponCode, setCouponCode] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const confirmPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsSubmitting(true);

    try {
      const paymentIntentResult: PaymentIntentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: FRONTEND_URL,
        },
        redirect: "if_required",
      });

      const { error } = paymentIntentResult;

      if (error) {
        setIsSubmitting(false);

        if (error?.type === "card_error" || error?.type === "validation_error") {
          error.message && toast.error(error.message);
        } else {
          toast.error("An unexpected error occurred.");
        }

        return;
      }

      await handleProcessPayment(handleOnSuccess, handleOnFailure);
    } catch (error: any) {
      handleOnFailure(error || error?.message);
    }

    setIsSubmitting(false);
  };

  return (
    <div className="flex flex-col h-full justify-between gap-4">
      <div className="flex flex-col w-full gap-2">
        <span className="text-[#A6ADBA] uppercase">Discount Code</span>
        <div className="flex w-full items-center justify-between gap-2">
          <input
            className="input input-bordered w-full"
            placeholder="Enter discount coupon code"
            value={couponCode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCouponCode(event.target.value.toUpperCase())
            }
          />
          <Button className="btn btn-success" onClick={() => handleApplyCoupon(couponCode)}>
            Apply
          </Button>
        </div>
      </div>
      <PaymentElement />
      <button
        className={`btn btn-primary w-full mt-5 ${
          isPaymentProcessing || isSubmitting ? "loading" : ""
        }`}
        onClick={confirmPayment}
      >
        Pay
      </button>
    </div>
  );
};

const PaymentFormWrapper = ({ payment }: { payment: StripePayment }) => {
  const paymentIntent = payment.details?.paymentIntent;
  const clientSecret = paymentIntent?.client_secret;

  return clientSecret ? (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <PaymentForm />
    </Elements>
  ) : null;
};

export default PaymentFormWrapper;
