export const drawTextAt = (
  context: CanvasRenderingContext2D,
  text: string,
  lineHeight: number,
  fitWidth: number,
  x: number,
  y: number,
) => {
  if (fitWidth <= 0) {
    context.fillText(text, x, y);
    context.strokeText(text, x, y);
    return;
  }

  let words = text.split(" ");
  let currentLine = 0;
  let index = 1;

  while (words.length > 0 && index <= words.length) {
    const str = words.slice(0, index).join(" ");
    const width = context.measureText(str).width;

    if (width > fitWidth) {
      currentLine++;
      words = words.splice(index);
      index = 1;
    } else {
      index++;
    }

    context.fillText(words.slice(0, index).join(" "), x, y + lineHeight * currentLine);
    context.strokeText(words.slice(0, index).join(" "), x, y + lineHeight * currentLine);
  }
};
