import React from "react";
import { useFormik } from "formik";
import { useAppSelector } from "@/app/hooks";
import { useSendQuestionMutation } from "../api/userApi";
import { selectUserProfile } from "@/reducers/user.slice";

const Support = () => {
  const userProfile = useAppSelector(selectUserProfile);
  const [sendQuestion, { isSuccess }] = useSendQuestionMutation();

  const formik = useFormik({
    initialValues: {
      name: "",
      question: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.question) {
        errors.question = "Required";
      }
      return errors;
    },
    onSubmit: async (values) => {
      await sendQuestion(values);
    },
  });

  return (
    <div className="grid grid-cols-1 px-4 xl:px-10">
      <div className="w-full max-w-xl text-left m-auto mt-6 xl:mt-24">
        <div className="container">
          <div className="w-full">
            {!isSuccess ? (
              <div className="flex flex-col w-full gap-4">
                <span className="text-title">Help & Support</span>
                <span className="text-secondary !text-white">
                  Have a question? Fill out the form below and we will get back to you soon!
                </span>
                <form onSubmit={formik.handleSubmit} className="form-control w-full p-0">
                  <label className="label uppercase">
                    <span className="label-text">Name</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="name"
                    className={`input input-bordered w-full rounded-full ${
                      formik.errors.name && formik.touched.name && " !border-red-500 !text-red-900"
                    }`}
                  />

                  <label className="label uppercase">
                    <span className="label-text">Question</span>
                  </label>
                  <textarea
                    name="question"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoComplete="question"
                    className={`input input-bordered w-full !rounded-[25px] py-4 h-[250px] resize-none ${
                      formik.errors.question &&
                      formik.touched.question &&
                      " !border-red-500 !text-red-900"
                    }`}
                  />

                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    className="btn btn-primary rounded-full my-10"
                  >
                    Submit
                  </button>
                </form>
              </div>
            ) : (
              <>
                <span className="text-title">Question Sent</span>
                <br />
                <span className="text-secondary">
                  The answer will be sent to your email{" "}
                  <span className="text-secondary accent">{userProfile?.email}</span>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
