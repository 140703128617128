import React from "react";

import { useAppDispatch, useAppNavigate } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";
import { useProfile } from "@/providers/profile";

import { CloseIcon, ScopeIcon } from "@/components/UI/Icons";
import MobileProfileMenu from "../MobileProfileMenu";

import { setIsMobileMenuOpen } from "@/reducers/ui.slice";
import { MOBILE_HEADERS_HEIGHT, getNavbarMenuItems } from "@/constants/app.constants";
import { ProfileTypes } from "@/types/profile-types.enum";
import { INavbarLink } from "@/types/app.types";
import { isPathsInLocation } from "@/helpers/history.helpers";

import "./MobileNavbarMenu.styles.css";
import AppLink from "@/components/AppLink";

const MobileNavbarMenu = () => {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();
  const { isMobileApp } = useGeneralUi();

  const { isAuthorized, managedPlayerProfile } = useProfile();

  const currentProfileType = managedPlayerProfile
    ? ProfileTypes.PLAYER_PROFILE_TYPE
    : ProfileTypes.USER_PROFILE_TYPE;

  const linkClickHandler = (link: INavbarLink) => {
    dispatch(setIsMobileMenuOpen(false));
    if (!isAuthorized && !link.public) {
      navigate("/sign-up");
      return;
    }
  };

  const menuItems = getNavbarMenuItems({ isMobileApp }).filter(
    (link) =>
      !link.isHidden &&
      ((isAuthorized && link.profileTypes.includes(currentProfileType)) ||
        (!isAuthorized && link.public)),
  );

  return (
    <div className="flex flex-col w-full h-full">
      <div
        className="flex w-full pl-4 pr-1 py-3 justify-between items-center z-50"
        style={{ height: MOBILE_HEADERS_HEIGHT }}
      >
        <AppLink to={isAuthorized ? "/" : "/"}>
          <ScopeIcon width={32} height={32} />
        </AppLink>
        <button
          className="btn btn-ghost btn-square"
          onClick={() => dispatch(setIsMobileMenuOpen(false))}
        >
          <CloseIcon />
        </button>
      </div>

      <div className="flex-1 w-full h-full overflow-hidden overflow-y-scroll scrollbar-hidden">
        <div className="flex flex-col w-full h-full border-top">
          {menuItems.length > 1 &&
            menuItems.map(
              (link: INavbarLink, index) =>
                ((isAuthorized && link.profileTypes.includes(currentProfileType)) ||
                  (!isAuthorized && link.public)) && (
                  <div
                    key={`${link.title}-${index}`}
                    className={`border-bottom text-caps text-13 font-heavy text-white mobile-navbar-item py-3 px-4 hover:bg-neutral-white-5 ${
                      isPathsInLocation(link.activePaths, location.pathname)
                        ? "bg-primary-500 hover:!bg-primary-500"
                        : ""
                    }
                  ${!link.isActive ? "pointer-events-none" : ""}`}
                  >
                    <AppLink
                      to={link.path}
                      data-testid={link.testId}
                      onClick={() => linkClickHandler(link)}
                      className="flex justify-between items-center"
                    >
                      <span className={`${!link.isActive ? "opacity-50" : ""}`}>
                        {link.title.toUpperCase()}
                      </span>
                      {link.badge && (
                        <div className="mobile-navbar-badge font-roobert">{link.badge}</div>
                      )}
                    </AppLink>
                  </div>
                ),
            )}
          {isAuthorized && <MobileProfileMenu />}
        </div>
      </div>
    </div>
  );
};

export default MobileNavbarMenu;
