import React from "react";

import PostForm from "./PostForm/PostForm";
import { PostType } from "@/types/fanClub/fanClub.types";

interface EditPostModalProps {
  onToggle: () => void;
  post: PostType;
}

const EditPostModal = ({ onToggle, post }: EditPostModalProps) => {
  return <PostForm post={post} cancelHandler={onToggle} />;
};

export default EditPostModal;
