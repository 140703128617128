import React, { useEffect, useState } from "react";
import { useProfile } from "@/providers/profile";

import CheckboxContainer from "@/components/UI/Forms/CheckboxContainer";

import { NotificationSettingsPayload } from "@/types/notification/notification.types";
import { snakeCaseToReadableString } from "@/helpers/string.helpers";

interface NotificationSettingsItem {
  itemKey: string;
  label: string;
  value: boolean;
  hidden: boolean;
  sortIndex: number;
}
interface NotificationSettings {
  [x: string]: NotificationSettingsItem;
}

const NotificationsTab = () => {
  const { updateNotificationSettings, userProfile } = useProfile();

  const [settings, setSettings] = useState<NotificationSettings>({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!userProfile?.settings?.notificationSettings) return;

    const notificationSettings: NotificationSettings = userProfile.settings.notificationSettings;

    const flattenedAndSortedSettings: NotificationSettings = Object.keys(notificationSettings)
      .sort((a, b) => notificationSettings[a].sortIndex - notificationSettings[b].sortIndex)
      .reduce((acc, key) => {
        const category: NotificationSettingsItem = notificationSettings[key];

        const sortedCategory = Object.keys(category)
          .filter((itemKey) => itemKey !== "sortIndex")
          .sort(
            (a, b) =>
              (category[a as keyof NotificationSettingsItem] as unknown as NotificationSettingsItem)
                .sortIndex -
              (category[b as keyof NotificationSettingsItem] as unknown as NotificationSettingsItem)
                .sortIndex,
          )
          .map((itemKey) => {
            const item: NotificationSettingsItem = category[
              itemKey as keyof NotificationSettingsItem
            ] as unknown as NotificationSettingsItem;

            return {
              ...item,
              itemKey,
            };
          });

        acc[key] = sortedCategory as unknown as NotificationSettingsItem;

        return acc;
      }, {} as NotificationSettings);

    setSettings(flattenedAndSortedSettings);
  }, [userProfile?.settings?.notificationSettings]);

  const handleChange = (settings: NotificationSettingsPayload) => {
    setIsLoading(true);
    updateNotificationSettings(settings).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div
      className="flex flex-col gap-2 xl:mt-0 xl:grid xl:grid-cols-2"
      data-testid="notifications-settings-tab"
    >
      {Object.keys(settings).map((key) => (
        <div key={key} className="flex flex-col gap-2 items-start w-full py-2">
          <span className="text-headline text-16 tracking-[0.12px] font-normal xl:py-5">
            {snakeCaseToReadableString(key)}
          </span>
          {Object.keys(settings[key])
            .sort()
            .map((itemIndex) => {
              const item = settings[key][itemIndex as keyof NotificationSettingsItem];
              const { value, label, itemKey, hidden } = item as unknown as NotificationSettingsItem;

              return (
                !hidden && (
                  <CheckboxContainer
                    dataTestId="notifications-settings-item"
                    key={`${key}_${itemKey}`}
                    checked={value}
                    label={(label || "").replace("''", "'")}
                    isLoading={isLoading}
                    disabled={isLoading}
                    onChange={(event) =>
                      handleChange({
                        key,
                        itemKey,
                        value: event.target.checked,
                      })
                    }
                  />
                )
              );
            })}
        </div>
      ))}
    </div>
  );
};

export default NotificationsTab;
