import React, { PropsWithChildren, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useAppNavigate } from "@/app/hooks";

import { useCardUserListMutation } from "@/api/cardUserApi";
import {
  useChangeNotificationSettingsMutation,
  useDeleteUserProfileMutation,
  useGetBlockedUsersMutation,
  useProfileMutation,
  useRemoveProfileImageMutation,
  useSetProfileImageMutation,
  useUnblockUserMutation,
  useUpdateUserProfileMutation,
} from "@/api/userApi";
import { useCheckForFreeCardMutation } from "@/api/inviteApi";
import {
  useGetByUidMutation,
  useGetPlayerProfilesMutation,
  useGetPublicPlayerProfilesMutation,
} from "@/api/playerApi";
import useToggleBackground from "@/app/hooks/useToggleBackground";
import { useLocation } from "react-router-dom";
import { useGeneralUi } from "../generalUi";

import MutedModal from "@/components/Modals/MutedModal";

import {
  logout,
  selectAuthToken,
  selectIsAuthorized,
  selectManagedPlayerProfile,
  selectUserCards,
  selectUserProfile,
  setUserCards,
  selectIsNew,
  selectIsAdmin,
  selectManagedPlayerProfiles,
  setUserProfile as setUserProfileAction,
  setManagedPlayerProfile as setManagedPlayerProfileAction,
  clearManagedPlayerProfile as clearManagedPlayerProfileAction,
  setIsNew as setIsNewAction,
  setIsEulaAgreed as setIsEulaAgreedAction,
  setManagedPlayerProfiles,
  selectIsEulaAgreed,
  selectBlockedUsers,
  setBlockedUsers,
  setBlockedUsersTotal,
  selectBlockedUsersTotal,
  setUserProfile,
  selectIsInstallAppInformed,
  setIsInstallAppInformed,
} from "@/reducers/user.slice";
import { selectIsGlobalLoading, setIsGlobalLoading } from "@/reducers/ui.slice";
import { setPlayerProfiles as setPlayerProfilesAction } from "@/reducers/scopeBlog.slice";
import {
  AllowedUpdateUserProfileType,
  UpdateUserProfileType,
  UserProfileType,
  UserRoles,
} from "@/types/user/profile.types";
import { GroupedPlayerProfiles, PlayerProfileType } from "@/types/player/player.types";
import { NotificationSettingsPayload } from "@/types/notification/notification.types";

import { batch } from "react-redux";
import Loading from "@/pages/Loading";
import { saveToLocalStorage } from "@/utils/localStorage";
import { Socket, io } from "socket.io-client";
import { PROSHOP_WS_URL } from "@/constants/app.constants";
import { ProfileContext } from "./ProfileContext";
import { toast } from "@/utils/toast";
import { ExternalWallet } from "@/types/crypto/crypto.types";

let proshopSocket: Socket;

const ProfileProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useAppNavigate();
  const { maintenanceMode } = useGeneralUi();

  const [init, setInit] = useState<boolean>(true);
  const [isMuteInformModalOpen, setIsMuteInformModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectedExternalWallet, setConnectedExternalWallet] = useState<ExternalWallet>();
  const isGlobalLoading = useAppSelector(selectIsGlobalLoading);
  const isOAuthAuthorizing = location.pathname.includes("oauth");

  const managedPlayerProfile = useAppSelector(selectManagedPlayerProfile);
  const isManager = !!managedPlayerProfile?.uuid;

  const authToken = useAppSelector(selectAuthToken);
  const userProfile = useAppSelector(selectUserProfile);
  const isAuthorized = useAppSelector(selectIsAuthorized);
  const isNew = useAppSelector(selectIsNew);
  const isEulaAgreed = useAppSelector(selectIsEulaAgreed);
  const isInstallAppInformed = useAppSelector(selectIsInstallAppInformed);
  const isAdmin = useAppSelector(selectIsAdmin);
  const userCards = useAppSelector(selectUserCards);
  const managedPlayerProfiles = useAppSelector(selectManagedPlayerProfiles);
  const blockedUsers = useAppSelector(selectBlockedUsers);
  const blockedUsersTotal = useAppSelector(selectBlockedUsersTotal);

  const [fetchUserProfile, fetchUserProfileStatus] = useProfileMutation();
  const [fetchPlayerProfile, fetchPlayerProfileStatus] = useGetByUidMutation();
  const [fetchUpdateUserProfile, updateUserProfileStatus] = useUpdateUserProfileMutation();
  const [fetchDeleteUserProfile, deleteUserProfileStatus] = useDeleteUserProfileMutation();
  const [getBlockedUsers, getBlockedUsersStatus] = useGetBlockedUsersMutation();
  const [unblockUser, unblockUserStatus] = useUnblockUserMutation();

  const [changeNotificationSettings, changeNotificationSettingsStatus] =
    useChangeNotificationSettingsMutation();
  const [fetchUserCards, fetchUserCardsStatus] = useCardUserListMutation();
  const [fetchSetUserAvatar, setUserAvatarStatus] = useSetProfileImageMutation();
  const [fetchRemoveUserAvatar, removeUserAvatarStatus] = useRemoveProfileImageMutation();
  const [checkForFreeCard, checkForFreeCardStatus] = useCheckForFreeCardMutation();
  const [fetchPublicPlayerProfiles, { isLoading: isPublicPlayerProfilesLoading }] =
    useGetPublicPlayerProfilesMutation();
  const [fetchPlayerProfiles, { isLoading: isPlayerProfilesLoading }] =
    useGetPlayerProfilesMutation();

  useEffect(() => {
    if (isOAuthAuthorizing) return;

    if (authToken) {
      proshopSocket = io(PROSHOP_WS_URL, {
        extraHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      getUserProfile();
    } else {
      getPublicPlayerProfiles();
    }
  }, [authToken, isOAuthAuthorizing]);

  useEffect(() => {
    if (!isAuthorized && proshopSocket) {
      proshopSocket.close();
    }
  }, [isAuthorized]);

  useEffect(() => {
    proshopSocket?.connected && subscribePlayers();
  }, [proshopSocket?.connected]);

  useEffect(() => {
    if (init) {
      setInit(false);
      return;
    }

    dispatch(
      setIsGlobalLoading(
        fetchUserProfileStatus.isLoading ||
          isPlayerProfilesLoading ||
          isPublicPlayerProfilesLoading,
      ),
    );
  }, [fetchUserProfileStatus.isLoading, isPlayerProfilesLoading, isPublicPlayerProfilesLoading]);

  useEffect(() => {
    if (!userProfile) return;

    userProfile.isMuted && setIsMuteInformModalOpen(true);
  }, [userProfile]);

  const subscribePlayers = () => {
    proshopSocket.on(
      `players`,
      (playerProfiles: {
        owned?: PlayerProfileType[];
        rest?: PlayerProfileType[];
        managed?: PlayerProfileType[];
      }) => {
        if (!playerProfiles) {
          return;
        }

        setPlayerProfiles(playerProfiles);
      },
    );
  };

  useToggleBackground(managedPlayerProfile?.backgroundSettings);

  const getPlayerProfiles = () => {
    fetchPlayerProfiles({})
      .unwrap()
      .then((playerProfiles: GroupedPlayerProfiles) => {
        batch(() => {
          setPlayerProfiles(playerProfiles);
        });
      });
  };

  const getPublicPlayerProfiles = async () => {
    fetchPublicPlayerProfiles({})
      .unwrap()
      .then((playerProfiles: GroupedPlayerProfiles) => {
        batch(() => {
          setPlayerProfiles(playerProfiles);
          dispatch(setIsGlobalLoading(false));
        });
      });
  };

  const getUserProfile = async (withAddons = true): Promise<UserProfileType> => {
    setIsLoading(true);

    if (withAddons) {
      checkForFreeCard({}).then((response: any) => {
        if (response.error) {
          setIsLoading(false);
          return;
        }

        const { giftCards } = response.data;

        giftCards?.length && getUserProfile(false);
      });

      getPlayerProfiles();
    }

    const profile = await fetchUserProfile().unwrap();

    if (maintenanceMode && profile.role !== UserRoles.ADMIN) {
      logOut();
      navigate("/");
      return profile;
    }

    batch(() => {
      dispatch(setUserProfileAction(profile));
      dispatch(setManagedPlayerProfiles(profile?.managedPlayers || []));
      setConnectedExternalWallet(profile.connectedExternalWallet);
      setIsLoading(false);
    });

    return profile;
  };

  const updateUserProfile = async (values: AllowedUpdateUserProfileType) => {
    if (!updateUserProfileStatus.isLoading && userProfile) {
      const updatedData: UpdateUserProfileType = {
        username: values.username || userProfile.username,
        fullName: values.fullName || userProfile.fullName || "unknown",
        bio: values.bio !== undefined ? values.bio || "" : userProfile.bio || "",
        isScopeAdmin: userProfile.isScopeAdmin || false,
        role: values.role || userProfile.role,
        country: values.country || userProfile.country,
        isOnboarded: values.isOnboarded || userProfile.isOnboarded,
      };

      const response = await fetchUpdateUserProfile({
        uuid: userProfile?.uuid,
        body: updatedData,
      }).unwrap();

      await getUserProfile(false);

      return response;
    }
  };

  const deleteUserProfile = async () => {
    fetchDeleteUserProfile({}).then((response: any) => {
      if (response.error) {
        return;
      }

      logOut();
    });
  };

  const getUserCards = async () => {
    const response = await fetchUserCards({ limit: 0 }).unwrap();
    dispatch(setUserCards(response.userCards));
  };

  const setUserAvatar = async (formData: FormData) => {
    await fetchSetUserAvatar(formData);
    await getUserProfile(false);
  };

  const removeUserAvatar = async () => {
    await fetchRemoveUserAvatar({});
    await getUserProfile(false);
  };

  const setManagedPlayerProfile = async (playerUuid: string) => {
    const playerProfile = await fetchPlayerProfile(playerUuid).unwrap();
    dispatch(setManagedPlayerProfileAction(playerProfile));
  };

  const clearManagedPlayerProfile = () => {
    dispatch(clearManagedPlayerProfileAction());
  };

  const handleExplore = (isNew: boolean, isEulaAgreed: boolean) => {
    saveToLocalStorage("isNewUser", isNew);
    saveToLocalStorage("isEulaAgreed", isEulaAgreed);
    batch(() => {
      dispatch(setIsNewAction(isNew));
      dispatch(setIsEulaAgreedAction(isEulaAgreed));
    });
  };

  const handleInstallAppInformed = () => {
    saveToLocalStorage("isInstallAppInformed", true);
    dispatch(setIsInstallAppInformed(true));
  };

  const handleGetBlockedUsers = async ({
    skip,
    limit = 10,
    searchByName,
  }: {
    skip?: number;
    limit?: number;
    searchByName?: string;
  }) => {
    getBlockedUsers({ skip, limit, searchByName })
      .unwrap()
      .then(({ blockedUsers, total }) => {
        batch(() => {
          dispatch(setBlockedUsers(blockedUsers));
          dispatch(setBlockedUsersTotal(total));
        });
      });
  };

  const handleUnblockUser = async (uuid: string) =>
    unblockUser(uuid)
      .unwrap()
      .then(({ message }) => {
        toast.success(message);
        handleGetBlockedUsers({});
      });

  const handleMuteUser = () => {
    dispatch(setUserProfile({ ...userProfile, isMuted: true }));
  };

  const logOut = () => {
    dispatch(logout());
  };

  const setPlayerProfiles = (playerProfiles: GroupedPlayerProfiles) => {
    dispatch(setPlayerProfilesAction(playerProfiles));
  };

  const updateNotificationSettings = async (values: NotificationSettingsPayload) => {
    if (!changeNotificationSettingsStatus.isLoading) {
      const response = await changeNotificationSettings(values).unwrap();

      dispatch(setUserProfileAction({ ...userProfile, settings: { ...response } }));

      return response;
    }
  };

  return (
    <ProfileContext.Provider
      value={{
        authToken,
        isAuthorized,
        isManager,
        isAdmin,
        isNew,
        isEulaAgreed,
        isInstallAppInformed,
        userProfile,
        isUserProfileLoading: fetchUserProfileStatus.isLoading,
        isHandlingUnblockUser: unblockUserStatus.isLoading,
        isUserCardsLoading: fetchUserCardsStatus.isLoading,
        isUserProfileUpdating: updateUserProfileStatus.isLoading,
        isUserProfileDeleting: deleteUserProfileStatus.isLoading,
        isCheckForFreeCardLoading: checkForFreeCardStatus.isLoading,
        isPlayerProfileLoading: fetchPlayerProfileStatus.isLoading,
        isBlockedUsersLoading: getBlockedUsersStatus.isLoading,
        blockedUsers,
        blockedUsersTotal,
        managedPlayerProfile,
        userCards,
        managedPlayerProfiles,
        setUserAvatarStatus,
        removeUserAvatarStatus,
        connectedExternalWallet,
        getUserCards,
        getUserProfile,
        updateUserProfile,
        deleteUserProfile,
        setUserAvatar,
        removeUserAvatar,
        setManagedPlayerProfile,
        clearManagedPlayerProfile,
        logOut,
        updateNotificationSettings,
        handleExplore,
        handleUnblockUser,
        handleGetBlockedUsers,
        handleMuteUser,
        handleInstallAppInformed,
      }}
    >
      {isMuteInformModalOpen && <MutedModal onToggle={() => setIsMuteInformModalOpen(false)} />}
      {!isOAuthAuthorizing &&
      (init ||
        isGlobalLoading ||
        isLoading ||
        (isAuthorized && !userProfile) ||
        fetchUserProfileStatus.isLoading ||
        isPublicPlayerProfilesLoading ||
        isPlayerProfilesLoading) ? (
        <Loading />
      ) : (
        children
      )}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
