import React, { ButtonHTMLAttributes } from "react";

const Button = ({
  children,
  onClick,
  title,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return title ? (
    <div className="tooltip !tooltip-black" data-tip={title}>
      <button type="button" className="btn" {...props} onClick={onClick}>
        {children}
      </button>
    </div>
  ) : (
    <button type="button" className="btn" {...props} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
