import React, { ReactNode } from "react";

const SearchDropdownMenu = ({
  title,
  menuItems,
  selectedIndex,
  onSelect,
  renderComponent,
  isVisible,
  top,
}: {
  title: string;
  menuItems: any[];
  selectedIndex: number;
  onSelect: (item: any) => void;
  renderComponent: (item: any) => ReactNode;
  isVisible: boolean;
  top: number;
}) => {
  return (
    <div
      tabIndex={0}
      className="z-50 absolute left-0 flex flex-col items-start p-3 gap-2 w-full max-h-[50vh] border-bottom border-top border-neutral-white-12 bg-neutral-black-90 rounded-[20px] overflow-hidden overflow-y-scroll scrollbar-hidden"
      style={{ top, display: isVisible ? "flex" : "none" }}
    >
      <span className="w-full text-button text-12 font-heavy text-white text-start px-4 py-2 rounded-lg">
        {title}
      </span>
      {menuItems.filter(Boolean).map((item: { value: string; displayValue: string }, index) => (
        <div
          key={index}
          className={`flex w-full items-center px-4 py-2 gap-2 rounded-lg bg-neutral-white-5 hover:bg-grayscale-800 cursor-pointer ${
            index === selectedIndex ? "bg-grayscale-800" : ""
          }`}
          onClick={() => onSelect(item)}
        >
          {renderComponent(item)}
        </div>
      ))}
    </div>
  );
};

export default SearchDropdownMenu;
