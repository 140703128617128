import { createContext, useContext } from "react";

interface BlockUserContextType {
  isHandlingBlockUser: boolean;
  handleBlockUser: (uuid: string) => void;
  isHandlingMuteUser: boolean;
  handleMuteUser: (uuid: string) => void;
  isHandlingUnmuteUser: boolean;
  handleUnmuteUser: (uuid: string) => void;
}

const BlockUserContext = createContext<BlockUserContextType | undefined>(undefined);

export const useBlockUser = (): BlockUserContextType => {
  const context = useContext(BlockUserContext);

  if (!context) {
    throw new Error("useBlockUser must be used within a BlockUserProvider");
  }

  return context;
};

export default BlockUserContext;
