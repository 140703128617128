import React, { useEffect, useState } from "react";
import { useProfile } from "@/providers/profile";
import { useGeneralUi } from "@/providers/generalUi";
import { useFormik } from "formik";

import ProfileAvatar from "@/components/User/ProfileAvatar";

import { useAddCommentMutation, useUpdateCommentMutation } from "@/api/fanClubApi";
import { useAddCommentMutation as useAddManagerCommentMutation } from "@/api/fanClubManagerApi";

import { CommentType } from "@/types/fanClub/fanClub.types";
import MobileCommentFormInput from "./MobileComments/MobileCommentFormInput";
import { useFanClub } from "@/providers/fanClub";

const COMMENT_LENGTH = 1000;

const CommentForm = ({
  comment,
  parentUuid,
  postUuid,
  postOwnerUuid,
  cancelHandler,
}: {
  comment?: CommentType;
  parentUuid?: string;
  postUuid?: string;
  postOwnerUuid: string;
  cancelHandler?: () => void;
}) => {
  const { isMobile } = useGeneralUi();
  const { userProfile, managedPlayerProfile, isManager } = useProfile();
  const { isMuted } = useFanClub();

  const [addComment, { isLoading: addUserLoading }] = isManager
    ? useAddManagerCommentMutation()
    : useAddCommentMutation();
  const [updateComment, { isLoading: updateUserLoading }] = useUpdateCommentMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(addUserLoading || updateUserLoading);
  }, [addUserLoading, updateUserLoading]);

  const isEdit = comment && comment.uuid;

  const initialValues = {
    content: isEdit ? comment.content : "",
    ownerUuid: isEdit ? comment.owner.uuid : null,
    postUuid: isEdit ? comment.postUuid : postUuid,
    parentUuid: parentUuid || null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors: any = {};
      const requiredFields = ["content"];
      requiredFields.forEach((reqField) => {
        if (!(values as any)[reqField]) {
          errors[reqField] = "Required";
        }
        if (values.content.length > COMMENT_LENGTH) {
          errors["content"] = `Max length ${COMMENT_LENGTH} chars`;
        }
      });

      return errors;
    },
    onSubmit: async (values: any, { resetForm }) => {
      if (!userProfile) {
        return;
      }

      if (isLoading) return;
      if (isEdit) {
        updateComment({ commentUuid: comment.uuid, postOwnerUuid: postOwnerUuid, body: values })
          .then(() => {
            resetForm();
            cancelHandler && cancelHandler();
          })
          .catch((error) => console.log(error));
      } else {
        if (!managedPlayerProfile) {
          values.ownerUuid = null;
          values.userUuid = userProfile.uuid;
        } else if (managedPlayerProfile) {
          values.ownerUuid = managedPlayerProfile.uuid;
          values.userUuid = null;
        }

        addComment(values)
          .then(() => {
            resetForm();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  });

  return isMobile ? (
    <MobileCommentFormInput
      formik={formik}
      showCloseButton={!!(isEdit || parentUuid)}
      cancelHandler={cancelHandler}
      disabled={isMuted}
    />
  ) : (
    <div className="card w-full">
      <div className="card-title">
        <form onSubmit={formik.handleSubmit} className="form w-full">
          <div className="flex justify-between py-4">
            <ProfileAvatar profile={isEdit ? comment.owner : managedPlayerProfile || userProfile} />
            <input
              type="text"
              className={`input input-bordered rounded-full w-full mx-5 ${
                formik.errors.content && "!border-red-500 !text-red-900"
              }`}
              name="content"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.content}
              placeholder="Add a comment..."
              disabled={isMuted}
            />
            <button
              type="submit"
              className={`btn btn-circle ${isLoading ? "loading" : ""}`}
              disabled={isLoading || isMuted}
            >
              {!isLoading && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  focusable="false"
                  width="24"
                  height="24"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                  style={{ transform: "rotate(360deg)" }}
                >
                  <path
                    d="M3.4 20.4l17.45-7.48a1 1 0 0 0 0-1.84L3.4 3.6a.993.993 0 0 0-1.39.91L2 9.12c0 .5.37.93.87.99L17 12L2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z"
                    fill="currentColor"
                  ></path>
                </svg>
              )}
            </button>
            {(isEdit || parentUuid) && (
              <button
                type="button"
                className="btn btn-error btn-circle ml-2"
                onClick={cancelHandler}
                disabled={isMuted}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommentForm;
