import React, { InputHTMLAttributes, useState } from "react";
import { CloseIcon, SearchIcon } from "@/components/UI/Icons";

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch: (value: string) => void;
  value?: string;
  containerClassName?: string;
}

const SearchInput = ({
  onSearch,
  containerClassName,
  value = "",
  ...inputProps
}: SearchInputProps) => {
  const [searchInput, setSearchInput] = useState(value);

  return (
    <div className={`relative min-w-[150px] ${containerClassName}`}>
      <button
        className="absolute btn btn-ghost btn-circle btn-sm left-2.5 inset-y-0 m-auto"
        onClick={() => onSearch(searchInput)}
      >
        <SearchIcon />
      </button>
      <input
        type="text"
        placeholder=""
        data-testid="proshop-search-input"
        className={`block w-full input input-bordered p-4 pr-10 pl-12 text-body text-14 font-medium placeholder:text-body placeholder:text-14 placeholder:font-medium placeholder:text-grayscale-500`}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            onSearch(searchInput);
          }
        }}
        {...inputProps}
      />
      {searchInput !== "" && (
        <button
          className="absolute btn btn-ghost btn-circle btn-sm right-2.5 inset-y-0 m-auto"
          onClick={() => {
            setSearchInput("");
            onSearch("");
          }}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
};

export default SearchInput;
