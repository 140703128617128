import React from "react";

import { MaintenanceIcon } from "@/components/UI/Icons";
import Container from "@/components/UI/Container";

const MaintenancePage = () => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container className="p-6 xl:max-w-[50%]">
        <div className="w-full text-center text-white">
          <div className="flex items-center justify-center mb-4 xl:mb-10">
            <MaintenanceIcon className="!w-16 !h-16 xl:!w-[72px] xl:!h-[72px]" />
          </div>
          <h2 className="text-heading font-medium xl:!text-[56px]">Scope will be back soon</h2>
          <h1 className="text-body text-16 font-regular opacity-70 mt-3">
            The app is currently undergoing scheduled maintenance, sorry for the inconvenience.
          </h1>
          <div className="flex items-center justify-center">
            <a href="/login" className="btn btn-primary rounded-full btn-block my-6 xl:my-10">
              Sign in
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MaintenancePage;
