import { useEffect } from "react";

import { RootState, store } from "@/app/store";
import { io, Socket } from "socket.io-client";
import { useProfile } from "@/providers/profile";
import {
  NOTIFICATIONS_WS_URL,
  VIDEO_PROCESSING_WS_URL,
  WISHLIST_WS_URL,
} from "@/constants/app.constants";

export let notificationsSocket: Socket;
export let wishlistSocket: Socket;
export let videoProcessingSocket: Socket;

// TODO: move to AppGatewayProvider
const WebSocketProvider = () => {
  const token = (store.getState() as RootState).user.authToken;
  const { isAuthorized, userProfile } = useProfile();

  const closeSockets = () => {
    notificationsSocket && notificationsSocket.close();
    wishlistSocket && wishlistSocket.close();
    videoProcessingSocket && videoProcessingSocket.close();
  };

  useEffect(() => {
    if (isAuthorized && userProfile?.uuid) {
      notificationsSocket = io(NOTIFICATIONS_WS_URL, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      wishlistSocket = io(WISHLIST_WS_URL, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      videoProcessingSocket = io(VIDEO_PROCESSING_WS_URL, {
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      closeSockets();
    }

    return () => closeSockets();
  }, [isAuthorized, userProfile?.uuid]);

  return null;
};

export default WebSocketProvider;
