import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { AppNavigateFunction } from "@/types/app.types";
import { selectIsAndroidMobileApp } from "@/reducers/platformSettings.slice";
import { resolveTo } from "@/helpers/history.helpers";

// Use instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useClickOutside = (ref: React.RefObject<HTMLElement>, onClickOutside: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export const useAppNavigate = (): AppNavigateFunction => {
  const navigate = useNavigate();
  const isAndroidMobileApp = useAppSelector(selectIsAndroidMobileApp);

  const appNavigate: AppNavigateFunction = (to: To | number, options?: NavigateOptions) => {
    if (typeof to === "number") {
      navigate(to);
      return;
    }

    navigate(isAndroidMobileApp ? resolveTo(to) : to, options);
  };

  return appNavigate;
};
