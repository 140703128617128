import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./common";
import { Payment, PaymentMethods } from "@/types/payment/payment.types";

const BASE_URL = import.meta.env.VITE_BACKEND_URL + "payments/";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: baseQuery(BASE_URL),
  endpoints: (builder) => ({
    createPayment: builder.mutation<
      Payment,
      { paymentMethod: PaymentMethods; tradeCardUuid: string }
    >({
      query: (params: { paymentMethod: PaymentMethods; tradeCardUuid: string }) => ({
        url: "/create-payment",
        method: "GET",
        params,
      }),
    }),
    processPayment: builder.mutation({
      query: (body: { paymentSessionUuid: string }) => ({
        url: "/process-payment",
        method: "POST",
        body,
      }),
    }),
    applyCoupon: builder.mutation({
      query: (body: { code: string; paymentSessionUuid: string }) => ({
        url: "/apply-coupon",
        method: "POST",
        body,
      }),
    }),
    setTransaction: builder.mutation({
      query: (body: {
        fromAddress: string;
        toAddress: string;
        value: string;
        transactionHash: string;
        paymentSessionUuid: string;
      }) => ({
        url: "/transaction",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useCreatePaymentMutation,
  useApplyCouponMutation,
  useProcessPaymentMutation,
  useSetTransactionMutation,
} = paymentsApi;
