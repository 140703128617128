import React, { useEffect, useRef, useState } from "react";

import { batch } from "react-redux";
import { useAppDispatch, useAppSelector, useClickOutside } from "@/app/hooks";
import { useGeneralUi } from "@/providers/generalUi";
import { useProfile } from "@/providers/profile";
import { useFullscreenModal } from "@/providers/fullscreenModal";
import { useFanClub } from "@/providers/fanClub";
import { useHandlePostReactionMutation } from "@/api/fanClubApi";

import Poll from "./Poll/Poll";
import EditPostModal from "./EditPostModal";
import {
  CommentIcon,
  EditIcon,
  LikeIcon,
  RemoveIcon,
  SendToIcon,
  ThreeDotsIcon,
  StarIcon,
} from "@/components/UI/Icons";
import CommentsContainer from "@/components/ScopeBlog/FanClub/Feed/Comment/CommentsContainer";
import ProfileAvatar from "@/components/User/ProfileAvatar";
import ConfirmModal from "@/components/UI/ConfirmModal";
import Container from "@/components/UI/Container";
import Button from "@/components/UI/Button";
import AudioPlayer from "./AudioPlayer";
import MediaGridWrapper from "@/components/ScopeBlog/Common/MediaGridWrapper";

import {
  useHandlePostReactionMutation as useHandleManagerPostReactionMutation,
  useRemovePostMutation,
  useToggleIsPinnedMutation,
} from "@/api/fanClubManagerApi";
import {
  PostType,
  PostTypes,
  PostReactionsEnum,
  HandlePostReactionRequest,
} from "@/types/fanClub/fanClub.types";
import { toast } from "@/utils/toast";
import {
  selectSelectedPost,
  setIsCommentsOpen as setIsMobileCommentsOpen,
  setSelectedPost,
} from "@/reducers/mobileFanClubUi.slice";
import { FRONTEND_URL, SCOPE_NAME } from "@/constants/app.constants";

const Post = ({
  post,
  editable,
  expandComments,
}: {
  post: PostType;
  editable: boolean;
  expandComments?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { userProfile, managedPlayerProfile } = useProfile();
  const { isMobile } = useGeneralUi();
  const { setModal, setIsModalOpen, handleCloseModal } = useFullscreenModal();
  const { isMuted } = useFanClub();

  const selectedPost = useAppSelector(selectSelectedPost);

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(!!expandComments);
  const [isPostMenuOpen, setIsPostMenuOpen] = useState<boolean>(false);
  const [isLikedByMe, setIsLikedByMe] = useState<boolean>(false);
  const [isTogglePinLoading, setIsTogglePinLoading] = useState<boolean>(false);

  const postMenuRef = useRef<HTMLDivElement>(document.createElement("div"));

  useClickOutside(postMenuRef, () => setIsPostMenuOpen(false));

  const [removePost] = useRemovePostMutation();
  const [toggleisPinned] = useToggleIsPinnedMutation();
  const [_handleReaction, _handleReactionStatus] = useHandlePostReactionMutation();
  const [_handleManagerReaction, _handleManagerReactionStatus] =
    useHandleManagerPostReactionMutation();

  useEffect(() => {
    if (selectedPost?.uuid && post.uuid === selectedPost.uuid) {
      dispatch(setSelectedPost(post));
    }
    if (post.votes?.length) {
      setIsLikedByMe(
        post.votes.some(
          (vote) =>
            ((managedPlayerProfile && vote?.owner?.uuid === managedPlayerProfile.uuid) ||
              (userProfile && vote?.owner?.uuid === userProfile.uuid)) &&
            vote.content === PostReactionsEnum.LIKE,
        ),
      );
    } else {
      setIsLikedByMe(false);
    }
  }, [userProfile?.uuid, managedPlayerProfile?.uuid, post]);

  const handleCopyPostLink = () => {
    navigator.clipboard.writeText(
      `${FRONTEND_URL}fan-club/${post.owner.uuid}?section=feed&postUuid=${post.uuid}`,
    );
    setIsPostMenuOpen(false);
    toast.success("Link copied!");
  };

  const handleReaction = async (reaction: PostReactionsEnum) => {
    if (_handleReactionStatus.isLoading || _handleManagerReactionStatus.isLoading || isMuted)
      return;

    const payload: HandlePostReactionRequest = {
      postUuid: post.uuid,
      content: reaction,
    };

    if (managedPlayerProfile) {
      payload.ownerUuid = managedPlayerProfile.uuid;
      await _handleManagerReaction(payload);
    } else if (userProfile) {
      await _handleReaction(payload);
    }
  };

  const handleToggleIsPinned = async () => {
    if (!managedPlayerProfile?.uuid) return;
    setIsTogglePinLoading(true);
    toggleisPinned({ postUuid: post.uuid, ownerUuid: managedPlayerProfile.uuid }).then(() => {
      setIsTogglePinLoading(false);
      !post.isPinned &&
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    });
  };

  const handleOpenMobileComments = () => {
    batch(() => {
      dispatch(setSelectedPost(post));
      dispatch(setIsMobileCommentsOpen(true));
    });
  };

  const handleDeletePost = () => {
    if (!managedPlayerProfile?.uuid) return;

    setIsModalOpen(false);
    setModal(
      <ConfirmModal
        title="Do you want to delete?"
        body="Once you delete this, it will be gone forever. Make sure you give thanks to it first."
        onConfirm={() =>
          removePost({ postUuid: post.uuid, ownerUuid: managedPlayerProfile.uuid }).finally(() =>
            handleCloseModal(),
          )
        }
        onToggle={handleCloseModal}
      />,
    );
    setIsModalOpen(true);
  };

  return (
    <>
      <Container
        className="card w-full h-full overflow-visible gap-0"
        border="rounded-[20px] xl:rounded-xl"
      >
        {isEditModalOpen ? (
          <EditPostModal onToggle={() => setIsEditModalOpen(false)} post={post} />
        ) : (
          <>
            <div className="flex items-center justify-between w-full py-4 px-5">
              <div className="flex items-center ">
                <ProfileAvatar
                  profile={post.owner}
                  scopeProfile={post.isScopePost}
                  imageClass="rounded-full w-10 h-10 xl:w-14 xl:h-14"
                />

                <div className="flex flex-col ml-4 text-start">
                  <span className="mr-2 text-body text-16 font-bold xl:text-heading xl:font-medium xl:text-lg">
                    {post?.owner?.name} {post.isScopePost ? SCOPE_NAME : ""}
                  </span>
                  <span className="uppercase text-caps text-12 font-medium text-grayscale-200 xl:text-caps xl:text-13 xl:text-grayscale-400">
                    {`Published: ${new Date(post.createdAt).toLocaleString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}${post.isDraft ? " (post is a draft)" : ""}`}
                  </span>
                </div>
              </div>

              <div className="relative dropdown-bottom dropdown-end">
                <button
                  className="btn btn-ghost btn-square btn-sm rounded-[3px]"
                  onClick={() => setIsPostMenuOpen((prev) => !prev)}
                >
                  <ThreeDotsIcon className="w-8" />
                </button>
                {isPostMenuOpen && (
                  <div
                    ref={postMenuRef}
                    className="join join-vertical absolute dropdown-content menu xl:p-2 bg-grayscale-1000 border-[1.5px] rounded-xl border-white border-opacity-[0.12] z-50 text-body text-14 font-medium text-grayscale-400"
                  >
                    <Button
                      className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start"
                      onClick={handleCopyPostLink}
                    >
                      <SendToIcon /> Copy post link
                    </Button>
                    {editable && (
                      <>
                        <Button
                          className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start"
                          onClick={() => setIsEditModalOpen(true)}
                        >
                          <EditIcon /> Edit post
                        </Button>
                        <Button
                          className="btn btn-ghost join-item whitespace-nowrap flex-nowrap !normal-case !justify-start"
                          onClick={handleDeletePost}
                        >
                          <RemoveIcon /> Delete post
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex w-full h-full items-start text-start p-0 gap-0 flex-col bg-neutral-black-5">
              {post.type === PostTypes.POLL ? (
                <Poll post={post} />
              ) : post.type === PostTypes.VOICE_NOTE ? (
                <div className="flex-1 w-full min-h-[500px]">
                  <AudioPlayer
                    src={post?.attachments?.[0]?.presignedUrl || ""}
                    postUuid={post.uuid}
                    mediaMimetype={post?.attachments?.[0]?.mimetype}
                  />
                </div>
              ) : (
                <>
                  <div className="px-4 text-body text-14 font-regular xl:text-16 pb-4">
                    {post.body}
                  </div>
                  {!!post.attachments?.length && <MediaGridWrapper media={post.attachments} />}
                </>
              )}
            </div>
            <>
              {isMobile ? (
                <div className="flex w-full border-top border-bottom justify-between items-center py-1">
                  <div className="flex w-full justify-between items-center border-right">
                    <button
                      className="btn btn-ghost w-full"
                      onClick={() => handleReaction(PostReactionsEnum.LIKE)}
                    >
                      {isLikedByMe ? (
                        <LikeIcon fill="#FF4C34" stroke="#FF4C34" />
                      ) : (
                        <LikeIcon stroke="white" />
                      )}
                      <span className="text-body text-12 font-medium ml-2 normal-case">{`${
                        post.likesCount ? post.likesCount : ""
                      } ${post.likesCount > 1 ? "Likes" : "Like"}`}</span>
                    </button>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <button className="btn btn-ghost w-full" onClick={handleOpenMobileComments}>
                      <CommentIcon stroke="white" />
                      <span className="text-body text-12 font-medium ml-2 normal-case">
                        {post.commentsCount || 0}{" "}
                        {post.commentsCount > 1 || post.commentsCount === 0
                          ? "Comments"
                          : "Comment"}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex w-full justify-between p-5">
                    <div className="flex justify-between items-center gap-4">
                      <div className="flex items-center gap-2">
                        <button
                          className="btn btn-circle btn-ghost !btn-sm"
                          onClick={() => handleReaction(PostReactionsEnum.LIKE)}
                        >
                          {isLikedByMe ? (
                            <LikeIcon fill="#FF4C34" stroke="#FF4C34" />
                          ) : (
                            <LikeIcon />
                          )}
                        </button>
                        {`${post.likesCount ? post.likesCount : ""} ${
                          post.likesCount > 1 ? "Likes" : "Like"
                        }`}
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          className="btn btn-ghost !btn-sm m-0 p-0 btn-block"
                          onClick={() => setIsCommentsOpen(!isCommentsOpen)}
                        >
                          <CommentIcon className="mr-2" />
                          {post.commentsCount || 0}{" "}
                          {post.commentsCount > 1 || post.commentsCount === 0
                            ? "Comments"
                            : "Comment"}
                        </button>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="dropdown dropdown-top dropdown-end dropdown-hover">
                        {editable && !post.isScopePost ? (
                          <button
                            onClick={handleToggleIsPinned}
                            className={`btn btn-circle !btn-sm btn-ghost p-0 m-0 ${
                              isTogglePinLoading ? "loading" : ""
                            }`}
                          >
                            {!isTogglePinLoading && post.isPinned ? (
                              <StarIcon fill="white" stroke="white" />
                            ) : (
                              <StarIcon />
                            )}
                          </button>
                        ) : (
                          post.isPinned && <StarIcon fill="white" stroke="white" />
                        )}
                      </div>
                    </div>
                  </div>
                  <CommentsContainer post={post} isOpen={isCommentsOpen} editable={editable} />
                </>
              )}
            </>
          </>
        )}
      </Container>
    </>
  );
};

export default Post;
