import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "@/components/Page/Navbar";
import Footer from "@/components/Page/Footer";
import { useGeneralUi } from "@/providers/generalUi";

const PageLayout = ({
  bluredBackground,
  staticBackground,
  gradientBackground,
}: {
  bluredBackground?: boolean;
  staticBackground?: boolean;
  gradientBackground?: boolean;
}) => {
  const { useStaticBackground, useGradientBackground, useBluredBackground } = useGeneralUi();

  useEffect(() => {
    useStaticBackground(!!staticBackground);
    useGradientBackground(!!gradientBackground);
    useBluredBackground(!!bluredBackground);
  }, [staticBackground, gradientBackground, bluredBackground]);

  return (
    <>
      <div className="flex flex-col w-screen h-screen overflow-hidden">
        <header className="py-0">
          <Navbar />
        </header>
        <div className="flex-1 h-full overflow-hidden relative">
          <div className="absolute inset-0 w-full h-full overflow-hidden overflow-y-scroll scrollbar-hidden">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PageLayout;
