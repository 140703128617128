import React, { useEffect } from "react";
import { useScopeChat } from "@/providers/scopeChat";
import { useGeneralUi } from "@/providers/generalUi";

import MessageList from "../MessageList/MessageList";
import CenterSpinner from "@/components/UI/CenterSpinner";
import SidebarHeader from "./SidebarHeader";
import MobileHeader from "@/components/UI/MobileHeader";

const PinnedMessagesView = () => {
  const {
    pinnedMessages,
    handleShowMessage,
    isHandlingPinnedMessages,
    setIsPinnedMessagesVisible,
  } = useScopeChat();
  const { setHeader } = useGeneralUi();

  useEffect(() => {
    setHeader(
      <MobileHeader label={label} handleBackClick={() => setIsPinnedMessagesVisible(false)} />,
    );
  }, []);

  const label = `Pinned messages`;

  return (
    <>
      <SidebarHeader label={label} handleBackClick={() => setIsPinnedMessagesVisible(false)} />
      <div className="flex-1 flex flex-col justify-between overflow-hidden">
        <div className="flex-1 flex xl:p-4 w-full h-full overflow-hidden">
          {isHandlingPinnedMessages ? (
            <CenterSpinner />
          ) : (
            <MessageList
              groupedMessages={pinnedMessages}
              handleClickMessage={(message) => handleShowMessage(message)}
              pinnedView
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PinnedMessagesView;
