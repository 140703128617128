import React, { useRef } from "react";
import { useClickOutside } from "@/app/hooks";
import { batch } from "react-redux";
import { ImageIcon, RosterIcon, SpeakerIcon } from "@/components/UI/Icons";

import {
  ScopeChatAttachmentMenuType,
  ScopeChatMessageTypes,
} from "@/types/scopeChat/scopeChat.types";

const AttachmentMenu = ({
  onClickOutside,
  setType,
  handleAttachMedia,
}: {
  onClickOutside: () => void;
  setType: (mediaType: ScopeChatMessageTypes) => void;
  handleAttachMedia: () => void;
}) => {
  const menuRef = useRef<HTMLDivElement>(document.createElement("div"));

  useClickOutside(menuRef, onClickOutside);

  const handleAttach = (type: ScopeChatMessageTypes) => {
    batch(() => {
      onClickOutside();
      setType(type);
    });
  };

  const SCOPE_CHAT_ATTACHMENT_MENU: ScopeChatAttachmentMenuType = {
    [ScopeChatMessageTypes.MEDIA]: {
      icon: ImageIcon,
      label: "Media",
      value: ScopeChatMessageTypes.MEDIA,
      action: handleAttachMedia,
    },
    [ScopeChatMessageTypes.POLL]: {
      icon: RosterIcon,
      label: "Poll",
      value: ScopeChatMessageTypes.POLL,
      action: () => handleAttach(ScopeChatMessageTypes.POLL),
    },
    [ScopeChatMessageTypes.VOICE]: {
      icon: SpeakerIcon,
      label: "Voice",
      value: ScopeChatMessageTypes.VOICE,
      action: () => handleAttach(ScopeChatMessageTypes.VOICE),
    },
  };

  return (
    <div
      ref={menuRef}
      className="absolute right-0 bottom-[110%] z-50 w-full xl:w-[200px] flex flex-col rounded-xl border-[1.5px] border-neutral-white-12 bg-grayscale-1000 xl:bg-neutral-black-20 p-3"
    >
      {Object.keys(SCOPE_CHAT_ATTACHMENT_MENU).map((key) => {
        const menuKey = key as ScopeChatMessageTypes;
        const menuItem = SCOPE_CHAT_ATTACHMENT_MENU[menuKey];
        if (!menuItem) return;

        const Icon = menuItem.icon;
        return (
          <div
            key={key}
            className="cursor-pointer flex gap-2 rounded-lg px-4 py-2 items-center text-caps text-13 font-heavy text-neutral-white-40 hover:bg-neutral-white-5 hover:text-neutral-white"
            onClick={menuItem.action}
          >
            <Icon />
            {menuItem.label}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentMenu;
